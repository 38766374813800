export const Data = {
  "140 Udaipura": [
    {
      "PART NO": 1,
      "PART NAME": "MALAJHIR",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MALJHIR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 584,
      location: "22.962282,78.018012",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MALJHIR'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MALJHIR',22.962282,78.018012)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MALJHIR',22.962282,78.018012),",
    },
    {
      "PART NO": 2,
      "PART NAME": "PATHRAI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING PATHRAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 415,
      location: "22.95145,77.987962",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING PATHRAI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PATHRAI',22.95145,77.987962)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PATHRAI',22.95145,77.987962),",
    },
    {
      "PART NO": 3,
      "PART NAME": "GAJIKHEDI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING GAJIKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 726,
      location: "22.948237,78.052142",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING GAJIKHEDI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GAJIKHEDI',22.948237,78.052142)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GAJIKHEDI',22.948237,78.052142),",
    },
    {
      "PART NO": 4,
      "PART NAME": "SILGENA",
      "Name of polling station building":
        "EKIKRIT GOVT.MIDDLE SCHOOL NEW BUILDING SILGENA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 856,
      location: "22.952572,78.106067",
      "inverted one": "'EKIKRIT GOVT.MIDDLE SCHOOL NEW BUILDING SILGENA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL NEW BUILDING SILGENA',22.952572,78.106067)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL NEW BUILDING SILGENA',22.952572,78.106067),",
    },
    {
      "PART NO": 5,
      "PART NAME": "NANPON",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NONPON ( ADDITIONAL ROOM)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 640,
      location: "22.935804,78.052878",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NONPON ( ADDITIONAL ROOM)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NONPON ( ADDITIONAL ROOM)',22.935804,78.052878)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NONPON ( ADDITIONAL ROOM)',22.935804,78.052878),",
    },
    {
      "PART NO": 6,
      "PART NAME": "CHANDWAD",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING CHANDWAD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 588,
      location: "22.939541,78.05845",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING CHANDWAD'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHANDWAD',22.939541,78.05845)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHANDWAD',22.939541,78.05845),",
    },
    {
      "PART NO": 7,
      "PART NAME": "DIGAWAD",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DIGWAD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1135,
      location: "22.919961,78.030307",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DIGWAD'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DIGWAD',22.919961,78.030307)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DIGWAD',22.919961,78.030307),",
    },
    {
      "PART NO": 8,
      "PART NAME": "KUTANASIR",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KUTNASIR ROOM NO 2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 804,
      location: "22.906861,78.043305",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KUTNASIR ROOM NO 2'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KUTNASIR ROOM NO 2',22.906861,78.043305)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KUTNASIR ROOM NO 2',22.906861,78.043305),",
    },
    {
      "PART NO": 9,
      "PART NAME": "KUTANASIR",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KUTNASIR ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 798,
      location: "22.906857,78.043301",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KUTNASIR ROOM NO 1'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KUTNASIR ROOM NO 1',22.906857,78.043301)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KUTNASIR ROOM NO 1',22.906857,78.043301),",
    },
    {
      "PART NO": 10,
      "PART NAME": "SAGOUNIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SAGOUNIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 858,
      location: "22.886801,78.04699",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SAGOUNIYA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SAGOUNIYA',22.886801,78.04699)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SAGOUNIYA',22.886801,78.04699),",
    },
    {
      "PART NO": 11,
      "PART NAME": "KHANDAWAR",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHANDAWAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 774,
      location: "22.87306,78.028032",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHANDAWAR'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHANDAWAR',22.87306,78.028032)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHANDAWAR',22.87306,78.028032),",
    },
    {
      "PART NO": 12,
      "PART NAME": "SULTANNAGAR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING ADDITIONAL ROOM SULTANNAGAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 559,
      location: "22.866982,78.020363",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL BUILDING ADDITIONAL ROOM SULTANNAGAR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ADDITIONAL ROOM SULTANNAGAR',22.866982,78.020363)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ADDITIONAL ROOM SULTANNAGAR',22.866982,78.020363),",
    },
    {
      "PART NO": 13,
      "PART NAME": "BADODIYA KALA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BADODIYA KALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 628,
      location: "22.847553,78.042368",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BADODIYA KALAN'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BADODIYA KALAN',22.847553,78.042368)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BADODIYA KALAN',22.847553,78.042368),",
    },
    {
      "PART NO": 14,
      "PART NAME": "BHARKACHCHH KALAN",
      "Name of polling station building":
        "GOVT. GILRS HIGH SCHOOL NEW BUILDING BHARKACHCHH KALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1128,
      location: "22.821048,78.060134",
      "inverted one":
        "'GOVT. GILRS HIGH SCHOOL NEW BUILDING BHARKACHCHH KALAN'",
      cosolideted:
        "ElectionBooth('GOVT. GILRS HIGH SCHOOL NEW BUILDING BHARKACHCHH KALAN',22.821048,78.060134)",
      "with comma":
        "ElectionBooth('GOVT. GILRS HIGH SCHOOL NEW BUILDING BHARKACHCHH KALAN',22.821048,78.060134),",
    },
    {
      "PART NO": 15,
      "PART NAME": "BHARKACHCHH KALAN",
      "Name of polling station building":
        "GOVT. BOYS MIDDLE SCHOOL BUILDING BHARKACHCHH KALAN ROOM NO. .1 HALL (EAST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1233,
      location: "22.822398,78.064658",
      "inverted one":
        "'GOVT. BOYS MIDDLE SCHOOL BUILDING BHARKACHCHH KALAN ROOM NO. .1 HALL (EAST PART)'",
      cosolideted:
        "ElectionBooth('GOVT. BOYS MIDDLE SCHOOL BUILDING BHARKACHCHH KALAN ROOM NO. .1 HALL (EAST PART)',22.822398,78.064658)",
      "with comma":
        "ElectionBooth('GOVT. BOYS MIDDLE SCHOOL BUILDING BHARKACHCHH KALAN ROOM NO. .1 HALL (EAST PART)',22.822398,78.064658),",
    },
    {
      "PART NO": 16,
      "PART NAME": "BHARKACHCHH KALAN",
      "Name of polling station building":
        "GOVT. H.S.S. BUILDING BHARKACHCHH KALAN ROOM NO.5",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1285,
      location: "22.821278,78.05975",
      "inverted one": "'GOVT. H.S.S. BUILDING BHARKACHCHH KALAN ROOM NO.5'",
      cosolideted:
        "ElectionBooth('GOVT. H.S.S. BUILDING BHARKACHCHH KALAN ROOM NO.5',22.821278,78.05975)",
      "with comma":
        "ElectionBooth('GOVT. H.S.S. BUILDING BHARKACHCHH KALAN ROOM NO.5',22.821278,78.05975),",
    },
    {
      "PART NO": 17,
      "PART NAME": "GADARVAS",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GADARVAS (NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1121,
      location: "22.81130,78.073568",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GADARVAS (NORTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GADARVAS (NORTH PART)',22.81130,78.073568)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GADARVAS (NORTH PART)',22.81130,78.073568),",
    },
    {
      "PART NO": 18,
      "PART NAME": "GADARVAS",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GADARVAS (SOUTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 820,
      location: "22.811340,78.073572",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GADARVAS (SOUTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GADARVAS (SOUTH PART)',22.811340,78.073572)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GADARVAS (SOUTH PART)',22.811340,78.073572),",
    },
    {
      "PART NO": 19,
      "PART NAME": "GOURA MACHHWAI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GOURA MACHHWAI (ADDITIONAL ROOM)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 866,
      location: "22.818281,78.122084",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GOURA MACHHWAI (ADDITIONAL ROOM)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GOURA MACHHWAI (ADDITIONAL ROOM)',22.818281,78.122084)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GOURA MACHHWAI (ADDITIONAL ROOM)',22.818281,78.122084),",
    },
    {
      "PART NO": 20,
      "PART NAME": "GOURA MACHHWAI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GORA MACHHBAI (WEST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 631,
      location: "22.817845,78.120986",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GORA MACHHBAI (WEST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GORA MACHHBAI (WEST PART)',22.817845,78.120986)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GORA MACHHBAI (WEST PART)',22.817845,78.120986),",
    },
    {
      "PART NO": 21,
      "PART NAME": "BISER",
      "Name of polling station building": "GRAM PANCHAYAT BUILDING BISER",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 923,
      location: "22.84163,78.121598",
      "inverted one": "'GRAM PANCHAYAT BUILDING BISER'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING BISER',22.84163,78.121598)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING BISER',22.84163,78.121598),",
    },
    {
      "PART NO": 22,
      "PART NAME": "DEHRI KALA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DEHRI KALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1177,
      location: "22.875279,78.10958",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DEHRI KALA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DEHRI KALA',22.875279,78.10958)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DEHRI KALA',22.875279,78.10958),",
    },
    {
      "PART NO": 23,
      "PART NAME": "KISHANPUR",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KISHANPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 541,
      location: "22.848317,78.082844",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KISHANPUR'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KISHANPUR',22.848317,78.082844)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KISHANPUR',22.848317,78.082844),",
    },
    {
      "PART NO": 24,
      "PART NAME": "DOOMAR",
      "Name of polling station building": "AANGANWADI KENDRA BUILDING DOOMAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 518,
      location: "22.84667,78.041477",
      "inverted one": "'AANGANWADI KENDRA BUILDING DOOMAR'",
      cosolideted:
        "ElectionBooth('AANGANWADI KENDRA BUILDING DOOMAR',22.84667,78.041477)",
      "with comma":
        "ElectionBooth('AANGANWADI KENDRA BUILDING DOOMAR',22.84667,78.041477),",
    },
    {
      "PART NO": 25,
      "PART NAME": "KHAPARIYA KALAN",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHAPARIYA KALAN (EAST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 804,
      location: "22.870999,78.068083",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHAPARIYA KALAN (EAST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHAPARIYA KALAN (EAST PART)',22.870999,78.068083)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHAPARIYA KALAN (EAST PART)',22.870999,78.068083),",
    },
    {
      "PART NO": 26,
      "PART NAME": "KHAPARIYA KALAN",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL KHAPARIYA KALAN (PRIMARY DEPARTMENT BUILDING)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 888,
      location: "22.871017,78.06801",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL KHAPARIYA KALAN (PRIMARY DEPARTMENT BUILDING)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL KHAPARIYA KALAN (PRIMARY DEPARTMENT BUILDING)',22.871017,78.06801)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL KHAPARIYA KALAN (PRIMARY DEPARTMENT BUILDING)',22.871017,78.06801),",
    },
    {
      "PART NO": 27,
      "PART NAME": "GOOGALAWADA",
      "Name of polling station building":
        "GOVT. H.S. SCHOOL BUILDING GOOGALWADA (EAST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 953,
      location: "22.9014,78.07525",
      "inverted one": "'GOVT. H.S. SCHOOL BUILDING GOOGALWADA (EAST PART)'",
      cosolideted:
        "ElectionBooth('GOVT. H.S. SCHOOL BUILDING GOOGALWADA (EAST PART)',22.9014,78.07525)",
      "with comma":
        "ElectionBooth('GOVT. H.S. SCHOOL BUILDING GOOGALWADA (EAST PART)',22.9014,78.07525),",
    },
    {
      "PART NO": 28,
      "PART NAME": "GOOGALWADA",
      "Name of polling station building":
        "GOVT.H.S.SCHOOL BUILDING GOOGALWADA (WEST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 900,
      location: "22.90138,78.075244",
      "inverted one": "'GOVT.H.S.SCHOOL BUILDING GOOGALWADA (WEST PART)'",
      cosolideted:
        "ElectionBooth('GOVT.H.S.SCHOOL BUILDING GOOGALWADA (WEST PART)',22.90138,78.075244)",
      "with comma":
        "ElectionBooth('GOVT.H.S.SCHOOL BUILDING GOOGALWADA (WEST PART)',22.90138,78.075244),",
    },
    {
      "PART NO": 29,
      "PART NAME": "SHIVTALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SHIVTALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1248,
      location: "22.91382879,78.07501668",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SHIVTALA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SHIVTALA',22.91382879,78.07501668)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SHIVTALA',22.91382879,78.07501668),",
    },
    {
      "PART NO": 30,
      "PART NAME": "SEMRI KHOJRA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SEMRI KHOJRA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1161,
      location: "22.95008,78.084371",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SEMRI KHOJRA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SEMRI KHOJRA',22.95008,78.084371)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SEMRI KHOJRA',22.95008,78.084371),",
    },
    {
      "PART NO": 31,
      "PART NAME": "DOOBTALAI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DOOBTALAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 911,
      location: "22.963443,78.09457",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DOOBTALAI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DOOBTALAI',22.963443,78.09457)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DOOBTALAI',22.963443,78.09457),",
    },
    {
      "PART NO": 32,
      "PART NAME": "SAMNAPUR KACHHI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SAMNAPUR KACHHI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1157,
      location: "23.004695,78.138805",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SAMNAPUR KACHHI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SAMNAPUR KACHHI',23.004695,78.138805)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SAMNAPUR KACHHI',23.004695,78.138805),",
    },
    {
      "PART NO": 33,
      "PART NAME": "RANIPURA KHURD",
      "Name of polling station building":
        "GOVT.PRAIMARY SCHOOL BUILDING RANIPURA KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 477,
      location: "22.993392,78.106145",
      "inverted one": "'GOVT.PRAIMARY SCHOOL BUILDING RANIPURA KHURD'",
      cosolideted:
        "ElectionBooth('GOVT.PRAIMARY SCHOOL BUILDING RANIPURA KHURD',22.993392,78.106145)",
      "with comma":
        "ElectionBooth('GOVT.PRAIMARY SCHOOL BUILDING RANIPURA KHURD',22.993392,78.106145),",
    },
    {
      "PART NO": 34,
      "PART NAME": "MOKALWADA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MOKALWADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1094,
      location: "22.967174,78.130206",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MOKALWADA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MOKALWADA',22.967174,78.130206)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MOKALWADA',22.967174,78.130206),",
    },
    {
      "PART NO": 35,
      "PART NAME": "GHURELA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING GHURELA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 557,
      location: "22.9271,78.141732",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING GHURELA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GHURELA',22.9271,78.141732)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GHURELA',22.9271,78.141732),",
    },
    {
      "PART NO": 36,
      "PART NAME": "JAITPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING JAITPURA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 155,
      location: "22.939872,78.130592",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING JAITPURA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING JAITPURA',22.939872,78.130592)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING JAITPURA',22.939872,78.130592),",
    },
    {
      "PART NO": 37,
      "PART NAME": "PALKASHRI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDEL SCHOOL BUILDING PALKASHRI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1070,
      location: "22.9332659,78.1152833",
      "inverted one": "'EKIKRIT GOVT. MIDDEL SCHOOL BUILDING PALKASHRI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDEL SCHOOL BUILDING PALKASHRI',22.9332659,78.1152833)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDEL SCHOOL BUILDING PALKASHRI',22.9332659,78.1152833),",
    },
    {
      "PART NO": 38,
      "PART NAME": "JAMUNIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING JAMUNIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 936,
      location: "22.9068,78.114318",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING JAMUNIYA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING JAMUNIYA',22.9068,78.114318)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING JAMUNIYA',22.9068,78.114318),",
    },
    {
      "PART NO": 39,
      "PART NAME": "MANKAPUR",
      "Name of polling station building":
        "GOVT.BOYS PRIMARY SCHOOL BUILDING MANKAPUR ROOM NO..1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1234,
      location: "78.145721,22.910728",
      "inverted one": "'GOVT.BOYS PRIMARY SCHOOL BUILDING MANKAPUR ROOM NO..1'",
      cosolideted:
        "ElectionBooth('GOVT.BOYS PRIMARY SCHOOL BUILDING MANKAPUR ROOM NO..1',78.145721,22.910728)",
      "with comma":
        "ElectionBooth('GOVT.BOYS PRIMARY SCHOOL BUILDING MANKAPUR ROOM NO..1',78.145721,22.910728),",
    },
    {
      "PART NO": 40,
      "PART NAME": "MANKAPUR",
      "Name of polling station building":
        "GOVT. BOYS PRIMARY SCHOOL BUILDING MANKAPUR ROOM NO..2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 621,
      location: "78.145661,22.910552",
      "inverted one":
        "'GOVT. BOYS PRIMARY SCHOOL BUILDING MANKAPUR ROOM NO..2'",
      cosolideted:
        "ElectionBooth('GOVT. BOYS PRIMARY SCHOOL BUILDING MANKAPUR ROOM NO..2',78.145661,22.910552)",
      "with comma":
        "ElectionBooth('GOVT. BOYS PRIMARY SCHOOL BUILDING MANKAPUR ROOM NO..2',78.145661,22.910552),",
    },
    {
      "PART NO": 41,
      "PART NAME": "DHANDHALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DHANDHALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 608,
      location: "22.880488,78.129628",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DHANDHALA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DHANDHALA',22.880488,78.129628)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DHANDHALA',22.880488,78.129628),",
    },
    {
      "PART NO": 42,
      "PART NAME": "SANKHEDA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SANKHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 722,
      location: "22.853338,78.141738",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SANKHEDA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SANKHEDA',22.853338,78.141738)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SANKHEDA',22.853338,78.141738),",
    },
    {
      "PART NO": 43,
      "PART NAME": "BAMANWADA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BAMANWADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 673,
      location: "22.870264,78.156167",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BAMANWADA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAMANWADA',22.870264,78.156167)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAMANWADA',22.870264,78.156167),",
    },
    {
      "PART NO": 44,
      "PART NAME": "MOTALSIR",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MOTALSIR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 951,
      location: "22.906757,78.147425",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MOTALSIR'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MOTALSIR',22.906757,78.147425)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MOTALSIR',22.906757,78.147425),",
    },
    {
      "PART NO": 45,
      "PART NAME": "SEMRI GHAT",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING SEMRI GHAT",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1137,
      location: "22.90104,78.19535",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING SEMRI GHAT'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING SEMRI GHAT',22.90104,78.19535)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING SEMRI GHAT',22.90104,78.19535),",
    },
    {
      "PART NO": 46,
      "PART NAME": "BAGALWADA",
      "Name of polling station building": "GRAM PANCHAYAT BUILDING BAGALWADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1122,
      location: "22.89992,78.208476",
      "inverted one": "'GRAM PANCHAYAT BUILDING BAGALWADA'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING BAGALWADA',22.89992,78.208476)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING BAGALWADA',22.89992,78.208476),",
    },
    {
      "PART NO": 47,
      "PART NAME": "BAGALWADA",
      "Name of polling station building":
        "GOVT. GIRLS PRIMARY SCHOOL BUILDING BAGALWADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 841,
      location: "22.903411,78.212765",
      "inverted one": "'GOVT. GIRLS PRIMARY SCHOOL BUILDING BAGALWADA'",
      cosolideted:
        "ElectionBooth('GOVT. GIRLS PRIMARY SCHOOL BUILDING BAGALWADA',22.903411,78.212765)",
      "with comma":
        "ElectionBooth('GOVT. GIRLS PRIMARY SCHOOL BUILDING BAGALWADA',22.903411,78.212765),",
    },
    {
      "PART NO": 48,
      "PART NAME": "BHILADIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BHILADIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 824,
      location: "22.920804,78.24997",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BHILADIYA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BHILADIYA',22.920804,78.24997)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BHILADIYA',22.920804,78.24997),",
    },
    {
      "PART NO": 49,
      "PART NAME": "BHARKACHCHH KHURD",
      "Name of polling station building":
        "EKIKIRIT GOVT. MIDDLE SCHOOL BUILDING BHARKACHCHH KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 939,
      location: "22.947274,78.21505",
      "inverted one":
        "'EKIKIRIT GOVT. MIDDLE SCHOOL BUILDING BHARKACHCHH KHURD'",
      cosolideted:
        "ElectionBooth('EKIKIRIT GOVT. MIDDLE SCHOOL BUILDING BHARKACHCHH KHURD',22.947274,78.21505)",
      "with comma":
        "ElectionBooth('EKIKIRIT GOVT. MIDDLE SCHOOL BUILDING BHARKACHCHH KHURD',22.947274,78.21505),",
    },
    {
      "PART NO": 50,
      "PART NAME": "KANHAIYAPAR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KANHAIYAPAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 417,
      location: "22.959256,78.214151",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KANHAIYAPAR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KANHAIYAPAR',22.959256,78.214151)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KANHAIYAPAR',22.959256,78.214151),",
    },
    {
      "PART NO": 51,
      "PART NAME": "DIMADA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL DIMADA ROOM NO.5",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 639,
      location: "22.920049,78.192195",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL DIMADA ROOM NO.5'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL DIMADA ROOM NO.5',22.920049,78.192195)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL DIMADA ROOM NO.5',22.920049,78.192195),",
    },
    {
      "PART NO": 52,
      "PART NAME": "DIMADA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DIMADA ROOM NO. 3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 904,
      location: "22.920049,78.192195",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DIMADA ROOM NO. 3'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DIMADA ROOM NO. 3',22.920049,78.192195)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DIMADA ROOM NO. 3',22.920049,78.192195),",
    },
    {
      "PART NO": 53,
      "PART NAME": "AKOLA",
      "Name of polling station building": "GRAM PANCHAYAT BUILDING AKOLA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1052,
      location: "22.963403,78.193462",
      "inverted one": "'GRAM PANCHAYAT BUILDING AKOLA'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING AKOLA',22.963403,78.193462)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING AKOLA',22.963403,78.193462),",
    },
    {
      "PART NO": 54,
      "PART NAME": "SAMNAPUR JAGEER",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING SAMNAPUR JAGEER",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1081,
      location: "22.990707,78.186542",
      "inverted one": "'EKIKRIT GOVT. HIGH SCHOOL BUILDING SAMNAPUR JAGEER'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING SAMNAPUR JAGEER',22.990707,78.186542)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING SAMNAPUR JAGEER',22.990707,78.186542),",
    },
    {
      "PART NO": 55,
      "PART NAME": "DHANASHRI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING DHANASHRI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 626,
      location: "22.992179,78.206736",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING DHANASHRI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING DHANASHRI',22.992179,78.206736)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING DHANASHRI',22.992179,78.206736),",
    },
    {
      "PART NO": 56,
      "PART NAME": "KOTPAR GANESH",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KOTPAR GANESH (ADDITIONAL ROOM EAST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 746,
      location: "22.981149,78.222357",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KOTPAR GANESH (ADDITIONAL ROOM EAST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KOTPAR GANESH (ADDITIONAL ROOM EAST PART)',22.981149,78.222357)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KOTPAR GANESH (ADDITIONAL ROOM EAST PART)',22.981149,78.222357),",
    },
    {
      "PART NO": 57,
      "PART NAME": "KOTPAR GANESH",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KOTPAR GANESH ADDITIONAL ROOM (NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 644,
      location: "22.981133,78.222565",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KOTPAR GANESH ADDITIONAL ROOM (NORTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KOTPAR GANESH ADDITIONAL ROOM (NORTH PART)',22.981133,78.222565)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KOTPAR GANESH ADDITIONAL ROOM (NORTH PART)',22.981133,78.222565),",
    },
    {
      "PART NO": 58,
      "PART NAME": "GOL",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL BUILDING GOL",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 608,
      location: "22.969586,78.214809",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING GOL'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GOL',22.969586,78.214809)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GOL',22.969586,78.214809),",
    },
    {
      "PART NO": 59,
      "PART NAME": "BAMANWADA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BAMANWADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 461,
      location: "22.974756,78.216623",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BAMANWADA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAMANWADA',22.974756,78.216623)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAMANWADA',22.974756,78.216623),",
    },
    {
      "PART NO": 60,
      "PART NAME": "GURARIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GURARIYA (ADDITIONAL ROOM NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1306,
      location: "22.964704,78.253687",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GURARIYA (ADDITIONAL ROOM NORTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GURARIYA (ADDITIONAL ROOM NORTH PART)',22.964704,78.253687)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GURARIYA (ADDITIONAL ROOM NORTH PART)',22.964704,78.253687),",
    },
    {
      "PART NO": 61,
      "PART NAME": "GURARIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GURARIYA (ADDITIONAL ROOM SOUTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 741,
      location: "22.964697,78.253627",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GURARIYA (ADDITIONAL ROOM SOUTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GURARIYA (ADDITIONAL ROOM SOUTH PART)',22.964697,78.253627)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GURARIYA (ADDITIONAL ROOM SOUTH PART)',22.964697,78.253627),",
    },
    {
      "PART NO": 62,
      "PART NAME": "DHOUKHEDA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DHOUKHEDA (NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 729,
      location: "22.98999087,78.22152272",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DHOUKHEDA (NORTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DHOUKHEDA (NORTH PART)',22.98999087,78.22152272)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DHOUKHEDA (NORTH PART)',22.98999087,78.22152272),",
    },
    {
      "PART NO": 63,
      "PART NAME": "DHOUKHEDA",
      "Name of polling station building":
        "DEENDAYAL PARISAR GOVT. COLLEGE BARELI DHOUKHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 794,
      location: "22.99116164,78.22856642",
      "inverted one": "'DEENDAYAL PARISAR GOVT. COLLEGE BARELI DHOUKHEDA'",
      cosolideted:
        "ElectionBooth('DEENDAYAL PARISAR GOVT. COLLEGE BARELI DHOUKHEDA',22.99116164,78.22856642)",
      "with comma":
        "ElectionBooth('DEENDAYAL PARISAR GOVT. COLLEGE BARELI DHOUKHEDA',22.99116164,78.22856642),",
    },
    {
      "PART NO": 64,
      "PART NAME": "DHOUKHEDA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DHOUKHEDA (SOUTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 699,
      location: "22.990256,78.221674",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DHOUKHEDA (SOUTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DHOUKHEDA (SOUTH PART)',22.990256,78.221674)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DHOUKHEDA (SOUTH PART)',22.990256,78.221674),",
    },
    {
      "PART NO": 65,
      "PART NAME": "DHOUKHEDA",
      "Name of polling station building": "GRAM PANCHAYAT BUILDING DHOKHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 678,
      location: "22.99005702,78.2215488",
      "inverted one": "'GRAM PANCHAYAT BUILDING DHOKHEDA'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING DHOKHEDA',22.99005702,78.2215488)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING DHOKHEDA',22.99005702,78.2215488),",
    },
    {
      "PART NO": 66,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "GOVT. SENIOR BOYS EXCELLENT HOSTEL BARELI ROOM NO.1",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1057,
      location: "23.004616,78.232047",
      "inverted one": "'GOVT. SENIOR BOYS EXCELLENT HOSTEL BARELI ROOM NO.1'",
      cosolideted:
        "ElectionBooth('GOVT. SENIOR BOYS EXCELLENT HOSTEL BARELI ROOM NO.1',23.004616,78.232047)",
      "with comma":
        "ElectionBooth('GOVT. SENIOR BOYS EXCELLENT HOSTEL BARELI ROOM NO.1',23.004616,78.232047),",
    },
    {
      "PART NO": 67,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "GOVT. SENIOR EXCELLENT BOYS HOSTAL BARELI (PRACTICE ROOM)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 871,
      location: "23.004991,78.232764",
      "inverted one":
        "'GOVT. SENIOR EXCELLENT BOYS HOSTAL BARELI (PRACTICE ROOM)'",
      cosolideted:
        "ElectionBooth('GOVT. SENIOR EXCELLENT BOYS HOSTAL BARELI (PRACTICE ROOM)',23.004991,78.232764)",
      "with comma":
        "ElectionBooth('GOVT. SENIOR EXCELLENT BOYS HOSTAL BARELI (PRACTICE ROOM)',23.004991,78.232764),",
    },
    {
      "PART NO": 68,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "GOVT. VETERINARY HOSPITAL BUILDING (EAST PART) BARELI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1010,
      location: "23.006286,78.229921",
      "inverted one": "'GOVT. VETERINARY HOSPITAL BUILDING (EAST PART) BARELI'",
      cosolideted:
        "ElectionBooth('GOVT. VETERINARY HOSPITAL BUILDING (EAST PART) BARELI',23.006286,78.229921)",
      "with comma":
        "ElectionBooth('GOVT. VETERINARY HOSPITAL BUILDING (EAST PART) BARELI',23.006286,78.229921),",
    },
    {
      "PART NO": 69,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "GOVT. VETERINARY HOSPITAL BUILDING TREATMENT ROOM NO 1 BARELI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 626,
      location: "23.006216,78.230074",
      "inverted one":
        "'GOVT. VETERINARY HOSPITAL BUILDING TREATMENT ROOM NO 1 BARELI'",
      cosolideted:
        "ElectionBooth('GOVT. VETERINARY HOSPITAL BUILDING TREATMENT ROOM NO 1 BARELI',23.006216,78.230074)",
      "with comma":
        "ElectionBooth('GOVT. VETERINARY HOSPITAL BUILDING TREATMENT ROOM NO 1 BARELI',23.006216,78.230074),",
    },
    {
      "PART NO": 70,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "GOVT. VETERINARY HOSPITAL OFFICE (SOUTH PART) BUILDING BARELI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 674,
      location: "23.005291,78.233068",
      "inverted one":
        "'GOVT. VETERINARY HOSPITAL OFFICE (SOUTH PART) BUILDING BARELI'",
      cosolideted:
        "ElectionBooth('GOVT. VETERINARY HOSPITAL OFFICE (SOUTH PART) BUILDING BARELI',23.005291,78.233068)",
      "with comma":
        "ElectionBooth('GOVT. VETERINARY HOSPITAL OFFICE (SOUTH PART) BUILDING BARELI',23.005291,78.233068),",
    },
    {
      "PART NO": 71,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "GOVT. VETERINARY HOSPITAL BUILDING CHIKITSA ROOM NO.2 BARELI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 672,
      location: "23.006308,78.230019",
      "inverted one":
        "'GOVT. VETERINARY HOSPITAL BUILDING CHIKITSA ROOM NO.2 BARELI'",
      cosolideted:
        "ElectionBooth('GOVT. VETERINARY HOSPITAL BUILDING CHIKITSA ROOM NO.2 BARELI',23.006308,78.230019)",
      "with comma":
        "ElectionBooth('GOVT. VETERINARY HOSPITAL BUILDING CHIKITSA ROOM NO.2 BARELI',23.006308,78.230019),",
    },
    {
      "PART NO": 72,
      "PART NAME": "SULTANPUR KHEDA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SULTANPUR KHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 553,
      location: "23.011135,78.218297",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SULTANPUR KHEDA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SULTANPUR KHEDA',23.011135,78.218297)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SULTANPUR KHEDA',23.011135,78.218297),",
    },
    {
      "PART NO": 73,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "GOVT. MAHAVIDHYALAYIN SC.BOYS HOSTAL BARELI (NORTH PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1107,
      location: "23.004931,78.232686",
      "inverted one":
        "'GOVT. MAHAVIDHYALAYIN SC.BOYS HOSTAL BARELI (NORTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT. MAHAVIDHYALAYIN SC.BOYS HOSTAL BARELI (NORTH PART)',23.004931,78.232686)",
      "with comma":
        "ElectionBooth('GOVT. MAHAVIDHYALAYIN SC.BOYS HOSTAL BARELI (NORTH PART)',23.004931,78.232686),",
    },
    {
      "PART NO": 74,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "GOVT.MAHAVIDHALAYIN SC BOYS HOSTAL BARELI (WEST PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 603,
      location: "23.005291,78.233068",
      "inverted one": "'GOVT.MAHAVIDHALAYIN SC BOYS HOSTAL BARELI (WEST PART)'",
      cosolideted:
        "ElectionBooth('GOVT.MAHAVIDHALAYIN SC BOYS HOSTAL BARELI (WEST PART)',23.005291,78.233068)",
      "with comma":
        "ElectionBooth('GOVT.MAHAVIDHALAYIN SC BOYS HOSTAL BARELI (WEST PART)',23.005291,78.233068),",
    },
    {
      "PART NO": 75,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "EKIKRIT GOVT.GIRLS H.S.SCHOOL BARELI (EAST PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1047,
      location: "23.004647,78.232324",
      "inverted one": "'EKIKRIT GOVT.GIRLS H.S.SCHOOL BARELI (EAST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.GIRLS H.S.SCHOOL BARELI (EAST PART)',23.004647,78.232324)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.GIRLS H.S.SCHOOL BARELI (EAST PART)',23.004647,78.232324),",
    },
    {
      "PART NO": 76,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "EKIKRIT GOVT. GIRLS H.S.SCHOOL BARELI (LABORATORY ROOM)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1080,
      location: "23.004812,78.232326",
      "inverted one":
        "'EKIKRIT GOVT. GIRLS H.S.SCHOOL BARELI (LABORATORY ROOM)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. GIRLS H.S.SCHOOL BARELI (LABORATORY ROOM)',23.004812,78.232326)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. GIRLS H.S.SCHOOL BARELI (LABORATORY ROOM)',23.004812,78.232326),",
    },
    {
      "PART NO": 77,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "EKIKRIT GOVT.GIRLS H.S.SCHOOL BARELI (WEST PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1007,
      location: "23.00471,78.232123",
      "inverted one": "'EKIKRIT GOVT.GIRLS H.S.SCHOOL BARELI (WEST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.GIRLS H.S.SCHOOL BARELI (WEST PART)',23.00471,78.232123)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.GIRLS H.S.SCHOOL BARELI (WEST PART)',23.00471,78.232123),",
    },
    {
      "PART NO": 78,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "EKIKRIT GOVT.GIRLS H.S.SCHOOL BARELI (JANSHIKSHA KENDRA ROOM)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 624,
      location: "23.00451,78.232039",
      "inverted one":
        "'EKIKRIT GOVT.GIRLS H.S.SCHOOL BARELI (JANSHIKSHA KENDRA ROOM)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.GIRLS H.S.SCHOOL BARELI (JANSHIKSHA KENDRA ROOM)',23.00451,78.232039)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.GIRLS H.S.SCHOOL BARELI (JANSHIKSHA KENDRA ROOM)',23.00451,78.232039),",
    },
    {
      "PART NO": 79,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "KRISHI UPAJ MANDI OFFICE BUILDING BARELI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1133,
      location: "22.993085,78.231259",
      "inverted one": "'KRISHI UPAJ MANDI OFFICE BUILDING BARELI'",
      cosolideted:
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE BUILDING BARELI',22.993085,78.231259)",
      "with comma":
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE BUILDING BARELI',22.993085,78.231259),",
    },
    {
      "PART NO": 80,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "KRISHI UPAJ MANDI OFFICE MEETING HALL BARELI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1075,
      location: "22.99305,78.231426",
      "inverted one": "'KRISHI UPAJ MANDI OFFICE MEETING HALL BARELI'",
      cosolideted:
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE MEETING HALL BARELI',22.99305,78.231426)",
      "with comma":
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE MEETING HALL BARELI',22.99305,78.231426),",
    },
    {
      "PART NO": 81,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARNA BARELI HEAD MASTER ROOM",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1132,
      location: "22.999802,78.234985",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARNA BARELI HEAD MASTER ROOM'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARNA BARELI HEAD MASTER ROOM',22.999802,78.234985)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARNA BARELI HEAD MASTER ROOM',22.999802,78.234985),",
    },
    {
      "PART NO": 82,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARNA BARELI (WEST PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 817,
      location: "22.999762,78.235051",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARNA BARELI (WEST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARNA BARELI (WEST PART)',22.999762,78.235051)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARNA BARELI (WEST PART)',22.999762,78.235051),",
    },
    {
      "PART NO": 83,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "GOVT. BOYS H.S.SCHOOL BUILDING BARELI (NORTH PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1367,
      location: "22.99305,78.231426",
      "inverted one": "'GOVT. BOYS H.S.SCHOOL BUILDING BARELI (NORTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT. BOYS H.S.SCHOOL BUILDING BARELI (NORTH PART)',22.99305,78.231426)",
      "with comma":
        "ElectionBooth('GOVT. BOYS H.S.SCHOOL BUILDING BARELI (NORTH PART)',22.99305,78.231426),",
    },
    {
      "PART NO": 84,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "GOVT.BOYS H.S.SCHOOL BUILDING BARELI (SOUTH PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1128,
      location: "23.002458,78.230771",
      "inverted one": "'GOVT.BOYS H.S.SCHOOL BUILDING BARELI (SOUTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT.BOYS H.S.SCHOOL BUILDING BARELI (SOUTH PART)',23.002458,78.230771)",
      "with comma":
        "ElectionBooth('GOVT.BOYS H.S.SCHOOL BUILDING BARELI (SOUTH PART)',23.002458,78.230771),",
    },
    {
      "PART NO": 85,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "GOVT. BOYS H.S.SCHOOL BUILDING BARELI (MAIN GATE PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1371,
      location: "23.002945,78.231413",
      "inverted one":
        "'GOVT. BOYS H.S.SCHOOL BUILDING BARELI (MAIN GATE PART)'",
      cosolideted:
        "ElectionBooth('GOVT. BOYS H.S.SCHOOL BUILDING BARELI (MAIN GATE PART)',23.002945,78.231413)",
      "with comma":
        "ElectionBooth('GOVT. BOYS H.S.SCHOOL BUILDING BARELI (MAIN GATE PART)',23.002945,78.231413),",
    },
    {
      "PART NO": 86,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "OFFICE GOVT. AYURVEDIC HOSPITAL BUILDING BARELI (NORTH PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 683,
      location: "23.001297,78.22834",
      "inverted one":
        "'OFFICE GOVT. AYURVEDIC HOSPITAL BUILDING BARELI (NORTH PART)'",
      cosolideted:
        "ElectionBooth('OFFICE GOVT. AYURVEDIC HOSPITAL BUILDING BARELI (NORTH PART)',23.001297,78.22834)",
      "with comma":
        "ElectionBooth('OFFICE GOVT. AYURVEDIC HOSPITAL BUILDING BARELI (NORTH PART)',23.001297,78.22834),",
    },
    {
      "PART NO": 87,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "M.P.ELECTRIC OFFICE COMPLENT ROOM POWAR HOUSE ROAD BARELI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 604,
      location: "23.003605,78.230239",
      "inverted one":
        "'M.P.ELECTRIC OFFICE COMPLENT ROOM POWAR HOUSE ROAD BARELI'",
      cosolideted:
        "ElectionBooth('M.P.ELECTRIC OFFICE COMPLENT ROOM POWAR HOUSE ROAD BARELI',23.003605,78.230239)",
      "with comma":
        "ElectionBooth('M.P.ELECTRIC OFFICE COMPLENT ROOM POWAR HOUSE ROAD BARELI',23.003605,78.230239),",
    },
    {
      "PART NO": 88,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "GOVT. AYURVEDIC HOSPITAL OFFICE BUILDING BARELI (SOUTH PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1151,
      location: "23.001299,78.228342",
      "inverted one":
        "'GOVT. AYURVEDIC HOSPITAL OFFICE BUILDING BARELI (SOUTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT. AYURVEDIC HOSPITAL OFFICE BUILDING BARELI (SOUTH PART)',23.001299,78.228342)",
      "with comma":
        "ElectionBooth('GOVT. AYURVEDIC HOSPITAL OFFICE BUILDING BARELI (SOUTH PART)',23.001299,78.228342),",
    },
    {
      "PART NO": 89,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "EKIKRIT GOVT.BOYS MIDDLE SCHOOL BUILDING BARELI ADDITIONAL ROOM",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 826,
      location: "23.003719,78.230181",
      "inverted one":
        "'EKIKRIT GOVT.BOYS MIDDLE SCHOOL BUILDING BARELI ADDITIONAL ROOM'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.BOYS MIDDLE SCHOOL BUILDING BARELI ADDITIONAL ROOM',23.003719,78.230181)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.BOYS MIDDLE SCHOOL BUILDING BARELI ADDITIONAL ROOM',23.003719,78.230181),",
    },
    {
      "PART NO": 90,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "EKIKRIT GOVT. BOYS MIDDLE SCHOOL BUILDING BARELI ROOM NO. 3",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1474,
      location: "23.003646,78.230217",
      "inverted one":
        "'EKIKRIT GOVT. BOYS MIDDLE SCHOOL BUILDING BARELI ROOM NO. 3'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. BOYS MIDDLE SCHOOL BUILDING BARELI ROOM NO. 3',23.003646,78.230217)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. BOYS MIDDLE SCHOOL BUILDING BARELI ROOM NO. 3',23.003646,78.230217),",
    },
    {
      "PART NO": 91,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOUDHARI CHOK BARELI (EAST PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1007,
      location: "23.00467,78.225124",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOUDHARI CHOK BARELI (EAST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOUDHARI CHOK BARELI (EAST PART)',23.00467,78.225124)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOUDHARI CHOK BARELI (EAST PART)',23.00467,78.225124),",
    },
    {
      "PART NO": 92,
      "PART NAME": "BARELI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOUDHRI CHOK BARELI (NORTH PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1137,
      location: "23.006216,78.230074",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOUDHRI CHOK BARELI (NORTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOUDHRI CHOK BARELI (NORTH PART)',23.006216,78.230074)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOUDHRI CHOK BARELI (NORTH PART)',23.006216,78.230074),",
    },
    {
      "PART NO": 93,
      "PART NAME": "KAMATONE",
      "Name of polling station building":
        "EKIKRIT GOVT. NEW MIDDLE SCHOOL BUILDING KAMTONE (NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 788,
      location: "23.020469,78.234904",
      "inverted one":
        "'EKIKRIT GOVT. NEW MIDDLE SCHOOL BUILDING KAMTONE (NORTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. NEW MIDDLE SCHOOL BUILDING KAMTONE (NORTH PART)',23.020469,78.234904)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. NEW MIDDLE SCHOOL BUILDING KAMTONE (NORTH PART)',23.020469,78.234904),",
    },
    {
      "PART NO": 94,
      "PART NAME": "KAMTONE",
      "Name of polling station building":
        "GOVT. GIRLS PRIMARY SCHOOL BUILDING KAMTONE (WEST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 776,
      location: "23.020595,78.236913",
      "inverted one":
        "'GOVT. GIRLS PRIMARY SCHOOL BUILDING KAMTONE (WEST PART)'",
      cosolideted:
        "ElectionBooth('GOVT. GIRLS PRIMARY SCHOOL BUILDING KAMTONE (WEST PART)',23.020595,78.236913)",
      "with comma":
        "ElectionBooth('GOVT. GIRLS PRIMARY SCHOOL BUILDING KAMTONE (WEST PART)',23.020595,78.236913),",
    },
    {
      "PART NO": 95,
      "PART NAME": "KAMATONE",
      "Name of polling station building":
        "EKIKRIT GOVT. NEW MIDDLE SCHOOL BUILDING KAMTONE (WEST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 869,
      location: "23.020523,78.234829",
      "inverted one":
        "'EKIKRIT GOVT. NEW MIDDLE SCHOOL BUILDING KAMTONE (WEST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. NEW MIDDLE SCHOOL BUILDING KAMTONE (WEST PART)',23.020523,78.234829)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. NEW MIDDLE SCHOOL BUILDING KAMTONE (WEST PART)',23.020523,78.234829),",
    },
    {
      "PART NO": 96,
      "PART NAME": "KAMATONE",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING KAMTONE",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 782,
      location: "23.022385,78.237468",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KAMTONE'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KAMTONE',23.022385,78.237468)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KAMTONE',23.022385,78.237468),",
    },
    {
      "PART NO": 97,
      "PART NAME": "KAMATONE",
      "Name of polling station building":
        "EKIKRIT GOVT. NEW MIDDLE SCHOOL BUILDING KAMTONE (EAST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1096,
      location: "23.020494,78.2348",
      "inverted one":
        "'EKIKRIT GOVT. NEW MIDDLE SCHOOL BUILDING KAMTONE (EAST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. NEW MIDDLE SCHOOL BUILDING KAMTONE (EAST PART)',23.020494,78.2348)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. NEW MIDDLE SCHOOL BUILDING KAMTONE (EAST PART)',23.020494,78.2348),",
    },
    {
      "PART NO": 98,
      "PART NAME": "JANAKPUR",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING JANAKPUR ADDITIONAL ROOM",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 644,
      location: "23.020591,78.22753",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING JANAKPUR ADDITIONAL ROOM'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING JANAKPUR ADDITIONAL ROOM',23.020591,78.22753)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING JANAKPUR ADDITIONAL ROOM',23.020591,78.22753),",
    },
    {
      "PART NO": 99,
      "PART NAME": "MEHRAGAON KALAN",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MEHRAGAON KALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1172,
      location: "23.050404,78.23462",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MEHRAGAON KALAN'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MEHRAGAON KALAN',23.050404,78.23462)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MEHRAGAON KALAN',23.050404,78.23462),",
    },
    {
      "PART NO": 100,
      "PART NAME": "KHAPARIYA KHURD",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING. KHAPARIYA KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 847,
      location: "23.0413,78.208765",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING. KHAPARIYA KHURD'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING. KHAPARIYA KHURD',23.0413,78.208765)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING. KHAPARIYA KHURD',23.0413,78.208765),",
    },
    {
      "PART NO": 101,
      "PART NAME": "UDAYGIRI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL NEW BUILDING UDAYGIRI ROOM NO. .1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 761,
      location: "23.075019,78.201148",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL NEW BUILDING UDAYGIRI ROOM NO. .1'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL NEW BUILDING UDAYGIRI ROOM NO. .1',23.075019,78.201148)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL NEW BUILDING UDAYGIRI ROOM NO. .1',23.075019,78.201148),",
    },
    {
      "PART NO": 102,
      "PART NAME": "UDAYGIRI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL NEW BUILDING UADAYGIRI ROOM NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 897,
      location: "23.072752,78.203692",
      "inverted one": "'GOVT. PRIMARY SCHOOL NEW BUILDING UADAYGIRI ROOM NO.2'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL NEW BUILDING UADAYGIRI ROOM NO.2',23.072752,78.203692)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL NEW BUILDING UADAYGIRI ROOM NO.2',23.072752,78.203692),",
    },
    {
      "PART NO": 103,
      "PART NAME": "PANJRA KASHIRAM",
      "Name of polling station building":
        "GRAM PANCHAYAT BUILDING PANJARA KASHIRAM",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 596,
      location: "23.073814,78.225845",
      "inverted one": "'GRAM PANCHAYAT BUILDING PANJARA KASHIRAM'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING PANJARA KASHIRAM',23.073814,78.225845)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING PANJARA KASHIRAM',23.073814,78.225845),",
    },
    {
      "PART NO": 104,
      "PART NAME": "SEMRI KHOOBCHAND",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SEMRI KHOOBCHAND",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 656,
      location: "23.084616,78.233999",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SEMRI KHOOBCHAND'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SEMRI KHOOBCHAND',23.084616,78.233999)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SEMRI KHOOBCHAND',23.084616,78.233999),",
    },
    {
      "PART NO": 105,
      "PART NAME": "BHAGDEI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING BHAGDEI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1001,
      location: "23.107325,78.256173",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING BHAGDEI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BHAGDEI',23.107325,78.256173)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BHAGDEI',23.107325,78.256173),",
    },
    {
      "PART NO": 106,
      "PART NAME": "JAMGARH",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING JAMGARH (NORTH PART) ROOM NO. 5",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 599,
      location: "23.109313,78.265647",
      "inverted one":
        "'EKIKRIT GOVT. HIGH SCHOOL BUILDING JAMGARH (NORTH PART) ROOM NO. 5'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING JAMGARH (NORTH PART) ROOM NO. 5',23.109313,78.265647)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING JAMGARH (NORTH PART) ROOM NO. 5',23.109313,78.265647),",
    },
    {
      "PART NO": 107,
      "PART NAME": "JAMGARH",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING JAMGARH LABORATORY ROOM",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 880,
      location: "23.109313,78.265647",
      "inverted one":
        "'EKIKRIT GOVT. HIGH SCHOOL BUILDING JAMGARH LABORATORY ROOM'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING JAMGARH LABORATORY ROOM',23.109313,78.265647)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING JAMGARH LABORATORY ROOM',23.109313,78.265647),",
    },
    {
      "PART NO": 108,
      "PART NAME": "UNTIYA KHURD",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING UNTIYA KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 848,
      location: "23.074364,78.267753",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING UNTIYA KHURD'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING UNTIYA KHURD',23.074364,78.267753)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING UNTIYA KHURD',23.074364,78.267753),",
    },
    {
      "PART NO": 109,
      "PART NAME": "SILWAH",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SILWAH",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 711,
      location: "23.056655,78.267436",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SILWAH'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SILWAH',23.056655,78.267436)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SILWAH',23.056655,78.267436),",
    },
    {
      "PART NO": 110,
      "PART NAME": "SINWAH",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SINWAH",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 196,
      location: "23.058998,78.236756",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SINWAH'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SINWAH',23.058998,78.236756)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SINWAH',23.058998,78.236756),",
    },
    {
      "PART NO": 111,
      "PART NAME": "PIPARIYA KARAN SINGH",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPARIYA KARAN SINGH",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1044,
      location: "23.044962,78.254714",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPARIYA KARAN SINGH'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPARIYA KARAN SINGH',23.044962,78.254714)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPARIYA KARAN SINGH',23.044962,78.254714),",
    },
    {
      "PART NO": 112,
      "PART NAME": "KINGI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KINGI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 652,
      location: "23.05874588,78.163744588",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KINGI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KINGI',23.05874588,78.163744588)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KINGI',23.05874588,78.163744588),",
    },
    {
      "PART NO": 113,
      "PART NAME": "NAYAGAON KALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING NAYAGAON KALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 751,
      location: "22.989994,78.27039",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING NAYAGAON KALA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NAYAGAON KALA',22.989994,78.27039)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NAYAGAON KALA',22.989994,78.27039),",
    },
    {
      "PART NO": 114,
      "PART NAME": "NAYAGAON KALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING ADDITIONAL ROOM NAYAGAON KALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 745,
      location: "22.990056,78.270438",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL BUILDING ADDITIONAL ROOM NAYAGAON KALA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ADDITIONAL ROOM NAYAGAON KALA',22.990056,78.270438)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ADDITIONAL ROOM NAYAGAON KALA',22.990056,78.270438),",
    },
    {
      "PART NO": 115,
      "PART NAME": "MAHESHWAR",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING MAHESHWAR ROOM NO. 2 (NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 669,
      location: "22.981786,78.290816",
      "inverted one":
        "'EKIKRIT GOVT. HIGH SCHOOL BUILDING MAHESHWAR ROOM NO. 2 (NORTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING MAHESHWAR ROOM NO. 2 (NORTH PART)',22.981786,78.290816)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING MAHESHWAR ROOM NO. 2 (NORTH PART)',22.981786,78.290816),",
    },
    {
      "PART NO": 116,
      "PART NAME": "MAHESHWAR",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING MAHESHWAR ROOM NO.1 (OLD GIRLS SCHOOL)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 854,
      location: "22.982,78.290829",
      "inverted one":
        "'EKIKRIT GOVT. HIGH SCHOOL BUILDING MAHESHWAR ROOM NO.1 (OLD GIRLS SCHOOL)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING MAHESHWAR ROOM NO.1 (OLD GIRLS SCHOOL)',22.982,78.290829)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING MAHESHWAR ROOM NO.1 (OLD GIRLS SCHOOL)',22.982,78.290829),",
    },
    {
      "PART NO": 117,
      "PART NAME": "MAHESHWAR",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING MAHESHWAR ROOM NO.3 (SOUTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 894,
      location: "22.981909,78.290829",
      "inverted one":
        "'EKIKRIT GOVT. HIGH SCHOOL BUILDING MAHESHWAR ROOM NO.3 (SOUTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING MAHESHWAR ROOM NO.3 (SOUTH PART)',22.981909,78.290829)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING MAHESHWAR ROOM NO.3 (SOUTH PART)',22.981909,78.290829),",
    },
    {
      "PART NO": 118,
      "PART NAME": "MAGARDHA",
      "Name of polling station building":
        "GOVT.GIRLS PRIMARY SCHOOL BUILDING MAGARDHA (NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1120,
      location: "22.929315,78.282673",
      "inverted one":
        "'GOVT.GIRLS PRIMARY SCHOOL BUILDING MAGARDHA (NORTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT.GIRLS PRIMARY SCHOOL BUILDING MAGARDHA (NORTH PART)',22.929315,78.282673)",
      "with comma":
        "ElectionBooth('GOVT.GIRLS PRIMARY SCHOOL BUILDING MAGARDHA (NORTH PART)',22.929315,78.282673),",
    },
    {
      "PART NO": 119,
      "PART NAME": "MAGARDHA",
      "Name of polling station building":
        "EKIKRIT GOVT.MIDDLE SCHOOL BUILDING MAGARDHA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 688,
      location: "22.967324,78.280894",
      "inverted one": "'EKIKRIT GOVT.MIDDLE SCHOOL BUILDING MAGARDHA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING MAGARDHA',22.967324,78.280894)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING MAGARDHA',22.967324,78.280894),",
    },
    {
      "PART NO": 120,
      "PART NAME": "UMARIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING.UAMARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 439,
      location: "22.938086,78.276248",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING.UAMARIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.UAMARIYA',22.938086,78.276248)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.UAMARIYA',22.938086,78.276248),",
    },
    {
      "PART NO": 121,
      "PART NAME": "PANJRA VIJAY SINGH",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PANJRA VIJAY SINGH",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 358,
      location: "22.928854,78.268374",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PANJRA VIJAY SINGH'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PANJRA VIJAY SINGH',22.928854,78.268374)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PANJRA VIJAY SINGH',22.928854,78.268374),",
    },
    {
      "PART NO": 122,
      "PART NAME": "SATARAWAN",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SATARAWAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 725,
      location: "22.884504,78.228628",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SATARAWAN'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SATARAWAN',22.884504,78.228628)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SATARAWAN',22.884504,78.228628),",
    },
    {
      "PART NO": 123,
      "PART NAME": "GOPALPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING GOPALPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 464,
      location: "22.901582,78.248001",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING GOPALPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GOPALPUR',22.901582,78.248001)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GOPALPUR',22.901582,78.248001),",
    },
    {
      "PART NO": 124,
      "PART NAME": "DOOMAR",
      "Name of polling station building":
        "GOVT. NEW HIGH SCHOOL BUILDING DOOMAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1269,
      location: "22.870027,78.244945",
      "inverted one": "'GOVT. NEW HIGH SCHOOL BUILDING DOOMAR'",
      cosolideted:
        "ElectionBooth('GOVT. NEW HIGH SCHOOL BUILDING DOOMAR',22.870027,78.244945)",
      "with comma":
        "ElectionBooth('GOVT. NEW HIGH SCHOOL BUILDING DOOMAR',22.870027,78.244945),",
    },
    {
      "PART NO": 125,
      "PART NAME": "GHAT PIPARIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GHAT PIPARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 802,
      location: "22.886725,78.289246",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GHAT PIPARIYA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GHAT PIPARIYA',22.886725,78.289246)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GHAT PIPARIYA',22.886725,78.289246),",
    },
    {
      "PART NO": 126,
      "PART NAME": "SOUJANI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SOUJANI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1069,
      location: "22.897027,78.297148",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SOUJANI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SOUJANI',22.897027,78.297148)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SOUJANI',22.897027,78.297148),",
    },
    {
      "PART NO": 127,
      "PART NAME": "PROHIT PIPARIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PROHIT PIPARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 776,
      location: "22.917006,78.279154",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PROHIT PIPARIYA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PROHIT PIPARIYA',22.917006,78.279154)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PROHIT PIPARIYA',22.917006,78.279154),",
    },
    {
      "PART NO": 128,
      "PART NAME": "SARRA",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL BUILDING SARRA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 923,
      location: "22.923185,78.323589",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SARRA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SARRA',22.923185,78.323589)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SARRA',22.923185,78.323589),",
    },
    {
      "PART NO": 129,
      "PART NAME": "KHANDRAJ",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHANDRAJ",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1028,
      location: "22.934733,78.276623",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHANDRAJ'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHANDRAJ',22.934733,78.276623)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHANDRAJ',22.934733,78.276623),",
    },
    {
      "PART NO": 130,
      "PART NAME": "BAMHORI PANDA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BAMHORI PANDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 796,
      location: "22.956873,78.307336",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BAMHORI PANDA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAMHORI PANDA',22.956873,78.307336)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAMHORI PANDA',22.956873,78.307336),",
    },
    {
      "PART NO": 131,
      "PART NAME": "SEERAWADA MURALIDHAR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SEERAWADA MURLIDHAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 564,
      location: "22.950652,78.300194",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SEERAWADA MURLIDHAR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SEERAWADA MURLIDHAR',22.950652,78.300194)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SEERAWADA MURLIDHAR',22.950652,78.300194),",
    },
    {
      "PART NO": 132,
      "PART NAME": "MANGROL",
      "Name of polling station building": "GOVT.HIGH SCHOOL BUILDING MANGROL",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 715,
      location: "22.923126,78.330317",
      "inverted one": "'GOVT.HIGH SCHOOL BUILDING MANGROL'",
      cosolideted:
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING MANGROL',22.923126,78.330317)",
      "with comma":
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING MANGROL',22.923126,78.330317),",
    },
    {
      "PART NO": 133,
      "PART NAME": "MANGROL",
      "Name of polling station building": "GRAM PANCHAYAT BUILDING MANGROL",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 690,
      location: "22.92305,78.330367",
      "inverted one": "'GRAM PANCHAYAT BUILDING MANGROL'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING MANGROL',22.92305,78.330367)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING MANGROL',22.92305,78.330367),",
    },
    {
      "PART NO": 134,
      "PART NAME": "KOTAPAR MAHANT",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING.KOTPAR MAHANT",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 579,
      location: "22.925673,78.355177",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING.KOTPAR MAHANT'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.KOTPAR MAHANT',22.925673,78.355177)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.KOTPAR MAHANT',22.925673,78.355177),",
    },
    {
      "PART NO": 135,
      "PART NAME": "ALIGANJ",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING ALIGANJ (EAST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 978,
      location: "22.932067,78.3626015",
      "inverted one":
        "'EKIKRIT GOVT. HIGH SCHOOL BUILDING ALIGANJ (EAST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING ALIGANJ (EAST PART)',22.932067,78.3626015)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING ALIGANJ (EAST PART)',22.932067,78.3626015),",
    },
    {
      "PART NO": 136,
      "PART NAME": "ALIGANJ",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING ALIGANJ (WEST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 810,
      location: "22.931966,78.36268",
      "inverted one":
        "'EKIKRIT GOVT. HIGH SCHOOL BUILDING ALIGANJ (WEST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING ALIGANJ (WEST PART)',22.931966,78.36268)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING ALIGANJ (WEST PART)',22.931966,78.36268),",
    },
    {
      "PART NO": 137,
      "PART NAME": "SIVNI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING. SIVNI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 527,
      location: "22.942053,78.37371",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING. SIVNI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. SIVNI',22.942053,78.37371)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. SIVNI',22.942053,78.37371),",
    },
    {
      "PART NO": 138,
      "PART NAME": "BARAHA KALA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARAHA KALA (EAST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 711,
      location: "22.96048,78.384151",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARAHA KALA (EAST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARAHA KALA (EAST PART)',22.96048,78.384151)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARAHA KALA (EAST PART)',22.96048,78.384151),",
    },
    {
      "PART NO": 139,
      "PART NAME": "BARAHA KALA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARAHA KALA (WEST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 618,
      location: "22.960451,78.384156",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARAHA KALA (WEST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARAHA KALA (WEST PART)',22.960451,78.384156)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BARAHA KALA (WEST PART)',22.960451,78.384156),",
    },
    {
      "PART NO": 140,
      "PART NAME": "CHHABARA",
      "Name of polling station building":
        "EKIKRTI GOVT. HIGH SCHOOL BUILDING CHHABARA (EAST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 942,
      location: "23.010635,78.37698",
      "inverted one":
        "'EKIKRTI GOVT. HIGH SCHOOL BUILDING CHHABARA (EAST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRTI GOVT. HIGH SCHOOL BUILDING CHHABARA (EAST PART)',23.010635,78.37698)",
      "with comma":
        "ElectionBooth('EKIKRTI GOVT. HIGH SCHOOL BUILDING CHHABARA (EAST PART)',23.010635,78.37698),",
    },
    {
      "PART NO": 141,
      "PART NAME": "CHHABARA",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING CHHABARA (NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1086,
      location: "23.010616,78.376925",
      "inverted one":
        "'EKIKRIT GOVT. HIGH SCHOOL BUILDING CHHABARA (NORTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING CHHABARA (NORTH PART)',23.010616,78.376925)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING CHHABARA (NORTH PART)',23.010616,78.376925),",
    },
    {
      "PART NO": 142,
      "PART NAME": "UNTIYA KALAN",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING UNTIYA KALAN (OLD GIRLS SCHOOL)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1260,
      location: "22.994768,7.353115",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING UNTIYA KALAN (OLD GIRLS SCHOOL)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING UNTIYA KALAN (OLD GIRLS SCHOOL)',22.994768,7.353115)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING UNTIYA KALAN (OLD GIRLS SCHOOL)',22.994768,7.353115),",
    },
    {
      "PART NO": 143,
      "PART NAME": "UNTIYA KALAN",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING UNTIYA KALAN ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1324,
      location: "22.995246,78.352774",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING UNTIYA KALAN ROOM NO 1'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING UNTIYA KALAN ROOM NO 1',22.995246,78.352774)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING UNTIYA KALAN ROOM NO 1',22.995246,78.352774),",
    },
    {
      "PART NO": 144,
      "PART NAME": "BAGPIPARIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING BAGPIPARIYA ROOM NO. 7",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1020,
      location: "22.97382,78.331992",
      "inverted one":
        "'EKIKRIT GOVT. HIGH SCHOOL BUILDING BAGPIPARIYA ROOM NO. 7'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING BAGPIPARIYA ROOM NO. 7',22.97382,78.331992)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING BAGPIPARIYA ROOM NO. 7',22.97382,78.331992),",
    },
    {
      "PART NO": 145,
      "PART NAME": "BAGPIPARIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING BAGPIPARIYA ROOM NO. 8",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1153,
      location: "22.973714,78.331961",
      "inverted one":
        "'EKIKRIT GOVT. HIGH SCHOOL BUILDING BAGPIPARIYA ROOM NO. 8'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING BAGPIPARIYA ROOM NO. 8',22.973714,78.331961)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING BAGPIPARIYA ROOM NO. 8',22.973714,78.331961),",
    },
    {
      "PART NO": 146,
      "PART NAME": "KAILKACHCHH",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KAILKACHCHH",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 690,
      location: "22.992894,78.322602",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KAILKACHCHH'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KAILKACHCHH',22.992894,78.322602)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KAILKACHCHH',22.992894,78.322602),",
    },
    {
      "PART NO": 147,
      "PART NAME": "JOUHARBARAHA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING.JOUHARBARHA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 914,
      location: "23.016464,78.326729",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING.JOUHARBARHA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING.JOUHARBARHA',23.016464,78.326729)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING.JOUHARBARHA',23.016464,78.326729),",
    },
    {
      "PART NO": 148,
      "PART NAME": "SIMROD",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SIMROD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 617,
      location: "23.009238,78.299475",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SIMROD'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SIMROD',23.009238,78.299475)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SIMROD',23.009238,78.299475),",
    },
    {
      "PART NO": 149,
      "PART NAME": "BANIYAKHEDI",
      "Name of polling station building":
        "GOVT. NEW PRIMARY SCHOOL BUILDING BANIYAKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 338,
      location: "23.049129,78..389733",
      "inverted one": "'GOVT. NEW PRIMARY SCHOOL BUILDING BANIYAKHEDI'",
      cosolideted:
        "ElectionBooth('GOVT. NEW PRIMARY SCHOOL BUILDING BANIYAKHEDI',23.049129,78..389733)",
      "with comma":
        "ElectionBooth('GOVT. NEW PRIMARY SCHOOL BUILDING BANIYAKHEDI',23.049129,78..389733),",
    },
    {
      "PART NO": 150,
      "PART NAME": "CHHUCHHAR",
      "Name of polling station building": "GRAM SWARAJ BUILDING CHHUCHHAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 530,
      location: "23.041555,78.299854",
      "inverted one": "'GRAM SWARAJ BUILDING CHHUCHHAR'",
      cosolideted:
        "ElectionBooth('GRAM SWARAJ BUILDING CHHUCHHAR',23.041555,78.299854)",
      "with comma":
        "ElectionBooth('GRAM SWARAJ BUILDING CHHUCHHAR',23.041555,78.299854),",
    },
    {
      "PART NO": 151,
      "PART NAME": "CHHIND",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL CHHIND (ADDITIONAL ROOM)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 637,
      location: "23.030856,78.289684",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL CHHIND (ADDITIONAL ROOM)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL CHHIND (ADDITIONAL ROOM)',23.030856,78.289684)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL CHHIND (ADDITIONAL ROOM)',23.030856,78.289684),",
    },
    {
      "PART NO": 152,
      "PART NAME": "BHONDIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BHONDIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1085,
      location: "23.055564,78.282306",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BHONDIYA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BHONDIYA',23.055564,78.282306)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BHONDIYA',23.055564,78.282306),",
    },
    {
      "PART NO": 153,
      "PART NAME": "KHAIRWADA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHAIRWADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1012,
      location: "23.067045,78.30509",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHAIRWADA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHAIRWADA',23.067045,78.30509)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KHAIRWADA',23.067045,78.30509),",
    },
    {
      "PART NO": 154,
      "PART NAME": "BAIGANIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAIGANIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1116,
      location: "23.039728,78.338232",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAIGANIYA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAIGANIYA',23.039728,78.338232)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAIGANIYA',23.039728,78.338232),",
    },
    {
      "PART NO": 155,
      "PART NAME": "TONGA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING TONGA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1069,
      location: "23.041307,78.362255",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING TONGA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING TONGA',23.041307,78.362255)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING TONGA',23.041307,78.362255),",
    },
    {
      "PART NO": 156,
      "PART NAME": "SIMARIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SIMARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 572,
      location: "23.066468,78.357777",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SIMARIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SIMARIYA',23.066468,78.357777)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SIMARIYA',23.066468,78.357777),",
    },
    {
      "PART NO": 157,
      "PART NAME": "NAYAGAON KHURD",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING. NAYAGAON KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 372,
      location: "23.058186,78.339185",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING. NAYAGAON KHURD'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING. NAYAGAON KHURD',23.058186,78.339185)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING. NAYAGAON KHURD',23.058186,78.339185),",
    },
    {
      "PART NO": 158,
      "PART NAME": "BAMHORI BALIRAM",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING RAJEEV GANDHI SHIKSHA MISSION BAMHORI BALIRAM",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 247,
      location: "23.059332,78.352659",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL BUILDING RAJEEV GANDHI SHIKSHA MISSION BAMHORI BALIRAM'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAJEEV GANDHI SHIKSHA MISSION BAMHORI BALIRAM',23.059332,78.352659)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAJEEV GANDHI SHIKSHA MISSION BAMHORI BALIRAM',23.059332,78.352659),",
    },
    {
      "PART NO": 159,
      "PART NAME": "RAJWADA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING RAJWADA (EAST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 786,
      location: "23.080178,78.339862",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING RAJWADA (EAST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING RAJWADA (EAST PART)',23.080178,78.339862)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING RAJWADA (EAST PART)',23.080178,78.339862),",
    },
    {
      "PART NO": 160,
      "PART NAME": "RAJWADA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING RAJWADA (WEST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 584,
      location: "23.080219,78.339931",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING RAJWADA (WEST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING RAJWADA (WEST PART)',23.080219,78.339931)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING RAJWADA (WEST PART)',23.080219,78.339931),",
    },
    {
      "PART NO": 161,
      "PART NAME": "BATERA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BATERA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1008,
      location: "23.093088,78.337828",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BATERA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BATERA',23.093088,78.337828)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BATERA',23.093088,78.337828),",
    },
    {
      "PART NO": 162,
      "PART NAME": "KHARGONE",
      "Name of polling station building":
        "EKIKRIT GOVT. H.S.SCHOOL KHARGONE ROOM NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 889,
      location: "23.094652,78.317653",
      "inverted one": "'EKIKRIT GOVT. H.S.SCHOOL KHARGONE ROOM NO.1'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. H.S.SCHOOL KHARGONE ROOM NO.1',23.094652,78.317653)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. H.S.SCHOOL KHARGONE ROOM NO.1',23.094652,78.317653),",
    },
    {
      "PART NO": 163,
      "PART NAME": "KHARGONE",
      "Name of polling station building":
        "EKIKRIT GOVT. H.S.SCHOOL KHARGONE ROOM NO.7",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1040,
      location: "23.09462,78.31764",
      "inverted one": "'EKIKRIT GOVT. H.S.SCHOOL KHARGONE ROOM NO.7'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. H.S.SCHOOL KHARGONE ROOM NO.7',23.09462,78.31764)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. H.S.SCHOOL KHARGONE ROOM NO.7',23.09462,78.31764),",
    },
    {
      "PART NO": 164,
      "PART NAME": "KHARGONE",
      "Name of polling station building":
        "EKIKRIT GOVT.H.S.SCHOOL KHARGONE ROOM NO. .13",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1083,
      location: "23.09462,78.31764",
      "inverted one": "'EKIKRIT GOVT.H.S.SCHOOL KHARGONE ROOM NO. .13'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.H.S.SCHOOL KHARGONE ROOM NO. .13',23.09462,78.31764)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.H.S.SCHOOL KHARGONE ROOM NO. .13',23.09462,78.31764),",
    },
    {
      "PART NO": 165,
      "PART NAME": "KHARGONE",
      "Name of polling station building": "GRAM PANCHAYAT BUILDING KHARGONE",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1046,
      location: "23.094708,78.317642",
      "inverted one": "'GRAM PANCHAYAT BUILDING KHARGONE'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING KHARGONE',23.094708,78.317642)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING KHARGONE',23.094708,78.317642),",
    },
    {
      "PART NO": 166,
      "PART NAME": "AHAMADPUR",
      "Name of polling station building": "GRAM PANCHAYAT BUILDING AHAMADPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 833,
      location: "23.106644,78.300109",
      "inverted one": "'GRAM PANCHAYAT BUILDING AHAMADPUR'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING AHAMADPUR',23.106644,78.300109)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING AHAMADPUR',23.106644,78.300109),",
    },
    {
      "PART NO": 167,
      "PART NAME": "AHAMADPUR",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING AHMADPUR ROOM NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 632,
      location: "23.103089,78.306513",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING AHMADPUR ROOM NO.1'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING AHMADPUR ROOM NO.1',23.103089,78.306513)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING AHMADPUR ROOM NO.1',23.103089,78.306513),",
    },
    {
      "PART NO": 168,
      "PART NAME": "KHAIRI MUGALI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING.KHAIRI MUGALI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 436,
      location: "23.119587,78.334853",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING.KHAIRI MUGALI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING.KHAIRI MUGALI',23.119587,78.334853)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING.KHAIRI MUGALI',23.119587,78.334853),",
    },
    {
      "PART NO": 169,
      "PART NAME": "KUCHWADA",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING KUCHWADA ROOM NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 833,
      location: "23.14553,78.35",
      "inverted one": "'EKIKRIT GOVT. HIGH SCHOOL BUILDING KUCHWADA ROOM NO.1'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING KUCHWADA ROOM NO.1',23.14553,78.35)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING KUCHWADA ROOM NO.1',23.14553,78.35),",
    },
    {
      "PART NO": 170,
      "PART NAME": "KUCHWADA",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING KUCHWADA ROOM NO.3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 763,
      location: "23.145437,78.350007",
      "inverted one": "'EKIKRIT GOVT. HIGH SCHOOL BUILDING KUCHWADA ROOM NO.3'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING KUCHWADA ROOM NO.3',23.145437,78.350007)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING KUCHWADA ROOM NO.3',23.145437,78.350007),",
    },
    {
      "PART NO": 171,
      "PART NAME": "KHURSURU",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING KHURSURU",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 579,
      location: "23.115998,78.36413",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING KHURSURU'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING KHURSURU',23.115998,78.36413)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING KHURSURU',23.115998,78.36413),",
    },
    {
      "PART NO": 172,
      "PART NAME": "DHOULPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DHOULPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1001,
      location: "23.158484,78.397789",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DHOULPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DHOULPUR',23.158484,78.397789)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DHOULPUR',23.158484,78.397789),",
    },
    {
      "PART NO": 173,
      "PART NAME": "PIPALIYA KEVAT",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPALIYA KEVAT",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 990,
      location: "23.1418305,78.4082172",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPALIYA KEVAT'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPALIYA KEVAT',23.1418305,78.4082172)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPALIYA KEVAT',23.1418305,78.4082172),",
    },
    {
      "PART NO": 174,
      "PART NAME": "SHAHPURA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING.SHAHPURA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 558,
      location: "23.135166,78.402364",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING.SHAHPURA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.SHAHPURA',23.135166,78.402364)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.SHAHPURA',23.135166,78.402364),",
    },
    {
      "PART NO": 175,
      "PART NAME": "BAIRASALA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAIRSALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 879,
      location: "23.144745,78.415837",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAIRSALA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAIRSALA',23.144745,78.415837)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAIRSALA',23.144745,78.415837),",
    },
    {
      "PART NO": 176,
      "PART NAME": "NOORNAGAR",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL NOORNAGAR (NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 972,
      location: "23.113673,78.403897",
      "inverted one": "'EKIKRIT GOVT. HIGH SCHOOL NOORNAGAR (NORTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL NOORNAGAR (NORTH PART)',23.113673,78.403897)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL NOORNAGAR (NORTH PART)',23.113673,78.403897),",
    },
    {
      "PART NO": 177,
      "PART NAME": "NOORNAGAR",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING NOORNAGAR (SOUTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 702,
      location: "23.11377,78.403869",
      "inverted one":
        "'EKIKRIT GOVT. HIGH SCHOOL BUILDING NOORNAGAR (SOUTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING NOORNAGAR (SOUTH PART)',23.11377,78.403869)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING NOORNAGAR (SOUTH PART)',23.11377,78.403869),",
    },
    {
      "PART NO": 178,
      "PART NAME": "SILARI KHURD",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SILARI KHURD (EAST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 804,
      location: "23.1101106,23.1101106",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SILARI KHURD (EAST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SILARI KHURD (EAST PART)',23.1101106,23.1101106)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SILARI KHURD (EAST PART)',23.1101106,23.1101106),",
    },
    {
      "PART NO": 179,
      "PART NAME": "SILARI KHURD",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SILARI KHURD (WEST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 399,
      location: "23.1101106,23.1101106",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SILARI KHURD (WEST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SILARI KHURD (WEST PART)',23.1101106,23.1101106)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SILARI KHURD (WEST PART)',23.1101106,23.1101106),",
    },
    {
      "PART NO": 180,
      "PART NAME": "BANKHEDI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BANKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1147,
      location: "23.0796074074983,78.506616256",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BANKHEDI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BANKHEDI',23.0796074074983,78.506616256)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BANKHEDI',23.0796074074983,78.506616256),",
    },
    {
      "PART NO": 181,
      "PART NAME": "TIKHAWAN",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING TIKHAWAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 778,
      location: "23.0971010844,78.4053936",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING TIKHAWAN'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING TIKHAWAN',23.0971010844,78.4053936)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING TIKHAWAN',23.0971010844,78.4053936),",
    },
    {
      "PART NO": 182,
      "PART NAME": "ANVARIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING AWARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1157,
      location: "23.079509,78.506513",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING AWARIYA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING AWARIYA',23.079509,78.506513)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING AWARIYA',23.079509,78.506513),",
    },
    {
      "PART NO": 183,
      "PART NAME": "SURELA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING. SURELA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 560,
      location: "23.05953,78.39618",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING. SURELA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. SURELA',23.05953,78.39618)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. SURELA',23.05953,78.39618),",
    },
    {
      "PART NO": 184,
      "PART NAME": "LAMATA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING LAMTA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 965,
      location: "23.079723,78.506613",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING LAMTA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING LAMTA',23.079723,78.506613)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING LAMTA',23.079723,78.506613),",
    },
    {
      "PART NO": 185,
      "PART NAME": "CHHATAIR",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING CHHATER (EAST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 890,
      location: "23.050567,78.407937",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING CHHATER (EAST PART)'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING CHHATER (EAST PART)',23.050567,78.407937)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING CHHATER (EAST PART)',23.050567,78.407937),",
    },
    {
      "PART NO": 186,
      "PART NAME": "CHHATAIR",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING CHHATER (WEST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 497,
      location: "23.047689,78.408143",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING CHHATER (WEST PART)'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING CHHATER (WEST PART)',23.047689,78.408143)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING CHHATER (WEST PART)',23.047689,78.408143),",
    },
    {
      "PART NO": 187,
      "PART NAME": "PIPALIYA PUARIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPARIYA PUARIYA ROOM NO. 01",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 744,
      location: "23.079723,78.506613",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPARIYA PUARIYA ROOM NO. 01'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPARIYA PUARIYA ROOM NO. 01',23.079723,78.506613)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPARIYA PUARIYA ROOM NO. 01',23.079723,78.506613),",
    },
    {
      "PART NO": 188,
      "PART NAME": "PIPALIYA PUARIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPARIYA PUARIYA ROOM NO. 02",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 725,
      location: "23.079725,78.50661166",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPARIYA PUARIYA ROOM NO. 02'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPARIYA PUARIYA ROOM NO. 02',23.079725,78.50661166)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PIPARIYA PUARIYA ROOM NO. 02',23.079725,78.50661166),",
    },
    {
      "PART NO": 189,
      "PART NAME": "BHADON",
      "Name of polling station building": "GOVT. MIDDLE SCHOOL BUILDING BHADON",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 743,
      location: "23.0032894,78.42945449",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING BHADON'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING BHADON',23.0032894,78.42945449)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING BHADON',23.0032894,78.42945449),",
    },
    {
      "PART NO": 190,
      "PART NAME": "BHADON",
      "Name of polling station building":
        "GOVT MIDDLE SCHOOL BUILDING ROOM NO. 01 BHADON",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 846,
      location: "23.0032894,78.42945449",
      "inverted one": "'GOVT MIDDLE SCHOOL BUILDING ROOM NO. 01 BHADON'",
      cosolideted:
        "ElectionBooth('GOVT MIDDLE SCHOOL BUILDING ROOM NO. 01 BHADON',23.0032894,78.42945449)",
      "with comma":
        "ElectionBooth('GOVT MIDDLE SCHOOL BUILDING ROOM NO. 01 BHADON',23.0032894,78.42945449),",
    },
    {
      "PART NO": 191,
      "PART NAME": "KHUNIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KHUNIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 524,
      location: "22.973762,78.389407",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KHUNIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHUNIYA',22.973762,78.389407)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHUNIYA',22.973762,78.389407),",
    },
    {
      "PART NO": 192,
      "PART NAME": "KETOGHAN",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KETOGHAN SOUTH PART",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1158,
      location: "22.978302,78.419049",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KETOGHAN SOUTH PART'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KETOGHAN SOUTH PART',22.978302,78.419049)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KETOGHAN SOUTH PART',22.978302,78.419049),",
    },
    {
      "PART NO": 193,
      "PART NAME": "KETOGHAN",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KETOGHAN (NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 459,
      location: "22.978347,78.419027",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KETOGHAN (NORTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KETOGHAN (NORTH PART)',22.978347,78.419027)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KETOGHAN (NORTH PART)',22.978347,78.419027),",
    },
    {
      "PART NO": 194,
      "PART NAME": "MOHADKALA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING MOHADKALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 665,
      location: "22.990798,78.443773",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING MOHADKALAN'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING MOHADKALAN',22.990798,78.443773)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING MOHADKALAN',22.990798,78.443773),",
    },
    {
      "PART NO": 195,
      "PART NAME": "NARAHARA",
      "Name of polling station building": "AANGANWADI BUILDING NARAHARA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 560,
      location: "23.00153,78.454347",
      "inverted one": "'AANGANWADI BUILDING NARAHARA'",
      cosolideted:
        "ElectionBooth('AANGANWADI BUILDING NARAHARA',23.00153,78.454347)",
      "with comma":
        "ElectionBooth('AANGANWADI BUILDING NARAHARA',23.00153,78.454347),",
    },
    {
      "PART NO": 196,
      "PART NAME": "UDIYA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING UDIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 614,
      location: "23.00821377,78.47115886",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING UDIYA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING UDIYA',23.00821377,78.47115886)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING UDIYA',23.00821377,78.47115886),",
    },
    {
      "PART NO": 197,
      "PART NAME": "KATHOTIYA",
      "Name of polling station building":
        "EKIKRIT GOVT.HIGH SCHOOL BUILDING KATHOTIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1099,
      location: "23.032756,78.474561",
      "inverted one": "'EKIKRIT GOVT.HIGH SCHOOL BUILDING KATHOTIYA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.HIGH SCHOOL BUILDING KATHOTIYA',23.032756,78.474561)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.HIGH SCHOOL BUILDING KATHOTIYA',23.032756,78.474561),",
    },
    {
      "PART NO": 198,
      "PART NAME": "SIMARIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING. SIMARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 798,
      location: "23.032934,78.438868",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING. SIMARIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. SIMARIYA',23.032934,78.438868)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. SIMARIYA',23.032934,78.438868),",
    },
    {
      "PART NO": 199,
      "PART NAME": "NIWADI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NIWADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1324,
      location: "23.073656,78.4551",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NIWADI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NIWADI',23.073656,78.4551)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NIWADI',23.073656,78.4551),",
    },
    {
      "PART NO": 200,
      "PART NAME": "CHANDALI",
      "Name of polling station building":
        "EKIKRIT GOVT.MIDDLE SCHOOL BUILDING CHANDLI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1052,
      location: "23.045828,78.453946",
      "inverted one": "'EKIKRIT GOVT.MIDDLE SCHOOL BUILDING CHANDLI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING CHANDLI',23.045828,78.453946)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING CHANDLI',23.045828,78.453946),",
    },
    {
      "PART NO": 201,
      "PART NAME": "GAYBYAN",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING GAYVYAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 771,
      location: "23.07956014,78.50644126",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING GAYVYAN'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING GAYVYAN',23.07956014,78.50644126)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING GAYVYAN',23.07956014,78.50644126),",
    },
    {
      "PART NO": 202,
      "PART NAME": "GHANA BAHEDIYA",
      "Name of polling station building":
        "PRIMARY SCHOOL BUILDING GHANA BAHEDIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1158,
      location: "23.060481,78.454058",
      "inverted one": "'PRIMARY SCHOOL BUILDING GHANA BAHEDIYA'",
      cosolideted:
        "ElectionBooth('PRIMARY SCHOOL BUILDING GHANA BAHEDIYA',23.060481,78.454058)",
      "with comma":
        "ElectionBooth('PRIMARY SCHOOL BUILDING GHANA BAHEDIYA',23.060481,78.454058),",
    },
    {
      "PART NO": 203,
      "PART NAME": "BAMHORI BASOUDA",
      "Name of polling station building":
        "EKIKRIT GOVT.MIDDLE SCHOOL BUILDING BAMHORI BASODA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 772,
      location: "23.048064,78.495615",
      "inverted one": "'EKIKRIT GOVT.MIDDLE SCHOOL BUILDING BAMHORI BASODA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING BAMHORI BASODA',23.048064,78.495615)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING BAMHORI BASODA',23.048064,78.495615),",
    },
    {
      "PART NO": 204,
      "PART NAME": "UDAIPURA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING.UDAIPURA (NORTH PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1010,
      location: "23.078924,78.508331",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING.UDAIPURA (NORTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.UDAIPURA (NORTH PART)',23.078924,78.508331)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.UDAIPURA (NORTH PART)',23.078924,78.508331),",
    },
    {
      "PART NO": 205,
      "PART NAME": "UDAIPURA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING UDAIPURA (EAST PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 774,
      location: "23.0780862,78.508958",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING UDAIPURA (EAST PART)'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING UDAIPURA (EAST PART)',23.0780862,78.508958)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING UDAIPURA (EAST PART)',23.0780862,78.508958),",
    },
    {
      "PART NO": 206,
      "PART NAME": "UDAIPURA",
      "Name of polling station building": "NAGAR PANCHAYAT BUILDING UADIPURA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 982,
      location: "23.075289,78.507225",
      "inverted one": "'NAGAR PANCHAYAT BUILDING UADIPURA'",
      cosolideted:
        "ElectionBooth('NAGAR PANCHAYAT BUILDING UADIPURA',23.075289,78.507225)",
      "with comma":
        "ElectionBooth('NAGAR PANCHAYAT BUILDING UADIPURA',23.075289,78.507225),",
    },
    {
      "PART NO": 207,
      "PART NAME": "UDAIPURA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING UDAIPURA LABORATORY CELL",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1262,
      location: "23.078619,78.508011",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL BUILDING UDAIPURA LABORATORY CELL'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING UDAIPURA LABORATORY CELL',23.078619,78.508011)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING UDAIPURA LABORATORY CELL',23.078619,78.508011),",
    },
    {
      "PART NO": 208,
      "PART NAME": "UDAIPUR",
      "Name of polling station building":
        "EKIKRIT GOVT.GIRLS H.S.SCHOOL BUILDING UDAIPURA NORTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1030,
      location: "23.074944,78.513524",
      "inverted one":
        "'EKIKRIT GOVT.GIRLS H.S.SCHOOL BUILDING UDAIPURA NORTH PART'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.GIRLS H.S.SCHOOL BUILDING UDAIPURA NORTH PART',23.074944,78.513524)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.GIRLS H.S.SCHOOL BUILDING UDAIPURA NORTH PART',23.074944,78.513524),",
    },
    {
      "PART NO": 209,
      "PART NAME": "UDAIPUR",
      "Name of polling station building":
        "EKIKRIT GOVT.GIRLS H.S.SCHOOL UDAIPURA WEST PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 780,
      location: "23.074979,78.513437",
      "inverted one": "'EKIKRIT GOVT.GIRLS H.S.SCHOOL UDAIPURA WEST PART'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.GIRLS H.S.SCHOOL UDAIPURA WEST PART',23.074979,78.513437)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.GIRLS H.S.SCHOOL UDAIPURA WEST PART',23.074979,78.513437),",
    },
    {
      "PART NO": 210,
      "PART NAME": "UDAIPURA",
      "Name of polling station building":
        "EKIKRIT GOVT.GIRLS H.S.SCHOOL BUILDING UDAIPURA SOUTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1287,
      location: "23.074971,78.51326",
      "inverted one":
        "'EKIKRIT GOVT.GIRLS H.S.SCHOOL BUILDING UDAIPURA SOUTH PART'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.GIRLS H.S.SCHOOL BUILDING UDAIPURA SOUTH PART',23.074971,78.51326)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.GIRLS H.S.SCHOOL BUILDING UDAIPURA SOUTH PART',23.074971,78.51326),",
    },
    {
      "PART NO": 211,
      "PART NAME": "UDAYPURA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KOLIPURA UDAIPURA (NORTH PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1210,
      location: "23.078855,78.508293",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL BUILDING KOLIPURA UDAIPURA (NORTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KOLIPURA UDAIPURA (NORTH PART)',23.078855,78.508293)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KOLIPURA UDAIPURA (NORTH PART)',23.078855,78.508293),",
    },
    {
      "PART NO": 212,
      "PART NAME": "UDAYPURA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KOLIPURA BHUTAL MADHYA UDAIPURA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1062,
      location: "23.073918,78.512293",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL BUILDING KOLIPURA BHUTAL MADHYA UDAIPURA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KOLIPURA BHUTAL MADHYA UDAIPURA',23.073918,78.512293)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KOLIPURA BHUTAL MADHYA UDAIPURA',23.073918,78.512293),",
    },
    {
      "PART NO": 213,
      "PART NAME": "UDAIPURA",
      "Name of polling station building":
        "EKIKRIT GOVT. GIRLS H.S.SCHOOL UDAIPURA (OLD G.G.P.S.BUILDING NORTH PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1040,
      location: "23.074797,78.514429",
      "inverted one":
        "'EKIKRIT GOVT. GIRLS H.S.SCHOOL UDAIPURA (OLD G.G.P.S.BUILDING NORTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. GIRLS H.S.SCHOOL UDAIPURA (OLD G.G.P.S.BUILDING NORTH PART)',23.074797,78.514429)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. GIRLS H.S.SCHOOL UDAIPURA (OLD G.G.P.S.BUILDING NORTH PART)',23.074797,78.514429),",
    },
    {
      "PART NO": 214,
      "PART NAME": "UDAIPURA",
      "Name of polling station building":
        "EKIKRIT GOVT. GIRLS H.S.SCHOOL UDAIPURA (OLD G.G.P.S.BUILDING WEST PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 967,
      location: "23.074797,78.514429",
      "inverted one":
        "'EKIKRIT GOVT. GIRLS H.S.SCHOOL UDAIPURA (OLD G.G.P.S.BUILDING WEST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. GIRLS H.S.SCHOOL UDAIPURA (OLD G.G.P.S.BUILDING WEST PART)',23.074797,78.514429)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. GIRLS H.S.SCHOOL UDAIPURA (OLD G.G.P.S.BUILDING WEST PART)',23.074797,78.514429),",
    },
    {
      "PART NO": 215,
      "PART NAME": "UDAIPURA",
      "Name of polling station building":
        "KARYALAY KRISHI UPAJ MANDI SAMEETI MEETING HALL UDAIPURA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1254,
      location: "23.07498,78.511596",
      "inverted one":
        "'KARYALAY KRISHI UPAJ MANDI SAMEETI MEETING HALL UDAIPURA'",
      cosolideted:
        "ElectionBooth('KARYALAY KRISHI UPAJ MANDI SAMEETI MEETING HALL UDAIPURA',23.07498,78.511596)",
      "with comma":
        "ElectionBooth('KARYALAY KRISHI UPAJ MANDI SAMEETI MEETING HALL UDAIPURA',23.07498,78.511596),",
    },
    {
      "PART NO": 216,
      "PART NAME": "UDAIPURA",
      "Name of polling station building":
        "SAMUDAYIK BHAVAN NEW BUS STAND BHATTA MOHALLA UDAIPURA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1133,
      location: "23.07498,78.511596",
      "inverted one":
        "'SAMUDAYIK BHAVAN NEW BUS STAND BHATTA MOHALLA UDAIPURA'",
      cosolideted:
        "ElectionBooth('SAMUDAYIK BHAVAN NEW BUS STAND BHATTA MOHALLA UDAIPURA',23.07498,78.511596)",
      "with comma":
        "ElectionBooth('SAMUDAYIK BHAVAN NEW BUS STAND BHATTA MOHALLA UDAIPURA',23.07498,78.511596),",
    },
    {
      "PART NO": 217,
      "PART NAME": "UDAIPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING.LODHIPURA UDAIPURA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1193,
      location: "23.077098,78.520472",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING.LODHIPURA UDAIPURA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.LODHIPURA UDAIPURA',23.077098,78.520472)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.LODHIPURA UDAIPURA',23.077098,78.520472),",
    },
    {
      "PART NO": 218,
      "PART NAME": "PACHAMA",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING PACHAMA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 798,
      location: "23.106889,78.483068",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING PACHAMA'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING PACHAMA',23.106889,78.483068)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING PACHAMA',23.106889,78.483068),",
    },
    {
      "PART NO": 219,
      "PART NAME": "BAMHORI BHUNARI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAMHORI BHUARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1212,
      location: "23.102776,78.460884",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAMHORI BHUARI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAMHORI BHUARI',23.102776,78.460884)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAMHORI BHUARI',23.102776,78.460884),",
    },
    {
      "PART NO": 220,
      "PART NAME": "KADHELI KALAN",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING. KADELIKALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 561,
      location: "23.119848,78.459598",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING. KADELIKALAN'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. KADELIKALAN',23.119848,78.459598)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. KADELIKALAN',23.119848,78.459598),",
    },
    {
      "PART NO": 221,
      "PART NAME": "NONIYA BARELI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NONIYA BARELI ROOM NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 797,
      location: "23.036616,78.483499",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NONIYA BARELI ROOM NO.1'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NONIYA BARELI ROOM NO.1',23.036616,78.483499)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NONIYA BARELI ROOM NO.1',23.036616,78.483499),",
    },
    {
      "PART NO": 222,
      "PART NAME": "NONIYA BARELI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NONIYA BARELI ROOM NO.3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 903,
      location: "23.1511272,78.4438829",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NONIYA BARELI ROOM NO.3'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NONIYA BARELI ROOM NO.3',23.1511272,78.4438829)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NONIYA BARELI ROOM NO.3',23.1511272,78.4438829),",
    },
    {
      "PART NO": 223,
      "PART NAME": "KISHANAPUR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING KISHANPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 932,
      location: "23.17461,78.4656067",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KISHANPUR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KISHANPUR',23.17461,78.4656067)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KISHANPUR',23.17461,78.4656067),",
    },
    {
      "PART NO": 224,
      "PART NAME": "DHOULSHRI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DHOULSHRI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 879,
      location: "23.140764,78.471272",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DHOULSHRI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DHOULSHRI',23.140764,78.471272)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING DHOULSHRI',23.140764,78.471272),",
    },
    {
      "PART NO": 225,
      "PART NAME": "DUNGARIYA KALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING.DUGARIYA KALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 468,
      location: "23.1387,78.48677",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING.DUGARIYA KALAN'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.DUGARIYA KALAN',23.1387,78.48677)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.DUGARIYA KALAN',23.1387,78.48677),",
    },
    {
      "PART NO": 226,
      "PART NAME": "NAYAGANV",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NAYAGOAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 446,
      location: "23.1526586,78.5052044",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NAYAGOAN'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NAYAGOAN',23.1526586,78.5052044)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING NAYAGOAN',23.1526586,78.5052044),",
    },
    {
      "PART NO": 227,
      "PART NAME": "SHOBHAPUR",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING SHOBHAPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1199,
      location: "23.140903,78.520426",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING SHOBHAPUR'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING SHOBHAPUR',23.140903,78.520426)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING SHOBHAPUR',23.140903,78.520426),",
    },
    {
      "PART NO": 228,
      "PART NAME": "BIJANAHAI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING VIJANHAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 793,
      location: "23.129625,78.508213",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING VIJANHAI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING VIJANHAI',23.129625,78.508213)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING VIJANHAI',23.129625,78.508213),",
    },
    {
      "PART NO": 229,
      "PART NAME": "UDADMAU",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING URADMAU",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 774,
      location: "23.120469,78.517327",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING URADMAU'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING URADMAU',23.120469,78.517327)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING URADMAU',23.120469,78.517327),",
    },
    {
      "PART NO": 230,
      "PART NAME": "IMAJHIRI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING IMJHIRI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 921,
      location: "23.108315,78.55597",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING IMJHIRI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING IMJHIRI',23.108315,78.55597)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING IMJHIRI',23.108315,78.55597),",
    },
    {
      "PART NO": 231,
      "PART NAME": "CHHIND",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING CHHIND",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1400,
      location: "23.108315,78.55597",
      "inverted one": "'EKIKRIT GOVT. HIGH SCHOOL BUILDING CHHIND'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING CHHIND',23.108315,78.55597)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING CHHIND',23.108315,78.55597),",
    },
    {
      "PART NO": 232,
      "PART NAME": "KIRAGI KALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KIRGIKALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 823,
      location: "23.10972,78.562561",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KIRGIKALAN'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KIRGIKALAN',23.10972,78.562561)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KIRGIKALAN',23.10972,78.562561),",
    },
    {
      "PART NO": 233,
      "PART NAME": "SATEHARI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SATEHRI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 946,
      location: "23.09211,78.55223",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SATEHRI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SATEHRI',23.09211,78.55223)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SATEHRI',23.09211,78.55223),",
    },
    {
      "PART NO": 234,
      "PART NAME": "SILARI KALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SILARI KALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1070,
      location: "23.075867,78.518697",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SILARI KALAN'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SILARI KALAN',23.075867,78.518697)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SILARI KALAN',23.075867,78.518697),",
    },
    {
      "PART NO": 235,
      "PART NAME": "RAHALI",
      "Name of polling station building": "GOVT. MIDDLE SCHOOL BUILDING RAHLI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 821,
      location: "23.054959,78.508755",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING RAHLI'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING RAHLI',23.054959,78.508755)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING RAHLI',23.054959,78.508755),",
    },
    {
      "PART NO": 236,
      "PART NAME": "SULTANAGANJ",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SULTANGANJ",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 743,
      location: "23.010153,78.49839",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SULTANGANJ'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SULTANGANJ',23.010153,78.49839)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SULTANGANJ',23.010153,78.49839),",
    },
    {
      "PART NO": 237,
      "PART NAME": "BAURAS",
      "Name of polling station building":
        "GOVT.MIDDLE SCHOOL BUILDING BORAS (NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1158,
      location: "23.01534,78.516183",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING BORAS (NORTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING BORAS (NORTH PART)',23.01534,78.516183)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING BORAS (NORTH PART)',23.01534,78.516183),",
    },
    {
      "PART NO": 238,
      "PART NAME": "BORAS",
      "Name of polling station building":
        "GOVT.MIDDLE SCHOOL BUILDING BORAS (SOUTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1130,
      location: "23.015335,78.516197",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING BORAS (SOUTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING BORAS (SOUTH PART)',23.015335,78.516197)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING BORAS (SOUTH PART)',23.015335,78.516197),",
    },
    {
      "PART NO": 239,
      "PART NAME": "CHOURAS",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOURAS ROON NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 864,
      location: "23.079329,78.506519",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOURAS ROON NO.1'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOURAS ROON NO.1',23.079329,78.506519)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOURAS ROON NO.1',23.079329,78.506519),",
    },
    {
      "PART NO": 240,
      "PART NAME": "CHOURAS",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOURAS ROOM NO.3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 596,
      location: "23.019997,78",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOURAS ROOM NO.3'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOURAS ROOM NO.3',23.019997,78)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHOURAS ROOM NO.3',23.019997,78),",
    },
    {
      "PART NO": 241,
      "PART NAME": "KIRGI KHURD",
      "Name of polling station building": "GRAM PANCHAYAT BUILDING KIRGIKHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 453,
      location: "23.05331,78.54309",
      "inverted one": "'GRAM PANCHAYAT BUILDING KIRGIKHURD'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING KIRGIKHURD',23.05331,78.54309)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING KIRGIKHURD',23.05331,78.54309),",
    },
    {
      "PART NO": 242,
      "PART NAME": "KAKARUA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KAKARUA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 455,
      location: "23.053571,78.544967",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KAKARUA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KAKARUA',23.053571,78.544967)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KAKARUA',23.053571,78.544967),",
    },
    {
      "PART NO": 243,
      "PART NAME": "BEESAWADI",
      "Name of polling station building":
        "GOVT.MIDDLE SCHOOL BUILDING NOUNIYA BARELI VISABERI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1027,
      location: "23.06567,78.56435",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING NOUNIYA BARELI VISABERI'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING NOUNIYA BARELI VISABERI',23.06567,78.56435)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING NOUNIYA BARELI VISABERI',23.06567,78.56435),",
    },
    {
      "PART NO": 244,
      "PART NAME": "GHANA TUNDA",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING. GHANA TUNDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 537,
      location: "23.08434,78.57227",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING. GHANA TUNDA'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING. GHANA TUNDA',23.08434,78.57227)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING. GHANA TUNDA',23.08434,78.57227),",
    },
    {
      "PART NO": 245,
      "PART NAME": "BINJHA",
      "Name of polling station building":
        "EKIKRIT GOVT.HIGH SCHOOL BUILDING BEENJHA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 821,
      location: "23.08783,78.60349",
      "inverted one": "'EKIKRIT GOVT.HIGH SCHOOL BUILDING BEENJHA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.HIGH SCHOOL BUILDING BEENJHA',23.08783,78.60349)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.HIGH SCHOOL BUILDING BEENJHA',23.08783,78.60349),",
    },
    {
      "PART NO": 246,
      "PART NAME": "BHOPATAPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING. BHOPATPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 716,
      location: "23.080695,78.59111",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING. BHOPATPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. BHOPATPUR',23.080695,78.59111)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. BHOPATPUR',23.080695,78.59111),",
    },
    {
      "PART NO": 247,
      "PART NAME": "KANIWADA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KANIWADA ROON NO.5",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 842,
      location: "23.07257,78.58982",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KANIWADA ROON NO.5'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KANIWADA ROON NO.5',23.07257,78.58982)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KANIWADA ROON NO.5',23.07257,78.58982),",
    },
    {
      "PART NO": 248,
      "PART NAME": "KANIWADA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KANIWADA ROOM NO. 2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 783,
      location: "23.07252,78.58973",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KANIWADA ROOM NO. 2'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KANIWADA ROOM NO. 2',23.07252,78.58973)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KANIWADA ROOM NO. 2',23.07252,78.58973),",
    },
    {
      "PART NO": 249,
      "PART NAME": "BARAHKALAN",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING BARHAKALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 867,
      location: "23.05501,78.56547",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING BARHAKALAN'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING BARHAKALAN',23.05501,78.56547)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING BARHAKALAN',23.05501,78.56547),",
    },
    {
      "PART NO": 250,
      "PART NAME": "BANSKHEDA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BANSKHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 862,
      location: "23.027048,78.563657",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BANSKHEDA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BANSKHEDA',23.027048,78.563657)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BANSKHEDA',23.027048,78.563657),",
    },
    {
      "PART NO": 251,
      "PART NAME": "ANDIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING ANDIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 797,
      location: "23.018023,78.580816",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING ANDIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ANDIYA',23.018023,78.580816)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ANDIYA',23.018023,78.580816),",
    },
    {
      "PART NO": 252,
      "PART NAME": "KAILAKACHCHH",
      "Name of polling station building":
        "EKIKRIT GOVT.HIGH SCHOOL BUILDING KAILKACHH (SOUTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 756,
      location: "23.021773,78.598886",
      "inverted one":
        "'EKIKRIT GOVT.HIGH SCHOOL BUILDING KAILKACHH (SOUTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.HIGH SCHOOL BUILDING KAILKACHH (SOUTH PART)',23.021773,78.598886)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.HIGH SCHOOL BUILDING KAILKACHH (SOUTH PART)',23.021773,78.598886),",
    },
    {
      "PART NO": 253,
      "PART NAME": "KAILAKACHCHH",
      "Name of polling station building":
        "EKIKRIT GOVT. HIGH SCHOOL BUILDING KELKACHH (EAST PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 900,
      location: "23.021796,78.598953",
      "inverted one":
        "'EKIKRIT GOVT. HIGH SCHOOL BUILDING KELKACHH (EAST PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING KELKACHH (EAST PART)',23.021796,78.598953)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. HIGH SCHOOL BUILDING KELKACHH (EAST PART)',23.021796,78.598953),",
    },
    {
      "PART NO": 254,
      "PART NAME": "ANAGHORA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING ANGHORA (NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1012,
      location: "23.03146,78.61422",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING ANGHORA (NORTH PART)'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING ANGHORA (NORTH PART)',23.03146,78.61422)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING ANGHORA (NORTH PART)',23.03146,78.61422),",
    },
    {
      "PART NO": 255,
      "PART NAME": "ANAGHORA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING ANGHORA ROOM NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 620,
      location: "23.0312426687578,78.6164989996445",
      "inverted one":
        "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING ANGHORA ROOM NO.2'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING ANGHORA ROOM NO.2',23.0312426687578,78.6164989996445)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING ANGHORA ROOM NO.2',23.0312426687578,78.6164989996445),",
    },
    {
      "PART NO": 256,
      "PART NAME": "MOTHAGAON",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MOTHAGAON",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 897,
      location: "23.053943,78.63098",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MOTHAGAON'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MOTHAGAON',23.053943,78.63098)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING MOTHAGAON',23.053943,78.63098),",
    },
    {
      "PART NO": 257,
      "PART NAME": "SAINKHEDA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SAINKHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1022,
      location: "23.0748316,78.629263",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SAINKHEDA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SAINKHEDA',23.0748316,78.629263)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING SAINKHEDA',23.0748316,78.629263),",
    },
    {
      "PART NO": 258,
      "PART NAME": "PANJARA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PANJARA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 936,
      location: "23.093799,78.615874",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PANJARA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PANJARA',23.093799,78.615874)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING PANJARA',23.093799,78.615874),",
    },
    {
      "PART NO": 259,
      "PART NAME": "PIPALIYA KALAN",
      "Name of polling station building":
        "EKIKRIT GOVT.MIDDLE SCHOOL BUILDING PIPALIYA KALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 964,
      location: "23.11841,78.62573",
      "inverted one": "'EKIKRIT GOVT.MIDDLE SCHOOL BUILDING PIPALIYA KALAN'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING PIPALIYA KALAN',23.11841,78.62573)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING PIPALIYA KALAN',23.11841,78.62573),",
    },
    {
      "PART NO": 260,
      "PART NAME": "RAMAKHIRIYA",
      "Name of polling station building":
        "EKIKRIT GOVT.MIDDLE SCHOOL BUILDING RAMKHARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1126,
      location: "23.111349,78.59252",
      "inverted one": "'EKIKRIT GOVT.MIDDLE SCHOOL BUILDING RAMKHARIYA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING RAMKHARIYA',23.111349,78.59252)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING RAMKHARIYA',23.111349,78.59252),",
    },
    {
      "PART NO": 261,
      "PART NAME": "MANAKAPUR",
      "Name of polling station building":
        "GOVT.GIRLS PRIMARY SCHOOL BUILDING MANKAPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 480,
      location: "23.12258,78.58467",
      "inverted one": "'GOVT.GIRLS PRIMARY SCHOOL BUILDING MANKAPUR'",
      cosolideted:
        "ElectionBooth('GOVT.GIRLS PRIMARY SCHOOL BUILDING MANKAPUR',23.12258,78.58467)",
      "with comma":
        "ElectionBooth('GOVT.GIRLS PRIMARY SCHOOL BUILDING MANKAPUR',23.12258,78.58467),",
    },
    {
      "PART NO": 262,
      "PART NAME": "CHHIKARA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING CHHIKARA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 551,
      location: "23.130721,78.587482",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING CHHIKARA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHHIKARA',23.130721,78.587482)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHHIKARA',23.130721,78.587482),",
    },
    {
      "PART NO": 263,
      "PART NAME": "BOODHANWADA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BOODHANWADA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 719,
      location: "23.090638,78.595359",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BOODHANWADA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BOODHANWADA',23.090638,78.595359)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BOODHANWADA',23.090638,78.595359),",
    },
    {
      "PART NO": 264,
      "PART NAME": "KODA DEVRI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KODA DEVRI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 335,
      location: "23.113362,78.62019",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KODA DEVRI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KODA DEVRI',23.113362,78.62019)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KODA DEVRI',23.113362,78.62019),",
    },
    {
      "PART NO": 265,
      "PART NAME": "MOHADPAPALAI",
      "Name of polling station building":
        "GOVT.NEW PRIMARY SCHOOL BUILDING MOHADAPPALAI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1068,
      location: "23.119872,78.66948",
      "inverted one": "'GOVT.NEW PRIMARY SCHOOL BUILDING MOHADAPPALAI'",
      cosolideted:
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING MOHADAPPALAI',23.119872,78.66948)",
      "with comma":
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING MOHADAPPALAI',23.119872,78.66948),",
    },
    {
      "PART NO": 266,
      "PART NAME": "KUMHADI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KUMHDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 538,
      location: "23.087018,78.644119",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KUMHDI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KUMHDI',23.087018,78.644119)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KUMHDI',23.087018,78.644119),",
    },
    {
      "PART NO": 267,
      "PART NAME": "BAMHORI (DEORI)",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAMHORI DEORI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 859,
      location: "23.106388,78.640406",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAMHORI DEORI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAMHORI DEORI',23.106388,78.640406)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BAMHORI DEORI',23.106388,78.640406),",
    },
    {
      "PART NO": 268,
      "PART NAME": "KENKDA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KENKADA ROOM NO. .1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 971,
      location: "23.059726,78.650256",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KENKADA ROOM NO. .1'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KENKADA ROOM NO. .1',23.059726,78.650256)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KENKADA ROOM NO. .1',23.059726,78.650256),",
    },
    {
      "PART NO": 269,
      "PART NAME": "KENKDA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KENKDA ROOM NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 647,
      location: "23.059693,78.650323",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KENKDA ROOM NO.2'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KENKDA ROOM NO.2',23.059693,78.650323)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KENKDA ROOM NO.2',23.059693,78.650323),",
    },
    {
      "PART NO": 270,
      "PART NAME": "PATAI",
      "Name of polling station building": "GOVT. MIDDLE SCHOOL BUILDING PATAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 988,
      location: "23.033832,78.660247",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING PATAI'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING PATAI',23.033832,78.660247)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING PATAI',23.033832,78.660247),",
    },
    {
      "PART NO": 271,
      "PART NAME": "DIGHAVAN",
      "Name of polling station building":
        "EKIKRIT GOVT. H.S.SCHOOL BUILDING (THALA) DIGHAWAN NORTH PART",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1222,
      location: "23.067505,78.672737",
      "inverted one":
        "'EKIKRIT GOVT. H.S.SCHOOL BUILDING (THALA) DIGHAWAN NORTH PART'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. H.S.SCHOOL BUILDING (THALA) DIGHAWAN NORTH PART',23.067505,78.672737)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. H.S.SCHOOL BUILDING (THALA) DIGHAWAN NORTH PART',23.067505,78.672737),",
    },
    {
      "PART NO": 272,
      "PART NAME": "DIGHAVAN",
      "Name of polling station building":
        "EKIKRIT GOVT. H.S.SCHOOL BUILDING (THALA) DIGHAWAN SOUTH PART",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1075,
      location: "23.067617,78.672804",
      "inverted one":
        "'EKIKRIT GOVT. H.S.SCHOOL BUILDING (THALA) DIGHAWAN SOUTH PART'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. H.S.SCHOOL BUILDING (THALA) DIGHAWAN SOUTH PART',23.067617,78.672804)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. H.S.SCHOOL BUILDING (THALA) DIGHAWAN SOUTH PART',23.067617,78.672804),",
    },
    {
      "PART NO": 273,
      "PART NAME": "THALA",
      "Name of polling station building":
        "EKIKRIT GOVT.GIRLS MIDDLE SCHOOL BUILDING THALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1150,
      location: "23.0681,78.676708",
      "inverted one": "'EKIKRIT GOVT.GIRLS MIDDLE SCHOOL BUILDING THALA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.GIRLS MIDDLE SCHOOL BUILDING THALA',23.0681,78.676708)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.GIRLS MIDDLE SCHOOL BUILDING THALA',23.0681,78.676708),",
    },
    {
      "PART NO": 274,
      "PART NAME": "RICHHAWAR",
      "Name of polling station building":
        "EKIKRIT GOVT.MIDDLE SCHOOL BUILDING RICHHAWAR ROOM NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1049,
      location: "23.042526,78.703487",
      "inverted one":
        "'EKIKRIT GOVT.MIDDLE SCHOOL BUILDING RICHHAWAR ROOM NO.1'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING RICHHAWAR ROOM NO.1',23.042526,78.703487)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING RICHHAWAR ROOM NO.1',23.042526,78.703487),",
    },
    {
      "PART NO": 275,
      "PART NAME": "RICHHAWAR",
      "Name of polling station building":
        "EKIKRIT GOVT.MIDDLE SCHOOL RICHHAWAR ROOM NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 378,
      location: "23.04253,78.703484",
      "inverted one": "'EKIKRIT GOVT.MIDDLE SCHOOL RICHHAWAR ROOM NO.2'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL RICHHAWAR ROOM NO.2',23.04253,78.703484)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL RICHHAWAR ROOM NO.2',23.04253,78.703484),",
    },
    {
      "PART NO": 276,
      "PART NAME": "RAMPURA",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING RAMPURA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1075,
      location: "23.048032,78.711659",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING RAMPURA'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING RAMPURA',23.048032,78.711659)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING RAMPURA',23.048032,78.711659),",
    },
    {
      "PART NO": 277,
      "PART NAME": "NAYAKHEDA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING NAYAKHEDA ROOM NO. .1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 779,
      location: "23.058566,78.715203",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING NAYAKHEDA ROOM NO. .1'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NAYAKHEDA ROOM NO. .1',23.058566,78.715203)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NAYAKHEDA ROOM NO. .1',23.058566,78.715203),",
    },
    {
      "PART NO": 278,
      "PART NAME": "NAYAKHEDA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING ROOM NO. .3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 781,
      location: "23.058566,78.715203",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING ROOM NO. .3'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ROOM NO. .3',23.058566,78.715203)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ROOM NO. .3',23.058566,78.715203),",
    },
    {
      "PART NO": 279,
      "PART NAME": "DEVARI",
      "Name of polling station building":
        "EKIKRIT GOVT. BOYS H.S.SCHOOL DEORI ADDITIONAL ROOM 15",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1192,
      location: "23.125143,78.682504",
      "inverted one":
        "'EKIKRIT GOVT. BOYS H.S.SCHOOL DEORI ADDITIONAL ROOM 15'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. BOYS H.S.SCHOOL DEORI ADDITIONAL ROOM 15',23.125143,78.682504)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. BOYS H.S.SCHOOL DEORI ADDITIONAL ROOM 15',23.125143,78.682504),",
    },
    {
      "PART NO": 280,
      "PART NAME": "DEVARI",
      "Name of polling station building":
        "EKIKRIT GOVT. BOYS H.S.SCHOOL DEORI ADDITIONAL ROOM NO. 13",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 871,
      location: "23.125052,78.682439",
      "inverted one":
        "'EKIKRIT GOVT. BOYS H.S.SCHOOL DEORI ADDITIONAL ROOM NO. 13'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. BOYS H.S.SCHOOL DEORI ADDITIONAL ROOM NO. 13',23.125052,78.682439)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. BOYS H.S.SCHOOL DEORI ADDITIONAL ROOM NO. 13',23.125052,78.682439),",
    },
    {
      "PART NO": 281,
      "PART NAME": "DEVARI",
      "Name of polling station building":
        "EKIKRIT GOVT. BOYS H.S.SCHOOL DEORI ADDITIONAL ROOM NO.10",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 741,
      location: "23.125556,78.682435",
      "inverted one":
        "'EKIKRIT GOVT. BOYS H.S.SCHOOL DEORI ADDITIONAL ROOM NO.10'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. BOYS H.S.SCHOOL DEORI ADDITIONAL ROOM NO.10',23.125556,78.682435)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. BOYS H.S.SCHOOL DEORI ADDITIONAL ROOM NO.10',23.125556,78.682435),",
    },
    {
      "PART NO": 282,
      "PART NAME": "DEVARI",
      "Name of polling station building":
        "EKIKRIT GOVT.BOYS H.S.SCHOOL DEORI ROOM NO.5",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 830,
      location: "23.125077,78.682499",
      "inverted one": "'EKIKRIT GOVT.BOYS H.S.SCHOOL DEORI ROOM NO.5'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.BOYS H.S.SCHOOL DEORI ROOM NO.5',23.125077,78.682499)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.BOYS H.S.SCHOOL DEORI ROOM NO.5',23.125077,78.682499),",
    },
    {
      "PART NO": 283,
      "PART NAME": "DEVARI",
      "Name of polling station building":
        "GOVT.GIRLS H.M.SCHOOL BUILDING DEVRI SOUTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1210,
      location: "23.121212,78.689295",
      "inverted one": "'GOVT.GIRLS H.M.SCHOOL BUILDING DEVRI SOUTH PART'",
      cosolideted:
        "ElectionBooth('GOVT.GIRLS H.M.SCHOOL BUILDING DEVRI SOUTH PART',23.121212,78.689295)",
      "with comma":
        "ElectionBooth('GOVT.GIRLS H.M.SCHOOL BUILDING DEVRI SOUTH PART',23.121212,78.689295),",
    },
    {
      "PART NO": 284,
      "PART NAME": "DEVARI",
      "Name of polling station building":
        "GOVT.GIRLS H.M.SCHOOL BUILDING DEVRI WEST PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1232,
      location: "23.121276,78.68931",
      "inverted one": "'GOVT.GIRLS H.M.SCHOOL BUILDING DEVRI WEST PART'",
      cosolideted:
        "ElectionBooth('GOVT.GIRLS H.M.SCHOOL BUILDING DEVRI WEST PART',23.121276,78.68931)",
      "with comma":
        "ElectionBooth('GOVT.GIRLS H.M.SCHOOL BUILDING DEVRI WEST PART',23.121276,78.68931),",
    },
    {
      "PART NO": 285,
      "PART NAME": "DEVARI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING SANJAY NAGAR DEVRI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 859,
      location: "23.123133,78.686416",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING SANJAY NAGAR DEVRI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SANJAY NAGAR DEVRI',23.123133,78.686416)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SANJAY NAGAR DEVRI',23.123133,78.686416),",
    },
    {
      "PART NO": 286,
      "PART NAME": "DEORI AALHANPUR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING ADDISTIONAL ROOM ALHANPUR",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 463,
      location: "23.132167,78.684749",
      "inverted one":
        "'GOVT.PRIMARY SCHOOL BUILDING ADDISTIONAL ROOM ALHANPUR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING ADDISTIONAL ROOM ALHANPUR',23.132167,78.684749)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING ADDISTIONAL ROOM ALHANPUR',23.132167,78.684749),",
    },
    {
      "PART NO": 287,
      "PART NAME": "GORAKHAPUR",
      "Name of polling station building":
        "GOVT.HIGH SCHOOL BUILDING GORAKHPUR (NORTH PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1236,
      location: "23.133334,78.717003",
      "inverted one": "'GOVT.HIGH SCHOOL BUILDING GORAKHPUR (NORTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING GORAKHPUR (NORTH PART)',23.133334,78.717003)",
      "with comma":
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING GORAKHPUR (NORTH PART)',23.133334,78.717003),",
    },
    {
      "PART NO": 288,
      "PART NAME": "GORAKHAPUR",
      "Name of polling station building":
        "GOVT.HIGH SCHOOL BUILDING GORAKHPUR (SOUTH PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1104,
      location: "23.133397,78.71691",
      "inverted one": "'GOVT.HIGH SCHOOL BUILDING GORAKHPUR (SOUTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING GORAKHPUR (SOUTH PART)',23.133397,78.71691)",
      "with comma":
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING GORAKHPUR (SOUTH PART)',23.133397,78.71691),",
    },
    {
      "PART NO": 289,
      "PART NAME": "BERKHEDI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BERKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1282,
      location: "23.167757,78.761344",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BERKHEDI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BERKHEDI',23.167757,78.761344)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING BERKHEDI',23.167757,78.761344),",
    },
    {
      "PART NO": 290,
      "PART NAME": "BARAKHANDA",
      "Name of polling station building":
        "GOVT. HIGH SCHOOL BUILDING BARKHANDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1058,
      location: "23.198077,78.770209",
      "inverted one": "'GOVT. HIGH SCHOOL BUILDING BARKHANDA'",
      cosolideted:
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING BARKHANDA',23.198077,78.770209)",
      "with comma":
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING BARKHANDA',23.198077,78.770209),",
    },
    {
      "PART NO": 291,
      "PART NAME": "KATAK",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KATAK",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 754,
      location: "23.246713,78.760064",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KATAK'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KATAK',23.246713,78.760064)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KATAK',23.246713,78.760064),",
    },
    {
      "PART NO": 292,
      "PART NAME": "HATHIDOL",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING HANTHIDOL",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 919,
      location: "23.246613,78.760054",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING HANTHIDOL'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING HANTHIDOL',23.246613,78.760054)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING HANTHIDOL',23.246613,78.760054),",
    },
    {
      "PART NO": 293,
      "PART NAME": "JAMANIYAN KHURD",
      "Name of polling station building":
        "GOVT.NEW PRIMARY SCHOOL BUILDING JAMANIYA KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 878,
      location: "23.193881,78.790778",
      "inverted one": "'GOVT.NEW PRIMARY SCHOOL BUILDING JAMANIYA KHURD'",
      cosolideted:
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING JAMANIYA KHURD',23.193881,78.790778)",
      "with comma":
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING JAMANIYA KHURD',23.193881,78.790778),",
    },
    {
      "PART NO": 294,
      "PART NAME": "PADARAI KHURD",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING PADRAI KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 462,
      location: "23.184479,78.789397",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING PADRAI KHURD'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PADRAI KHURD',23.184479,78.789397)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PADRAI KHURD',23.184479,78.789397),",
    },
    {
      "PART NO": 295,
      "PART NAME": "CHIKHLI",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHIKHLI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 563,
      location: "23.165526,78.789821",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHIKHLI'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHIKHLI',23.165526,78.789821)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING CHIKHLI',23.165526,78.789821),",
    },
    {
      "PART NO": 296,
      "PART NAME": "KODA JAMUNIYAN",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KODA JAMUNIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 972,
      location: "23.153398,78.783006",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KODA JAMUNIYA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KODA JAMUNIYA',23.153398,78.783006)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING KODA JAMUNIYA',23.153398,78.783006),",
    },
    {
      "PART NO": 297,
      "PART NAME": "KARAHAIYA KALA",
      "Name of polling station building": "PANCHAYAT BUILDING KARHIYAKALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1092,
      location: "23.144704,78.764446",
      "inverted one": "'PANCHAYAT BUILDING KARHIYAKALAN'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING KARHIYAKALAN',23.144704,78.764446)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING KARHIYAKALAN',23.144704,78.764446),",
    },
    {
      "PART NO": 298,
      "PART NAME": "GHUARAPUR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING GHUARPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 516,
      location: "23.143919,78.763737",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING GHUARPUR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING GHUARPUR',23.143919,78.763737)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING GHUARPUR',23.143919,78.763737),",
    },
    {
      "PART NO": 299,
      "PART NAME": "ITUAA",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING ITUA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 994,
      location: "23.129594,78.788834",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING ITUA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING ITUA',23.129594,78.788834)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING ITUA',23.129594,78.788834),",
    },
    {
      "PART NO": 300,
      "PART NAME": "AALIWADA",
      "Name of polling station building":
        "EKIKRIT GOVT.HIGH SCHOOL BUILDING AALIWADA ROOM NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 846,
      location: "23.11532,78.75085",
      "inverted one": "'EKIKRIT GOVT.HIGH SCHOOL BUILDING AALIWADA ROOM NO.1'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.HIGH SCHOOL BUILDING AALIWADA ROOM NO.1',23.11532,78.75085)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.HIGH SCHOOL BUILDING AALIWADA ROOM NO.1',23.11532,78.75085),",
    },
    {
      "PART NO": 301,
      "PART NAME": "AALIWADA",
      "Name of polling station building":
        "EKIKRIT GOVT.HIGH SCHOOL BUILDING AALIWADA ROOM NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 945,
      location: "23.11532,78.75085",
      "inverted one": "'EKIKRIT GOVT.HIGH SCHOOL BUILDING AALIWADA ROOM NO.2'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.HIGH SCHOOL BUILDING AALIWADA ROOM NO.2',23.11532,78.75085)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.HIGH SCHOOL BUILDING AALIWADA ROOM NO.2',23.11532,78.75085),",
    },
    {
      "PART NO": 302,
      "PART NAME": "KHIRETI",
      "Name of polling station building": "PANCHAYAT BUILDING KHIRETI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1042,
      location: "23.113426,78.752164",
      "inverted one": "'PANCHAYAT BUILDING KHIRETI'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING KHIRETI',23.113426,78.752164)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING KHIRETI',23.113426,78.752164),",
    },
    {
      "PART NO": 303,
      "PART NAME": "GAHLAWAN",
      "Name of polling station building":
        "EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GAHLAWAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 915,
      location: "23.08183,78.737524",
      "inverted one": "'EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GAHLAWAN'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GAHLAWAN',23.08183,78.737524)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT. MIDDLE SCHOOL BUILDING GAHLAWAN',23.08183,78.737524),",
    },
    {
      "PART NO": 304,
      "PART NAME": "TIMARAVAN",
      "Name of polling station building":
        "GOVT. HIGH SCHOOL BUILDING TIMRAVAN (NORTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 666,
      location: "23.01044,78.732919",
      "inverted one": "'GOVT. HIGH SCHOOL BUILDING TIMRAVAN (NORTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING TIMRAVAN (NORTH PART)',23.01044,78.732919)",
      "with comma":
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING TIMRAVAN (NORTH PART)',23.01044,78.732919),",
    },
    {
      "PART NO": 305,
      "PART NAME": "TIMARAVAN",
      "Name of polling station building":
        "GOVT. HIGH SCHOOL BUILDING TIMRAVAN (SOUTH PART)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 936,
      location: "23.091044,78.732919",
      "inverted one": "'GOVT. HIGH SCHOOL BUILDING TIMRAVAN (SOUTH PART)'",
      cosolideted:
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING TIMRAVAN (SOUTH PART)',23.091044,78.732919)",
      "with comma":
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING TIMRAVAN (SOUTH PART)',23.091044,78.732919),",
    },
    {
      "PART NO": 306,
      "PART NAME": "SADRAI",
      "Name of polling station building": "GOVT. MIDDLE SCHOOL BUILDING SADRAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 996,
      location: "23.076893,78.740359",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING SADRAI'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING SADRAI',23.076893,78.740359)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING SADRAI',23.076893,78.740359),",
    },
    {
      "PART NO": 307,
      "PART NAME": "PADRAI KALAN",
      "Name of polling station building":
        "EKIKRIT NEW MIDDLE SCHOOL BUILDING PADRAI KALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 516,
      location: "23.090405,78.771362",
      "inverted one": "'EKIKRIT NEW MIDDLE SCHOOL BUILDING PADRAI KALAN'",
      cosolideted:
        "ElectionBooth('EKIKRIT NEW MIDDLE SCHOOL BUILDING PADRAI KALAN',23.090405,78.771362)",
      "with comma":
        "ElectionBooth('EKIKRIT NEW MIDDLE SCHOOL BUILDING PADRAI KALAN',23.090405,78.771362),",
    },
    {
      "PART NO": 308,
      "PART NAME": "BOODHA",
      "Name of polling station building":
        "EKIKRIT GOVT.MIDDLE SCHOOL BUILDING BOODHA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 980,
      location: "23.096712,78.787535",
      "inverted one": "'EKIKRIT GOVT.MIDDLE SCHOOL BUILDING BOODHA'",
      cosolideted:
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING BOODHA',23.096712,78.787535)",
      "with comma":
        "ElectionBooth('EKIKRIT GOVT.MIDDLE SCHOOL BUILDING BOODHA',23.096712,78.787535),",
    },
  ],
  "141 Bhojpur": [
    {
      "PART NO": 1,
      "PART NAME": "BARAKHEDA SETU",
      "Name of polling station building": "PANCHAYAT BUILDING BARKHEDA SETU",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 975,
      location: "23.0506686330087,77.4199453270498",
      "inverted one": "'PANCHAYAT BUILDING BARKHEDA SETU'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING BARKHEDA SETU',23.0506686330087,77.4199453270498)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING BARKHEDA SETU',23.0506686330087,77.4199453270498),",
    },
    {
      "PART NO": 2,
      "PART NAME": "JHIRI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL JHIRIBAHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 591,
      location: "23.0380786051146,77.3801412363056",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL JHIRIBAHEDA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL JHIRIBAHEDA',23.0380786051146,77.3801412363056)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL JHIRIBAHEDA',23.0380786051146,77.3801412363056),",
    },
    {
      "PART NO": 3,
      "PART NAME": "BAHEDA",
      "Name of polling station building": "GOVT PRIMARI SCHOOL BUILDING BAHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 278,
      location: "23.0252351,77.3965636",
      "inverted one": "'GOVT PRIMARI SCHOOL BUILDING BAHEDA'",
      cosolideted:
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING BAHEDA',23.0252351,77.3965636)",
      "with comma":
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING BAHEDA',23.0252351,77.3965636),",
    },
    {
      "PART NO": 4,
      "PART NAME": "NANDOR",
      "Name of polling station building": "PANCHAYAT BUILDING NANDOR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1217,
      location: "23.0773363,77.4312293",
      "inverted one": "'PANCHAYAT BUILDING NANDOR'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING NANDOR',23.0773363,77.4312293)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING NANDOR',23.0773363,77.4312293),",
    },
    {
      "PART NO": 5,
      "PART NAME": "TUMDA KHEDA",
      "Name of polling station building": "PRIMARY SCHOOL BULDIND TUMDA KHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 580,
      location: "23.038188161287,77.380335506324",
      "inverted one": "'PRIMARY SCHOOL BULDIND TUMDA KHEDA'",
      cosolideted:
        "ElectionBooth('PRIMARY SCHOOL BULDIND TUMDA KHEDA',23.038188161287,77.380335506324)",
      "with comma":
        "ElectionBooth('PRIMARY SCHOOL BULDIND TUMDA KHEDA',23.038188161287,77.380335506324),",
    },
    {
      "PART NO": 6,
      "PART NAME": "GURADIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING GURADIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 490,
      location: "23.0699982093905,77.4584710789996",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING GURADIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GURADIYA',23.0699982093905,77.4584710789996)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GURADIYA',23.0699982093905,77.4584710789996),",
    },
    {
      "PART NO": 7,
      "PART NAME": "POLAHA",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGH SCHOOL POLAHA ROOM NO. 01",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 840,
      location: "23.087317201478,77.469747949769",
      "inverted one": "'EKIKRAT GOVT. HIGH SCHOOL POLAHA ROOM NO. 01'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL POLAHA ROOM NO. 01',23.087317201478,77.469747949769)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL POLAHA ROOM NO. 01',23.087317201478,77.469747949769),",
    },
    {
      "PART NO": 8,
      "PART NAME": "POLAHA",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGH SCHOOL POLAHA ROOM NO. 6",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 682,
      location: "23.087317201478,77.469747949769",
      "inverted one": "'EKIKRAT GOVT. HIGH SCHOOL POLAHA ROOM NO. 6'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL POLAHA ROOM NO. 6',23.087317201478,77.469747949769)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL POLAHA ROOM NO. 6',23.087317201478,77.469747949769),",
    },
    {
      "PART NO": 9,
      "PART NAME": "PADONIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PADENIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 750,
      location: "23.0601508289216,77.4718446483121",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PADENIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PADENIYA',23.0601508289216,77.4718446483121)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PADENIYA',23.0601508289216,77.4718446483121),",
    },
    {
      "PART NO": 10,
      "PART NAME": "KUMADIVITHORI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL KUMDI BITHORI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 978,
      location: "23.02799444,77.44268467",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL KUMDI BITHORI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL KUMDI BITHORI',23.02799444,77.44268467)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL KUMDI BITHORI',23.02799444,77.44268467),",
    },
    {
      "PART NO": 11,
      "PART NAME": "JAVARA MALAKHAR",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL JAVRA MALAKHAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 543,
      location: "22.9847332325711,77.4692314260234",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL JAVRA MALAKHAR'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL JAVRA MALAKHAR',22.9847332325711,77.4692314260234)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL JAVRA MALAKHAR',22.9847332325711,77.4692314260234),",
    },
    {
      "PART NO": 12,
      "PART NAME": "BAGVANI",
      "Name of polling station building":
        "GOVT PRIMARI SCHOOL BUILDING BAGVANI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 669,
      location: "23.0034346,77.4528034",
      "inverted one": "'GOVT PRIMARI SCHOOL BUILDING BAGVANI'",
      cosolideted:
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING BAGVANI',23.0034346,77.4528034)",
      "with comma":
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING BAGVANI',23.0034346,77.4528034),",
    },
    {
      "PART NO": 13,
      "PART NAME": "DAHOD",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGH SCHOOL DAHOD ROOM NO. 3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1015,
      location: "23.0345951732055,77.4877123955761",
      "inverted one": "'EKIKRAT GOVT. HIGH SCHOOL DAHOD ROOM NO. 3'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL DAHOD ROOM NO. 3',23.0345951732055,77.4877123955761)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL DAHOD ROOM NO. 3',23.0345951732055,77.4877123955761),",
    },
    {
      "PART NO": 14,
      "PART NAME": "DAHOD",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGH SCHOOL DAHOD ROOM NO. 2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1050,
      location: "23.0346084364858,77.4876437433852",
      "inverted one": "'EKIKRAT GOVT. HIGH SCHOOL DAHOD ROOM NO. 2'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL DAHOD ROOM NO. 2',23.0346084364858,77.4876437433852)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL DAHOD ROOM NO. 2',23.0346084364858,77.4876437433852),",
    },
    {
      "PART NO": 15,
      "PART NAME": "DAHOD",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGH SCHOOL DAHOD ROOM NO. 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 557,
      location: "23.0344528371358,77.487632592832",
      "inverted one": "'EKIKRAT GOVT. HIGH SCHOOL DAHOD ROOM NO. 1'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL DAHOD ROOM NO. 1',23.0344528371358,77.487632592832)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL DAHOD ROOM NO. 1',23.0344528371358,77.487632592832),",
    },
    {
      "PART NO": 16,
      "PART NAME": "PIPALIYA GAJJU",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL PIPALIYAGAJJU ROOM NO. 1",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 953,
      location: "23.0893001393019,77.4973595248554",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL PIPALIYAGAJJU ROOM NO. 1'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL PIPALIYAGAJJU ROOM NO. 1',23.0893001393019,77.4973595248554)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL PIPALIYAGAJJU ROOM NO. 1',23.0893001393019,77.4973595248554),",
    },
    {
      "PART NO": 17,
      "PART NAME": "PIPALIYA GAJJU",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL PIPALIYAGAJJU ROOM NO. 2",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 843,
      location: "23.0890366430716,77.4974229836607",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL PIPALIYAGAJJU ROOM NO. 2'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL PIPALIYAGAJJU ROOM NO. 2',23.0890366430716,77.4974229836607)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL PIPALIYAGAJJU ROOM NO. 2',23.0890366430716,77.4974229836607),",
    },
    {
      "PART NO": 18,
      "PART NAME": "BAMULIYA PAVAR",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL VAMULIYA PAWAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 537,
      location: "23.0739313101219,77.4855409841306",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL VAMULIYA PAWAR'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL VAMULIYA PAWAR',23.0739313101219,77.4855409841306)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL VAMULIYA PAWAR',23.0739313101219,77.4855409841306),",
    },
    {
      "PART NO": 19,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "KRISHI UPAJ MANDI OFFICE NORTH PART ROOM NO. 1 MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 743,
      location: "23.10069943,77.5139587",
      "inverted one":
        "'KRISHI UPAJ MANDI OFFICE NORTH PART ROOM NO. 1 MANDIDEEP'",
      cosolideted:
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE NORTH PART ROOM NO. 1 MANDIDEEP',23.10069943,77.5139587)",
      "with comma":
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE NORTH PART ROOM NO. 1 MANDIDEEP',23.10069943,77.5139587),",
    },
    {
      "PART NO": 20,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "KRISHI UPAJ MANDI OFFICE NORTH PART ROOM NO 2 MANDEEDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 798,
      location: "23.10082086,77.51395705",
      "inverted one":
        "'KRISHI UPAJ MANDI OFFICE NORTH PART ROOM NO 2 MANDEEDEEP'",
      cosolideted:
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE NORTH PART ROOM NO 2 MANDEEDEEP',23.10082086,77.51395705)",
      "with comma":
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE NORTH PART ROOM NO 2 MANDEEDEEP',23.10082086,77.51395705),",
    },
    {
      "PART NO": 21,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "KRISHI UPAJ MANDI OFFICE BUILDING NORTH PART MANDEEP ROOM NO. 3",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1063,
      location: "23.10088375,77.51376488",
      "inverted one":
        "'KRISHI UPAJ MANDI OFFICE BUILDING NORTH PART MANDEEP ROOM NO. 3'",
      cosolideted:
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE BUILDING NORTH PART MANDEEP ROOM NO. 3',23.10088375,77.51376488)",
      "with comma":
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE BUILDING NORTH PART MANDEEP ROOM NO. 3',23.10088375,77.51376488),",
    },
    {
      "PART NO": 22,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "KRISHI UPAJ MANDI OFFICE BUILDING NORTH PART MANDIDEEP ROOM NO. 4",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 756,
      location: "23.10062257,77.51401391",
      "inverted one":
        "'KRISHI UPAJ MANDI OFFICE BUILDING NORTH PART MANDIDEEP ROOM NO. 4'",
      cosolideted:
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE BUILDING NORTH PART MANDIDEEP ROOM NO. 4',23.10062257,77.51401391)",
      "with comma":
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE BUILDING NORTH PART MANDIDEEP ROOM NO. 4',23.10062257,77.51401391),",
    },
    {
      "PART NO": 23,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "KRASI UPAJ MANDI OFFICE BUILDING NORTH PART MANDIDEEP ROOM NO. 5",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 718,
      location: "23.1006683,77.5138317",
      "inverted one":
        "'KRASI UPAJ MANDI OFFICE BUILDING NORTH PART MANDIDEEP ROOM NO. 5'",
      cosolideted:
        "ElectionBooth('KRASI UPAJ MANDI OFFICE BUILDING NORTH PART MANDIDEEP ROOM NO. 5',23.1006683,77.5138317)",
      "with comma":
        "ElectionBooth('KRASI UPAJ MANDI OFFICE BUILDING NORTH PART MANDIDEEP ROOM NO. 5',23.1006683,77.5138317),",
    },
    {
      "PART NO": 24,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "KRISHI UPAJ MANDI OFFICE MANDEEP ROOM NO. 6",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 897,
      location: "23.1006743,77.5142714",
      "inverted one": "'KRISHI UPAJ MANDI OFFICE MANDEEP ROOM NO. 6'",
      cosolideted:
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE MANDEEP ROOM NO. 6',23.1006743,77.5142714)",
      "with comma":
        "ElectionBooth('KRISHI UPAJ MANDI OFFICE MANDEEP ROOM NO. 6',23.1006743,77.5142714),",
    },
    {
      "PART NO": 25,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "KRASI UPAJ MANDI OFFICE MANDIDEEP ROOM NO. 7",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 740,
      location: "23.1007719,77.5136142",
      "inverted one": "'KRASI UPAJ MANDI OFFICE MANDIDEEP ROOM NO. 7'",
      cosolideted:
        "ElectionBooth('KRASI UPAJ MANDI OFFICE MANDIDEEP ROOM NO. 7',23.1007719,77.5136142)",
      "with comma":
        "ElectionBooth('KRASI UPAJ MANDI OFFICE MANDIDEEP ROOM NO. 7',23.1007719,77.5136142),",
    },
    {
      "PART NO": 26,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "MAHARANA PRATAP  HIGHER SECONDARY  SCHOOL MANDIDEEP ROOM NO. 2",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1062,
      location: "23.10043301,77.51422535",
      "inverted one":
        "'MAHARANA PRATAP  HIGHER SECONDARY  SCHOOL MANDIDEEP ROOM NO. 2'",
      cosolideted:
        "ElectionBooth('MAHARANA PRATAP  HIGHER SECONDARY  SCHOOL MANDIDEEP ROOM NO. 2',23.10043301,77.51422535)",
      "with comma":
        "ElectionBooth('MAHARANA PRATAP  HIGHER SECONDARY  SCHOOL MANDIDEEP ROOM NO. 2',23.10043301,77.51422535),",
    },
    {
      "PART NO": 27,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "MAHARANA PRATAP  HIGHER SECONDARY  SCHOOL MANDIDEEP ROOM NO. 1",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1070,
      location: "23.1003883,77.51423534",
      "inverted one":
        "'MAHARANA PRATAP  HIGHER SECONDARY  SCHOOL MANDIDEEP ROOM NO. 1'",
      cosolideted:
        "ElectionBooth('MAHARANA PRATAP  HIGHER SECONDARY  SCHOOL MANDIDEEP ROOM NO. 1',23.1003883,77.51423534)",
      "with comma":
        "ElectionBooth('MAHARANA PRATAP  HIGHER SECONDARY  SCHOOL MANDIDEEP ROOM NO. 1',23.1003883,77.51423534),",
    },
    {
      "PART NO": 28,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "VRIHATTAKAR GOVT. SEVA SOCIETY NEW BUILDING MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1073,
      location: "23.09996411,77.51025066",
      "inverted one": "'VRIHATTAKAR GOVT. SEVA SOCIETY NEW BUILDING MANDIDEEP'",
      cosolideted:
        "ElectionBooth('VRIHATTAKAR GOVT. SEVA SOCIETY NEW BUILDING MANDIDEEP',23.09996411,77.51025066)",
      "with comma":
        "ElectionBooth('VRIHATTAKAR GOVT. SEVA SOCIETY NEW BUILDING MANDIDEEP',23.09996411,77.51025066),",
    },
    {
      "PART NO": 29,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDDEP ROOM NO. 12",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 970,
      location: "23.09595636,77.51479163",
      "inverted one":
        "'EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDDEP ROOM NO. 12'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDDEP ROOM NO. 12',23.09595636,77.51479163)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDDEP ROOM NO. 12',23.09595636,77.51479163),",
    },
    {
      "PART NO": 30,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. BOYS HIGHER SCHOOL MANDIDEEP ROOM NO. 13",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 664,
      location: "23.0959557,77.5148497",
      "inverted one":
        "'EKIKRAT GOVT. BOYS HIGHER SCHOOL MANDIDEEP ROOM NO. 13'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SCHOOL MANDIDEEP ROOM NO. 13',23.0959557,77.5148497)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SCHOOL MANDIDEEP ROOM NO. 13',23.0959557,77.5148497),",
    },
    {
      "PART NO": 31,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 1",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 554,
      location: "23.09605058,77.5150987",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 1'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 1',23.09605058,77.5150987)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 1',23.09605058,77.5150987),",
    },
    {
      "PART NO": 32,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 2",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 717,
      location: "23.09588734,77.51433189",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 2'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 2',23.09588734,77.51433189)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 2',23.09588734,77.51433189),",
    },
    {
      "PART NO": 33,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 3",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1081,
      location: "23.09588727,77.51432343",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 3'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 3',23.09588727,77.51432343)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 3',23.09588727,77.51432343),",
    },
    {
      "PART NO": 34,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 05",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 448,
      location: "23.09559655,77.51518154",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 05'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 05',23.09559655,77.51518154)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (BOYS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 05',23.09559655,77.51518154),",
    },
    {
      "PART NO": 35,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL SOUTH PART MANDIDEEP ROOM NO. 1",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 634,
      location: "23.09629392,77.51406156",
      "inverted one":
        "'EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL SOUTH PART MANDIDEEP ROOM NO. 1'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL SOUTH PART MANDIDEEP ROOM NO. 1',23.09629392,77.51406156)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL SOUTH PART MANDIDEEP ROOM NO. 1',23.09629392,77.51406156),",
    },
    {
      "PART NO": 36,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL SOUTH PART MANDIDEEP ROOM NO. 3",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 589,
      location: "23.09559733,77.51513526",
      "inverted one":
        "'EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL SOUTH PART MANDIDEEP ROOM NO. 3'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL SOUTH PART MANDIDEEP ROOM NO. 3',23.09559733,77.51513526)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL SOUTH PART MANDIDEEP ROOM NO. 3',23.09559733,77.51513526),",
    },
    {
      "PART NO": 37,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 2",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 786,
      location: "23.0956114,77.51470678",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 2'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 2',23.0956114,77.51470678)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 2',23.0956114,77.51470678),",
    },
    {
      "PART NO": 38,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 3",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 733,
      location: "23.09568139,77.51459487",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 3'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 3',23.09568139,77.51459487)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 3',23.09568139,77.51459487),",
    },
    {
      "PART NO": 39,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 01",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 840,
      location: "23.09527119,77.51449885",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 01'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 01',23.09527119,77.51449885)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 01',23.09527119,77.51449885),",
    },
    {
      "PART NO": 40,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 02",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 786,
      location: "23.0953697,77.51463785",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 02'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 02',23.0953697,77.51463785)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 02',23.0953697,77.51463785),",
    },
    {
      "PART NO": 41,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 03",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 969,
      location: "23.0951345,77.51447207",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 03'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 03',23.0951345,77.51447207)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 03',23.0951345,77.51447207),",
    },
    {
      "PART NO": 42,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 04",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 715,
      location: "23.0952087,77.5145284",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 04'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 04',23.0952087,77.5145284)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 04',23.0952087,77.5145284),",
    },
    {
      "PART NO": 43,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 06",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 504,
      location: "23.0953904,77.5143256",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 06'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 06',23.0953904,77.5143256)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 06',23.0953904,77.5143256),",
    },
    {
      "PART NO": 44,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 09",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 640,
      location: "23.09535833,77.51450757",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 09'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 09',23.09535833,77.51450757)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 09',23.09535833,77.51450757),",
    },
    {
      "PART NO": 45,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 10",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 960,
      location: "23.0954032,77.5143649",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 10'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 10',23.0954032,77.5143649)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 10',23.0954032,77.5143649),",
    },
    {
      "PART NO": 46,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 11",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 732,
      location: "23.0954367435747,77.5143719896849",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 11'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 11',23.0954367435747,77.5143719896849)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL (GIRLS MIDDLE SCHOOL BUILDING) MANDIDEEP ROOM NO. 11',23.0954367435747,77.5143719896849),",
    },
    {
      "PART NO": 47,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 4",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 732,
      location: "23.0956868081222,77.515123838194",
      "inverted one":
        "'EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 4'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 4',23.0956868081222,77.515123838194)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 4',23.0956868081222,77.515123838194),",
    },
    {
      "PART NO": 48,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 10",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 944,
      location: "23.0955918,77.5152446",
      "inverted one":
        "'EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 10'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 10',23.0955918,77.5152446)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 10',23.0955918,77.5152446),",
    },
    {
      "PART NO": 49,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 15",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1164,
      location: "23.0959648187495,77.5149983412033",
      "inverted one":
        "'EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 15'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 15',23.0959648187495,77.5149983412033)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. BOYS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 15',23.0959648187495,77.5149983412033),",
    },
    {
      "PART NO": 50,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "C.L.AARYA SCIENCE H.S.SCHOOL ROOM NO 1 MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1006,
      location: "23.0933876430984,77.5169437793471",
      "inverted one": "'C.L.AARYA SCIENCE H.S.SCHOOL ROOM NO 1 MANDIDEEP'",
      cosolideted:
        "ElectionBooth('C.L.AARYA SCIENCE H.S.SCHOOL ROOM NO 1 MANDIDEEP',23.0933876430984,77.5169437793471)",
      "with comma":
        "ElectionBooth('C.L.AARYA SCIENCE H.S.SCHOOL ROOM NO 1 MANDIDEEP',23.0933876430984,77.5169437793471),",
    },
    {
      "PART NO": 51,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "C.L.AARYA SCIENCE H.S.SCHOOL ROOM NO 2 MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 400,
      location: "23.095224728997,77.516599562024",
      "inverted one": "'C.L.AARYA SCIENCE H.S.SCHOOL ROOM NO 2 MANDIDEEP'",
      cosolideted:
        "ElectionBooth('C.L.AARYA SCIENCE H.S.SCHOOL ROOM NO 2 MANDIDEEP',23.095224728997,77.516599562024)",
      "with comma":
        "ElectionBooth('C.L.AARYA SCIENCE H.S.SCHOOL ROOM NO 2 MANDIDEEP',23.095224728997,77.516599562024),",
    },
    {
      "PART NO": 52,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 10",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 537,
      location: "23.0955780196359,77.5145215863682",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 10'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 10',23.0955780196359,77.5145215863682)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 10',23.0955780196359,77.5145215863682),",
    },
    {
      "PART NO": 53,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 4",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 449,
      location: "23.0954048726884,77.5145162367329",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 4'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 4',23.0954048726884,77.5145162367329)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS HIGHER SECONDARY SCHOOL MANDIDEEP ROOM NO. 4',23.0954048726884,77.5145162367329),",
    },
    {
      "PART NO": 54,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "NEW NAGAR PALIKA OFFICE BUILDING COMMUNITY HALL MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 811,
      location: "23.0933787053257,77.5168875359269",
      "inverted one":
        "'NEW NAGAR PALIKA OFFICE BUILDING COMMUNITY HALL MANDIDEEP'",
      cosolideted:
        "ElectionBooth('NEW NAGAR PALIKA OFFICE BUILDING COMMUNITY HALL MANDIDEEP',23.0933787053257,77.5168875359269)",
      "with comma":
        "ElectionBooth('NEW NAGAR PALIKA OFFICE BUILDING COMMUNITY HALL MANDIDEEP',23.0933787053257,77.5168875359269),",
    },
    {
      "PART NO": 55,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "NEW NAGAR PALIKA BHAWAN COMMUNITY HALL PURVI BHAG MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 480,
      location: "23.0935405033843,77.5167302729455",
      "inverted one":
        "'NEW NAGAR PALIKA BHAWAN COMMUNITY HALL PURVI BHAG MANDIDEEP'",
      cosolideted:
        "ElectionBooth('NEW NAGAR PALIKA BHAWAN COMMUNITY HALL PURVI BHAG MANDIDEEP',23.0935405033843,77.5167302729455)",
      "with comma":
        "ElectionBooth('NEW NAGAR PALIKA BHAWAN COMMUNITY HALL PURVI BHAG MANDIDEEP',23.0935405033843,77.5167302729455),",
    },
    {
      "PART NO": 56,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING ROOM NO. 01 RAHUL NAGAR MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 736,
      location: "23.09554947,77.51450911",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL BUILDING ROOM NO. 01 RAHUL NAGAR MANDIDEEP'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ROOM NO. 01 RAHUL NAGAR MANDIDEEP',23.09554947,77.51450911)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ROOM NO. 01 RAHUL NAGAR MANDIDEEP',23.09554947,77.51450911),",
    },
    {
      "PART NO": 57,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING ROOM NO. 02 RAHUL NAGAR MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 625,
      location: "23.0916593,77.5305551",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL BUILDING ROOM NO. 02 RAHUL NAGAR MANDIDEEP'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ROOM NO. 02 RAHUL NAGAR MANDIDEEP',23.0916593,77.5305551)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ROOM NO. 02 RAHUL NAGAR MANDIDEEP',23.0916593,77.5305551),",
    },
    {
      "PART NO": 58,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING ROOM NO. 03 RAHUL NAGAR MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 733,
      location: "23.09341598,77.51683572",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL BUILDING ROOM NO. 03 RAHUL NAGAR MANDIDEEP'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ROOM NO. 03 RAHUL NAGAR MANDIDEEP',23.09341598,77.51683572)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ROOM NO. 03 RAHUL NAGAR MANDIDEEP',23.09341598,77.51683572),",
    },
    {
      "PART NO": 59,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "AKVN BUILDING WESTERN PART MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1450,
      location: "23.09167225,77.5200398",
      "inverted one": "'AKVN BUILDING WESTERN PART MANDIDEEP'",
      cosolideted:
        "ElectionBooth('AKVN BUILDING WESTERN PART MANDIDEEP',23.09167225,77.5200398)",
      "with comma":
        "ElectionBooth('AKVN BUILDING WESTERN PART MANDIDEEP',23.09167225,77.5200398),",
    },
    {
      "PART NO": 60,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "STALLED KENDRA VIKAS NGAM KE PRANGAN ME STHIT TAHSEEL OFFICE BUILDING OF ADDITIONAL ROOM MANDIDEEP ROOM NO 1",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 750,
      location: "23.08669454,77.52667957",
      "inverted one":
        "'STALLED KENDRA VIKAS NGAM KE PRANGAN ME STHIT TAHSEEL OFFICE BUILDING OF ADDITIONAL ROOM MANDIDEEP ROOM NO 1'",
      cosolideted:
        "ElectionBooth('STALLED KENDRA VIKAS NGAM KE PRANGAN ME STHIT TAHSEEL OFFICE BUILDING OF ADDITIONAL ROOM MANDIDEEP ROOM NO 1',23.08669454,77.52667957)",
      "with comma":
        "ElectionBooth('STALLED KENDRA VIKAS NGAM KE PRANGAN ME STHIT TAHSEEL OFFICE BUILDING OF ADDITIONAL ROOM MANDIDEEP ROOM NO 1',23.08669454,77.52667957),",
    },
    {
      "PART NO": 61,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "STALLED KENDRA VIKAS NIGAM KE PRANGAD ME STHIT TAHSEEL OFFICE BUILDING OF ADDITIONAL ROOM MANDIDEEP ROOM NO 2",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 824,
      location: "23.0916975146858,77.5200167332239",
      "inverted one":
        "'STALLED KENDRA VIKAS NIGAM KE PRANGAD ME STHIT TAHSEEL OFFICE BUILDING OF ADDITIONAL ROOM MANDIDEEP ROOM NO 2'",
      cosolideted:
        "ElectionBooth('STALLED KENDRA VIKAS NIGAM KE PRANGAD ME STHIT TAHSEEL OFFICE BUILDING OF ADDITIONAL ROOM MANDIDEEP ROOM NO 2',23.0916975146858,77.5200167332239)",
      "with comma":
        "ElectionBooth('STALLED KENDRA VIKAS NIGAM KE PRANGAD ME STHIT TAHSEEL OFFICE BUILDING OF ADDITIONAL ROOM MANDIDEEP ROOM NO 2',23.0916975146858,77.5200167332239),",
    },
    {
      "PART NO": 62,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "GOVT.GIRLS MIDDLE SCHOOL NORTH PART ROOM NO.-1 PATEL NAGAR MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 999,
      location: "23.0917894,77.5163853",
      "inverted one":
        "'GOVT.GIRLS MIDDLE SCHOOL NORTH PART ROOM NO.-1 PATEL NAGAR MANDIDEEP'",
      cosolideted:
        "ElectionBooth('GOVT.GIRLS MIDDLE SCHOOL NORTH PART ROOM NO.-1 PATEL NAGAR MANDIDEEP',23.0917894,77.5163853)",
      "with comma":
        "ElectionBooth('GOVT.GIRLS MIDDLE SCHOOL NORTH PART ROOM NO.-1 PATEL NAGAR MANDIDEEP',23.0917894,77.5163853),",
    },
    {
      "PART NO": 63,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "GOVT GIRLS MIDDLE SCHOOL NORTH PART ROOM NO 2 PATEL NAGAR MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 631,
      location: "23.0918294,77.5164271",
      "inverted one":
        "'GOVT GIRLS MIDDLE SCHOOL NORTH PART ROOM NO 2 PATEL NAGAR MANDIDEEP'",
      cosolideted:
        "ElectionBooth('GOVT GIRLS MIDDLE SCHOOL NORTH PART ROOM NO 2 PATEL NAGAR MANDIDEEP',23.0918294,77.5164271)",
      "with comma":
        "ElectionBooth('GOVT GIRLS MIDDLE SCHOOL NORTH PART ROOM NO 2 PATEL NAGAR MANDIDEEP',23.0918294,77.5164271),",
    },
    {
      "PART NO": 64,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "GOVT. GIRLS MIDDLE SCHOOL ROOM NO.-3 MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 806,
      location: "23.09195454,77.5166464",
      "inverted one": "'GOVT. GIRLS MIDDLE SCHOOL ROOM NO.-3 MANDIDEEP'",
      cosolideted:
        "ElectionBooth('GOVT. GIRLS MIDDLE SCHOOL ROOM NO.-3 MANDIDEEP',23.09195454,77.5166464)",
      "with comma":
        "ElectionBooth('GOVT. GIRLS MIDDLE SCHOOL ROOM NO.-3 MANDIDEEP',23.09195454,77.5166464),",
    },
    {
      "PART NO": 65,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "GOVT GIRLS MIDDLE SCHOOL ROOM NO 4 MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 401,
      location: "23.09198162,77.51654298",
      "inverted one": "'GOVT GIRLS MIDDLE SCHOOL ROOM NO 4 MANDIDEEP'",
      cosolideted:
        "ElectionBooth('GOVT GIRLS MIDDLE SCHOOL ROOM NO 4 MANDIDEEP',23.09198162,77.51654298)",
      "with comma":
        "ElectionBooth('GOVT GIRLS MIDDLE SCHOOL ROOM NO 4 MANDIDEEP',23.09198162,77.51654298),",
    },
    {
      "PART NO": 66,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "GOVT.GIRLS MIDDLE SCHOOL EXTRA ROOM (PATEL NAGAR) MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 797,
      location: "23.09219764,77.51647393",
      "inverted one":
        "'GOVT.GIRLS MIDDLE SCHOOL EXTRA ROOM (PATEL NAGAR) MANDIDEEP'",
      cosolideted:
        "ElectionBooth('GOVT.GIRLS MIDDLE SCHOOL EXTRA ROOM (PATEL NAGAR) MANDIDEEP',23.09219764,77.51647393)",
      "with comma":
        "ElectionBooth('GOVT.GIRLS MIDDLE SCHOOL EXTRA ROOM (PATEL NAGAR) MANDIDEEP',23.09219764,77.51647393),",
    },
    {
      "PART NO": 67,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "OFFICE AYURVEDIK DISPENSARY NORTH PART MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1402,
      location: "23.09218493,77.51653397",
      "inverted one": "'OFFICE AYURVEDIK DISPENSARY NORTH PART MANDIDEEP'",
      cosolideted:
        "ElectionBooth('OFFICE AYURVEDIK DISPENSARY NORTH PART MANDIDEEP',23.09218493,77.51653397)",
      "with comma":
        "ElectionBooth('OFFICE AYURVEDIK DISPENSARY NORTH PART MANDIDEEP',23.09218493,77.51653397),",
    },
    {
      "PART NO": 68,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "OFFICE AYURVEDIK DISPENSARY SOUTH PART MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1167,
      location: "23.0925392,77.5110308",
      "inverted one": "'OFFICE AYURVEDIK DISPENSARY SOUTH PART MANDIDEEP'",
      cosolideted:
        "ElectionBooth('OFFICE AYURVEDIK DISPENSARY SOUTH PART MANDIDEEP',23.0925392,77.5110308)",
      "with comma":
        "ElectionBooth('OFFICE AYURVEDIK DISPENSARY SOUTH PART MANDIDEEP',23.0925392,77.5110308),",
    },
    {
      "PART NO": 69,
      "PART NAME": "MANDIDIP",
      "Name of polling station building": "VIDIYA PUBLIC H.S.S. MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1246,
      location: "23.0953531,77.5167061",
      "inverted one": "'VIDIYA PUBLIC H.S.S. MANDIDEEP'",
      cosolideted:
        "ElectionBooth('VIDIYA PUBLIC H.S.S. MANDIDEEP',23.0953531,77.5167061)",
      "with comma":
        "ElectionBooth('VIDIYA PUBLIC H.S.S. MANDIDEEP',23.0953531,77.5167061),",
    },
    {
      "PART NO": 70,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "VIDIYA PUBLIC H.S.S. MANDIDEEP ROOM NO. 4",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1127,
      location: "23.0978691,77.5047893",
      "inverted one": "'VIDIYA PUBLIC H.S.S. MANDIDEEP ROOM NO. 4'",
      cosolideted:
        "ElectionBooth('VIDIYA PUBLIC H.S.S. MANDIDEEP ROOM NO. 4',23.0978691,77.5047893)",
      "with comma":
        "ElectionBooth('VIDIYA PUBLIC H.S.S. MANDIDEEP ROOM NO. 4',23.0978691,77.5047893),",
    },
    {
      "PART NO": 71,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "VIDIYA PUBLIC H.S.S. MANDIDEEP ROOM NO. 6",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 900,
      location: "23.0977212,77.504598",
      "inverted one": "'VIDIYA PUBLIC H.S.S. MANDIDEEP ROOM NO. 6'",
      cosolideted:
        "ElectionBooth('VIDIYA PUBLIC H.S.S. MANDIDEEP ROOM NO. 6',23.0977212,77.504598)",
      "with comma":
        "ElectionBooth('VIDIYA PUBLIC H.S.S. MANDIDEEP ROOM NO. 6',23.0977212,77.504598),",
    },
    {
      "PART NO": 72,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "GREFAID H.S.SCHOOL SOUTH PART MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 690,
      location: "23.0970115,77.5189925",
      "inverted one": "'GREFAID H.S.SCHOOL SOUTH PART MANDIDEEP'",
      cosolideted:
        "ElectionBooth('GREFAID H.S.SCHOOL SOUTH PART MANDIDEEP',23.0970115,77.5189925)",
      "with comma":
        "ElectionBooth('GREFAID H.S.SCHOOL SOUTH PART MANDIDEEP',23.0970115,77.5189925),",
    },
    {
      "PART NO": 73,
      "PART NAME": "MANDIDIP",
      "Name of polling station building":
        "GREFAID H.S.SCHOOL SOUTH PART MANDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 586,
      location: "23.0970315,77.5190319",
      "inverted one": "'GREFAID H.S.SCHOOL SOUTH PART MANDEEP'",
      cosolideted:
        "ElectionBooth('GREFAID H.S.SCHOOL SOUTH PART MANDEEP',23.0970315,77.5190319)",
      "with comma":
        "ElectionBooth('GREFAID H.S.SCHOOL SOUTH PART MANDEEP',23.0970315,77.5190319),",
    },
    {
      "PART NO": 74,
      "PART NAME": "ITAYAKALAN",
      "Name of polling station building":
        "WARD KAARYAALAY , WARD KRAMANK 25 , ITAYAKALAN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1078,
      location: "22.9987104982137,77.5862031709402",
      "inverted one": "'WARD KAARYAALAY , WARD KRAMANK 25 , ITAYAKALAN'",
      cosolideted:
        "ElectionBooth('WARD KAARYAALAY , WARD KRAMANK 25 , ITAYAKALAN',22.9987104982137,77.5862031709402)",
      "with comma":
        "ElectionBooth('WARD KAARYAALAY , WARD KRAMANK 25 , ITAYAKALAN',22.9987104982137,77.5862031709402),",
    },
    {
      "PART NO": 75,
      "PART NAME": "NAYAPURA MEVATI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BULDING NAYAPURA MEVATI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 657,
      location: "23.0738496500998,77.5363326072692",
      "inverted one": "'GOVT.PRIMARY SCHOOL BULDING NAYAPURA MEVATI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BULDING NAYAPURA MEVATI',23.0738496500998,77.5363326072692)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BULDING NAYAPURA MEVATI',23.0738496500998,77.5363326072692),",
    },
    {
      "PART NO": 76,
      "PART NAME": "NAYAPURA MEVATI",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING NAYAPURAMEVATI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 465,
      location: "23.0739835929125,77.5367394648492",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING NAYAPURAMEVATI'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING NAYAPURAMEVATI',23.0739835929125,77.5367394648492)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING NAYAPURAMEVATI',23.0739835929125,77.5367394648492),",
    },
    {
      "PART NO": 77,
      "PART NAME": "JHALAR KLA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING JHALARKALAN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1007,
      location: "23.0021882755681,77.5853427685797",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING JHALARKALAN'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING JHALARKALAN',23.0021882755681,77.5853427685797)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING JHALARKALAN',23.0021882755681,77.5853427685797),",
    },
    {
      "PART NO": 78,
      "PART NAME": "SARAKIYA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL NORTH PART SARAKIYA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1190,
      location: "22.9985319636762,77.5863384548574",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL NORTH PART SARAKIYA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL NORTH PART SARAKIYA',22.9985319636762,77.5863384548574)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL NORTH PART SARAKIYA',22.9985319636762,77.5863384548574),",
    },
    {
      "PART NO": 79,
      "PART NAME": "SARAKIYA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL NEW PRIMARY SCHOOL BUILDING SARAKIYA SOUTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 752,
      location: "22.9985435726121,77.5864326674491",
      "inverted one":
        "'EKIKRAT GOVT. MIDDILE SCHOOL NEW PRIMARY SCHOOL BUILDING SARAKIYA SOUTH PART'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL NEW PRIMARY SCHOOL BUILDING SARAKIYA SOUTH PART',22.9985435726121,77.5864326674491)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL NEW PRIMARY SCHOOL BUILDING SARAKIYA SOUTH PART',22.9985435726121,77.5864326674491),",
    },
    {
      "PART NO": 80,
      "PART NAME": "SIMARAI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BUILDING SIMRAI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 788,
      location: "23.04815487,77.54976083",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL BUILDING SIMRAI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING SIMRAI',23.04815487,77.54976083)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING SIMRAI',23.04815487,77.54976083),",
    },
    {
      "PART NO": 81,
      "PART NAME": "SIMARAI",
      "Name of polling station building":
        "WARD KARYALAY NO 25 NAGAR PALIKA PARISHAD SIMRAI MANDIDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 812,
      location: "23.0482491,77.5497431",
      "inverted one":
        "'WARD KARYALAY NO 25 NAGAR PALIKA PARISHAD SIMRAI MANDIDEEP'",
      cosolideted:
        "ElectionBooth('WARD KARYALAY NO 25 NAGAR PALIKA PARISHAD SIMRAI MANDIDEEP',23.0482491,77.5497431)",
      "with comma":
        "ElectionBooth('WARD KARYALAY NO 25 NAGAR PALIKA PARISHAD SIMRAI MANDIDEEP',23.0482491,77.5497431),",
    },
    {
      "PART NO": 82,
      "PART NAME": "BAIRASIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BAIRASIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 350,
      location: "22.9987856000661,77.586207948625",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BAIRASIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAIRASIYA',22.9987856000661,77.586207948625)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAIRASIYA',22.9987856000661,77.586207948625),",
    },
    {
      "PART NO": 83,
      "PART NAME": "NURGANJ",
      "Name of polling station building":
        "EKIKRAT GOVT.  MIDDILE  SECONDARY SCHOOL BUILDING EAST PART NOORGANJ",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1363,
      location: "22.9985900921747,77.5862004049122",
      "inverted one":
        "'EKIKRAT GOVT.  MIDDILE  SECONDARY SCHOOL BUILDING EAST PART NOORGANJ'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT.  MIDDILE  SECONDARY SCHOOL BUILDING EAST PART NOORGANJ',22.9985900921747,77.5862004049122)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT.  MIDDILE  SECONDARY SCHOOL BUILDING EAST PART NOORGANJ',22.9985900921747,77.5862004049122),",
    },
    {
      "PART NO": 84,
      "PART NAME": "NURGANJ",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE  SECONDARY SCHOOL BUILDING WEST PART NOORGANJ",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 957,
      location: "22.9987111268565,77.5863141473382",
      "inverted one":
        "'EKIKRAT GOVT. MIDDILE  SECONDARY SCHOOL BUILDING WEST PART NOORGANJ'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE  SECONDARY SCHOOL BUILDING WEST PART NOORGANJ',22.9987111268565,77.5863141473382)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE  SECONDARY SCHOOL BUILDING WEST PART NOORGANJ',22.9987111268565,77.5863141473382),",
    },
    {
      "PART NO": 85,
      "PART NAME": "NANAKHEDI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BUILDING NANAKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1331,
      location: "23.0020078551024,77.5853946525603",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL BUILDING NANAKHEDI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING NANAKHEDI',23.0020078551024,77.5853946525603)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING NANAKHEDI',23.0020078551024,77.5853946525603),",
    },
    {
      "PART NO": 86,
      "PART NAME": "PREMATALAB",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL NEW BUILDING PREMTALAB",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 729,
      location: "23.0068271783442,77.5527252629399",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL NEW BUILDING PREMTALAB'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL NEW BUILDING PREMTALAB',23.0068271783442,77.5527252629399)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL NEW BUILDING PREMTALAB',23.0068271783442,77.5527252629399),",
    },
    {
      "PART NO": 87,
      "PART NAME": "IMALIYA GONDI",
      "Name of polling station building":
        "GOVT.SECONDARY  SCHOOL BUILDING IMALIYA GONDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 501,
      location: "23.0026917,77.4949167",
      "inverted one": "'GOVT.SECONDARY  SCHOOL BUILDING IMALIYA GONDI'",
      cosolideted:
        "ElectionBooth('GOVT.SECONDARY  SCHOOL BUILDING IMALIYA GONDI',23.0026917,77.4949167)",
      "with comma":
        "ElectionBooth('GOVT.SECONDARY  SCHOOL BUILDING IMALIYA GONDI',23.0026917,77.4949167),",
    },
    {
      "PART NO": 88,
      "PART NAME": "HARRAI",
      "Name of polling station building": "GOVT. MIDDLE SCHOOL BUILDING HARRAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1246,
      location: "23.005203497596,77.5521094445139",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING HARRAI'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING HARRAI',23.005203497596,77.5521094445139)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING HARRAI',23.005203497596,77.5521094445139),",
    },
    {
      "PART NO": 89,
      "PART NAME": "DIVATIYA",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 6",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 672,
      location: "22.9750416666666,77.558195",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 6'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 6',22.9750416666666,77.558195)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 6',22.9750416666666,77.558195),",
    },
    {
      "PART NO": 90,
      "PART NAME": "DIVATIYA",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 754,
      location: "22.9750783333333,77.5582766666666",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 3'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 3',22.9750783333333,77.5582766666666)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 3',22.9750783333333,77.5582766666666),",
    },
    {
      "PART NO": 91,
      "PART NAME": "DIVATIYA",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 12",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 507,
      location: "22.9750933333333,77.5580166666666",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 12'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 12',22.9750933333333,77.5580166666666)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 12',22.9750933333333,77.5580166666666),",
    },
    {
      "PART NO": 92,
      "PART NAME": "DIVATIYA",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1173,
      location: "22.9750883333333,77.5583183333333",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 1'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 1',22.9750883333333,77.5583183333333)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING DIVATIYA ROOM NO. 1',22.9750883333333,77.5583183333333),",
    },
    {
      "PART NO": 93,
      "PART NAME": "TIGARIYA",
      "Name of polling station building": "PANCHAYAT BUILDING TIGARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 634,
      location: "22.9677283,77.5871133",
      "inverted one": "'PANCHAYAT BUILDING TIGARIYA'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING TIGARIYA',22.9677283,77.5871133)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING TIGARIYA',22.9677283,77.5871133),",
    },
    {
      "PART NO": 94,
      "PART NAME": "AMCHHAKALA",
      "Name of polling station building":
        "GOVT PRIMARI SCHOOL BUILDING AMCHHAKALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 416,
      location: "22.952822,77.5971456",
      "inverted one": "'GOVT PRIMARI SCHOOL BUILDING AMCHHAKALA'",
      cosolideted:
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING AMCHHAKALA',22.952822,77.5971456)",
      "with comma":
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING AMCHHAKALA',22.952822,77.5971456),",
    },
    {
      "PART NO": 95,
      "PART NAME": "GOTAMAPUR",
      "Name of polling station building":
        "GOVT. PRIMARI SCHOOL BUILDING GAUTAMPUR COLONY",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1132,
      location: "22.9404059,77.5495821",
      "inverted one": "'GOVT. PRIMARI SCHOOL BUILDING GAUTAMPUR COLONY'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARI SCHOOL BUILDING GAUTAMPUR COLONY',22.9404059,77.5495821)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARI SCHOOL BUILDING GAUTAMPUR COLONY',22.9404059,77.5495821),",
    },
    {
      "PART NO": 96,
      "PART NAME": "BAMANAI",
      "Name of polling station building": "PANCHAYAT BUILDING BAMNAYI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1085,
      location: "22.9109883333333,77.5402133333333",
      "inverted one": "'PANCHAYAT BUILDING BAMNAYI'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING BAMNAYI',22.9109883333333,77.5402133333333)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING BAMNAYI',22.9109883333333,77.5402133333333),",
    },
    {
      "PART NO": 97,
      "PART NAME": "KARAMODA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL KARMODA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 932,
      location: "22.9151849999999,77.6469966666666",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL KARMODA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL KARMODA',22.9151849999999,77.6469966666666)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL KARMODA',22.9151849999999,77.6469966666666),",
    },
    {
      "PART NO": 98,
      "PART NAME": "PIPLANI KHURD",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PIPLANIKHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1034,
      location: "22.9328433333333,77.6568316666666",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PIPLANIKHURD'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PIPLANIKHURD',22.9328433333333,77.6568316666666)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PIPLANIKHURD',22.9328433333333,77.6568316666666),",
    },
    {
      "PART NO": 99,
      "PART NAME": "BARAKHEDA",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING BARKHEDA ROOM NO. 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 715,
      location: "22.9127921,77.6499673",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING BARKHEDA ROOM NO. 1'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING BARKHEDA ROOM NO. 1',22.9127921,77.6499673)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING BARKHEDA ROOM NO. 1',22.9127921,77.6499673),",
    },
    {
      "PART NO": 100,
      "PART NAME": "BARAKHEDA",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING BARKHEDA ROOM NO. 6",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 911,
      location: "23.0270537,77.6763202",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING BARKHEDA ROOM NO. 6'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING BARKHEDA ROOM NO. 6',23.0270537,77.6763202)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING BARKHEDA ROOM NO. 6',23.0270537,77.6763202),",
    },
    {
      "PART NO": 101,
      "PART NAME": "BANSAGAHAN",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BASGAHAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1076,
      location: "22.95917143,77.67322863",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL BASGAHAN'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BASGAHAN',22.95917143,77.67322863)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BASGAHAN',22.95917143,77.67322863),",
    },
    {
      "PART NO": 102,
      "PART NAME": "TAJPURA",
      "Name of polling station building":
        "GOVT.  PRIMARI  SCHOOL BUILDING TAJPURA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 783,
      location: "22.95915909,77.6732342",
      "inverted one": "'GOVT.  PRIMARI  SCHOOL BUILDING TAJPURA'",
      cosolideted:
        "ElectionBooth('GOVT.  PRIMARI  SCHOOL BUILDING TAJPURA',22.95915909,77.6732342)",
      "with comma":
        "ElectionBooth('GOVT.  PRIMARI  SCHOOL BUILDING TAJPURA',22.95915909,77.6732342),",
    },
    {
      "PART NO": 103,
      "PART NAME": "IKALAVADA",
      "Name of polling station building":
        "EKIKRAT MIDDLE SCHOOL BUILDING IKALBADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1160,
      location: "22.996873,77.643411",
      "inverted one": "'EKIKRAT MIDDLE SCHOOL BUILDING IKALBADA'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDLE SCHOOL BUILDING IKALBADA',22.996873,77.643411)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDLE SCHOOL BUILDING IKALBADA',22.996873,77.643411),",
    },
    {
      "PART NO": 104,
      "PART NAME": "BILAKHEDI",
      "Name of polling station building": "GRAAM PRANCHAYAT BUILDING BEELKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 765,
      location: "23.00855458,77.65143896",
      "inverted one": "'GRAAM PRANCHAYAT BUILDING BEELKHEDI'",
      cosolideted:
        "ElectionBooth('GRAAM PRANCHAYAT BUILDING BEELKHEDI',23.00855458,77.65143896)",
      "with comma":
        "ElectionBooth('GRAAM PRANCHAYAT BUILDING BEELKHEDI',23.00855458,77.65143896),",
    },
    {
      "PART NO": 105,
      "PART NAME": "TAMOT",
      "Name of polling station building":
        "GOVT.NEW MIDDLE SCHOOL BUILDING TAMOT",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 968,
      location: "23.00138842,77.63927139",
      "inverted one": "'GOVT.NEW MIDDLE SCHOOL BUILDING TAMOT'",
      cosolideted:
        "ElectionBooth('GOVT.NEW MIDDLE SCHOOL BUILDING TAMOT',23.00138842,77.63927139)",
      "with comma":
        "ElectionBooth('GOVT.NEW MIDDLE SCHOOL BUILDING TAMOT',23.00138842,77.63927139),",
    },
    {
      "PART NO": 106,
      "PART NAME": "TAMOT",
      "Name of polling station building": "GOVT.H.SCHOOL SOUTH PART TAMOT",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 720,
      location: "23.00535665,77.63933115",
      "inverted one": "'GOVT.H.SCHOOL SOUTH PART TAMOT'",
      cosolideted:
        "ElectionBooth('GOVT.H.SCHOOL SOUTH PART TAMOT',23.00535665,77.63933115)",
      "with comma":
        "ElectionBooth('GOVT.H.SCHOOL SOUTH PART TAMOT',23.00535665,77.63933115),",
    },
    {
      "PART NO": 107,
      "PART NAME": "TAMOT",
      "Name of polling station building": "SAMUDAYIK BUILDING TAMOT",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 739,
      location: "23.00537611,77.63942584",
      "inverted one": "'SAMUDAYIK BUILDING TAMOT'",
      cosolideted:
        "ElectionBooth('SAMUDAYIK BUILDING TAMOT',23.00537611,77.63942584)",
      "with comma":
        "ElectionBooth('SAMUDAYIK BUILDING TAMOT',23.00537611,77.63942584),",
    },
    {
      "PART NO": 108,
      "PART NAME": "TAMOT",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS MIDDILE SCHOOL TAMOT",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 775,
      location: "23.00535605,77.63938929",
      "inverted one": "'EKIKRAT GOVT. GIRLS MIDDILE SCHOOL TAMOT'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS MIDDILE SCHOOL TAMOT',23.00535605,77.63938929)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS MIDDILE SCHOOL TAMOT',23.00535605,77.63938929),",
    },
    {
      "PART NO": 109,
      "PART NAME": "BANSAKUANR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BANSKUNVAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 678,
      location: "22.953343,77.634288",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BANSKUNVAR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BANSKUNVAR',22.953343,77.634288)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BANSKUNVAR',22.953343,77.634288),",
    },
    {
      "PART NO": 110,
      "PART NAME": "UMARIYA",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL NEW BUILDING UMARIYA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 758,
      location: "22.9969599999999,77.5881716666666",
      "inverted one": "'GOVT. MIDDLE SCHOOL NEW BUILDING UMARIYA'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL NEW BUILDING UMARIYA',22.9969599999999,77.5881716666666)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL NEW BUILDING UMARIYA',22.9969599999999,77.5881716666666),",
    },
    {
      "PART NO": 111,
      "PART NAME": "IKLAMA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING IKLAMA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 712,
      location: "22.98728,77.6033966666666",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING IKLAMA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING IKLAMA',22.98728,77.6033966666666)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING IKLAMA',22.98728,77.6033966666666),",
    },
    {
      "PART NO": 112,
      "PART NAME": "OBEDULLAGANJ",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BUILDING HIRANIYA O.GANJ NORTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 751,
      location: "22.99877208,77.58680185",
      "inverted one":
        "'EKIKRAT GOVT. MIDDILE SCHOOL BUILDING HIRANIYA O.GANJ NORTH PART'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING HIRANIYA O.GANJ NORTH PART',22.99877208,77.58680185)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING HIRANIYA O.GANJ NORTH PART',22.99877208,77.58680185),",
    },
    {
      "PART NO": 113,
      "PART NAME": "OBEDULLAGANJ",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BUILDING HIRANIYA O.GANJ SOUTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 709,
      location: "22.99879801,77.5868512",
      "inverted one":
        "'EKIKRAT GOVT. MIDDILE SCHOOL BUILDING HIRANIYA O.GANJ SOUTH PART'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING HIRANIYA O.GANJ SOUTH PART',22.99879801,77.5868512)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING HIRANIYA O.GANJ SOUTH PART',22.99879801,77.5868512),",
    },
    {
      "PART NO": 114,
      "PART NAME": "OBEDULLAGANJ",
      "Name of polling station building": "MARKTING SOSAYTI O GANJ NORTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 959,
      location: "22.9972783333333,77.584465",
      "inverted one": "'MARKTING SOSAYTI O GANJ NORTH PART'",
      cosolideted:
        "ElectionBooth('MARKTING SOSAYTI O GANJ NORTH PART',22.9972783333333,77.584465)",
      "with comma":
        "ElectionBooth('MARKTING SOSAYTI O GANJ NORTH PART',22.9972783333333,77.584465),",
    },
    {
      "PART NO": 115,
      "PART NAME": "OBEDULLAGANJ",
      "Name of polling station building": "MARKETING SOCIETY O.GANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1022,
      location: "22.9973333333333,77.5842133333333",
      "inverted one": "'MARKETING SOCIETY O.GANJ'",
      cosolideted:
        "ElectionBooth('MARKETING SOCIETY O.GANJ',22.9973333333333,77.5842133333333)",
      "with comma":
        "ElectionBooth('MARKETING SOCIETY O.GANJ',22.9973333333333,77.5842133333333),",
    },
    {
      "PART NO": 116,
      "PART NAME": "OBENDULLAGANJ",
      "Name of polling station building":
        "KRISHI UPAJ MANDI BUILDING SOUTH PART O.GANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1279,
      location: "22.99680832,77.5837407",
      "inverted one": "'KRISHI UPAJ MANDI BUILDING SOUTH PART O.GANJ'",
      cosolideted:
        "ElectionBooth('KRISHI UPAJ MANDI BUILDING SOUTH PART O.GANJ',22.99680832,77.5837407)",
      "with comma":
        "ElectionBooth('KRISHI UPAJ MANDI BUILDING SOUTH PART O.GANJ',22.99680832,77.5837407),",
    },
    {
      "PART NO": 117,
      "PART NAME": "OBENDULLAGANJ",
      "Name of polling station building":
        "KRISHI UPAJ MANDI BUILDING KRASAK RESTHOUSE O.GANJ (EAST PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1201,
      location: "22.9948142,77.5833894",
      "inverted one":
        "'KRISHI UPAJ MANDI BUILDING KRASAK RESTHOUSE O.GANJ (EAST PART)'",
      cosolideted:
        "ElectionBooth('KRISHI UPAJ MANDI BUILDING KRASAK RESTHOUSE O.GANJ (EAST PART)',22.9948142,77.5833894)",
      "with comma":
        "ElectionBooth('KRISHI UPAJ MANDI BUILDING KRASAK RESTHOUSE O.GANJ (EAST PART)',22.9948142,77.5833894),",
    },
    {
      "PART NO": 118,
      "PART NAME": "OBENDULLAGANJ",
      "Name of polling station building":
        "KRISHI UPAJ MANDI BUILDING (FARMER RESTAURENT HOUSE ) O GANJ WEST PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1020,
      location: "23.00191183,77.58565836",
      "inverted one":
        "'KRISHI UPAJ MANDI BUILDING (FARMER RESTAURENT HOUSE ) O GANJ WEST PART'",
      cosolideted:
        "ElectionBooth('KRISHI UPAJ MANDI BUILDING (FARMER RESTAURENT HOUSE ) O GANJ WEST PART',23.00191183,77.58565836)",
      "with comma":
        "ElectionBooth('KRISHI UPAJ MANDI BUILDING (FARMER RESTAURENT HOUSE ) O GANJ WEST PART',23.00191183,77.58565836),",
    },
    {
      "PART NO": 119,
      "PART NAME": "OBENDULLAGANJ",
      "Name of polling station building":
        "GOVT.GIRLS PRIMARY SCHOOL NORTH PART O.GANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 907,
      location: "22.99882076,77.58588495",
      "inverted one": "'GOVT.GIRLS PRIMARY SCHOOL NORTH PART O.GANJ'",
      cosolideted:
        "ElectionBooth('GOVT.GIRLS PRIMARY SCHOOL NORTH PART O.GANJ',22.99882076,77.58588495)",
      "with comma":
        "ElectionBooth('GOVT.GIRLS PRIMARY SCHOOL NORTH PART O.GANJ',22.99882076,77.58588495),",
    },
    {
      "PART NO": 120,
      "PART NAME": "OBENDULLAGANJ",
      "Name of polling station building":
        "GOVT.GIRLS PRIMARY SCHOOL SOUTH PART O.GANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1136,
      location: "22.9959151,77.588387",
      "inverted one": "'GOVT.GIRLS PRIMARY SCHOOL SOUTH PART O.GANJ'",
      cosolideted:
        "ElectionBooth('GOVT.GIRLS PRIMARY SCHOOL SOUTH PART O.GANJ',22.9959151,77.588387)",
      "with comma":
        "ElectionBooth('GOVT.GIRLS PRIMARY SCHOOL SOUTH PART O.GANJ',22.9959151,77.588387),",
    },
    {
      "PART NO": 121,
      "PART NAME": "OBENDULLAGANJ",
      "Name of polling station building":
        "GOVT.M.L.K.U.M.V. OBEDULLAGANJ ROOM NO 17",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 738,
      location: "22.9987216666666,77.5867866666666",
      "inverted one": "'GOVT.M.L.K.U.M.V. OBEDULLAGANJ ROOM NO 17'",
      cosolideted:
        "ElectionBooth('GOVT.M.L.K.U.M.V. OBEDULLAGANJ ROOM NO 17',22.9987216666666,77.5867866666666)",
      "with comma":
        "ElectionBooth('GOVT.M.L.K.U.M.V. OBEDULLAGANJ ROOM NO 17',22.9987216666666,77.5867866666666),",
    },
    {
      "PART NO": 122,
      "PART NAME": "OBENDULLAGANJ",
      "Name of polling station building":
        "GOVT.M.L.K.U.M.V. OBEDULLAGANJ ROOM NO 18",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1062,
      location: "22.99877196,77.58680068",
      "inverted one": "'GOVT.M.L.K.U.M.V. OBEDULLAGANJ ROOM NO 18'",
      cosolideted:
        "ElectionBooth('GOVT.M.L.K.U.M.V. OBEDULLAGANJ ROOM NO 18',22.99877196,77.58680068)",
      "with comma":
        "ElectionBooth('GOVT.M.L.K.U.M.V. OBEDULLAGANJ ROOM NO 18',22.99877196,77.58680068),",
    },
    {
      "PART NO": 123,
      "PART NAME": "OBENDULLAGANJ",
      "Name of polling station building": "VIKAS KHAND OFFICE BUILDING O.GANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 673,
      location: "23.0025015814317,77.5849079540182",
      "inverted one": "'VIKAS KHAND OFFICE BUILDING O.GANJ'",
      cosolideted:
        "ElectionBooth('VIKAS KHAND OFFICE BUILDING O.GANJ',23.0025015814317,77.5849079540182)",
      "with comma":
        "ElectionBooth('VIKAS KHAND OFFICE BUILDING O.GANJ',23.0025015814317,77.5849079540182),",
    },
    {
      "PART NO": 124,
      "PART NAME": "OBENDULLAGANJ",
      "Name of polling station building":
        "VIKAS KHAND OFFICE B.R.C. BUILDING O.GANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 855,
      location: "23.0023083333333,77.5851166666666",
      "inverted one": "'VIKAS KHAND OFFICE B.R.C. BUILDING O.GANJ'",
      cosolideted:
        "ElectionBooth('VIKAS KHAND OFFICE B.R.C. BUILDING O.GANJ',23.0023083333333,77.5851166666666)",
      "with comma":
        "ElectionBooth('VIKAS KHAND OFFICE B.R.C. BUILDING O.GANJ',23.0023083333333,77.5851166666666),",
    },
    {
      "PART NO": 125,
      "PART NAME": "OBENDULLAGANJ",
      "Name of polling station building":
        "VIKAS KHAND OFFICE NEW BUILDING O.GANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 839,
      location: "22.9987483333333,77.5867783333333",
      "inverted one": "'VIKAS KHAND OFFICE NEW BUILDING O.GANJ'",
      cosolideted:
        "ElectionBooth('VIKAS KHAND OFFICE NEW BUILDING O.GANJ',22.9987483333333,77.5867783333333)",
      "with comma":
        "ElectionBooth('VIKAS KHAND OFFICE NEW BUILDING O.GANJ',22.9987483333333,77.5867783333333),",
    },
    {
      "PART NO": 126,
      "PART NAME": "KHILLIKHEDA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL KHILLIKHEDA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1194,
      location: "23.0022603,77.5864782",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL KHILLIKHEDA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL KHILLIKHEDA',23.0022603,77.5864782)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL KHILLIKHEDA',23.0022603,77.5864782),",
    },
    {
      "PART NO": 127,
      "PART NAME": "VISHANAKHEDA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL VISHANKHEDA BUILDING ROOM NO. 3",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 757,
      location: "23.0206464,77.5736446",
      "inverted one":
        "'EKIKRAT GOVT. MIDDILE SCHOOL VISHANKHEDA BUILDING ROOM NO. 3'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL VISHANKHEDA BUILDING ROOM NO. 3',23.0206464,77.5736446)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL VISHANKHEDA BUILDING ROOM NO. 3',23.0206464,77.5736446),",
    },
    {
      "PART NO": 128,
      "PART NAME": "VISHANAKHEDA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL VISHANKHEDA BUILDING ROOM NO. 4",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 768,
      location: "23.0206516666666,77.5736016666666",
      "inverted one":
        "'EKIKRAT GOVT. MIDDILE SCHOOL VISHANKHEDA BUILDING ROOM NO. 4'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL VISHANKHEDA BUILDING ROOM NO. 4',23.0206516666666,77.5736016666666)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL VISHANKHEDA BUILDING ROOM NO. 4',23.0206516666666,77.5736016666666),",
    },
    {
      "PART NO": 129,
      "PART NAME": "GEHUKHEDA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING GEHUNKHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 715,
      location: "23.03257297,77.60778485",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING GEHUNKHEDA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GEHUNKHEDA',23.03257297,77.60778485)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GEHUNKHEDA',23.03257297,77.60778485),",
    },
    {
      "PART NO": 130,
      "PART NAME": "PARAKHEDI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PARKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 794,
      location: "23.03245563,77.60774366",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PARKHEDI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PARKHEDI',23.03245563,77.60774366)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PARKHEDI',23.03245563,77.60774366),",
    },
    {
      "PART NO": 131,
      "PART NAME": "KHASROD",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BUILDING KHASROD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1088,
      location: "23.04924803,77.60391724",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL BUILDING KHASROD'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING KHASROD',23.04924803,77.60391724)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING KHASROD',23.04924803,77.60391724),",
    },
    {
      "PART NO": 132,
      "PART NAME": "SALAKANI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SALKANI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 711,
      location: "23.06365894,77.60167797",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SALKANI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SALKANI',23.06365894,77.60167797)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SALKANI',23.06365894,77.60167797),",
    },
    {
      "PART NO": 133,
      "PART NAME": "HAJALI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BUILDING HAJLI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 420,
      location: "23.05420079,77.58845367",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL BUILDING HAJLI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING HAJLI',23.05420079,77.58845367)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING HAJLI',23.05420079,77.58845367),",
    },
    {
      "PART NO": 134,
      "PART NAME": "BHOJAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BUILDING BHOJPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 589,
      location: "23.09880549,77.58136501",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL BUILDING BHOJPUR'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING BHOJPUR',23.09880549,77.58136501)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING BHOJPUR',23.09880549,77.58136501),",
    },
    {
      "PART NO": 135,
      "PART NAME": "IMALIYA SINGPUR",
      "Name of polling station building":
        "GOVT MIDDLE SCHOOL BUILDING IMALIYA SINGPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 503,
      location: "23.1129328,77.6028",
      "inverted one": "'GOVT MIDDLE SCHOOL BUILDING IMALIYA SINGPUR'",
      cosolideted:
        "ElectionBooth('GOVT MIDDLE SCHOOL BUILDING IMALIYA SINGPUR',23.1129328,77.6028)",
      "with comma":
        "ElectionBooth('GOVT MIDDLE SCHOOL BUILDING IMALIYA SINGPUR',23.1129328,77.6028),",
    },
    {
      "PART NO": 136,
      "PART NAME": "DEHARI(NIMAKHEDA)",
      "Name of polling station building":
        "INTEGRATED GOVT. PRIMARY SCHOOL BUILDING ADITIONAL ROOM DEHRI NEEMKHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 912,
      location: "23.0907583333333,77.5938933333333",
      "inverted one":
        "'INTEGRATED GOVT. PRIMARY SCHOOL BUILDING ADITIONAL ROOM DEHRI NEEMKHEDA'",
      cosolideted:
        "ElectionBooth('INTEGRATED GOVT. PRIMARY SCHOOL BUILDING ADITIONAL ROOM DEHRI NEEMKHEDA',23.0907583333333,77.5938933333333)",
      "with comma":
        "ElectionBooth('INTEGRATED GOVT. PRIMARY SCHOOL BUILDING ADITIONAL ROOM DEHRI NEEMKHEDA',23.0907583333333,77.5938933333333),",
    },
    {
      "PART NO": 137,
      "PART NAME": "PIPALIYA LORAKA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PIPALIYALORKA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 775,
      location: "23.09683677,77.55789332",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PIPALIYALORKA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PIPALIYALORKA',23.09683677,77.55789332)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PIPALIYALORKA',23.09683677,77.55789332),",
    },
    {
      "PART NO": 138,
      "PART NAME": "KHANPURA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KHANPURA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 875,
      location: "23.08405916,77.55774177",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KHANPURA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHANPURA',23.08405916,77.55774177)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHANPURA',23.08405916,77.55774177),",
    },
    {
      "PART NO": 139,
      "PART NAME": "SATALAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT.HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 02",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 930,
      location: "23.1142606693382,77.528384695604",
      "inverted one":
        "'EKIKRAT GOVT.HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 02'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT.HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 02',23.1142606693382,77.528384695604)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT.HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 02',23.1142606693382,77.528384695604),",
    },
    {
      "PART NO": 140,
      "PART NAME": "SATALAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT.HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 04",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 705,
      location: "23.1141660797397,77.5284747949355",
      "inverted one":
        "'EKIKRAT GOVT.HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 04'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT.HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 04',23.1141660797397,77.5284747949355)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT.HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 04',23.1141660797397,77.5284747949355),",
    },
    {
      "PART NO": 141,
      "PART NAME": "SATALAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 05",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 989,
      location: "23.1142196032594,77.5286711234258",
      "inverted one":
        "'EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 05'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 05',23.1142196032594,77.5286711234258)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 05',23.1142196032594,77.5286711234258),",
    },
    {
      "PART NO": 142,
      "PART NAME": "SATALAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 08",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 632,
      location: "23.1141810701333,77.5291791662119",
      "inverted one":
        "'EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 08'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 08',23.1141810701333,77.5291791662119)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 08',23.1141810701333,77.5291791662119),",
    },
    {
      "PART NO": 143,
      "PART NAME": "SATALAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 11",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1302,
      location: "23.1146533333333,77.528525",
      "inverted one":
        "'EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 11'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 11',23.1146533333333,77.528525)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 11',23.1146533333333,77.528525),",
    },
    {
      "PART NO": 144,
      "PART NAME": "SATALAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 12",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1005,
      location: "23.1142183333333,77.5289566666666",
      "inverted one":
        "'EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 12'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 12',23.1142183333333,77.5289566666666)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 12',23.1142183333333,77.5289566666666),",
    },
    {
      "PART NO": 145,
      "PART NAME": "SATALAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 13",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 810,
      location: "23.1143247479685,77.5286768884067",
      "inverted one":
        "'EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 13'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 13',23.1143247479685,77.5286768884067)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 13',23.1143247479685,77.5286768884067),",
    },
    {
      "PART NO": 146,
      "PART NAME": "SATALAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 16",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 868,
      location: "23.1140654699226,77.5287299252626",
      "inverted one":
        "'EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 16'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 16',23.1140654699226,77.5287299252626)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 16',23.1140654699226,77.5287299252626),",
    },
    {
      "PART NO": 147,
      "PART NAME": "SATALAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 21",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 850,
      location: "23.1144428963919,77.5286558470817",
      "inverted one":
        "'EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 21'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 21',23.1144428963919,77.5286558470817)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. .HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 21',23.1144428963919,77.5286558470817),",
    },
    {
      "PART NO": 148,
      "PART NAME": "SATALAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 22",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 623,
      location: "23.1144223287455,77.5288357037836",
      "inverted one":
        "'EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 22'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 22',23.1144223287455,77.5288357037836)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT..HIGHER SCHOOL BUILDING SATLAPUR ROOM NO. 22',23.1144223287455,77.5288357037836),",
    },
    {
      "PART NO": 149,
      "PART NAME": "SATALAPUR",
      "Name of polling station building":
        "GOVT.COLLAGE ROOM NO. 1 MANDEEP SATLAPUR",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 729,
      location: "23.106559,77.526894",
      "inverted one": "'GOVT.COLLAGE ROOM NO. 1 MANDEEP SATLAPUR'",
      cosolideted:
        "ElectionBooth('GOVT.COLLAGE ROOM NO. 1 MANDEEP SATLAPUR',23.106559,77.526894)",
      "with comma":
        "ElectionBooth('GOVT.COLLAGE ROOM NO. 1 MANDEEP SATLAPUR',23.106559,77.526894),",
    },
    {
      "PART NO": 150,
      "PART NAME": "SATALAPUR",
      "Name of polling station building":
        "GOVT. HIGH SCHOOL ROOM NO. 2 MANDIDEEP SATLAPUR",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1010,
      location: "23.1066587,77.5269595",
      "inverted one": "'GOVT. HIGH SCHOOL ROOM NO. 2 MANDIDEEP SATLAPUR'",
      cosolideted:
        "ElectionBooth('GOVT. HIGH SCHOOL ROOM NO. 2 MANDIDEEP SATLAPUR',23.1066587,77.5269595)",
      "with comma":
        "ElectionBooth('GOVT. HIGH SCHOOL ROOM NO. 2 MANDIDEEP SATLAPUR',23.1066587,77.5269595),",
    },
    {
      "PART NO": 151,
      "PART NAME": "SATALAPUR",
      "Name of polling station building": "GOVT.COLLAGE ROOM NO. 3 MANDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 866,
      location: "23.1065912,77.5269503",
      "inverted one": "'GOVT.COLLAGE ROOM NO. 3 MANDEEP'",
      cosolideted:
        "ElectionBooth('GOVT.COLLAGE ROOM NO. 3 MANDEEP',23.1065912,77.5269503)",
      "with comma":
        "ElectionBooth('GOVT.COLLAGE ROOM NO. 3 MANDEEP',23.1065912,77.5269503),",
    },
    {
      "PART NO": 152,
      "PART NAME": "SATALAPUR",
      "Name of polling station building": "GOVT.COLLAGE ROOM NO. 4 MANDEEP",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 798,
      location: "23.1066403,77.5269849",
      "inverted one": "'GOVT.COLLAGE ROOM NO. 4 MANDEEP'",
      cosolideted:
        "ElectionBooth('GOVT.COLLAGE ROOM NO. 4 MANDEEP',23.1066403,77.5269849)",
      "with comma":
        "ElectionBooth('GOVT.COLLAGE ROOM NO. 4 MANDEEP',23.1066403,77.5269849),",
    },
    {
      "PART NO": 153,
      "PART NAME": "MENDUA",
      "Name of polling station building": "GOVT. MIDDLE SCHOOL BUILDING MENDUA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 937,
      location: "23.0266168800726,77.5508594280953",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING MENDUA'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING MENDUA',23.0266168800726,77.5508594280953)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING MENDUA',23.0266168800726,77.5508594280953),",
    },
    {
      "PART NO": 154,
      "PART NAME": "KIRATANAGAR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KEERATNAGAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 790,
      location: "23.0266168800726,77.5508594280953",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KEERATNAGAR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KEERATNAGAR',23.0266168800726,77.5508594280953)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KEERATNAGAR',23.0266168800726,77.5508594280953),",
    },
    {
      "PART NO": 155,
      "PART NAME": "KANORA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KANORA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1034,
      location: "23.0259833245658,77.6794449412644",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KANORA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KANORA',23.0259833245658,77.6794449412644)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KANORA',23.0259833245658,77.6794449412644),",
    },
    {
      "PART NO": 156,
      "PART NAME": "THANA",
      "Name of polling station building": "PANCHAYAT BUILDING THANA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1189,
      location: "23.0260244263957,77.6793944097144",
      "inverted one": "'PANCHAYAT BUILDING THANA'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING THANA',23.0260244263957,77.6793944097144)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING THANA',23.0260244263957,77.6793944097144),",
    },
    {
      "PART NO": 157,
      "PART NAME": "GHATKHEDI",
      "Name of polling station building": "PRIMARY SCHOOL BUILDING GHATKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 484,
      location: "23.026123799489,77.6798140024161",
      "inverted one": "'PRIMARY SCHOOL BUILDING GHATKHEDI'",
      cosolideted:
        "ElectionBooth('PRIMARY SCHOOL BUILDING GHATKHEDI',23.026123799489,77.6798140024161)",
      "with comma":
        "ElectionBooth('PRIMARY SCHOOL BUILDING GHATKHEDI',23.026123799489,77.6798140024161),",
    },
    {
      "PART NO": 158,
      "PART NAME": "TARAVALI",
      "Name of polling station building": "PANCHAYAT BUILDING TARAVALI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 896,
      location: "23.1509229043663,77.591850310564",
      "inverted one": "'PANCHAYAT BUILDING TARAVALI'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING TARAVALI',23.1509229043663,77.591850310564)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING TARAVALI',23.1509229043663,77.591850310564),",
    },
    {
      "PART NO": 159,
      "PART NAME": "UMARAVAGANJ",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING UMRAVGANJ",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1051,
      location: "23.1916975,77.6029522",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING UMRAVGANJ'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING UMRAVGANJ',23.1916975,77.6029522)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING UMRAVGANJ',23.1916975,77.6029522),",
    },
    {
      "PART NO": 160,
      "PART NAME": "MUNEERGAD",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING MUNIRGAD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 617,
      location: "23.2020897141778,77.6037673764051",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING MUNIRGAD'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING MUNIRGAD',23.2020897141778,77.6037673764051)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING MUNIRGAD',23.2020897141778,77.6037673764051),",
    },
    {
      "PART NO": 161,
      "PART NAME": "GUDAVAL",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING GUDAVAL",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 762,
      location: "23.236399323926,77.604904755214",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING GUDAVAL'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING GUDAVAL',23.236399323926,77.604904755214)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING GUDAVAL',23.236399323926,77.604904755214),",
    },
    {
      "PART NO": 162,
      "PART NAME": "CHIKALOD KHURD",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGH SCHOOL CHIKLOD KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 885,
      location: "23.231154433372,77.6176214642379",
      "inverted one": "'EKIKRAT GOVT. HIGH SCHOOL CHIKLOD KHURD'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL CHIKLOD KHURD',23.231154433372,77.6176214642379)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL CHIKLOD KHURD',23.231154433372,77.6176214642379),",
    },
    {
      "PART NO": 163,
      "PART NAME": "CHHATARAPURA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING CHATARPURA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 758,
      location: "23.2248425223956,77.6289217576005",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING CHATARPURA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHATARPURA',23.2248425223956,77.6289217576005)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHATARPURA',23.2248425223956,77.6289217576005),",
    },
    {
      "PART NO": 164,
      "PART NAME": "MAKODIYA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING MAKODIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 776,
      location: "23.0267372697367,77.550333707265",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING MAKODIYA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING MAKODIYA',23.0267372697367,77.550333707265)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING MAKODIYA',23.0267372697367,77.550333707265),",
    },
    {
      "PART NO": 165,
      "PART NAME": "BEGAMAPURA",
      "Name of polling station building":
        "GOVT.MIDDILE SCHOOL BUILDING BEGUMPURA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 734,
      location: "23.2025197251406,77.6389788874695",
      "inverted one": "'GOVT.MIDDILE SCHOOL BUILDING BEGUMPURA'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDILE SCHOOL BUILDING BEGUMPURA',23.2025197251406,77.6389788874695)",
      "with comma":
        "ElectionBooth('GOVT.MIDDILE SCHOOL BUILDING BEGUMPURA',23.2025197251406,77.6389788874695),",
    },
    {
      "PART NO": 166,
      "PART NAME": "CHIROLIYA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING CHIROLIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 962,
      location: "23.1913832655964,77.6049196656733",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING CHIROLIYA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING CHIROLIYA',23.1913832655964,77.6049196656733)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING CHIROLIYA',23.1913832655964,77.6049196656733),",
    },
    {
      "PART NO": 167,
      "PART NAME": "KHUKARIYA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KHUKARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 674,
      location: "23.1879294331781,77.6381827276893",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KHUKARIYA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KHUKARIYA',23.1879294331781,77.6381827276893)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KHUKARIYA',23.1879294331781,77.6381827276893),",
    },
    {
      "PART NO": 168,
      "PART NAME": "RAJAMAU",
      "Name of polling station building": "PANCHAYAT BUILDING RAJMAU",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1066,
      location: "23.1641414947337,77.6506698806342",
      "inverted one": "'PANCHAYAT BUILDING RAJMAU'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING RAJMAU',23.1641414947337,77.6506698806342)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING RAJMAU',23.1641414947337,77.6506698806342),",
    },
    {
      "PART NO": 169,
      "PART NAME": "KARAHODA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KARHODA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 817,
      location: "23.19387910411,77.6875460301021",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KARHODA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KARHODA',23.19387910411,77.6875460301021)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KARHODA',23.19387910411,77.6875460301021),",
    },
    {
      "PART NO": 170,
      "PART NAME": "CHAMARIYA KHAMARIYA",
      "Name of polling station building":
        "GOVT PRIMARI SCHOOL BUILDING CHAMARIYA KHAMARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 469,
      location: "23.2125911,77.6956627",
      "inverted one": "'GOVT PRIMARI SCHOOL BUILDING CHAMARIYA KHAMARIYA'",
      cosolideted:
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING CHAMARIYA KHAMARIYA',23.2125911,77.6956627)",
      "with comma":
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING CHAMARIYA KHAMARIYA',23.2125911,77.6956627),",
    },
    {
      "PART NO": 171,
      "PART NAME": "DHANDHAR",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING DHANDHAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 585,
      location: "23.2077216105344,77.6657824229617",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING DHANDHAR'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING DHANDHAR',23.2077216105344,77.6657824229617)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING DHANDHAR',23.2077216105344,77.6657824229617),",
    },
    {
      "PART NO": 172,
      "PART NAME": "CHANDALAKHEDI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING CHANDLAKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 873,
      location: "23.1533240773704,77.6259805264487",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING CHANDLAKHEDI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHANDLAKHEDI',23.1533240773704,77.6259805264487)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHANDLAKHEDI',23.1533240773704,77.6259805264487),",
    },
    {
      "PART NO": 173,
      "PART NAME": "SHAHAVAD TILENDI",
      "Name of polling station building": "EKIKRAT GOVT. High School TILENDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 997,
      location: "23.1635479586696,77.6507472842312",
      "inverted one": "'EKIKRAT GOVT. High School TILENDI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. High School TILENDI',23.1635479586696,77.6507472842312)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. High School TILENDI',23.1635479586696,77.6507472842312),",
    },
    {
      "PART NO": 174,
      "PART NAME": "CHIKALODAKALAN",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL CHIKLOD KALA ROOM NO. 3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 821,
      location: "23.120510196983,77.7066003537589",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL CHIKLOD KALA ROOM NO. 3'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL CHIKLOD KALA ROOM NO. 3',23.120510196983,77.7066003537589)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL CHIKLOD KALA ROOM NO. 3',23.120510196983,77.7066003537589),",
    },
    {
      "PART NO": 175,
      "PART NAME": "CHIKALODAKALAN",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL CHIKLOD KALA ROOM NO. 2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 554,
      location: "23.1204872769558,77.7065630317816",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL CHIKLOD KALA ROOM NO. 2'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL CHIKLOD KALA ROOM NO. 2',23.1204872769558,77.7065630317816)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL CHIKLOD KALA ROOM NO. 2',23.1204872769558,77.7065630317816),",
    },
    {
      "PART NO": 176,
      "PART NAME": "CHIKALODAKALA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL CHIKLOD KALA ROOM NO. 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 789,
      location: "23.1209712019385,77.706860434555",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL CHIKLOD KALA ROOM NO. 1'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL CHIKLOD KALA ROOM NO. 1',23.1209712019385,77.706860434555)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL CHIKLOD KALA ROOM NO. 1',23.1209712019385,77.706860434555),",
    },
    {
      "PART NO": 177,
      "PART NAME": "AMARATHON",
      "Name of polling station building": "GRAM SWARAJ BUILDING AMARTHON",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 567,
      location: "23.104715,77.680189",
      "inverted one": "'GRAM SWARAJ BUILDING AMARTHON'",
      cosolideted:
        "ElectionBooth('GRAM SWARAJ BUILDING AMARTHON',23.104715,77.680189)",
      "with comma":
        "ElectionBooth('GRAM SWARAJ BUILDING AMARTHON',23.104715,77.680189),",
    },
    {
      "PART NO": 178,
      "PART NAME": "AMBAI",
      "Name of polling station building": "GOVT. MIDDLE SCHOOL BUILDING AMBAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 993,
      location: "23.06527,77.674545",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING AMBAI'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING AMBAI',23.06527,77.674545)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING AMBAI',23.06527,77.674545),",
    },
    {
      "PART NO": 179,
      "PART NAME": "MURAHARI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING MURHARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1169,
      location: "23.0250106608873,77.6741485297679",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING MURHARI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MURHARI',23.0250106608873,77.6741485297679)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MURHARI',23.0250106608873,77.6741485297679),",
    },
    {
      "PART NO": 180,
      "PART NAME": "PADRAI",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING PADARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 454,
      location: "23.0511983333333,77.6441383333333",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING PADARI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PADARI',23.0511983333333,77.6441383333333)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PADARI',23.0511983333333,77.6441383333333),",
    },
    {
      "PART NO": 181,
      "PART NAME": "ASHAPURI",
      "Name of polling station building": "GOVT. HIGH SCHOOL BUILDING ASHAPURI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 796,
      location: "23.07252,77.625755",
      "inverted one": "'GOVT. HIGH SCHOOL BUILDING ASHAPURI'",
      cosolideted:
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING ASHAPURI',23.07252,77.625755)",
      "with comma":
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING ASHAPURI',23.07252,77.625755),",
    },
    {
      "PART NO": 182,
      "PART NAME": "ASHAPURI",
      "Name of polling station building": "GOVT.MIDDLE BUILDING ASHAPURI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 495,
      location: "23.0721933333333,77.6260116666666",
      "inverted one": "'GOVT.MIDDLE BUILDING ASHAPURI'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE BUILDING ASHAPURI',23.0721933333333,77.6260116666666)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE BUILDING ASHAPURI',23.0721933333333,77.6260116666666),",
    },
    {
      "PART NO": 183,
      "PART NAME": "SINGALADIP",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BUILDING SINGALDEEP",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 817,
      location: "23.0322166666666,77.6748399999999",
      "inverted one": "'EKIKRAT GOVT. MIDDILE SCHOOL BUILDING SINGALDEEP'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING SINGALDEEP',23.0322166666666,77.6748399999999)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING SINGALDEEP',23.0322166666666,77.6748399999999),",
    },
    {
      "PART NO": 184,
      "PART NAME": "AMODA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BUILDING AMODA ROOM NO. 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 913,
      location: "23.0232977996549,77.6705245301127",
      "inverted one":
        "'EKIKRAT GOVT. MIDDILE SCHOOL BUILDING AMODA ROOM NO. 1'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING AMODA ROOM NO. 1',23.0232977996549,77.6705245301127)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING AMODA ROOM NO. 1',23.0232977996549,77.6705245301127),",
    },
    {
      "PART NO": 185,
      "PART NAME": "AMODA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BUILDING AMODA ROOM NO. 4",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 742,
      location: "23.0233633333333,77.6705566666666",
      "inverted one":
        "'EKIKRAT GOVT. MIDDILE SCHOOL BUILDING AMODA ROOM NO. 4'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING AMODA ROOM NO. 4',23.0233633333333,77.6705566666666)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING AMODA ROOM NO. 4',23.0233633333333,77.6705566666666),",
    },
    {
      "PART NO": 186,
      "PART NAME": "GOHARAGANJ",
      "Name of polling station building":
        "EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 803,
      location: "23.0242266666666,77.674635",
      "inverted one":
        "'EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 1'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 1',23.0242266666666,77.674635)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 1',23.0242266666666,77.674635),",
    },
    {
      "PART NO": 187,
      "PART NAME": "GOHARAGANJ",
      "Name of polling station building":
        "EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 569,
      location: "23.0242566666666,77.67459",
      "inverted one":
        "'EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 2'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 2',23.0242566666666,77.67459)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 2',23.0242566666666,77.67459),",
    },
    {
      "PART NO": 188,
      "PART NAME": "GOHARAGANJ",
      "Name of polling station building":
        "EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 5",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1066,
      location: "23.0240249999999,77.6739666666666",
      "inverted one":
        "'EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 5'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 5',23.0240249999999,77.6739666666666)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 5',23.0240249999999,77.6739666666666),",
    },
    {
      "PART NO": 189,
      "PART NAME": "GOHARAGANJ",
      "Name of polling station building":
        "EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1131,
      location: "23.0276003,77.6779143",
      "inverted one":
        "'EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 3'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 3',23.0276003,77.6779143)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT.BOYS H. S. SCHOOL BUILDING GOUHARGANJ ROOM NO. 3',23.0276003,77.6779143),",
    },
    {
      "PART NO": 190,
      "PART NAME": "NAYAPURA SODARAPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING NAYAPURA SODARPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1088,
      location: "23.0324416666666,77.6748399999999",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING NAYAPURA SODARPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NAYAPURA SODARPUR',23.0324416666666,77.6748399999999)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NAYAPURA SODARPUR',23.0324416666666,77.6748399999999),",
    },
    {
      "PART NO": 191,
      "PART NAME": "DANGARWARA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DANGADBADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1011,
      location: "23.0368785158337,77.6907423511147",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DANGADBADA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DANGADBADA',23.0368785158337,77.6907423511147)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DANGADBADA',23.0368785158337,77.6907423511147),",
    },
    {
      "PART NO": 192,
      "PART NAME": "DHAMADHUSAR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DHAMDHOOSAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 765,
      location: "23.0389516666666,77.7057466666666",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DHAMDHOOSAR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DHAMDHOOSAR',23.0389516666666,77.7057466666666)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DHAMDHOOSAR',23.0389516666666,77.7057466666666),",
    },
    {
      "PART NO": 193,
      "PART NAME": "SAJADI VANAGRAM",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SAJDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 845,
      location: "23.041697,77.711384",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SAJDI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SAJDI',23.041697,77.711384)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SAJDI',23.041697,77.711384),",
    },
    {
      "PART NO": 194,
      "PART NAME": "VADAVAI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BADBAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 704,
      location: "23.0677333333333,77.7094533333333",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BADBAI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BADBAI',23.0677333333333,77.7094533333333)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BADBAI',23.0677333333333,77.7094533333333),",
    },
    {
      "PART NO": 195,
      "PART NAME": "NANDORA",
      "Name of polling station building":
        "GOVT PRIMARI SCHOOL BUILDING NANDORA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 347,
      location: "22.997207,77.585972",
      "inverted one": "'GOVT PRIMARI SCHOOL BUILDING NANDORA'",
      cosolideted:
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING NANDORA',22.997207,77.585972)",
      "with comma":
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING NANDORA',22.997207,77.585972),",
    },
    {
      "PART NO": 196,
      "PART NAME": "CHAPALASIR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING CAHPLASIR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 777,
      location: "23.0791949999999,77.7198933333333",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING CAHPLASIR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CAHPLASIR',23.0791949999999,77.7198933333333)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CAHPLASIR',23.0791949999999,77.7198933333333),",
    },
    {
      "PART NO": 197,
      "PART NAME": "BARAVATAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BARBATPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 846,
      location: "23.128565,77.7206533333333",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BARBATPUR'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BARBATPUR',23.128565,77.7206533333333)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BARBATPUR',23.128565,77.7206533333333),",
    },
    {
      "PART NO": 198,
      "PART NAME": "BEEJOR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BEEJOR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 743,
      location: "23.1052149999999,77.7233116666666",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BEEJOR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BEEJOR',23.1052149999999,77.7233116666666)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BEEJOR',23.1052149999999,77.7233116666666),",
    },
    {
      "PART NO": 199,
      "PART NAME": "SOUNTHAR",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SONTHAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 786,
      location: "23.13915,77.776495",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SONTHAR'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SONTHAR',23.13915,77.776495)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SONTHAR',23.13915,77.776495),",
    },
    {
      "PART NO": 200,
      "PART NAME": "KAMATOUN KASINYA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING ADDITIONAL ROOM KAAMTOUN KASIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 523,
      location: "23.0263416,77.6763908",
      "inverted one":
        "'GOVT.PRIMARY SCHOOL BUILDING ADDITIONAL ROOM KAAMTOUN KASIYA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING ADDITIONAL ROOM KAAMTOUN KASIYA',23.0263416,77.6763908)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING ADDITIONAL ROOM KAAMTOUN KASIYA',23.0263416,77.6763908),",
    },
    {
      "PART NO": 201,
      "PART NAME": "KAMATOUN TOLA",
      "Name of polling station building":
        "govt primari building kamtoun kansiya tola",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 441,
      location: "23.111995,77.797162",
      "inverted one": "'govt primari building kamtoun kansiya tola'",
      cosolideted:
        "ElectionBooth('govt primari building kamtoun kansiya tola',23.111995,77.797162)",
      "with comma":
        "ElectionBooth('govt primari building kamtoun kansiya tola',23.111995,77.797162),",
    },
    {
      "PART NO": 202,
      "PART NAME": "MAHOLI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING MAHOLI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 698,
      location: "23.1293866666666,77.8071399999999",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING MAHOLI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING MAHOLI',23.1293866666666,77.8071399999999)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING MAHOLI',23.1293866666666,77.8071399999999),",
    },
    {
      "PART NO": 203,
      "PART NAME": "CHAMPANER",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING CHAMPANER",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1238,
      location: "23.1293866666666,77.8071399999999",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING CHAMPANER'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHAMPANER',23.1293866666666,77.8071399999999)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHAMPANER',23.1293866666666,77.8071399999999),",
    },
    {
      "PART NO": 204,
      "PART NAME": "DAMDONGRI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING DAMDONGARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 344,
      location: "23.1086289324982,77.8349379077553",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING DAMDONGARI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING DAMDONGARI',23.1086289324982,77.8349379077553)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING DAMDONGARI',23.1086289324982,77.8349379077553),",
    },
    {
      "PART NO": 205,
      "PART NAME": "BINEKA",
      "Name of polling station building": "GRAM SWARAJ BUILDING BINEKA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1167,
      location: "23.0823466666666,77.7988933333333",
      "inverted one": "'GRAM SWARAJ BUILDING BINEKA'",
      cosolideted:
        "ElectionBooth('GRAM SWARAJ BUILDING BINEKA',23.0823466666666,77.7988933333333)",
      "with comma":
        "ElectionBooth('GRAM SWARAJ BUILDING BINEKA',23.0823466666666,77.7988933333333),",
    },
    {
      "PART NO": 206,
      "PART NAME": "LULKA",
      "Name of polling station building": "PRIMARY SCHOOL BUILDING LULKA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 488,
      location: "23.058285,77.8261316666666",
      "inverted one": "'PRIMARY SCHOOL BUILDING LULKA'",
      cosolideted:
        "ElectionBooth('PRIMARY SCHOOL BUILDING LULKA',23.058285,77.8261316666666)",
      "with comma":
        "ElectionBooth('PRIMARY SCHOOL BUILDING LULKA',23.058285,77.8261316666666),",
    },
    {
      "PART NO": 207,
      "PART NAME": "SAMANAPURAKALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SAMNAPUR KALAN ROOM NO. 2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1078,
      location: "23.0599335,77.7368173",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL BUILDING SAMNAPUR KALAN ROOM NO. 2'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SAMNAPUR KALAN ROOM NO. 2',23.0599335,77.7368173)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SAMNAPUR KALAN ROOM NO. 2',23.0599335,77.7368173),",
    },
    {
      "PART NO": 208,
      "PART NAME": "ALAMPUR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING ALAMPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1200,
      location: "23.0323632816602,77.6942292228341",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING ALAMPUR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING ALAMPUR',23.0323632816602,77.6942292228341)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING ALAMPUR',23.0323632816602,77.6942292228341),",
    },
    {
      "PART NO": 209,
      "PART NAME": "AGARIYA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING AGARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1226,
      location: "23.0306316849801,77.7331094510586",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING AGARIYA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING AGARIYA',23.0306316849801,77.7331094510586)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING AGARIYA',23.0306316849801,77.7331094510586),",
    },
    {
      "PART NO": 210,
      "PART NAME": "KHODRA",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING KHODRA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 505,
      location: "23.015819005359,77.761605824145",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KHODRA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KHODRA',23.015819005359,77.761605824145)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KHODRA',23.015819005359,77.761605824145),",
    },
    {
      "PART NO": 211,
      "PART NAME": "KARAKAVANI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KARAKBANI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 448,
      location: "23.0022074993615,77.7816731294874",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KARAKBANI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KARAKBANI',23.0022074993615,77.7816731294874)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KARAKBANI',23.0022074993615,77.7816731294874),",
    },
    {
      "PART NO": 212,
      "PART NAME": "PIPALIYA GOLI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PIPALIYAGOLI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 810,
      location: "22.9713511500306,77.7568282932043",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PIPALIYAGOLI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PIPALIYAGOLI',22.9713511500306,77.7568282932043)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PIPALIYAGOLI',22.9713511500306,77.7568282932043),",
    },
    {
      "PART NO": 213,
      "PART NAME": "DOB",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING DOB",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 895,
      location: "22.9703142498086,77.7543853704067",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING DOB'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING DOB',22.9703142498086,77.7543853704067)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING DOB',22.9703142498086,77.7543853704067),",
    },
    {
      "PART NO": 214,
      "PART NAME": "SIHORA",
      "Name of polling station building": "GOVT PRIMARI SCHOOL BUILDING SIHORA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 812,
      location: "22.997997,77.751109",
      "inverted one": "'GOVT PRIMARI SCHOOL BUILDING SIHORA'",
      cosolideted:
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING SIHORA',22.997997,77.751109)",
      "with comma":
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING SIHORA',22.997997,77.751109),",
    },
    {
      "PART NO": 215,
      "PART NAME": "VORAPANI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BORPANI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 795,
      location: "23.08135363,77.80038566",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BORPANI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BORPANI',23.08135363,77.80038566)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BORPANI',23.08135363,77.80038566),",
    },
    {
      "PART NO": 216,
      "PART NAME": "BORAKHADI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BORKHADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 725,
      location: "23.08036155,77.85626266",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BORKHADI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BORKHADI',23.08036155,77.85626266)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING BORKHADI',23.08036155,77.85626266),",
    },
    {
      "PART NO": 217,
      "PART NAME": "SIVANI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SIVNI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 821,
      location: "23.12317082,77.90127562",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SIVNI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SIVNI',23.12317082,77.90127562)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SIVNI',23.12317082,77.90127562),",
    },
    {
      "PART NO": 218,
      "PART NAME": "TADI",
      "Name of polling station building": "PANCHAYAT BUILDING TAADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 547,
      location: "23.1336614,77.9329984",
      "inverted one": "'PANCHAYAT BUILDING TAADI'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING TAADI',23.1336614,77.9329984)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING TAADI',23.1336614,77.9329984),",
    },
    {
      "PART NO": 219,
      "PART NAME": "KARAGHATI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KARAGHATI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 485,
      location: "23.16471944,77.90566179",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KARAGHATI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KARAGHATI',23.16471944,77.90566179)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KARAGHATI',23.16471944,77.90566179),",
    },
    {
      "PART NO": 220,
      "PART NAME": "RAJALAVADIKALA",
      "Name of polling station building":
        "GRAM PANCHAYAT BUILDING RAJALVADI KALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 736,
      location: "23.1645573091884,77.9288557916879",
      "inverted one": "'GRAM PANCHAYAT BUILDING RAJALVADI KALA'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING RAJALVADI KALA',23.1645573091884,77.9288557916879)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING RAJALVADI KALA',23.1645573091884,77.9288557916879),",
    },
    {
      "PART NO": 221,
      "PART NAME": "TAJAPURA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING TAJPURA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 814,
      location: "23.17638029,77.94390797",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING TAJPURA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING TAJPURA',23.17638029,77.94390797)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING TAJPURA',23.17638029,77.94390797),",
    },
    {
      "PART NO": 222,
      "PART NAME": "SEMARIKALA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING PALAKMATI SEMRI KALA ROOM NO. 01",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1244,
      location: "23.15629589,77.955798",
      "inverted one":
        "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING PALAKMATI SEMRI KALA ROOM NO. 01'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING PALAKMATI SEMRI KALA ROOM NO. 01',23.15629589,77.955798)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING PALAKMATI SEMRI KALA ROOM NO. 01',23.15629589,77.955798),",
    },
    {
      "PART NO": 223,
      "PART NAME": "SEMARIKALA",
      "Name of polling station building":
        "EKIKRAT GOVT. SCHOOL BUILDING PALAKMATI SEMARI KALA ROOM NO. 03",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 852,
      location: "23.15627055,77.95578288",
      "inverted one":
        "'EKIKRAT GOVT. SCHOOL BUILDING PALAKMATI SEMARI KALA ROOM NO. 03'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. SCHOOL BUILDING PALAKMATI SEMARI KALA ROOM NO. 03',23.15627055,77.95578288)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. SCHOOL BUILDING PALAKMATI SEMARI KALA ROOM NO. 03',23.15627055,77.95578288),",
    },
    {
      "PART NO": 224,
      "PART NAME": "IMALIYA TOLA",
      "Name of polling station building":
        "GOVT PRIMARI SCHOOL BUILDING IMALIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 962,
      location: "23.1469949,77.9795135",
      "inverted one": "'GOVT PRIMARI SCHOOL BUILDING IMALIYA'",
      cosolideted:
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING IMALIYA',23.1469949,77.9795135)",
      "with comma":
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING IMALIYA',23.1469949,77.9795135),",
    },
    {
      "PART NO": 225,
      "PART NAME": "SULTANAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR ROOM NO. 05",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 764,
      location: "23.13572283,77.93672",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR ROOM NO. 05'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR ROOM NO. 05',23.13572283,77.93672)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR ROOM NO. 05',23.13572283,77.93672),",
    },
    {
      "PART NO": 226,
      "PART NAME": "SULTANAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR ROOM NO. 03",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 640,
      location: "23.13574425,77.93668896",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR ROOM NO. 03'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR ROOM NO. 03',23.13574425,77.93668896)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR ROOM NO. 03',23.13574425,77.93668896),",
    },
    {
      "PART NO": 227,
      "PART NAME": "SULTANAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS MIDDLE SCHOOL BUILDING SULTANPUR ROOM NO. 03",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1201,
      location: "23.13642885,77.93397521",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS MIDDLE SCHOOL BUILDING SULTANPUR ROOM NO. 03'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS MIDDLE SCHOOL BUILDING SULTANPUR ROOM NO. 03',23.13642885,77.93397521)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS MIDDLE SCHOOL BUILDING SULTANPUR ROOM NO. 03',23.13642885,77.93397521),",
    },
    {
      "PART NO": 228,
      "PART NAME": "SULTANAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS MIDDLE SCHOOL BUILDING SULTANPUR ROOM NO. 04",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1210,
      location: "23.13674343,77.93386796",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS MIDDLE SCHOOL BUILDING SULTANPUR ROOM NO. 04'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS MIDDLE SCHOOL BUILDING SULTANPUR ROOM NO. 04',23.13674343,77.93386796)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS MIDDLE SCHOOL BUILDING SULTANPUR ROOM NO. 04',23.13674343,77.93386796),",
    },
    {
      "PART NO": 229,
      "PART NAME": "SULTANAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR CENTRAL PART ROOM NO. 04",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 756,
      location: "23.13589017,77.93677864",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR CENTRAL PART ROOM NO. 04'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR CENTRAL PART ROOM NO. 04',23.13589017,77.93677864)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR CENTRAL PART ROOM NO. 04',23.13589017,77.93677864),",
    },
    {
      "PART NO": 230,
      "PART NAME": "SULTANAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR NORTH PART ROOM NO. 12",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 996,
      location: "23.13556229,77.93696366",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR NORTH PART ROOM NO. 12'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR NORTH PART ROOM NO. 12',23.13556229,77.93696366)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR NORTH PART ROOM NO. 12',23.13556229,77.93696366),",
    },
    {
      "PART NO": 231,
      "PART NAME": "SULTANAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR ROOM NO. 10",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1137,
      location: "23.1360505,77.93706888",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR ROOM NO. 10'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR ROOM NO. 10',23.1360505,77.93706888)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING SULTANPUR ROOM NO. 10',23.1360505,77.93706888),",
    },
    {
      "PART NO": 232,
      "PART NAME": "SULTANAPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING (BOYS P.S.) SULTANPUR ROOM NO. 30",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1365,
      location: "23.1362761,77.93765986",
      "inverted one":
        "'EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING (BOYS P.S.) SULTANPUR ROOM NO. 30'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING (BOYS P.S.) SULTANPUR ROOM NO. 30',23.1362761,77.93765986)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGHER SECONDARY SCHOOL BUILDING (BOYS P.S.) SULTANPUR ROOM NO. 30',23.1362761,77.93765986),",
    },
    {
      "PART NO": 233,
      "PART NAME": "VAGASAPUR",
      "Name of polling station building": "SAMUDAYIK BUILDING BAGASPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1234,
      location: "23.09590313,77.95863816",
      "inverted one": "'SAMUDAYIK BUILDING BAGASPUR'",
      cosolideted:
        "ElectionBooth('SAMUDAYIK BUILDING BAGASPUR',23.09590313,77.95863816)",
      "with comma":
        "ElectionBooth('SAMUDAYIK BUILDING BAGASPUR',23.09590313,77.95863816),",
    },
    {
      "PART NO": 234,
      "PART NAME": "GHATAPIPALIYA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING GHATPIPALIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1085,
      location: "23.09807893,77.94033419",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING GHATPIPALIYA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING GHATPIPALIYA',23.09807893,77.94033419)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING GHATPIPALIYA',23.09807893,77.94033419),",
    },
    {
      "PART NO": 235,
      "PART NAME": "DEHAGANV",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DEHGAON",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 740,
      location: "23.09395835,77.95347689",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DEHGAON'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DEHGAON',23.09395835,77.95347689)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DEHGAON',23.09395835,77.95347689),",
    },
    {
      "PART NO": 236,
      "PART NAME": "KHAMARIYA NIMAVAR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING KHAMARIYA NIMAWAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 797,
      location: "23.1338183333333,77.932685",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KHAMARIYA NIMAWAR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KHAMARIYA NIMAWAR',23.1338183333333,77.932685)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KHAMARIYA NIMAWAR',23.1338183333333,77.932685),",
    },
    {
      "PART NO": 237,
      "PART NAME": "KHAPARIYA KHAPA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KHAPARIYA KHAPA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 972,
      location: "23.0884806,77.8935834",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KHAPARIYA KHAPA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KHAPARIYA KHAPA',23.0884806,77.8935834)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING KHAPARIYA KHAPA',23.0884806,77.8935834),",
    },
    {
      "PART NO": 238,
      "PART NAME": "RAMPURA MANDVA",
      "Name of polling station building":
        "GOVT PRIMARI SCHOOL BUILDING RAMPURA MANDVA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 336,
      location: "23.0855926,77.9154508",
      "inverted one": "'GOVT PRIMARI SCHOOL BUILDING RAMPURA MANDVA'",
      cosolideted:
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING RAMPURA MANDVA',23.0855926,77.9154508)",
      "with comma":
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING RAMPURA MANDVA',23.0855926,77.9154508),",
    },
    {
      "PART NO": 239,
      "PART NAME": "JAITPUR",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING JAITPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 462,
      location: "23.032671,77.9147807",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING JAITPUR'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING JAITPUR',23.032671,77.9147807)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING JAITPUR',23.032671,77.9147807),",
    },
    {
      "PART NO": 240,
      "PART NAME": "SEMARIKHURD",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SEMRIKHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 658,
      location: "23.070765,77.96601",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SEMRIKHURD'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SEMRIKHURD',23.070765,77.96601)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SEMRIKHURD',23.070765,77.96601),",
    },
    {
      "PART NO": 241,
      "PART NAME": "SILARI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SILARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 968,
      location: "23.05828355,77.96189484",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SILARI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SILARI',23.05828355,77.96189484)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING SILARI',23.05828355,77.96189484),",
    },
    {
      "PART NO": 242,
      "PART NAME": "JHIRPAI",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDLE SCHOOL BUILDING JHIRPAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1190,
      location: "23.0419333,77.96681504",
      "inverted one": "'EKIKRAT GOVT. MIDDLE SCHOOL BUILDING JHIRPAI'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING JHIRPAI',23.0419333,77.96681504)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDLE SCHOOL BUILDING JHIRPAI',23.0419333,77.96681504),",
    },
    {
      "PART NO": 243,
      "PART NAME": "DUNGARIYA PONIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PONIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 810,
      location: "23.0271986852575,77.9837036132812",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PONIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PONIYA',23.0271986852575,77.9837036132812)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PONIYA',23.0271986852575,77.9837036132812),",
    },
    {
      "PART NO": 244,
      "PART NAME": "KEMBALI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING KEMBALI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 904,
      location: "23.02496648,77.99724688",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KEMBALI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KEMBALI',23.02496648,77.99724688)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KEMBALI',23.02496648,77.99724688),",
    },
    {
      "PART NO": 245,
      "PART NAME": "NEELGAD",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING NEELGAD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 356,
      location: "23.09392243,77.9534566",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING NEELGAD'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING NEELGAD',23.09392243,77.9534566)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING NEELGAD',23.09392243,77.9534566),",
    },
    {
      "PART NO": 246,
      "PART NAME": "KEVALAJHIR",
      "Name of polling station building":
        "EKIKRAT MIDDLE SCHOOL BUILDING EXTRA ROOM KEVLAJHIR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 467,
      location: "22.9950822254131,78.0894351705721",
      "inverted one": "'EKIKRAT MIDDLE SCHOOL BUILDING EXTRA ROOM KEVLAJHIR'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDLE SCHOOL BUILDING EXTRA ROOM KEVLAJHIR',22.9950822254131,78.0894351705721)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDLE SCHOOL BUILDING EXTRA ROOM KEVLAJHIR',22.9950822254131,78.0894351705721),",
    },
    {
      "PART NO": 247,
      "PART NAME": "MANGAROL",
      "Name of polling station building": "MIDDLE SCHOOL BUILDING MANGROLE",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1154,
      location: "22.9950822254131,78.0894351705721",
      "inverted one": "'MIDDLE SCHOOL BUILDING MANGROLE'",
      cosolideted:
        "ElectionBooth('MIDDLE SCHOOL BUILDING MANGROLE',22.9950822254131,78.0894351705721)",
      "with comma":
        "ElectionBooth('MIDDLE SCHOOL BUILDING MANGROLE',22.9950822254131,78.0894351705721),",
    },
    {
      "PART NO": 248,
      "PART NAME": "BHAINSAYA",
      "Name of polling station building":
        "EKIKRAT MIDDLE SCHOOL BUILDING BHAISAYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 719,
      location: "22.9950822254131,78.0894351705721",
      "inverted one": "'EKIKRAT MIDDLE SCHOOL BUILDING BHAISAYA'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDLE SCHOOL BUILDING BHAISAYA',22.9950822254131,78.0894351705721)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDLE SCHOOL BUILDING BHAISAYA',22.9950822254131,78.0894351705721),",
    },
    {
      "PART NO": 249,
      "PART NAME": "AMARAVADAKALAN",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGH SCHOOL NORTH EAST PART AMRAVAD KALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1236,
      location: "22.9658209,78.0863582",
      "inverted one":
        "'EKIKRAT GOVT. HIGH SCHOOL NORTH EAST PART AMRAVAD KALA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL NORTH EAST PART AMRAVAD KALA',22.9658209,78.0863582)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL NORTH EAST PART AMRAVAD KALA',22.9658209,78.0863582),",
    },
    {
      "PART NO": 250,
      "PART NAME": "AMARAVADAKALAN",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGH SCHOOL SOUTH EAST PART AMRAVADKALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1112,
      location: "22.9950822254131,78.0894351705721",
      "inverted one": "'EKIKRAT GOVT. HIGH SCHOOL SOUTH EAST PART AMRAVADKALA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL SOUTH EAST PART AMRAVADKALA',22.9950822254131,78.0894351705721)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL SOUTH EAST PART AMRAVADKALA',22.9950822254131,78.0894351705721),",
    },
    {
      "PART NO": 251,
      "PART NAME": "BABAI",
      "Name of polling station building":
        "EKIKRAT MIDDLE SCHOOL BUILDING VAVAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1176,
      location: "22.9950466098035,78.0894120840296",
      "inverted one": "'EKIKRAT MIDDLE SCHOOL BUILDING VAVAI'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDLE SCHOOL BUILDING VAVAI',22.9950466098035,78.0894120840296)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDLE SCHOOL BUILDING VAVAI',22.9950466098035,78.0894120840296),",
    },
    {
      "PART NO": 252,
      "PART NAME": "PATANI",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL BUILDING PATNI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 603,
      location: "22.9950822254131,78.0894351705721",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PATNI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PATNI',22.9950822254131,78.0894351705721)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PATNI',22.9950822254131,78.0894351705721),",
    },
    {
      "PART NO": 253,
      "PART NAME": "BADI KALAN",
      "Name of polling station building":
        "ADIWASI ASHRAM MANORANJAN BHAVAN BADI KALA UTTARI BHAG",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 884,
      location: "23.0374245563231,78.0875767044193",
      "inverted one":
        "'ADIWASI ASHRAM MANORANJAN BHAVAN BADI KALA UTTARI BHAG'",
      cosolideted:
        "ElectionBooth('ADIWASI ASHRAM MANORANJAN BHAVAN BADI KALA UTTARI BHAG',23.0374245563231,78.0875767044193)",
      "with comma":
        "ElectionBooth('ADIWASI ASHRAM MANORANJAN BHAVAN BADI KALA UTTARI BHAG',23.0374245563231,78.0875767044193),",
    },
    {
      "PART NO": 254,
      "PART NAME": "BADI KALA-4",
      "Name of polling station building": "ENTERTAINMENT CENTRE BADIKALAN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 688,
      location: "23.04011,78.081955",
      "inverted one": "'ENTERTAINMENT CENTRE BADIKALAN'",
      cosolideted:
        "ElectionBooth('ENTERTAINMENT CENTRE BADIKALAN',23.04011,78.081955)",
      "with comma":
        "ElectionBooth('ENTERTAINMENT CENTRE BADIKALAN',23.04011,78.081955),",
    },
    {
      "PART NO": 255,
      "PART NAME": "BADI KHURD-1",
      "Name of polling station building":
        "KRISHI VIBHAG S.A.D.O.OFFICE BADIKHURD",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1192,
      location: "23.0336193988366,78.0779266779864",
      "inverted one": "'KRISHI VIBHAG S.A.D.O.OFFICE BADIKHURD'",
      cosolideted:
        "ElectionBooth('KRISHI VIBHAG S.A.D.O.OFFICE BADIKHURD',23.0336193988366,78.0779266779864)",
      "with comma":
        "ElectionBooth('KRISHI VIBHAG S.A.D.O.OFFICE BADIKHURD',23.0336193988366,78.0779266779864),",
    },
    {
      "PART NO": 256,
      "PART NAME": "BADI KHURD-2",
      "Name of polling station building":
        "B.R.C.BUILDING JANPAD PANCHAYAT BADI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1330,
      location: "23.0308098575233,78.0809254315768",
      "inverted one": "'B.R.C.BUILDING JANPAD PANCHAYAT BADI'",
      cosolideted:
        "ElectionBooth('B.R.C.BUILDING JANPAD PANCHAYAT BADI',23.0308098575233,78.0809254315768)",
      "with comma":
        "ElectionBooth('B.R.C.BUILDING JANPAD PANCHAYAT BADI',23.0308098575233,78.0809254315768),",
    },
    {
      "PART NO": 257,
      "PART NAME": "BADI KHURD-3",
      "Name of polling station building":
        "ADDITIONAL BUILDING JANPAD PANCHAYAT BADI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 447,
      location: "23.0309406399275,78.0809704076728",
      "inverted one": "'ADDITIONAL BUILDING JANPAD PANCHAYAT BADI'",
      cosolideted:
        "ElectionBooth('ADDITIONAL BUILDING JANPAD PANCHAYAT BADI',23.0309406399275,78.0809704076728)",
      "with comma":
        "ElectionBooth('ADDITIONAL BUILDING JANPAD PANCHAYAT BADI',23.0309406399275,78.0809704076728),",
    },
    {
      "PART NO": 258,
      "PART NAME": "BADI KALAN-1",
      "Name of polling station building":
        "EKIKRAT GOVT. BOYS MIDDILE SCHOOL NORTH PART BADI KALA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1009,
      location: "23.0373283,78.08766",
      "inverted one":
        "'EKIKRAT GOVT. BOYS MIDDILE SCHOOL NORTH PART BADI KALA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. BOYS MIDDILE SCHOOL NORTH PART BADI KALA',23.0373283,78.08766)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. BOYS MIDDILE SCHOOL NORTH PART BADI KALA',23.0373283,78.08766),",
    },
    {
      "PART NO": 259,
      "PART NAME": "BADI KALA -2",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS MIDDILE SCHOOL NORTH PART BADI KALA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1009,
      location: "23.0417683,78.08145",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS MIDDILE SCHOOL NORTH PART BADI KALA'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS MIDDILE SCHOOL NORTH PART BADI KALA',23.0417683,78.08145)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS MIDDILE SCHOOL NORTH PART BADI KALA',23.0417683,78.08145),",
    },
    {
      "PART NO": 260,
      "PART NAME": "BADI KHURD",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS MIDDILE SCHOOL BADIKHURD NORTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 809,
      location: "23.0326267876352,78.0781383175405",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS MIDDILE SCHOOL BADIKHURD NORTH PART'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS MIDDILE SCHOOL BADIKHURD NORTH PART',23.0326267876352,78.0781383175405)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS MIDDILE SCHOOL BADIKHURD NORTH PART',23.0326267876352,78.0781383175405),",
    },
    {
      "PART NO": 261,
      "PART NAME": "BADI KHURD",
      "Name of polling station building":
        "EKIKRAT GOVT. GIRLS MIDDILE SCHOOL BADIKHURD SOUTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1020,
      location: "23.0324434785513,78.0779744285873",
      "inverted one":
        "'EKIKRAT GOVT. GIRLS MIDDILE SCHOOL BADIKHURD SOUTH PART'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. GIRLS MIDDILE SCHOOL BADIKHURD SOUTH PART',23.0324434785513,78.0779744285873)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. GIRLS MIDDILE SCHOOL BADIKHURD SOUTH PART',23.0324434785513,78.0779744285873),",
    },
    {
      "PART NO": 262,
      "PART NAME": "BADI KHURD",
      "Name of polling station building":
        "GOVT. UTKRAT HIGHER SECONDARY SCHOOL BADI KHURD ROOM NO. 16",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1151,
      location: "23.0332883351617,78.0739934576682",
      "inverted one":
        "'GOVT. UTKRAT HIGHER SECONDARY SCHOOL BADI KHURD ROOM NO. 16'",
      cosolideted:
        "ElectionBooth('GOVT. UTKRAT HIGHER SECONDARY SCHOOL BADI KHURD ROOM NO. 16',23.0332883351617,78.0739934576682)",
      "with comma":
        "ElectionBooth('GOVT. UTKRAT HIGHER SECONDARY SCHOOL BADI KHURD ROOM NO. 16',23.0332883351617,78.0739934576682),",
    },
    {
      "PART NO": 263,
      "PART NAME": "BADI KHURD",
      "Name of polling station building":
        "GOVT. UTKRAT HIGHER SECONDARY SCHOOL BADI KHURD ROOM NO. 18",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 658,
      location: "23.033281268506,78.0737998854125",
      "inverted one":
        "'GOVT. UTKRAT HIGHER SECONDARY SCHOOL BADI KHURD ROOM NO. 18'",
      cosolideted:
        "ElectionBooth('GOVT. UTKRAT HIGHER SECONDARY SCHOOL BADI KHURD ROOM NO. 18',23.033281268506,78.0737998854125)",
      "with comma":
        "ElectionBooth('GOVT. UTKRAT HIGHER SECONDARY SCHOOL BADI KHURD ROOM NO. 18',23.033281268506,78.0737998854125),",
    },
    {
      "PART NO": 264,
      "PART NAME": "BADI KHURD",
      "Name of polling station building":
        "JANPAD PANCHAYAT OFFICE BUILDING BADIKHURD",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1362,
      location: "23.0312798331134,78.0790447356666",
      "inverted one": "'JANPAD PANCHAYAT OFFICE BUILDING BADIKHURD'",
      cosolideted:
        "ElectionBooth('JANPAD PANCHAYAT OFFICE BUILDING BADIKHURD',23.0312798331134,78.0790447356666)",
      "with comma":
        "ElectionBooth('JANPAD PANCHAYAT OFFICE BUILDING BADIKHURD',23.0312798331134,78.0790447356666),",
    },
    {
      "PART NO": 265,
      "PART NAME": "BADI KHURD",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BADIKHURD",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 817,
      location: "23.033369835844,78.0716928630373",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BADIKHURD'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BADIKHURD',23.033369835844,78.0716928630373)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BADIKHURD',23.033369835844,78.0716928630373),",
    },
    {
      "PART NO": 266,
      "PART NAME": "BAMHORI BAJIRAGANJ",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL NEW BUILDING BAMHORI BAJEERGANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1322,
      location: "23.0340708357225,78.0692732724028",
      "inverted one": "'GOVT. PRIMARY SCHOOL NEW BUILDING BAMHORI BAJEERGANJ'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL NEW BUILDING BAMHORI BAJEERGANJ',23.0340708357225,78.0692732724028)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL NEW BUILDING BAMHORI BAJEERGANJ',23.0340708357225,78.0692732724028),",
    },
    {
      "PART NO": 267,
      "PART NAME": "SIRAVARA",
      "Name of polling station building":
        "SOIL TESTING LABORATORY (AGRICULTURE) DEM ROAD BADI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 856,
      location: "23.01840109,78.05610634",
      "inverted one": "'SOIL TESTING LABORATORY (AGRICULTURE) DEM ROAD BADI'",
      cosolideted:
        "ElectionBooth('SOIL TESTING LABORATORY (AGRICULTURE) DEM ROAD BADI',23.01840109,78.05610634)",
      "with comma":
        "ElectionBooth('SOIL TESTING LABORATORY (AGRICULTURE) DEM ROAD BADI',23.01840109,78.05610634),",
    },
    {
      "PART NO": 268,
      "PART NAME": "SIRAVARA",
      "Name of polling station building": "COMMUNITY BUILDING SIRVARA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 707,
      location: "23.0297931,78.0782081",
      "inverted one": "'COMMUNITY BUILDING SIRVARA'",
      cosolideted:
        "ElectionBooth('COMMUNITY BUILDING SIRVARA',23.0297931,78.0782081)",
      "with comma":
        "ElectionBooth('COMMUNITY BUILDING SIRVARA',23.0297931,78.0782081),",
    },
    {
      "PART NO": 269,
      "PART NAME": "PANAGAR",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGH SCHOOL PRIMARY BUILDING PANAGAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 677,
      location: "23.04968779,78.1640552",
      "inverted one": "'EKIKRAT GOVT. HIGH SCHOOL PRIMARY BUILDING PANAGAR'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL PRIMARY BUILDING PANAGAR',23.04968779,78.1640552)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL PRIMARY BUILDING PANAGAR',23.04968779,78.1640552),",
    },
    {
      "PART NO": 270,
      "PART NAME": "RATANAPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING RATANPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 577,
      location: "23.09046498,78.14638898",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING RATANPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RATANPUR',23.09046498,78.14638898)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RATANPUR',23.09046498,78.14638898),",
    },
    {
      "PART NO": 271,
      "PART NAME": "PALI",
      "Name of polling station building":
        "EKIKRAT MIDDILE SCHOOL KA. PRIMARY BUILDING PALI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 838,
      location: "23.05061217,78.16135436",
      "inverted one": "'EKIKRAT MIDDILE SCHOOL KA. PRIMARY BUILDING PALI'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL KA. PRIMARY BUILDING PALI',23.05061217,78.16135436)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL KA. PRIMARY BUILDING PALI',23.05061217,78.16135436),",
    },
    {
      "PART NO": 272,
      "PART NAME": "GHANA KALAN",
      "Name of polling station building":
        "GOVT PRIMARI SCHOOL BUILDING GHANA KALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 618,
      location: "23.13598225,77.93482494",
      "inverted one": "'GOVT PRIMARI SCHOOL BUILDING GHANA KALA'",
      cosolideted:
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING GHANA KALA',23.13598225,77.93482494)",
      "with comma":
        "ElectionBooth('GOVT PRIMARI SCHOOL BUILDING GHANA KALA',23.13598225,77.93482494),",
    },
    {
      "PART NO": 273,
      "PART NAME": "AJITANAGAR (BHIMAPUR KANJII)",
      "Name of polling station building":
        "GOVT. HIGH SCHOOL BUILDING AJITNAGAR ROOM NO. 2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 890,
      location: "23.13598222,77.93482473",
      "inverted one": "'GOVT. HIGH SCHOOL BUILDING AJITNAGAR ROOM NO. 2'",
      cosolideted:
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING AJITNAGAR ROOM NO. 2',23.13598222,77.93482473)",
      "with comma":
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING AJITNAGAR ROOM NO. 2',23.13598222,77.93482473),",
    },
    {
      "PART NO": 274,
      "PART NAME": "BAREKHEDI KALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL NEW BUILDING BERKHEDIKALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 764,
      location: "23.13598209,77.93482468",
      "inverted one": "'GOVT. PRIMARY SCHOOL NEW BUILDING BERKHEDIKALAN'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL NEW BUILDING BERKHEDIKALAN',23.13598209,77.93482468)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL NEW BUILDING BERKHEDIKALAN',23.13598209,77.93482468),",
    },
    {
      "PART NO": 275,
      "PART NAME": "KADHAIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KADHAIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 711,
      location: "23.13613376,77.93498774",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KADHAIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KADHAIYA',23.13613376,77.93498774)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KADHAIYA',23.13613376,77.93498774),",
    },
    {
      "PART NO": 276,
      "PART NAME": "GORAKHAPUR",
      "Name of polling station building":
        "EKIKRAT MIDDILE SCHOOL BUILDING GORAKHPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 823,
      location: "23.1157209,78.0157866",
      "inverted one": "'EKIKRAT MIDDILE SCHOOL BUILDING GORAKHPUR'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING GORAKHPUR',23.1157209,78.0157866)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING GORAKHPUR',23.1157209,78.0157866),",
    },
    {
      "PART NO": 277,
      "PART NAME": "KINAGI",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL BUILDING KINGI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1187,
      location: "23.1272730824704,77.9883478569857",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KINGI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KINGI',23.1272730824704,77.9883478569857)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KINGI',23.1272730824704,77.9883478569857),",
    },
    {
      "PART NO": 278,
      "PART NAME": "MAJOOS KALA",
      "Name of polling station building":
        "EKIKRAT MIDDILE GOVT. SCHOOL EXTRA BUILDING MANJUSKALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 490,
      location: "23.1129299,77.9712183",
      "inverted one":
        "'EKIKRAT MIDDILE GOVT. SCHOOL EXTRA BUILDING MANJUSKALA'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDILE GOVT. SCHOOL EXTRA BUILDING MANJUSKALA',23.1129299,77.9712183)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDILE GOVT. SCHOOL EXTRA BUILDING MANJUSKALA',23.1129299,77.9712183),",
    },
    {
      "PART NO": 279,
      "PART NAME": "UDADAMAU",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BUILDING EAST PART UDADMAU",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 866,
      location: "23.173975,77.9863183333333",
      "inverted one":
        "'EKIKRAT GOVT. MIDDILE SCHOOL BUILDING EAST PART UDADMAU'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING EAST PART UDADMAU',23.173975,77.9863183333333)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING EAST PART UDADMAU',23.173975,77.9863183333333),",
    },
    {
      "PART NO": 280,
      "PART NAME": "INTAKHEDI",
      "Name of polling station building": "GOVT.H.S.S BUILDING ITKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 595,
      location: "23.178673,77.966717",
      "inverted one": "'GOVT.H.S.S BUILDING ITKHEDI'",
      cosolideted:
        "ElectionBooth('GOVT.H.S.S BUILDING ITKHEDI',23.178673,77.966717)",
      "with comma":
        "ElectionBooth('GOVT.H.S.S BUILDING ITKHEDI',23.178673,77.966717),",
    },
    {
      "PART NO": 281,
      "PART NAME": "KODARI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL NEW BUILDING KODHRI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1010,
      location: "23.1786833333333,77.9667833333333",
      "inverted one": "'GOVT. PRIMARY SCHOOL NEW BUILDING KODHRI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL NEW BUILDING KODHRI',23.1786833333333,77.9667833333333)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL NEW BUILDING KODHRI',23.1786833333333,77.9667833333333),",
    },
    {
      "PART NO": 282,
      "PART NAME": "BAGABADA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BAGBADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 485,
      location: "23.2075733333333,77.9816333333333",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BAGBADA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAGBADA',23.2075733333333,77.9816333333333)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAGBADA',23.2075733333333,77.9816333333333),",
    },
    {
      "PART NO": 283,
      "PART NAME": "UMARAI BAHARA",
      "Name of polling station building":
        "EKIKRAT GOVT.HIGH SCHOOL SHALA BHAWAN UMRAI BEHRA ROOM NO. 04",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 868,
      location: "23.22900823,77.97444263",
      "inverted one":
        "'EKIKRAT GOVT.HIGH SCHOOL SHALA BHAWAN UMRAI BEHRA ROOM NO. 04'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT.HIGH SCHOOL SHALA BHAWAN UMRAI BEHRA ROOM NO. 04',23.22900823,77.97444263)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT.HIGH SCHOOL SHALA BHAWAN UMRAI BEHRA ROOM NO. 04',23.22900823,77.97444263),",
    },
    {
      "PART NO": 284,
      "PART NAME": "KHAMARIYA SOHANAPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KHAMARIYA SOHANPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1010,
      location: "23.23493433,77.99617083",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KHAMARIYA SOHANPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHAMARIYA SOHANPUR',23.23493433,77.99617083)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHAMARIYA SOHANPUR',23.23493433,77.99617083),",
    },
    {
      "PART NO": 285,
      "PART NAME": "GHONTI BAHARA",
      "Name of polling station building":
        "EKIKRAT MIDDILE SCHOOL BUILDING EXTRA ROOM SOUTH PART GHOTI VEHRA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1093,
      location: "23.1798867,77.9704807",
      "inverted one":
        "'EKIKRAT MIDDILE SCHOOL BUILDING EXTRA ROOM SOUTH PART GHOTI VEHRA'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING EXTRA ROOM SOUTH PART GHOTI VEHRA',23.1798867,77.9704807)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING EXTRA ROOM SOUTH PART GHOTI VEHRA',23.1798867,77.9704807),",
    },
    {
      "PART NO": 286,
      "PART NAME": "SANTARA",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGH SCHOOL BUILDING SANTRA ROOM NO. 01",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 865,
      location: "23.23663627,78.12976769",
      "inverted one": "'EKIKRAT GOVT. HIGH SCHOOL BUILDING SANTRA ROOM NO. 01'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL BUILDING SANTRA ROOM NO. 01',23.23663627,78.12976769)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL BUILDING SANTRA ROOM NO. 01',23.23663627,78.12976769),",
    },
    {
      "PART NO": 287,
      "PART NAME": "KAUSMI",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGH SCHOOL BUILDING KOSMI ROOM NO. 02",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 586,
      location: "23.20444297,78.11902958",
      "inverted one": "'EKIKRAT GOVT. HIGH SCHOOL BUILDING KOSMI ROOM NO. 02'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL BUILDING KOSMI ROOM NO. 02',23.20444297,78.11902958)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL BUILDING KOSMI ROOM NO. 02',23.20444297,78.11902958),",
    },
    {
      "PART NO": 288,
      "PART NAME": "CHURAKA",
      "Name of polling station building":
        "EKIKRAT GOVT. MIDDILE SCHOOL BUILDING CHURKA ROOM NO. 2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 473,
      location: "23.19922961,78.08147287",
      "inverted one":
        "'EKIKRAT GOVT. MIDDILE SCHOOL BUILDING CHURKA ROOM NO. 2'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING CHURKA ROOM NO. 2',23.19922961,78.08147287)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. MIDDILE SCHOOL BUILDING CHURKA ROOM NO. 2',23.19922961,78.08147287),",
    },
    {
      "PART NO": 289,
      "PART NAME": "CHOURAKAMAROURA",
      "Name of polling station building":
        "GOVT.BOYS ADIWASI CHHATRAVAS CHAURAKAMRORA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1030,
      location: "23.13598689,77.93479847",
      "inverted one": "'GOVT.BOYS ADIWASI CHHATRAVAS CHAURAKAMRORA'",
      cosolideted:
        "ElectionBooth('GOVT.BOYS ADIWASI CHHATRAVAS CHAURAKAMRORA',23.13598689,77.93479847)",
      "with comma":
        "ElectionBooth('GOVT.BOYS ADIWASI CHHATRAVAS CHAURAKAMRORA',23.13598689,77.93479847),",
    },
    {
      "PART NO": 290,
      "PART NAME": "CHOURAKAMAROURA",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL BUILDING",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 582,
      location: "23.1847,78.1149567",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING',23.1847,78.1149567)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING',23.1847,78.1149567),",
    },
    {
      "PART NO": 291,
      "PART NAME": "BHARATIPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BHARTIPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 870,
      location: "23.154615,78.1102217",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BHARTIPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BHARTIPUR',23.154615,78.1102217)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BHARTIPUR',23.154615,78.1102217),",
    },
    {
      "PART NO": 292,
      "PART NAME": "PANAJHIRAPA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DUDADEH",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 570,
      location: "23.0171775817871,78.2413864135742",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DUDADEH'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DUDADEH',23.0171775817871,78.2413864135742)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DUDADEH',23.0171775817871,78.2413864135742),",
    },
    {
      "PART NO": 293,
      "PART NAME": "RIMASILI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING RIMSILI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 593,
      location: "23.05061206,78.16135445",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING RIMSILI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RIMSILI',23.05061206,78.16135445)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RIMSILI',23.05061206,78.16135445),",
    },
    {
      "PART NO": 294,
      "PART NAME": "KARAMABADA",
      "Name of polling station building":
        "EKIKRAT MIDDILE SCHOOL BUILDING KARAMBADA ROOM NO. 01",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 833,
      location: "23.05467101,78.18537918",
      "inverted one": "'EKIKRAT MIDDILE SCHOOL BUILDING KARAMBADA ROOM NO. 01'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING KARAMBADA ROOM NO. 01',23.05467101,78.18537918)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING KARAMBADA ROOM NO. 01',23.05467101,78.18537918),",
    },
    {
      "PART NO": 295,
      "PART NAME": "CHAINAPUR",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL CHAINPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1180,
      location: "23.047535,78.152702",
      "inverted one": "'GOVT. PRIMARY SCHOOL CHAINPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL CHAINPUR',23.047535,78.152702)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL CHAINPUR',23.047535,78.152702),",
    },
    {
      "PART NO": 296,
      "PART NAME": "CHAINAPUR",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING CHAINPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 609,
      location: "23.04968781,78.16405523",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING CHAINPUR'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING CHAINPUR',23.04968781,78.16405523)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING CHAINPUR',23.04968781,78.16405523),",
    },
    {
      "PART NO": 297,
      "PART NAME": "NAYAGANV",
      "Name of polling station building":
        "GOVT. NEW PRIMARY SCHOOL BUILDING NAYAGAON",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 526,
      location: "23.05061117,78.16135585",
      "inverted one": "'GOVT. NEW PRIMARY SCHOOL BUILDING NAYAGAON'",
      cosolideted:
        "ElectionBooth('GOVT. NEW PRIMARY SCHOOL BUILDING NAYAGAON',23.05061117,78.16135585)",
      "with comma":
        "ElectionBooth('GOVT. NEW PRIMARY SCHOOL BUILDING NAYAGAON',23.05061117,78.16135585),",
    },
    {
      "PART NO": 298,
      "PART NAME": "PARATALAI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PARTALAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1164,
      location: "23.03958334,78.14226572",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PARTALAI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PARTALAI',23.03958334,78.14226572)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PARTALAI',23.03958334,78.14226572),",
    },
    {
      "PART NO": 299,
      "PART NAME": "PARATALAI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PAARTAALAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 531,
      location: "23.03236175968,78.0719014314483",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PAARTAALAI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PAARTAALAI',23.03236175968,78.0719014314483)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PAARTAALAI',23.03236175968,78.0719014314483),",
    },
    {
      "PART NO": 300,
      "PART NAME": "GEHALPUR",
      "Name of polling station building": "GOVT PRIMARI SCHOOL GEHALPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 771,
      location: "23.0090833,78.1096062",
      "inverted one": "'GOVT PRIMARI SCHOOL GEHALPUR'",
      cosolideted:
        "ElectionBooth('GOVT PRIMARI SCHOOL GEHALPUR',23.0090833,78.1096062)",
      "with comma":
        "ElectionBooth('GOVT PRIMARI SCHOOL GEHALPUR',23.0090833,78.1096062),",
    },
    {
      "PART NO": 301,
      "PART NAME": "KANAVAR",
      "Name of polling station building":
        "EKIKRAT MIDDILE SCHOOL BUILDING KANVAR ROOM NO. 2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 645,
      location: "23.028254211797,78.1191678692053",
      "inverted one": "'EKIKRAT MIDDILE SCHOOL BUILDING KANVAR ROOM NO. 2'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING KANVAR ROOM NO. 2',23.028254211797,78.1191678692053)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING KANVAR ROOM NO. 2',23.028254211797,78.1191678692053),",
    },
    {
      "PART NO": 302,
      "PART NAME": "HARASILI",
      "Name of polling station building":
        "EKIKRAT MIDDILE SCHOOL BUILDING KA ROOM NO. 03 HARSILI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 811,
      location: "23.0091265946493,78.1541965529322",
      "inverted one":
        "'EKIKRAT MIDDILE SCHOOL BUILDING KA ROOM NO. 03 HARSILI'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING KA ROOM NO. 03 HARSILI',23.0091265946493,78.1541965529322)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING KA ROOM NO. 03 HARSILI',23.0091265946493,78.1541965529322),",
    },
    {
      "PART NO": 303,
      "PART NAME": "DAHALABADA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DAHALVADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 416,
      location: "23.0072721827293,78.1455783623112",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DAHALVADA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DAHALVADA',23.0072721827293,78.1455783623112)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DAHALVADA',23.0072721827293,78.1455783623112),",
    },
    {
      "PART NO": 304,
      "PART NAME": "GAGANABADA",
      "Name of polling station building":
        "EKIKRAT MIDDILE SCHOOL BUILDING EAST PART GAGANVADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 729,
      location: "22.9957462,78.2000799",
      "inverted one": "'EKIKRAT MIDDILE SCHOOL BUILDING EAST PART GAGANVADA'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING EAST PART GAGANVADA',22.9957462,78.2000799)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING EAST PART GAGANVADA',22.9957462,78.2000799),",
    },
    {
      "PART NO": 305,
      "PART NAME": "GAGANABADA",
      "Name of polling station building":
        "EKIKRAT MIDDILE SCHOOL BUILDING NORTH PART GAGANVADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 683,
      location: "23.01831,78.1975883",
      "inverted one": "'EKIKRAT MIDDILE SCHOOL BUILDING NORTH PART GAGANVADA'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING NORTH PART GAGANVADA',23.01831,78.1975883)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDILE SCHOOL BUILDING NORTH PART GAGANVADA',23.01831,78.1975883),",
    },
    {
      "PART NO": 306,
      "PART NAME": "SEMARI TIPPA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SEMRI TIPPA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 385,
      location: "23.0112467,78.2185483",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SEMRI TIPPA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SEMRI TIPPA',23.0112467,78.2185483)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SEMRI TIPPA',23.0112467,78.2185483),",
    },
    {
      "PART NO": 307,
      "PART NAME": "CHARAGANV",
      "Name of polling station building":
        "EKIKRAT GOVT. HIGH SCHOOL KA. ROOM NO. 04 CHARGAV",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 797,
      location: "23.01826092,78.19752106",
      "inverted one": "'EKIKRAT GOVT. HIGH SCHOOL KA. ROOM NO. 04 CHARGAV'",
      cosolideted:
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL KA. ROOM NO. 04 CHARGAV',23.01826092,78.19752106)",
      "with comma":
        "ElectionBooth('EKIKRAT GOVT. HIGH SCHOOL KA. ROOM NO. 04 CHARGAV',23.01826092,78.19752106),",
    },
    {
      "PART NO": 308,
      "PART NAME": "SALAIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SALLIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1104,
      location: "23.00919884,78.18725406",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SALLIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SALLIYA',23.00919884,78.18725406)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SALLIYA',23.00919884,78.18725406),",
    },
    {
      "PART NO": 309,
      "PART NAME": "BANS PIPALIYA",
      "Name of polling station building":
        "EKIKRAT MIDDLE SCHOOL BUILDING NORTH PART BANSPIPALIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 857,
      location: "23.0015209986612,78.1683133665954",
      "inverted one":
        "'EKIKRAT MIDDLE SCHOOL BUILDING NORTH PART BANSPIPALIYA'",
      cosolideted:
        "ElectionBooth('EKIKRAT MIDDLE SCHOOL BUILDING NORTH PART BANSPIPALIYA',23.0015209986612,78.1683133665954)",
      "with comma":
        "ElectionBooth('EKIKRAT MIDDLE SCHOOL BUILDING NORTH PART BANSPIPALIYA',23.0015209986612,78.1683133665954),",
    },
    {
      "PART NO": 310,
      "PART NAME": "BANS PIPALIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING UTIYA KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 739,
      location: "22.9932033922865,78.1463374406955",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING UTIYA KHURD'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING UTIYA KHURD',22.9932033922865,78.1463374406955)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING UTIYA KHURD',22.9932033922865,78.1463374406955),",
    },
  ],
  "142 Sanchi": [
    {
      "PART NO": 1,
      "PART NAME": "NINOD",
      "Name of polling station building":
        "INTERGRETED GOVT. MIDDLE SCHOOL BUILDING NINOD ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 911,
      location: "23.466,77.515",
      "inverted one":
        "'INTERGRETED GOVT. MIDDLE SCHOOL BUILDING NINOD ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTERGRETED GOVT. MIDDLE SCHOOL BUILDING NINOD ROOM NO 1',23.466,77.515)",
      "with comma":
        "ElectionBooth('INTERGRETED GOVT. MIDDLE SCHOOL BUILDING NINOD ROOM NO 1',23.466,77.515),",
    },
    {
      "PART NO": 2,
      "PART NAME": "KAYAMPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING ADDI. ROOM KAYAMPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 801,
      location: "23.430,77.505",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING ADDI. ROOM KAYAMPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ADDI. ROOM KAYAMPUR',23.430,77.505)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ADDI. ROOM KAYAMPUR',23.430,77.505),",
    },
    {
      "PART NO": 3,
      "PART NAME": "BARJORPUR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING BARJORPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 658,
      location: "23.434,77.555",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING BARJORPUR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BARJORPUR',23.434,77.555)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BARJORPUR',23.434,77.555),",
    },
    {
      "PART NO": 4,
      "PART NAME": "JAMUNIYA",
      "Name of polling station building":
        "INTERGRETED GOVT. MIDDLE SCHOOL BUILDING JAMUNIYA ROOM NO. 01",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 684,
      location: "23.418,77.534",
      "inverted one":
        "'INTERGRETED GOVT. MIDDLE SCHOOL BUILDING JAMUNIYA ROOM NO. 01'",
      cosolideted:
        "ElectionBooth('INTERGRETED GOVT. MIDDLE SCHOOL BUILDING JAMUNIYA ROOM NO. 01',23.418,77.534)",
      "with comma":
        "ElectionBooth('INTERGRETED GOVT. MIDDLE SCHOOL BUILDING JAMUNIYA ROOM NO. 01',23.418,77.534),",
    },
    {
      "PART NO": 5,
      "PART NAME": "JAMUNIYA",
      "Name of polling station building":
        "INTERGRETED GOVT. MIDDLE SCHOOL BUILDING JAMUNIYA ROOM NO. 02",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 607,
      location: "23.421,77.561",
      "inverted one":
        "'INTERGRETED GOVT. MIDDLE SCHOOL BUILDING JAMUNIYA ROOM NO. 02'",
      cosolideted:
        "ElectionBooth('INTERGRETED GOVT. MIDDLE SCHOOL BUILDING JAMUNIYA ROOM NO. 02',23.421,77.561)",
      "with comma":
        "ElectionBooth('INTERGRETED GOVT. MIDDLE SCHOOL BUILDING JAMUNIYA ROOM NO. 02',23.421,77.561),",
    },
    {
      "PART NO": 6,
      "PART NAME": "SARAR",
      "Name of polling station building": "GOVT. MIDDLE SCHOOL BUILDING SARAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1166,
      location: "23.448,77.552",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING SARAR'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING SARAR',23.448,77.552)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING SARAR',23.448,77.552),",
    },
    {
      "PART NO": 7,
      "PART NAME": "KHOHA",
      "Name of polling station building": "GOVT.HIGH SCHOOL BUILDING KHOHA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1040,
      location: "23.478,77.579",
      "inverted one": "'GOVT.HIGH SCHOOL BUILDING KHOHA'",
      cosolideted:
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING KHOHA',23.478,77.579)",
      "with comma":
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING KHOHA',23.478,77.579),",
    },
    {
      "PART NO": 8,
      "PART NAME": "KACHANARIYA",
      "Name of polling station building":
        "GOV. PRIMARY SCHOOL BUILDING A.B.L.ROOM KACHANARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 632,
      location: "23.466,77.628",
      "inverted one": "'GOV. PRIMARY SCHOOL BUILDING A.B.L.ROOM KACHANARIYA'",
      cosolideted:
        "ElectionBooth('GOV. PRIMARY SCHOOL BUILDING A.B.L.ROOM KACHANARIYA',23.466,77.628)",
      "with comma":
        "ElectionBooth('GOV. PRIMARY SCHOOL BUILDING A.B.L.ROOM KACHANARIYA',23.466,77.628),",
    },
    {
      "PART NO": 9,
      "PART NAME": "BERKHEDI TUNDA",
      "Name of polling station building":
        "INTERGRETED GOVT. MIDDLE SCHOOL BUILDING BERKHEDI TUNDA ROOM NO. 01",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 899,
      location: "23.464,77.602",
      "inverted one":
        "'INTERGRETED GOVT. MIDDLE SCHOOL BUILDING BERKHEDI TUNDA ROOM NO. 01'",
      cosolideted:
        "ElectionBooth('INTERGRETED GOVT. MIDDLE SCHOOL BUILDING BERKHEDI TUNDA ROOM NO. 01',23.464,77.602)",
      "with comma":
        "ElectionBooth('INTERGRETED GOVT. MIDDLE SCHOOL BUILDING BERKHEDI TUNDA ROOM NO. 01',23.464,77.602),",
    },
    {
      "PART NO": 10,
      "PART NAME": "PIPALIYA CHAND KHA",
      "Name of polling station building":
        "GOVT.NEW PRIMARY SCHOOL BUILDING PIPALIYA CHANDKHA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 684,
      location: "23.461,77.585",
      "inverted one": "'GOVT.NEW PRIMARY SCHOOL BUILDING PIPALIYA CHANDKHA'",
      cosolideted:
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING PIPALIYA CHANDKHA',23.461,77.585)",
      "with comma":
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING PIPALIYA CHANDKHA',23.461,77.585),",
    },
    {
      "PART NO": 11,
      "PART NAME": "NARAKHEDA",
      "Name of polling station building":
        "GOV. PRIMARY. SCHOOL BULDING NARKHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 820,
      location: "23.445,77.570",
      "inverted one": "'GOV. PRIMARY. SCHOOL BULDING NARKHEDA'",
      cosolideted:
        "ElectionBooth('GOV. PRIMARY. SCHOOL BULDING NARKHEDA',23.445,77.570)",
      "with comma":
        "ElectionBooth('GOV. PRIMARY. SCHOOL BULDING NARKHEDA',23.445,77.570),",
    },
    {
      "PART NO": 12,
      "PART NAME": "MUDIYAKHEDA",
      "Name of polling station building":
        "INTERGRETED GOVT. MIDDLE SCHOOL BUILDING MUDIYAKHEDA ROOM NO. 01",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 914,
      location: "23.442,77.612",
      "inverted one":
        "'INTERGRETED GOVT. MIDDLE SCHOOL BUILDING MUDIYAKHEDA ROOM NO. 01'",
      cosolideted:
        "ElectionBooth('INTERGRETED GOVT. MIDDLE SCHOOL BUILDING MUDIYAKHEDA ROOM NO. 01',23.442,77.612)",
      "with comma":
        "ElectionBooth('INTERGRETED GOVT. MIDDLE SCHOOL BUILDING MUDIYAKHEDA ROOM NO. 01',23.442,77.612),",
    },
    {
      "PART NO": 13,
      "PART NAME": "SEMARA",
      "Name of polling station building":
        "INTERGRETED GOVT. HIGH SCHOOL BUILDING SEMARA (SOUTH PART) ROOM NO. 01",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 742,
      location: "23.431,77.587",
      "inverted one":
        "'INTERGRETED GOVT. HIGH SCHOOL BUILDING SEMARA (SOUTH PART) ROOM NO. 01'",
      cosolideted:
        "ElectionBooth('INTERGRETED GOVT. HIGH SCHOOL BUILDING SEMARA (SOUTH PART) ROOM NO. 01',23.431,77.587)",
      "with comma":
        "ElectionBooth('INTERGRETED GOVT. HIGH SCHOOL BUILDING SEMARA (SOUTH PART) ROOM NO. 01',23.431,77.587),",
    },
    {
      "PART NO": 14,
      "PART NAME": "SEMARA",
      "Name of polling station building":
        "INTEGRETED GOVT.HIGH.SCHOOL BUILDING SEMRA (SOUTH PART) ROOM.NO.02",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 667,
      location: "23.431,77.587",
      "inverted one":
        "'INTEGRETED GOVT.HIGH.SCHOOL BUILDING SEMRA (SOUTH PART) ROOM.NO.02'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.HIGH.SCHOOL BUILDING SEMRA (SOUTH PART) ROOM.NO.02',23.431,77.587)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.HIGH.SCHOOL BUILDING SEMRA (SOUTH PART) ROOM.NO.02',23.431,77.587),",
    },
    {
      "PART NO": 15,
      "PART NAME": "SEMARA",
      "Name of polling station building": "COMMUNITY BUILDING SEMRA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 417,
      location: "23.431,77.587",
      "inverted one": "'COMMUNITY BUILDING SEMRA'",
      cosolideted: "ElectionBooth('COMMUNITY BUILDING SEMRA',23.431,77.587)",
      "with comma": "ElectionBooth('COMMUNITY BUILDING SEMRA',23.431,77.587),",
    },
    {
      "PART NO": 16,
      "PART NAME": "AMBADI",
      "Name of polling station building":
        "INTEGRETED GOVT. MIDDLE SCHOOL BUILDING AMBADI NORTH PART R NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1242,
      location: "23.427,77.578",
      "inverted one":
        "'INTEGRETED GOVT. MIDDLE SCHOOL BUILDING AMBADI NORTH PART R NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. MIDDLE SCHOOL BUILDING AMBADI NORTH PART R NO 1',23.427,77.578)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. MIDDLE SCHOOL BUILDING AMBADI NORTH PART R NO 1',23.427,77.578),",
    },
    {
      "PART NO": 17,
      "PART NAME": "AMBADI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL AMBADI SOUTH PART R.NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 913,
      location: "23.427,77.578",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL AMBADI SOUTH PART R.NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL AMBADI SOUTH PART R.NO.2',23.427,77.578)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL AMBADI SOUTH PART R.NO.2',23.427,77.578),",
    },
    {
      "PART NO": 18,
      "PART NAME": "DIVANAGANJ",
      "Name of polling station building":
        "INTEGRETED GOVT. H S SCHOOL BUILDING DEEWANGANJ R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 891,
      location: "23.425,77.569",
      "inverted one":
        "'INTEGRETED GOVT. H S SCHOOL BUILDING DEEWANGANJ R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING DEEWANGANJ R.NO.1',23.425,77.569)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING DEEWANGANJ R.NO.1',23.425,77.569),",
    },
    {
      "PART NO": 19,
      "PART NAME": "DIVANAGANJ",
      "Name of polling station building":
        "INTEGRETED GOVT H S SCHOOL BUILDING DEEWANGANJ R.NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 731,
      location: "23.432,77.568",
      "inverted one": "'INTEGRETED GOVT H S SCHOOL BUILDING DEEWANGANJ R.NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT H S SCHOOL BUILDING DEEWANGANJ R.NO.2',23.432,77.568)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT H S SCHOOL BUILDING DEEWANGANJ R.NO.2',23.432,77.568),",
    },
    {
      "PART NO": 20,
      "PART NAME": "GIDAGADH",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING GEETGARH R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 896,
      location: "23.387,77.562",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING GEETGARH R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING GEETGARH R.NO.1',23.387,77.562)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING GEETGARH R.NO.1',23.387,77.562),",
    },
    {
      "PART NO": 21,
      "PART NAME": "BHANVARAKHEDI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING BHANVARKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 467,
      location: "23.395,77.589",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING BHANVARKHEDI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BHANVARKHEDI',23.395,77.589)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BHANVARKHEDI',23.395,77.589),",
    },
    {
      "PART NO": 22,
      "PART NAME": "MUSHKABAD",
      "Name of polling station building": "GOVT. PS. BUILDING MUSHKABAD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 768,
      location: "23.410,77.582",
      "inverted one": "'GOVT. PS. BUILDING MUSHKABAD'",
      cosolideted:
        "ElectionBooth('GOVT. PS. BUILDING MUSHKABAD',23.410,77.582)",
      "with comma":
        "ElectionBooth('GOVT. PS. BUILDING MUSHKABAD',23.410,77.582),",
    },
    {
      "PART NO": 23,
      "PART NAME": "BHARATIPURA(SHAHAPURA)",
      "Name of polling station building":
        "SAMUDAYIK BUILDING SHAHPUR BHARTIPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1218,
      location: "23.433,77.633",
      "inverted one": "'SAMUDAYIK BUILDING SHAHPUR BHARTIPUR'",
      cosolideted:
        "ElectionBooth('SAMUDAYIK BUILDING SHAHPUR BHARTIPUR',23.433,77.633)",
      "with comma":
        "ElectionBooth('SAMUDAYIK BUILDING SHAHPUR BHARTIPUR',23.433,77.633),",
    },
    {
      "PART NO": 24,
      "PART NAME": "BHAGBANPUR COLONY",
      "Name of polling station building":
        "GOVT PRIMARY SCHOOL BUILDING BHAGWANPUR COLONY",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 660,
      location: "23.408,77.647",
      "inverted one": "'GOVT PRIMARY SCHOOL BUILDING BHAGWANPUR COLONY'",
      cosolideted:
        "ElectionBooth('GOVT PRIMARY SCHOOL BUILDING BHAGWANPUR COLONY',23.408,77.647)",
      "with comma":
        "ElectionBooth('GOVT PRIMARY SCHOOL BUILDING BHAGWANPUR COLONY',23.408,77.647),",
    },
    {
      "PART NO": 25,
      "PART NAME": "KALI TOR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING KALITOR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 543,
      location: "23.408,77.647",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING KALITOR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING KALITOR R.NO.1',23.408,77.647)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING KALITOR R.NO.1',23.408,77.647),",
    },
    {
      "PART NO": 26,
      "PART NAME": "SUNARI SALAMATAPUR",
      "Name of polling station building":
        "INTEGRETED GOVT. H S SCHOOL SALAMATPUR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 811,
      location: "23.458,76.733",
      "inverted one": "'INTEGRETED GOVT. H S SCHOOL SALAMATPUR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL SALAMATPUR R.NO.1',23.458,76.733)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL SALAMATPUR R.NO.1',23.458,76.733),",
    },
    {
      "PART NO": 27,
      "PART NAME": "SUNARI SALAMATAPUR",
      "Name of polling station building":
        "INTEGRETED GOVT. H S SCHOOL SALAMATPUR R.NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 593,
      location: "23.457,77.673",
      "inverted one": "'INTEGRETED GOVT. H S SCHOOL SALAMATPUR R.NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL SALAMATPUR R.NO.2',23.457,77.673)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL SALAMATPUR R.NO.2',23.457,77.673),",
    },
    {
      "PART NO": 28,
      "PART NAME": "SUNARI SALAMATAPUR",
      "Name of polling station building":
        "INTEGRETED GOVT.H S SCHOOL SALAMATPUR R.NO.3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1222,
      location: ",",
      "inverted one": "'INTEGRETED GOVT.H S SCHOOL SALAMATPUR R.NO.3'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.H S SCHOOL SALAMATPUR R.NO.3',,)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.H S SCHOOL SALAMATPUR R.NO.3',,),",
    },
    {
      "PART NO": 29,
      "PART NAME": "SUNARI SALAMATAPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING RAJEEV NAGAR ROOM NO. 01",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 906,
      location: "23.460,77.668",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL BUILDING RAJEEV NAGAR ROOM NO. 01'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAJEEV NAGAR ROOM NO. 01',23.460,77.668)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAJEEV NAGAR ROOM NO. 01',23.460,77.668),",
    },
    {
      "PART NO": 30,
      "PART NAME": "SUNARI SALAMATAPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING RAJEEV NAGAR ROOM NO. 02",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 442,
      location: "23.460,77.668",
      "inverted one":
        "'GOVT. PRIMARY SCHOOL BUILDING RAJEEV NAGAR ROOM NO. 02'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAJEEV NAGAR ROOM NO. 02',23.460,77.668)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAJEEV NAGAR ROOM NO. 02',23.460,77.668),",
    },
    {
      "PART NO": 31,
      "PART NAME": "MUKTAPUR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING MUKTAPUR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 473,
      location: "23.442,77.612",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING MUKTAPUR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING MUKTAPUR R.NO.1',23.442,77.612)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING MUKTAPUR R.NO.1',23.442,77.612),",
    },
    {
      "PART NO": 32,
      "PART NAME": "RATATALAI",
      "Name of polling station building":
        "GOVT. PARIMARY SCHOOL BUILDING RATATALAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 793,
      location: "23.460,77.681",
      "inverted one": "'GOVT. PARIMARY SCHOOL BUILDING RATATALAI'",
      cosolideted:
        "ElectionBooth('GOVT. PARIMARY SCHOOL BUILDING RATATALAI',23.460,77.681)",
      "with comma":
        "ElectionBooth('GOVT. PARIMARY SCHOOL BUILDING RATATALAI',23.460,77.681),",
    },
    {
      "PART NO": 33,
      "PART NAME": "RATATALAI",
      "Name of polling station building":
        "GOVT.P.S.BUILDING EAST PART RATATALAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 707,
      location: "23.460,77.681",
      "inverted one": "'GOVT.P.S.BUILDING EAST PART RATATALAI'",
      cosolideted:
        "ElectionBooth('GOVT.P.S.BUILDING EAST PART RATATALAI',23.460,77.681)",
      "with comma":
        "ElectionBooth('GOVT.P.S.BUILDING EAST PART RATATALAI',23.460,77.681),",
    },
    {
      "PART NO": 34,
      "PART NAME": "RATATALAI",
      "Name of polling station building":
        "OFFICE SDO SAMRAT ASHOK SAGAR DIVISION RATATALAI SALAMATPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 920,
      location: "23.455,77.678",
      "inverted one":
        "'OFFICE SDO SAMRAT ASHOK SAGAR DIVISION RATATALAI SALAMATPUR'",
      cosolideted:
        "ElectionBooth('OFFICE SDO SAMRAT ASHOK SAGAR DIVISION RATATALAI SALAMATPUR',23.455,77.678)",
      "with comma":
        "ElectionBooth('OFFICE SDO SAMRAT ASHOK SAGAR DIVISION RATATALAI SALAMATPUR',23.455,77.678),",
    },
    {
      "PART NO": 35,
      "PART NAME": "BANSAKHEDA",
      "Name of polling station building":
        "INTEGRETED GOVT.H SCHOOL BUILDINGBANSKHEDA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 919,
      location: "23.526,77.643",
      "inverted one": "'INTEGRETED GOVT.H SCHOOL BUILDINGBANSKHEDA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.H SCHOOL BUILDINGBANSKHEDA R.NO.1',23.526,77.643)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.H SCHOOL BUILDINGBANSKHEDA R.NO.1',23.526,77.643),",
    },
    {
      "PART NO": 36,
      "PART NAME": "BANSAKHEDA",
      "Name of polling station building":
        "INTEGRETED GOVT. HSCHOOL BUILDING BANSKHEDA R.NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 789,
      location: "23.526,77.641",
      "inverted one": "'INTEGRETED GOVT. HSCHOOL BUILDING BANSKHEDA R.NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. HSCHOOL BUILDING BANSKHEDA R.NO.2',23.526,77.641)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. HSCHOOL BUILDING BANSKHEDA R.NO.2',23.526,77.641),",
    },
    {
      "PART NO": 37,
      "PART NAME": "SILAVAH",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING SILVAH",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 652,
      location: "23.526,77.650",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING SILVAH'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SILVAH',23.526,77.650)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SILVAH',23.526,77.650),",
    },
    {
      "PART NO": 38,
      "PART NAME": "SARACHAMPA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING SARCHAMPA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 581,
      location: "23.512,77.656",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING SARCHAMPA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SARCHAMPA R.NO.1',23.512,77.656)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SARCHAMPA R.NO.1',23.512,77.656),",
    },
    {
      "PART NO": 39,
      "PART NAME": "CHIRAHOLI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING CHIRHOLI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 585,
      location: "23.515,77.675",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING CHIRHOLI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING CHIRHOLI',23.515,77.675)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING CHIRHOLI',23.515,77.675),",
    },
    {
      "PART NO": 40,
      "PART NAME": "NARODA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING NARODA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 602,
      location: "23.776,77.762",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING NARODA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NARODA R.NO.1',23.776,77.762)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NARODA R.NO.1',23.776,77.762),",
    },
    {
      "PART NO": 41,
      "PART NAME": "ERAN",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL BUILDING ERAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 569,
      location: "23.507,77.698",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING ERAN'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ERAN',23.507,77.698)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ERAN',23.507,77.698),",
    },
    {
      "PART NO": 42,
      "PART NAME": "DHADIYA KHEDI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING DHANIYAKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 312,
      location: "23.518,77.707",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING DHANIYAKHEDI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING DHANIYAKHEDI',23.518,77.707)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING DHANIYAKHEDI',23.518,77.707),",
    },
    {
      "PART NO": 43,
      "PART NAME": "PHATEHPUR MARMAT",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL FATEHPUR MARMATA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 463,
      location: "23.525,77.686",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL FATEHPUR MARMATA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL FATEHPUR MARMATA R.NO.1',23.525,77.686)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL FATEHPUR MARMATA R.NO.1',23.525,77.686),",
    },
    {
      "PART NO": 44,
      "PART NAME": "UCHER",
      "Name of polling station building":
        "INTEGRETED GOVT.H S SCHOOL BUILDING UCHER R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1141,
      location: "23.510,77.725",
      "inverted one": "'INTEGRETED GOVT.H S SCHOOL BUILDING UCHER R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.H S SCHOOL BUILDING UCHER R.NO.1',23.510,77.725)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.H S SCHOOL BUILDING UCHER R.NO.1',23.510,77.725),",
    },
    {
      "PART NO": 45,
      "PART NAME": "ANHORI BERAKHEDI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING ANHORI BERKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 402,
      location: "23.523,77.727",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING ANHORI BERKHEDI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING ANHORI BERKHEDI',23.523,77.727)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING ANHORI BERKHEDI',23.523,77.727),",
    },
    {
      "PART NO": 46,
      "PART NAME": "MADHAVAI",
      "Name of polling station building": "GOVT. MIDDLE SCHOOL BUILDING MADBAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1144,
      location: "23.518,77.753",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING MADBAI'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING MADBAI',23.518,77.753)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING MADBAI',23.518,77.753),",
    },
    {
      "PART NO": 47,
      "PART NAME": "KANAKHEDA KALA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING NORTH PART KANAKHEDA KLA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1106,
      location: "23.486,77.740",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING NORTH PART KANAKHEDA KLA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING NORTH PART KANAKHEDA KLA',23.486,77.740)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING NORTH PART KANAKHEDA KLA',23.486,77.740),",
    },
    {
      "PART NO": 48,
      "PART NAME": "KANAKHEDAKALAN",
      "Name of polling station building":
        "GOVT.GIRLS HIGHER SECONDARY NEW BUILDING SANCHI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1147,
      location: "23.339,77.780",
      "inverted one": "'GOVT.GIRLS HIGHER SECONDARY NEW BUILDING SANCHI'",
      cosolideted:
        "ElectionBooth('GOVT.GIRLS HIGHER SECONDARY NEW BUILDING SANCHI',23.339,77.780)",
      "with comma":
        "ElectionBooth('GOVT.GIRLS HIGHER SECONDARY NEW BUILDING SANCHI',23.339,77.780),",
    },
    {
      "PART NO": 49,
      "PART NAME": "SANCHI",
      "Name of polling station building":
        "INTEGRETED GOVT.UTKRISHT H S SCHOOL BUILDING SANCHI (NORTH PART) R.NO.1",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1124,
      location: "23.487,77.738",
      "inverted one":
        "'INTEGRETED GOVT.UTKRISHT H S SCHOOL BUILDING SANCHI (NORTH PART) R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.UTKRISHT H S SCHOOL BUILDING SANCHI (NORTH PART) R.NO.1',23.487,77.738)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.UTKRISHT H S SCHOOL BUILDING SANCHI (NORTH PART) R.NO.1',23.487,77.738),",
    },
    {
      "PART NO": 50,
      "PART NAME": "SANCHI",
      "Name of polling station building":
        "INTEGRETED GOVT.UTKRISHT H S SCHOOL BUILDING SANCHI (NORTH PART) R.NO.2",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1044,
      location: "23.487,77.738",
      "inverted one":
        "'INTEGRETED GOVT.UTKRISHT H S SCHOOL BUILDING SANCHI (NORTH PART) R.NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.UTKRISHT H S SCHOOL BUILDING SANCHI (NORTH PART) R.NO.2',23.487,77.738)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.UTKRISHT H S SCHOOL BUILDING SANCHI (NORTH PART) R.NO.2',23.487,77.738),",
    },
    {
      "PART NO": 51,
      "PART NAME": "SANCHI",
      "Name of polling station building":
        "INTEGRETED GOVT.UTKRISHT H S SCHOOL SANCHI R.NO.3",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 926,
      location: "23.487,77.738",
      "inverted one": "'INTEGRETED GOVT.UTKRISHT H S SCHOOL SANCHI R.NO.3'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.UTKRISHT H S SCHOOL SANCHI R.NO.3',23.487,77.738)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.UTKRISHT H S SCHOOL SANCHI R.NO.3',23.487,77.738),",
    },
    {
      "PART NO": 52,
      "PART NAME": "SANCHI",
      "Name of polling station building":
        "INTEGRETED GOVT.UTKRISHT H S SCHOOL SANCHI R.NO.4",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 524,
      location: "23.487,77.738",
      "inverted one": "'INTEGRETED GOVT.UTKRISHT H S SCHOOL SANCHI R.NO.4'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.UTKRISHT H S SCHOOL SANCHI R.NO.4',23.487,77.738)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.UTKRISHT H S SCHOOL SANCHI R.NO.4',23.487,77.738),",
    },
    {
      "PART NO": 53,
      "PART NAME": "BILOURI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING BILOURI ADDI. ROMM",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 600,
      location: "23.460,77.734",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING BILOURI ADDI. ROMM'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BILOURI ADDI. ROMM',23.460,77.734)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BILOURI ADDI. ROMM',23.460,77.734),",
    },
    {
      "PART NO": 54,
      "PART NAME": "KACHHI KANHAKHEDA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING KACHHI KANHAKHEDA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 707,
      location: "23.460,23.460",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KACHHI KANHAKHEDA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KACHHI KANHAKHEDA',23.460,23.460)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KACHHI KANHAKHEDA',23.460,23.460),",
    },
    {
      "PART NO": 55,
      "PART NAME": "AMAKHEDA",
      "Name of polling station building": "GOVT. P. SCHOOL BUILDING . AMAKHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 571,
      location: "23.466,77.716",
      "inverted one": "'GOVT. P. SCHOOL BUILDING . AMAKHEDA'",
      cosolideted:
        "ElectionBooth('GOVT. P. SCHOOL BUILDING . AMAKHEDA',23.466,77.716)",
      "with comma":
        "ElectionBooth('GOVT. P. SCHOOL BUILDING . AMAKHEDA',23.466,77.716),",
    },
    {
      "PART NO": 56,
      "PART NAME": "DHAKANA CHAPANA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING . DHAKNA CHAPNA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 972,
      location: "23+.480.,77.730",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING . DHAKNA CHAPNA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING . DHAKNA CHAPNA',23+.480.,77.730)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING . DHAKNA CHAPNA',23+.480.,77.730),",
    },
    {
      "PART NO": 57,
      "PART NAME": "MEDHAKI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING MEDKI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1295,
      location: "23.445,77.711",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING MEDKI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING MEDKI R.NO.1',23.445,77.711)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING MEDKI R.NO.1',23.445,77.711),",
    },
    {
      "PART NO": 58,
      "PART NAME": "GULAGANV",
      "Name of polling station building":
        "INTEGRETED GOVT. H SCHOOL BUILDING GULGAON R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1345,
      location: "23.492,77.700",
      "inverted one": "'INTEGRETED GOVT. H SCHOOL BUILDING GULGAON R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING GULGAON R.NO.1',23.492,77.700)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING GULGAON R.NO.1',23.492,77.700),",
    },
    {
      "PART NO": 59,
      "PART NAME": "PAGNESHVAR",
      "Name of polling station building":
        "INTEGRETED GOVT.H S SCHOOL BUILDING PAGNESHWER R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 715,
      location: "23.334,77.780",
      "inverted one": "'INTEGRETED GOVT.H S SCHOOL BUILDING PAGNESHWER R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.H S SCHOOL BUILDING PAGNESHWER R.NO.1',23.334,77.780)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.H S SCHOOL BUILDING PAGNESHWER R.NO.1',23.334,77.780),",
    },
    {
      "PART NO": 60,
      "PART NAME": "PAGNESHVAR",
      "Name of polling station building":
        "INTEGRETED GOVT.H S.SCHOOL BUILDING PAGNESHWER R.NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 691,
      location: "23.334,77.780",
      "inverted one": "'INTEGRETED GOVT.H S.SCHOOL BUILDING PAGNESHWER R.NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.H S.SCHOOL BUILDING PAGNESHWER R.NO.2',23.334,77.780)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.H S.SCHOOL BUILDING PAGNESHWER R.NO.2',23.334,77.780),",
    },
    {
      "PART NO": 61,
      "PART NAME": "CHIRAHOLI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING CHIRHOULI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 936,
      location: "23.473,77.667",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING CHIRHOULI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING CHIRHOULI',23.473,77.667)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING CHIRHOULI',23.473,77.667),",
    },
    {
      "PART NO": 62,
      "PART NAME": "UMARIYA",
      "Name of polling station building":
        "GOVT. HIGHER SECONDARY SCHOOL BUILDING UMARIYA ROOM NO.02",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 542,
      location: "23.464,77.796",
      "inverted one":
        "'GOVT. HIGHER SECONDARY SCHOOL BUILDING UMARIYA ROOM NO.02'",
      cosolideted:
        "ElectionBooth('GOVT. HIGHER SECONDARY SCHOOL BUILDING UMARIYA ROOM NO.02',23.464,77.796)",
      "with comma":
        "ElectionBooth('GOVT. HIGHER SECONDARY SCHOOL BUILDING UMARIYA ROOM NO.02',23.464,77.796),",
    },
    {
      "PART NO": 63,
      "PART NAME": "SANAKHEDI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING SANKHEDI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 812,
      location: "23.441,77.806",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING SANKHEDI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SANKHEDI R.NO.1',23.441,77.806)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SANKHEDI R.NO.1',23.441,77.806),",
    },
    {
      "PART NO": 64,
      "PART NAME": "ALAMAKHEDA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING. ALAMKHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 581,
      location: "23.446,77.797",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING. ALAMKHEDA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. ALAMKHEDA',23.446,77.797)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. ALAMKHEDA',23.446,77.797),",
    },
    {
      "PART NO": 65,
      "PART NAME": "MEHAGANV",
      "Name of polling station building":
        "GOVT. HIGH SCHOOL BUILDING NORTH PART MEHGANV",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 656,
      location: "23.453,77.770",
      "inverted one": "'GOVT. HIGH SCHOOL BUILDING NORTH PART MEHGANV'",
      cosolideted:
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING NORTH PART MEHGANV',23.453,77.770)",
      "with comma":
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING NORTH PART MEHGANV',23.453,77.770),",
    },
    {
      "PART NO": 66,
      "PART NAME": "MEHAGANV",
      "Name of polling station building":
        "INTEGRETED GOVT.GIRLS M S SCHOOL MEHGAO R NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 764,
      location: "23.453,77.770",
      "inverted one": "'INTEGRETED GOVT.GIRLS M S SCHOOL MEHGAO R NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.GIRLS M S SCHOOL MEHGAO R NO 1',23.453,77.770)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.GIRLS M S SCHOOL MEHGAO R NO 1',23.453,77.770),",
    },
    {
      "PART NO": 67,
      "PART NAME": "PARAVARIYA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING PARWARIYA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 921,
      location: "23.464,77.797",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING PARWARIYA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING PARWARIYA R.NO.1',23.464,77.797)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING PARWARIYA R.NO.1',23.464,77.797),",
    },
    {
      "PART NO": 68,
      "PART NAME": "GIRAVAR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING GIRWAR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 435,
      location: "23.426,77.762",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING GIRWAR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING GIRWAR R.NO.1',23.426,77.762)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING GIRWAR R.NO.1',23.426,77.762),",
    },
    {
      "PART NO": 69,
      "PART NAME": "BARALA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING BARLA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 895,
      location: "23.334,77.780",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING BARLA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BARLA R.NO.1',23.334,77.780)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BARLA R.NO.1',23.334,77.780),",
    },
    {
      "PART NO": 70,
      "PART NAME": "RATANAPUR GIRDHARI",
      "Name of polling station building":
        "INTEGRETED GOVT.HIGH SCHOOL BUILDING . RATANPUR GIRDHARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1316,
      location: "23.486,77.739",
      "inverted one":
        "'INTEGRETED GOVT.HIGH SCHOOL BUILDING . RATANPUR GIRDHARI'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.HIGH SCHOOL BUILDING . RATANPUR GIRDHARI',23.486,77.739)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.HIGH SCHOOL BUILDING . RATANPUR GIRDHARI',23.486,77.739),",
    },
    {
      "PART NO": 71,
      "PART NAME": "TIJALAPUR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING TIJALPUR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 800,
      location: "23.786,77.739",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING TIJALPUR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING TIJALPUR R.NO.1',23.786,77.739)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING TIJALPUR R.NO.1',23.786,77.739),",
    },
    {
      "PART NO": 72,
      "PART NAME": "KATSARI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KATSARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 461,
      location: "23.334,77.790",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KATSARI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KATSARI',23.334,77.790)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KATSARI',23.334,77.790),",
    },
    {
      "PART NO": 73,
      "PART NAME": "BARAIKHAS",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING BARAIKHAS",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 622,
      location: "23.386,77.704",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING BARAIKHAS'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BARAIKHAS',23.386,77.704)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BARAIKHAS',23.386,77.704),",
    },
    {
      "PART NO": 74,
      "PART NAME": "MAKHANI",
      "Name of polling station building": "PANCHAYAT BUILDING MAKHNI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 778,
      location: "23.387,77.739",
      "inverted one": "'PANCHAYAT BUILDING MAKHNI'",
      cosolideted: "ElectionBooth('PANCHAYAT BUILDING MAKHNI',23.387,77.739)",
      "with comma": "ElectionBooth('PANCHAYAT BUILDING MAKHNI',23.387,77.739),",
    },
    {
      "PART NO": 75,
      "PART NAME": "KOTARA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING KOTRA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 714,
      location: "23.390,77.783",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING KOTRA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING KOTRA R.NO.1',23.390,77.783)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING KOTRA R.NO.1',23.390,77.783),",
    },
    {
      "PART NO": 76,
      "PART NAME": "MARDANPUR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING MARDANPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 713,
      location: "23.387,77.759",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING MARDANPUR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING MARDANPUR',23.387,77.759)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING MARDANPUR',23.387,77.759),",
    },
    {
      "PART NO": 77,
      "PART NAME": "AMARAVAT VAJYAPHT",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . AMRAVAT . VAJYAFAT",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1107,
      location: "23.356,77.727",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . AMRAVAT . VAJYAFAT'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . AMRAVAT . VAJYAFAT',23.356,77.727)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . AMRAVAT . VAJYAFAT',23.356,77.727),",
    },
    {
      "PART NO": 78,
      "PART NAME": "BAGOD",
      "Name of polling station building": "GOVT.MIDDLE SCHOOL BUILDING BAGOD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 505,
      location: "23.667,77.702",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING BAGOD'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING BAGOD',23.667,77.702)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING BAGOD',23.667,77.702),",
    },
    {
      "PART NO": 79,
      "PART NAME": "SEVASANI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING SEWASANI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 841,
      location: "23.334,77.781",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING SEWASANI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SEWASANI R.NO.1',23.334,77.781)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SEWASANI R.NO.1',23.334,77.781),",
    },
    {
      "PART NO": 80,
      "PART NAME": "SEVASANI",
      "Name of polling station building":
        "INTEGRETED GOVT. H SCHOOL BUILDING SWASANI (EAST PART) R.NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 609,
      location: "23.334,77.781",
      "inverted one":
        "'INTEGRETED GOVT. H SCHOOL BUILDING SWASANI (EAST PART) R.NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING SWASANI (EAST PART) R.NO.2',23.334,77.781)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING SWASANI (EAST PART) R.NO.2',23.334,77.781),",
    },
    {
      "PART NO": 81,
      "PART NAME": "BADODA",
      "Name of polling station building": "PANCHAYAT BUILDING BARODA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 520,
      location: "23.334,77.781",
      "inverted one": "'PANCHAYAT BUILDING BARODA'",
      cosolideted: "ElectionBooth('PANCHAYAT BUILDING BARODA',23.334,77.781)",
      "with comma": "ElectionBooth('PANCHAYAT BUILDING BARODA',23.334,77.781),",
    },
    {
      "PART NO": 82,
      "PART NAME": "AGARIYA NAYAPURA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING AGARIYA NAYAPURA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 779,
      location: "23.334,77.670",
      "inverted one":
        "'INTEGRETED GOVT.M.SCHOOL BUILDING AGARIYA NAYAPURA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING AGARIYA NAYAPURA R.NO.1',23.334,77.670)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING AGARIYA NAYAPURA R.NO.1',23.334,77.670),",
    },
    {
      "PART NO": 83,
      "PART NAME": "GOPISURASATAKUNDA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING. GOPISUR SATKUNDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 946,
      location: "23.262,77.631",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING. GOPISUR SATKUNDA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. GOPISUR SATKUNDA',23.262,77.631)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. GOPISUR SATKUNDA',23.262,77.631),",
    },
    {
      "PART NO": 84,
      "PART NAME": "GOPISUR SATKUNDA",
      "Name of polling station building":
        "CAMPUS NEW GOVT. MIDDLE SCHOOL BUILDING GOPISUR SATKUNDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 690,
      location: "23.304,77.624",
      "inverted one":
        "'CAMPUS NEW GOVT. MIDDLE SCHOOL BUILDING GOPISUR SATKUNDA'",
      cosolideted:
        "ElectionBooth('CAMPUS NEW GOVT. MIDDLE SCHOOL BUILDING GOPISUR SATKUNDA',23.304,77.624)",
      "with comma":
        "ElectionBooth('CAMPUS NEW GOVT. MIDDLE SCHOOL BUILDING GOPISUR SATKUNDA',23.304,77.624),",
    },
    {
      "PART NO": 85,
      "PART NAME": "PIPALIKHARIYA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING PIPALKHIRIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 951,
      location: "23.257,77.644",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING PIPALKHIRIYA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PIPALKHIRIYA',23.257,77.644)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PIPALKHIRIYA',23.257,77.644),",
    },
    {
      "PART NO": 86,
      "PART NAME": "SEHATAGANJ",
      "Name of polling station building": "GRAM PANCHAYAT BUILDING SEHATGANJ",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1036,
      location: "23.267,77.663",
      "inverted one": "'GRAM PANCHAYAT BUILDING SEHATGANJ'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING SEHATGANJ',23.267,77.663)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING SEHATGANJ',23.267,77.663),",
    },
    {
      "PART NO": 87,
      "PART NAME": "KHARABAI",
      "Name of polling station building":
        "CAMPUS GOVT. MIDDLE SCHOOL BUILDING KHARBAI ROOM NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 933,
      location: "23.271,77.668",
      "inverted one": "'CAMPUS GOVT. MIDDLE SCHOOL BUILDING KHARBAI ROOM NO.1'",
      cosolideted:
        "ElectionBooth('CAMPUS GOVT. MIDDLE SCHOOL BUILDING KHARBAI ROOM NO.1',23.271,77.668)",
      "with comma":
        "ElectionBooth('CAMPUS GOVT. MIDDLE SCHOOL BUILDING KHARBAI ROOM NO.1',23.271,77.668),",
    },
    {
      "PART NO": 88,
      "PART NAME": "KHARABAI",
      "Name of polling station building":
        "INTEGRETED GOVT. MIDDLE SCHOOL BUILDING WEST PART KHARBAI ROOM NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 506,
      location: "23.267,77.663",
      "inverted one":
        "'INTEGRETED GOVT. MIDDLE SCHOOL BUILDING WEST PART KHARBAI ROOM NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. MIDDLE SCHOOL BUILDING WEST PART KHARBAI ROOM NO.2',23.267,77.663)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. MIDDLE SCHOOL BUILDING WEST PART KHARBAI ROOM NO.2',23.267,77.663),",
    },
    {
      "PART NO": 89,
      "PART NAME": "SHILAPURI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING SILPURI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 897,
      location: "23.293,77.686",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING SILPURI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SILPURI R.NO.1',23.293,77.686)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SILPURI R.NO.1',23.293,77.686),",
    },
    {
      "PART NO": 90,
      "PART NAME": "SIHORA IMALIYA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING SEEHORA IMALIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 734,
      location: "23.322,77.697",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING SEEHORA IMALIYA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SEEHORA IMALIYA',23.322,77.697)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SEEHORA IMALIYA',23.322,77.697),",
    },
    {
      "PART NO": 91,
      "PART NAME": "BEEDPURA",
      "Name of polling station building":
        "GOVT MIDDLE SCHOOL BUILDING BEEDPURA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 792,
      location: "23.297,77.717",
      "inverted one": "'GOVT MIDDLE SCHOOL BUILDING BEEDPURA'",
      cosolideted:
        "ElectionBooth('GOVT MIDDLE SCHOOL BUILDING BEEDPURA',23.297,77.717)",
      "with comma":
        "ElectionBooth('GOVT MIDDLE SCHOOL BUILDING BEEDPURA',23.297,77.717),",
    },
    {
      "PART NO": 92,
      "PART NAME": "KUSAYARI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING . KUSHYARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 610,
      location: "23.282,77.695",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING . KUSHYARI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING . KUSHYARI',23.282,77.695)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING . KUSHYARI',23.282,77.695),",
    },
    {
      "PART NO": 93,
      "PART NAME": "RANGAPURA KESARI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING RANGPUR KESRI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 546,
      location: "23.260,77.686",
      "inverted one":
        "'INTEGRETED GOVT.M.SCHOOL BUILDING RANGPUR KESRI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING RANGPUR KESRI R.NO.1',23.260,77.686)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING RANGPUR KESRI R.NO.1',23.260,77.686),",
    },
    {
      "PART NO": 94,
      "PART NAME": "NAND",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING NAND R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 715,
      location: "23.229,77.697",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING NAND R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NAND R.NO.1',23.229,77.697)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NAND R.NO.1',23.229,77.697),",
    },
    {
      "PART NO": 95,
      "PART NAME": "BYAVARA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING VYABRA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1136,
      location: "23.252,77.730",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING VYABRA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING VYABRA R.NO.1',23.252,77.730)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING VYABRA R.NO.1',23.252,77.730),",
    },
    {
      "PART NO": 96,
      "PART NAME": "MEHARMANGA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . MEHARMANGA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 816,
      location: "23.214,77.737",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . MEHARMANGA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . MEHARMANGA',23.214,77.737)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . MEHARMANGA',23.214,77.737),",
    },
    {
      "PART NO": 97,
      "PART NAME": "BARUKHAR",
      "Name of polling station building":
        "INTEGRETED GOVT. H SCHOOL BUILDING BARRUKHAR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 987,
      location: "23.182,77.737",
      "inverted one": "'INTEGRETED GOVT. H SCHOOL BUILDING BARRUKHAR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING BARRUKHAR R.NO.1',23.182,77.737)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING BARRUKHAR R.NO.1',23.182,77.737),",
    },
    {
      "PART NO": 98,
      "PART NAME": "VEERPUR",
      "Name of polling station building": "COMMUNITY BUILDING..VEERPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 581,
      location: "23.217,77.784",
      "inverted one": "'COMMUNITY BUILDING..VEERPUR'",
      cosolideted: "ElectionBooth('COMMUNITY BUILDING..VEERPUR',23.217,77.784)",
      "with comma":
        "ElectionBooth('COMMUNITY BUILDING..VEERPUR',23.217,77.784),",
    },
    {
      "PART NO": 99,
      "PART NAME": "VANACHHOD",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING BANCHHOD R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 558,
      location: "23.219,77.778",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING BANCHHOD R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BANCHHOD R.NO.1',23.219,77.778)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BANCHHOD R.NO.1',23.219,77.778),",
    },
    {
      "PART NO": 100,
      "PART NAME": "BAGARODA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . BAGRODA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 524,
      location: "23.236,77.754",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . BAGRODA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . BAGRODA',23.236,77.754)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . BAGRODA',23.236,77.754),",
    },
    {
      "PART NO": 101,
      "PART NAME": "PEMAT",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING PEMAT R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1027,
      location: "23.238,77.755",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING PEMAT R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING PEMAT R.NO.1',23.238,77.755)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING PEMAT R.NO.1',23.238,77.755),",
    },
    {
      "PART NO": 102,
      "PART NAME": "VIRAHOLI",
      "Name of polling station building": "PANCHAYAT BUILDING.. BIRHOLI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 504,
      location: "23.237,77.774",
      "inverted one": "'PANCHAYAT BUILDING.. BIRHOLI'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING.. BIRHOLI',23.237,77.774)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING.. BIRHOLI',23.237,77.774),",
    },
    {
      "PART NO": 103,
      "PART NAME": "PARASOURA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . PARSORA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 517,
      location: "23.235,77.799",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . PARSORA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . PARSORA',23.235,77.799)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . PARSORA',23.235,77.799),",
    },
    {
      "PART NO": 104,
      "PART NAME": "MUCHHAIL",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . MUCHHAIL",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 541,
      location: "23.223,77.805",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . MUCHHAIL'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . MUCHHAIL',23.223,77.805)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . MUCHHAIL',23.223,77.805),",
    },
    {
      "PART NO": 105,
      "PART NAME": "MAHUJAGIR",
      "Name of polling station building": "GOVT.HIGH SCHOOL BUILDING MHUJAGEER",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 746,
      location: "23.209,77.797",
      "inverted one": "'GOVT.HIGH SCHOOL BUILDING MHUJAGEER'",
      cosolideted:
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING MHUJAGEER',23.209,77.797)",
      "with comma":
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING MHUJAGEER',23.209,77.797),",
    },
    {
      "PART NO": 106,
      "PART NAME": "CHANDAPUR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . CHANDPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 558,
      location: "23.198,77.816",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . CHANDPUR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . CHANDPUR',23.198,77.816)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . CHANDPUR',23.198,77.816),",
    },
    {
      "PART NO": 107,
      "PART NAME": "NAYAPURA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING NAYAPURA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 682,
      location: "23.208,77.848",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING NAYAPURA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NAYAPURA R.NO.1',23.208,77.848)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NAYAPURA R.NO.1',23.208,77.848),",
    },
    {
      "PART NO": 108,
      "PART NAME": "GONDRA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . GONDRA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 671,
      location: "23.297,77.789",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . GONDRA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . GONDRA',23.297,77.789)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . GONDRA',23.297,77.789),",
    },
    {
      "PART NO": 109,
      "PART NAME": "MANAPUR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING MANPUR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 887,
      location: "23.226,77.825",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING MANPUR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING MANPUR R.NO.1',23.226,77.825)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING MANPUR R.NO.1',23.226,77.825),",
    },
    {
      "PART NO": 110,
      "PART NAME": "BARANIJAGIR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING.. BARNIJAGEER",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 964,
      location: "23.140,77.492",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING.. BARNIJAGEER'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.. BARNIJAGEER',23.140,77.492)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING.. BARNIJAGEER',23.140,77.492),",
    },
    {
      "PART NO": 111,
      "PART NAME": "MASER",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING MASER R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 728,
      location: "23.297,77.789",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING MASER R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING MASER R.NO.1',23.297,77.789)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING MASER R.NO.1',23.297,77.789),",
    },
    {
      "PART NO": 112,
      "PART NAME": "ALLI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING ALLI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 976,
      location: "23.331,77.795",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING ALLI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING ALLI R.NO.1',23.331,77.795)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING ALLI R.NO.1',23.331,77.795),",
    },
    {
      "PART NO": 113,
      "PART NAME": "BANAGABA",
      "Name of polling station building":
        "INTEGRETED GOVT.MIDDLE.SCHOOL BUILDING EAST PART BANGABA ROOM NO.03",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 897,
      location: "23.279,77.759",
      "inverted one":
        "'INTEGRETED GOVT.MIDDLE.SCHOOL BUILDING EAST PART BANGABA ROOM NO.03'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.MIDDLE.SCHOOL BUILDING EAST PART BANGABA ROOM NO.03',23.279,77.759)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.MIDDLE.SCHOOL BUILDING EAST PART BANGABA ROOM NO.03',23.279,77.759),",
    },
    {
      "PART NO": 114,
      "PART NAME": "VANAGAVAN",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING NORTH PART R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 782,
      location: "23.331,77.795",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING NORTH PART R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NORTH PART R.NO.1',23.331,77.795)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NORTH PART R.NO.1',23.331,77.795),",
    },
    {
      "PART NO": 115,
      "PART NAME": "BANAGABA",
      "Name of polling station building": "COMMUNITY BUILDING BANGABA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 479,
      location: "23.278,77.758",
      "inverted one": "'COMMUNITY BUILDING BANGABA'",
      cosolideted: "ElectionBooth('COMMUNITY BUILDING BANGABA',23.278,77.758)",
      "with comma":
        "ElectionBooth('COMMUNITY BUILDING BANGABA',23.278,77.758),",
    },
    {
      "PART NO": 116,
      "PART NAME": "SENDORA",
      "Name of polling station building":
        "NEW GOVT. PRIMARY SCHOOL BUILDING SENDORA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 615,
      location: "23.299,77.723",
      "inverted one": "'NEW GOVT. PRIMARY SCHOOL BUILDING SENDORA'",
      cosolideted:
        "ElectionBooth('NEW GOVT. PRIMARY SCHOOL BUILDING SENDORA',23.299,77.723)",
      "with comma":
        "ElectionBooth('NEW GOVT. PRIMARY SCHOOL BUILDING SENDORA',23.299,77.723),",
    },
    {
      "PART NO": 117,
      "PART NAME": "RAIPUR RAMASIYA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . RAIPUR RAMASIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 586,
      location: "23.331,77.795",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . RAIPUR RAMASIYA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . RAIPUR RAMASIYA',23.331,77.795)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . RAIPUR RAMASIYA',23.331,77.795),",
    },
    {
      "PART NO": 118,
      "PART NAME": "SADALATAPUR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING SADALATPUR WEST PART R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1234,
      location: "23.330,77.795",
      "inverted one":
        "'INTEGRETED GOVT.M.SCHOOL BUILDING SADALATPUR WEST PART R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SADALATPUR WEST PART R.NO.1',23.330,77.795)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SADALATPUR WEST PART R.NO.1',23.330,77.795),",
    },
    {
      "PART NO": 119,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING NAYAPUR RAISEN R.NO.1",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1319,
      location: "23.330,77.771",
      "inverted one":
        "'INTEGRETED GOVT.M.SCHOOL BUILDING NAYAPUR RAISEN R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NAYAPUR RAISEN R.NO.1',23.330,77.771)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NAYAPUR RAISEN R.NO.1',23.330,77.771),",
    },
    {
      "PART NO": 120,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "NEW GOVT. PRIMARY SCHOOL BUILDING NARAPURA RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1096,
      location: "23.330,77.771",
      "inverted one": "'NEW GOVT. PRIMARY SCHOOL BUILDING NARAPURA RAISEN'",
      cosolideted:
        "ElectionBooth('NEW GOVT. PRIMARY SCHOOL BUILDING NARAPURA RAISEN',23.330,77.771)",
      "with comma":
        "ElectionBooth('NEW GOVT. PRIMARY SCHOOL BUILDING NARAPURA RAISEN',23.330,77.771),",
    },
    {
      "PART NO": 121,
      "PART NAME": "RAISEN",
      "Name of polling station building": "KAMIUNITY HOLL WARD NO. 3 RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 731,
      location: "23.332,77.779",
      "inverted one": "'KAMIUNITY HOLL WARD NO. 3 RAISEN'",
      cosolideted:
        "ElectionBooth('KAMIUNITY HOLL WARD NO. 3 RAISEN',23.332,77.779)",
      "with comma":
        "ElectionBooth('KAMIUNITY HOLL WARD NO. 3 RAISEN',23.332,77.779),",
    },
    {
      "PART NO": 122,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "LOKSEVA KENDRA NEAR TEHSIL OFFICE RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 484,
      location: "23.334,77.780",
      "inverted one": "'LOKSEVA KENDRA NEAR TEHSIL OFFICE RAISEN'",
      cosolideted:
        "ElectionBooth('LOKSEVA KENDRA NEAR TEHSIL OFFICE RAISEN',23.334,77.780)",
      "with comma":
        "ElectionBooth('LOKSEVA KENDRA NEAR TEHSIL OFFICE RAISEN',23.334,77.780),",
    },
    {
      "PART NO": 123,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "INTEGRETED GOVT. H SCHOOL BUILDING R.NO.1 COLLECTRARTE COLONY RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1019,
      location: "23.339,77.780",
      "inverted one":
        "'INTEGRETED GOVT. H SCHOOL BUILDING R.NO.1 COLLECTRARTE COLONY RAISEN'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING R.NO.1 COLLECTRARTE COLONY RAISEN',23.339,77.780)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING R.NO.1 COLLECTRARTE COLONY RAISEN',23.339,77.780),",
    },
    {
      "PART NO": 124,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING R.NO 03 COLLECTRATE COLONY RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1021,
      location: "23.339,77.780",
      "inverted one":
        "'INTEGRETED GOVT.M.SCHOOL BUILDING R.NO 03 COLLECTRATE COLONY RAISEN'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING R.NO 03 COLLECTRATE COLONY RAISEN',23.339,77.780)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING R.NO 03 COLLECTRATE COLONY RAISEN',23.339,77.780),",
    },
    {
      "PART NO": 125,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "INTEGRETED GOVT H.SCHOOL BUILDING R.NO.4 COLLETCERAT COLONY RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 585,
      location: "23.339,77.779",
      "inverted one":
        "'INTEGRETED GOVT H.SCHOOL BUILDING R.NO.4 COLLETCERAT COLONY RAISEN'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT H.SCHOOL BUILDING R.NO.4 COLLETCERAT COLONY RAISEN',23.339,77.779)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT H.SCHOOL BUILDING R.NO.4 COLLETCERAT COLONY RAISEN',23.339,77.779),",
    },
    {
      "PART NO": 126,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "INTEGRETED GOVT.H.SCHOOL BUILDING R.NO.06 COLLECTRATE COLONY RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 830,
      location: "23.338,77.777",
      "inverted one":
        "'INTEGRETED GOVT.H.SCHOOL BUILDING R.NO.06 COLLECTRATE COLONY RAISEN'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.H.SCHOOL BUILDING R.NO.06 COLLECTRATE COLONY RAISEN',23.338,77.777)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.H.SCHOOL BUILDING R.NO.06 COLLECTRATE COLONY RAISEN',23.338,77.777),",
    },
    {
      "PART NO": 127,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING GOPALPUR ROOM NO. 01",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 916,
      location: "23.339,77.780",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING GOPALPUR ROOM NO. 01'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING GOPALPUR ROOM NO. 01',23.339,77.780)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING GOPALPUR ROOM NO. 01',23.339,77.780),",
    },
    {
      "PART NO": 128,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING GOPALPUR ROOM NO. 02",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 921,
      location: "23.356,77.768",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING GOPALPUR ROOM NO. 02'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING GOPALPUR ROOM NO. 02',23.356,77.768)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING GOPALPUR ROOM NO. 02',23.356,77.768),",
    },
    {
      "PART NO": 129,
      "PART NAME": "RAISEN",
      "Name of polling station building": "D.I.C. NORTH PART . RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 764,
      location: "23.331,77.782",
      "inverted one": "'D.I.C. NORTH PART . RAISEN'",
      cosolideted: "ElectionBooth('D.I.C. NORTH PART . RAISEN',23.331,77.782)",
      "with comma":
        "ElectionBooth('D.I.C. NORTH PART . RAISEN',23.331,77.782),",
    },
    {
      "PART NO": 130,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.MIDDLE SCHOOL OLD POLICE STATION ROOM NO.1 RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1152,
      location: "23.327,77.782",
      "inverted one":
        "'GOVT.MIDDLE SCHOOL OLD POLICE STATION ROOM NO.1 RAISEN'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL OLD POLICE STATION ROOM NO.1 RAISEN',23.327,77.782)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL OLD POLICE STATION ROOM NO.1 RAISEN',23.327,77.782),",
    },
    {
      "PART NO": 131,
      "PART NAME": "RAISEN",
      "Name of polling station building": "D.I.C. SOUTH PART . RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 790,
      location: "23.331,77.782",
      "inverted one": "'D.I.C. SOUTH PART . RAISEN'",
      cosolideted: "ElectionBooth('D.I.C. SOUTH PART . RAISEN',23.331,77.782)",
      "with comma":
        "ElectionBooth('D.I.C. SOUTH PART . RAISEN',23.331,77.782),",
    },
    {
      "PART NO": 132,
      "PART NAME": "RAISEN",
      "Name of polling station building": "D.I.C.KENDRA RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 506,
      location: "23.331,77.782",
      "inverted one": "'D.I.C.KENDRA RAISEN'",
      cosolideted: "ElectionBooth('D.I.C.KENDRA RAISEN',23.331,77.782)",
      "with comma": "ElectionBooth('D.I.C.KENDRA RAISEN',23.331,77.782),",
    },
    {
      "PART NO": 133,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING THANE KE PAS ROOM NO.2 RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1120,
      location: "23.327,77.781",
      "inverted one":
        "'GOVT. MIDDLE SCHOOL BUILDING THANE KE PAS ROOM NO.2 RAISEN'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING THANE KE PAS ROOM NO.2 RAISEN',23.327,77.781)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING THANE KE PAS ROOM NO.2 RAISEN',23.327,77.781),",
    },
    {
      "PART NO": 134,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "ANU.DIPART. ADHIKARI OFF.LO.NI.BI. RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1015,
      location: "23.333,77.780",
      "inverted one": "'ANU.DIPART. ADHIKARI OFF.LO.NI.BI. RAISEN'",
      cosolideted:
        "ElectionBooth('ANU.DIPART. ADHIKARI OFF.LO.NI.BI. RAISEN',23.333,77.780)",
      "with comma":
        "ElectionBooth('ANU.DIPART. ADHIKARI OFF.LO.NI.BI. RAISEN',23.333,77.780),",
    },
    {
      "PART NO": 135,
      "PART NAME": "RAISEN",
      "Name of polling station building": "FOREST OFFICE SOUTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 600,
      location: "23.333,77.781",
      "inverted one": "'FOREST OFFICE SOUTH PART'",
      cosolideted: "ElectionBooth('FOREST OFFICE SOUTH PART',23.333,77.781)",
      "with comma": "ElectionBooth('FOREST OFFICE SOUTH PART',23.333,77.781),",
    },
    {
      "PART NO": 136,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "INTEGRETED GOVT.ADARSH. KANYA. HS.SCHOOL R. NO. 01 EAST PART RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 556,
      location: "23.331,77.788",
      "inverted one":
        "'INTEGRETED GOVT.ADARSH. KANYA. HS.SCHOOL R. NO. 01 EAST PART RAISEN'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.ADARSH. KANYA. HS.SCHOOL R. NO. 01 EAST PART RAISEN',23.331,77.788)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.ADARSH. KANYA. HS.SCHOOL R. NO. 01 EAST PART RAISEN',23.331,77.788),",
    },
    {
      "PART NO": 137,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "INTEGRETED GOVT. ADARSH KANYA H S SCHOOL R.NO.2 EAST PART RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 845,
      location: "23.331,77.787",
      "inverted one":
        "'INTEGRETED GOVT. ADARSH KANYA H S SCHOOL R.NO.2 EAST PART RAISEN'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. ADARSH KANYA H S SCHOOL R.NO.2 EAST PART RAISEN',23.331,77.787)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. ADARSH KANYA H S SCHOOL R.NO.2 EAST PART RAISEN',23.331,77.787),",
    },
    {
      "PART NO": 138,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "INTEGRETED GOVT. ADARSH KANYA H S SCHOOL EAST PART RAISEN R.NO.3",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 703,
      location: "23.453,77.770",
      "inverted one":
        "'INTEGRETED GOVT. ADARSH KANYA H S SCHOOL EAST PART RAISEN R.NO.3'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. ADARSH KANYA H S SCHOOL EAST PART RAISEN R.NO.3',23.453,77.770)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. ADARSH KANYA H S SCHOOL EAST PART RAISEN R.NO.3',23.453,77.770),",
    },
    {
      "PART NO": 139,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.SWAMI V. COLLEGE NEW BUILDING EAST PART ROOM NO. 1 RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 496,
      location: "23.329,77.795",
      "inverted one":
        "'GOVT.SWAMI V. COLLEGE NEW BUILDING EAST PART ROOM NO. 1 RAISEN'",
      cosolideted:
        "ElectionBooth('GOVT.SWAMI V. COLLEGE NEW BUILDING EAST PART ROOM NO. 1 RAISEN',23.329,77.795)",
      "with comma":
        "ElectionBooth('GOVT.SWAMI V. COLLEGE NEW BUILDING EAST PART ROOM NO. 1 RAISEN',23.329,77.795),",
    },
    {
      "PART NO": 140,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.SWAMI V COLLAGE NEW BUILDING SOUTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 924,
      location: "23.329,77.795",
      "inverted one": "'GOVT.SWAMI V COLLAGE NEW BUILDING SOUTH PART'",
      cosolideted:
        "ElectionBooth('GOVT.SWAMI V COLLAGE NEW BUILDING SOUTH PART',23.329,77.795)",
      "with comma":
        "ElectionBooth('GOVT.SWAMI V COLLAGE NEW BUILDING SOUTH PART',23.329,77.795),",
    },
    {
      "PART NO": 141,
      "PART NAME": "RAISEN",
      "Name of polling station building": "MANGAL BUILDING,NAGARPALIKA RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 505,
      location: "23.331,77.787",
      "inverted one": "'MANGAL BUILDING,NAGARPALIKA RAISEN'",
      cosolideted:
        "ElectionBooth('MANGAL BUILDING,NAGARPALIKA RAISEN',23.331,77.787)",
      "with comma":
        "ElectionBooth('MANGAL BUILDING,NAGARPALIKA RAISEN',23.331,77.787),",
    },
    {
      "PART NO": 142,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "DISTRICT INSTITUTE FOR EDUCATION AND TRANING RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 659,
      location: "23.331,77.787",
      "inverted one": "'DISTRICT INSTITUTE FOR EDUCATION AND TRANING RAISEN'",
      cosolideted:
        "ElectionBooth('DISTRICT INSTITUTE FOR EDUCATION AND TRANING RAISEN',23.331,77.787)",
      "with comma":
        "ElectionBooth('DISTRICT INSTITUTE FOR EDUCATION AND TRANING RAISEN',23.331,77.787),",
    },
    {
      "PART NO": 143,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "TRIBEL HOSTAL (BAPU ASHRAM) WEST PART . RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1001,
      location: "23.331,77.786",
      "inverted one": "'TRIBEL HOSTAL (BAPU ASHRAM) WEST PART . RAISEN'",
      cosolideted:
        "ElectionBooth('TRIBEL HOSTAL (BAPU ASHRAM) WEST PART . RAISEN',23.331,77.786)",
      "with comma":
        "ElectionBooth('TRIBEL HOSTAL (BAPU ASHRAM) WEST PART . RAISEN',23.331,77.786),",
    },
    {
      "PART NO": 144,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.EXCILENCE H.S.S. (NORTH PART) RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1016,
      location: "23.330,77.795",
      "inverted one": "'GOVT.EXCILENCE H.S.S. (NORTH PART) RAISEN'",
      cosolideted:
        "ElectionBooth('GOVT.EXCILENCE H.S.S. (NORTH PART) RAISEN',23.330,77.795)",
      "with comma":
        "ElectionBooth('GOVT.EXCILENCE H.S.S. (NORTH PART) RAISEN',23.330,77.795),",
    },
    {
      "PART NO": 145,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "OFFICE E.E. PUBLIC HELTH ENGINEERING DEPART RAISEN (P.H.E.)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1279,
      location: "23.193,77.474",
      "inverted one":
        "'OFFICE E.E. PUBLIC HELTH ENGINEERING DEPART RAISEN (P.H.E.)'",
      cosolideted:
        "ElectionBooth('OFFICE E.E. PUBLIC HELTH ENGINEERING DEPART RAISEN (P.H.E.)',23.193,77.474)",
      "with comma":
        "ElectionBooth('OFFICE E.E. PUBLIC HELTH ENGINEERING DEPART RAISEN (P.H.E.)',23.193,77.474),",
    },
    {
      "PART NO": 146,
      "PART NAME": "PATANDEV",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL PATANDEV BUILDING RAISEN R.NO.1",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 990,
      location: "23.328,77.798",
      "inverted one":
        "'INTEGRETED GOVT.M.SCHOOL PATANDEV BUILDING RAISEN R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL PATANDEV BUILDING RAISEN R.NO.1',23.328,77.798)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL PATANDEV BUILDING RAISEN R.NO.1',23.328,77.798),",
    },
    {
      "PART NO": 147,
      "PART NAME": "PATANDEV",
      "Name of polling station building":
        "FOREST OFFECER PRODECTION PATENDEV RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1168,
      location: "23.327,77.798",
      "inverted one": "'FOREST OFFECER PRODECTION PATENDEV RAISEN'",
      cosolideted:
        "ElectionBooth('FOREST OFFECER PRODECTION PATENDEV RAISEN',23.327,77.798)",
      "with comma":
        "ElectionBooth('FOREST OFFECER PRODECTION PATENDEV RAISEN',23.327,77.798),",
    },
    {
      "PART NO": 148,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.EXCELLENCE BOY H.S.SCHOOL SOUTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 963,
      location: "23.260,77.651",
      "inverted one": "'GOVT.EXCELLENCE BOY H.S.SCHOOL SOUTH PART'",
      cosolideted:
        "ElectionBooth('GOVT.EXCELLENCE BOY H.S.SCHOOL SOUTH PART',23.260,77.651)",
      "with comma":
        "ElectionBooth('GOVT.EXCELLENCE BOY H.S.SCHOOL SOUTH PART',23.260,77.651),",
    },
    {
      "PART NO": 149,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.EXCELANCE BOYA H.S.S NORTH PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1120,
      location: "23.330,77.799",
      "inverted one": "'GOVT.EXCELANCE BOYA H.S.S NORTH PART'",
      cosolideted:
        "ElectionBooth('GOVT.EXCELANCE BOYA H.S.S NORTH PART',23.330,77.799)",
      "with comma":
        "ElectionBooth('GOVT.EXCELANCE BOYA H.S.S NORTH PART',23.330,77.799),",
    },
    {
      "PART NO": 150,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING. TAJPURMAHAL",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1015,
      location: "23.337,77.799",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING. TAJPURMAHAL'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. TAJPURMAHAL',23.337,77.799)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING. TAJPURMAHAL',23.337,77.799),",
    },
    {
      "PART NO": 151,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "ANUBHAGIYA ADHIKARI OFFICE KRASI KA HOLL RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 745,
      location: "23.329,77.786",
      "inverted one": "'ANUBHAGIYA ADHIKARI OFFICE KRASI KA HOLL RAISEN'",
      cosolideted:
        "ElectionBooth('ANUBHAGIYA ADHIKARI OFFICE KRASI KA HOLL RAISEN',23.329,77.786)",
      "with comma":
        "ElectionBooth('ANUBHAGIYA ADHIKARI OFFICE KRASI KA HOLL RAISEN',23.329,77.786),",
    },
    {
      "PART NO": 152,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.SWAMI VIVEKANAND COLLAGE NEW BUILDING (WEST PART) RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 655,
      location: "23.329,77.795",
      "inverted one":
        "'GOVT.SWAMI VIVEKANAND COLLAGE NEW BUILDING (WEST PART) RAISEN'",
      cosolideted:
        "ElectionBooth('GOVT.SWAMI VIVEKANAND COLLAGE NEW BUILDING (WEST PART) RAISEN',23.329,77.795)",
      "with comma":
        "ElectionBooth('GOVT.SWAMI VIVEKANAND COLLAGE NEW BUILDING (WEST PART) RAISEN',23.329,77.795),",
    },
    {
      "PART NO": 153,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.SWAMI COLLAGE NEW BUILDING WEST PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 595,
      location: "23.329,77.795",
      "inverted one": "'GOVT.SWAMI COLLAGE NEW BUILDING WEST PART'",
      cosolideted:
        "ElectionBooth('GOVT.SWAMI COLLAGE NEW BUILDING WEST PART',23.329,77.795)",
      "with comma":
        "ElectionBooth('GOVT.SWAMI COLLAGE NEW BUILDING WEST PART',23.329,77.795),",
    },
    {
      "PART NO": 154,
      "PART NAME": "RAISEN",
      "Name of polling station building": "GOVT.LAND SAVING OFFICE . RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 522,
      location: "23.329,77.787",
      "inverted one": "'GOVT.LAND SAVING OFFICE . RAISEN'",
      cosolideted:
        "ElectionBooth('GOVT.LAND SAVING OFFICE . RAISEN',23.329,77.787)",
      "with comma":
        "ElectionBooth('GOVT.LAND SAVING OFFICE . RAISEN',23.329,77.787),",
    },
    {
      "PART NO": 155,
      "PART NAME": "RAISEN",
      "Name of polling station building": "AMBEDKAR BUILDING KALAIPURA RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 672,
      location: "23.324,77.783",
      "inverted one": "'AMBEDKAR BUILDING KALAIPURA RAISEN'",
      cosolideted:
        "ElectionBooth('AMBEDKAR BUILDING KALAIPURA RAISEN',23.324,77.783)",
      "with comma":
        "ElectionBooth('AMBEDKAR BUILDING KALAIPURA RAISEN',23.324,77.783),",
    },
    {
      "PART NO": 156,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "KRISHI UPAJ MANDI SOUTH PART . RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 704,
      location: "23.323,77.784",
      "inverted one": "'KRISHI UPAJ MANDI SOUTH PART . RAISEN'",
      cosolideted:
        "ElectionBooth('KRISHI UPAJ MANDI SOUTH PART . RAISEN',23.323,77.784)",
      "with comma":
        "ElectionBooth('KRISHI UPAJ MANDI SOUTH PART . RAISEN',23.323,77.784),",
    },
    {
      "PART NO": 157,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "KRISHI UPAJ MANDI BUILDING EAST PART HOLL . RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1496,
      location: "23.323,77.784",
      "inverted one": "'KRISHI UPAJ MANDI BUILDING EAST PART HOLL . RAISEN'",
      cosolideted:
        "ElectionBooth('KRISHI UPAJ MANDI BUILDING EAST PART HOLL . RAISEN',23.323,77.784)",
      "with comma":
        "ElectionBooth('KRISHI UPAJ MANDI BUILDING EAST PART HOLL . RAISEN',23.323,77.784),",
    },
    {
      "PART NO": 158,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING SHIKARIPURA MADYA PART RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 905,
      location: "23.322,77.780",
      "inverted one":
        "'GOVT.PRIMARY SCHOOL BUILDING SHIKARIPURA MADYA PART RAISEN'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SHIKARIPURA MADYA PART RAISEN',23.322,77.780)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SHIKARIPURA MADYA PART RAISEN',23.322,77.780),",
    },
    {
      "PART NO": 159,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING SHIKARIPURA NORTH PART RAISEN",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 833,
      location: "23.322,77.780",
      "inverted one":
        "'GOVT.PRIMARY SCHOOL BUILDING SHIKARIPURA NORTH PART RAISEN'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SHIKARIPURA NORTH PART RAISEN',23.322,77.780)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SHIKARIPURA NORTH PART RAISEN',23.322,77.780),",
    },
    {
      "PART NO": 160,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING SANJAY NAGAR RAISEN WEST PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 436,
      location: "23.322,77.780",
      "inverted one":
        "'GOVT.PRIMARY SCHOOL BUILDING SANJAY NAGAR RAISEN WEST PART'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SANJAY NAGAR RAISEN WEST PART',23.322,77.780)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SANJAY NAGAR RAISEN WEST PART',23.322,77.780),",
    },
    {
      "PART NO": 161,
      "PART NAME": "RAISEN",
      "Name of polling station building":
        "GOVT PRIMARY SCHOOL BUILDING SANJAY NAGAR RAISEN EAST PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1159,
      location: "23.319,77.774",
      "inverted one":
        "'GOVT PRIMARY SCHOOL BUILDING SANJAY NAGAR RAISEN EAST PART'",
      cosolideted:
        "ElectionBooth('GOVT PRIMARY SCHOOL BUILDING SANJAY NAGAR RAISEN EAST PART',23.319,77.774)",
      "with comma":
        "ElectionBooth('GOVT PRIMARY SCHOOL BUILDING SANJAY NAGAR RAISEN EAST PART',23.319,77.774),",
    },
    {
      "PART NO": 162,
      "PART NAME": "MAUPATHARAI",
      "Name of polling station building":
        "GOVT.MIDDLE SCHOOL BUILDING MAHUPATHRAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1262,
      location: "23.364,77.793",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING MAHUPATHRAI'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING MAHUPATHRAI',23.364,77.793)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING MAHUPATHRAI',23.364,77.793),",
    },
    {
      "PART NO": 163,
      "PART NAME": "SALERA",
      "Name of polling station building":
        "INTEGRETED GOVT. H SCHOOL BUILDING SALERA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1070,
      location: "23.348,77.772",
      "inverted one": "'INTEGRETED GOVT. H SCHOOL BUILDING SALERA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING SALERA R.NO.1',23.348,77.772)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING SALERA R.NO.1',23.348,77.772),",
    },
    {
      "PART NO": 164,
      "PART NAME": "CHANDANA",
      "Name of polling station building": "GOVT.MIDDLE SCHOOL BUILDING CHANDNA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1140,
      location: "23.410,77.828",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING CHANDNA'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING CHANDNA',23.410,77.828)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING CHANDNA',23.410,77.828),",
    },
    {
      "PART NO": 165,
      "PART NAME": "MURELAKHURD",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING MURELKHURD R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 919,
      location: "23.428,77.877",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING MURELKHURD R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING MURELKHURD R.NO.1',23.428,77.877)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING MURELKHURD R.NO.1',23.428,77.877),",
    },
    {
      "PART NO": 166,
      "PART NAME": "KANAPOHARA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING KANPOHRA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 696,
      location: "23.428,77.877",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING KANPOHRA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING KANPOHRA R.NO.1',23.428,77.877)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING KANPOHRA R.NO.1',23.428,77.877),",
    },
    {
      "PART NO": 167,
      "PART NAME": "KANAPOHARA",
      "Name of polling station building": "PANCHAYAT BUILDNIG KANPOHRA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 556,
      location: "23.428,77.877",
      "inverted one": "'PANCHAYAT BUILDNIG KANPOHRA'",
      cosolideted: "ElectionBooth('PANCHAYAT BUILDNIG KANPOHRA',23.428,77.877)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDNIG KANPOHRA',23.428,77.877),",
    },
    {
      "PART NO": 168,
      "PART NAME": "MURELKALAN",
      "Name of polling station building":
        "GOVT.MIDDLE SCHOOL BUILDING EAST PART MURELKALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 685,
      location: "23.404,77.862",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING EAST PART MURELKALAN'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING EAST PART MURELKALAN',23.404,77.862)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING EAST PART MURELKALAN',23.404,77.862),",
    },
    {
      "PART NO": 169,
      "PART NAME": "MURELKALAN",
      "Name of polling station building":
        "GOVT.MIDDLE SCHOOL BUILDING WEST PART MURELKALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 868,
      location: "23.403,77.861",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING WEST PART MURELKALAN'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING WEST PART MURELKALAN',23.403,77.861)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING WEST PART MURELKALAN',23.403,77.861),",
    },
    {
      "PART NO": 170,
      "PART NAME": "DAVARAIMALIYA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING ,DABRA IMALIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 828,
      location: "23.377,77.847",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING ,DABRA IMALIYA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING ,DABRA IMALIYA',23.377,77.847)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING ,DABRA IMALIYA',23.377,77.847),",
    },
    {
      "PART NO": 171,
      "PART NAME": "SANGRAMAPUR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING SANGRAMPUR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 508,
      location: "23.379,77.853",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING SANGRAMPUR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SANGRAMPUR R.NO.1',23.379,77.853)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SANGRAMPUR R.NO.1',23.379,77.853),",
    },
    {
      "PART NO": 172,
      "PART NAME": "ANDOL",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING ANDOL R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 664,
      location: "23.381,77.854",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING ANDOL R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING ANDOL R.NO.1',23.381,77.854)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING ANDOL R.NO.1',23.381,77.854),",
    },
    {
      "PART NO": 173,
      "PART NAME": "BAHED",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING BAHED",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 575,
      location: "23.379,77.891",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING BAHED'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BAHED',23.379,77.891)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BAHED',23.379,77.891),",
    },
    {
      "PART NO": 174,
      "PART NAME": "HAKIMAKHEDI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING HAKEEMKHEDI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1256,
      location: "23.400,77.907",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING HAKEEMKHEDI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING HAKEEMKHEDI R.NO.1',23.400,77.907)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING HAKEEMKHEDI R.NO.1',23.400,77.907),",
    },
    {
      "PART NO": 175,
      "PART NAME": "KARAHOUD",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING KARHOD R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 772,
      location: "23.434,77.904",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING KARHOD R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING KARHOD R.NO.1',23.434,77.904)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING KARHOD R.NO.1',23.434,77.904),",
    },
    {
      "PART NO": 176,
      "PART NAME": "PIPALAKHEDI",
      "Name of polling station building":
        "INTEGRETED GOVT.MIDDLE SCHOOL BUILDING PIPALKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 534,
      location: "23.420,77.942",
      "inverted one": "'INTEGRETED GOVT.MIDDLE SCHOOL BUILDING PIPALKHEDI'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.MIDDLE SCHOOL BUILDING PIPALKHEDI',23.420,77.942)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.MIDDLE SCHOOL BUILDING PIPALKHEDI',23.420,77.942),",
    },
    {
      "PART NO": 177,
      "PART NAME": "BABALIA",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BABALIA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 479,
      location: "23.426,77.918",
      "inverted one": "'GOVT.PRIMARY SCHOOL BABALIA'",
      cosolideted: "ElectionBooth('GOVT.PRIMARY SCHOOL BABALIA',23.426,77.918)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BABALIA',23.426,77.918),",
    },
    {
      "PART NO": 178,
      "PART NAME": "GYARASABAD",
      "Name of polling station building":
        "INTEGRETED GOVT.MIDDLE SCHOOL BUILDING GYARSABAD ROOM NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 583,
      location: "23.334,77.781",
      "inverted one":
        "'INTEGRETED GOVT.MIDDLE SCHOOL BUILDING GYARSABAD ROOM NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.MIDDLE SCHOOL BUILDING GYARSABAD ROOM NO.1',23.334,77.781)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.MIDDLE SCHOOL BUILDING GYARSABAD ROOM NO.1',23.334,77.781),",
    },
    {
      "PART NO": 179,
      "PART NAME": "SAGONIYA",
      "Name of polling station building": "AGANWADI CENTER BUILDING SAGONIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 310,
      location: "23.386,77.938",
      "inverted one": "'AGANWADI CENTER BUILDING SAGONIYA'",
      cosolideted:
        "ElectionBooth('AGANWADI CENTER BUILDING SAGONIYA',23.386,77.938)",
      "with comma":
        "ElectionBooth('AGANWADI CENTER BUILDING SAGONIYA',23.386,77.938),",
    },
    {
      "PART NO": 180,
      "PART NAME": "SANCHET",
      "Name of polling station building":
        "INTEGRETED GOVT. H S SCHOOL BUILDING SANCHET NORTH PART R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 914,
      location: "23.356,77.934",
      "inverted one":
        "'INTEGRETED GOVT. H S SCHOOL BUILDING SANCHET NORTH PART R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING SANCHET NORTH PART R.NO.1',23.356,77.934)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING SANCHET NORTH PART R.NO.1',23.356,77.934),",
    },
    {
      "PART NO": 181,
      "PART NAME": "SANCHET",
      "Name of polling station building":
        "INTEGRETED GOVT. H S SCHOOL BUILDING SANCHET EAST PART R.NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 860,
      location: "23.334,77.781",
      "inverted one":
        "'INTEGRETED GOVT. H S SCHOOL BUILDING SANCHET EAST PART R.NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING SANCHET EAST PART R.NO.2',23.334,77.781)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING SANCHET EAST PART R.NO.2',23.334,77.781),",
    },
    {
      "PART NO": 182,
      "PART NAME": "SANCHET",
      "Name of polling station building":
        "INTEGRETED GOVT. H S SCHOOL BUILDING SANCHET SOUTH PART R.NO.3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 725,
      location: "23.356,77.934",
      "inverted one":
        "'INTEGRETED GOVT. H S SCHOOL BUILDING SANCHET SOUTH PART R.NO.3'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING SANCHET SOUTH PART R.NO.3',23.356,77.934)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING SANCHET SOUTH PART R.NO.3',23.356,77.934),",
    },
    {
      "PART NO": 183,
      "PART NAME": "DANDERA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING DANDERA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 581,
      location: "23.380,77.932",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING DANDERA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING DANDERA R.NO.1',23.380,77.932)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING DANDERA R.NO.1',23.380,77.932),",
    },
    {
      "PART NO": 184,
      "PART NAME": "BADODA",
      "Name of polling station building":
        "ENTIGRATED GOVT.MIDDLE SCHOOL BUILDING BARODA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 701,
      location: "23.345,77.890",
      "inverted one": "'ENTIGRATED GOVT.MIDDLE SCHOOL BUILDING BARODA'",
      cosolideted:
        "ElectionBooth('ENTIGRATED GOVT.MIDDLE SCHOOL BUILDING BARODA',23.345,77.890)",
      "with comma":
        "ElectionBooth('ENTIGRATED GOVT.MIDDLE SCHOOL BUILDING BARODA',23.345,77.890),",
    },
    {
      "PART NO": 185,
      "PART NAME": "BADODA",
      "Name of polling station building":
        "ENTIGRETED GOVT.MIDDLE SCHOOL BUILDING BARODA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 587,
      location: "23.346,77.890",
      "inverted one": "'ENTIGRETED GOVT.MIDDLE SCHOOL BUILDING BARODA'",
      cosolideted:
        "ElectionBooth('ENTIGRETED GOVT.MIDDLE SCHOOL BUILDING BARODA',23.346,77.890)",
      "with comma":
        "ElectionBooth('ENTIGRETED GOVT.MIDDLE SCHOOL BUILDING BARODA',23.346,77.890),",
    },
    {
      "PART NO": 186,
      "PART NAME": "BHADANER",
      "Name of polling station building": "GOVT.MIDDLE SCHOOL BUILDING BHADNER",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1093,
      location: "23.337,77.827",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING BHADNER'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING BHADNER',23.337,77.827)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING BHADNER',23.337,77.827),",
    },
    {
      "PART NO": 187,
      "PART NAME": "KHARAGAVALI",
      "Name of polling station building":
        "INTEGRETED GOVT. H.S BUILDING KHARGAVALI R.NO. 01",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 965,
      location: "23.328,77.818",
      "inverted one": "'INTEGRETED GOVT. H.S BUILDING KHARGAVALI R.NO. 01'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H.S BUILDING KHARGAVALI R.NO. 01',23.328,77.818)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H.S BUILDING KHARGAVALI R.NO. 01',23.328,77.818),",
    },
    {
      "PART NO": 188,
      "PART NAME": "DHANIYAKHEDI",
      "Name of polling station building":
        "GRAM PANCHAYAT BUILDING DHANIYAKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 795,
      location: "23.314,77.795",
      "inverted one": "'GRAM PANCHAYAT BUILDING DHANIYAKHEDI'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING DHANIYAKHEDI',23.314,77.795)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING DHANIYAKHEDI',23.314,77.795),",
    },
    {
      "PART NO": 189,
      "PART NAME": "PIPALAI RAJANABADI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING PIPLAI RAJAN BARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 868,
      location: "23.314,77.817",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING PIPLAI RAJAN BARI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PIPLAI RAJAN BARI',23.314,77.817)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PIPLAI RAJAN BARI',23.314,77.817),",
    },
    {
      "PART NO": 190,
      "PART NAME": "PATHARI",
      "Name of polling station building": "PANCHAYAT BUILDNIG PATHARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 959,
      location: "23.320,77.837",
      "inverted one": "'PANCHAYAT BUILDNIG PATHARI'",
      cosolideted: "ElectionBooth('PANCHAYAT BUILDNIG PATHARI',23.320,77.837)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDNIG PATHARI',23.320,77.837),",
    },
    {
      "PART NO": 191,
      "PART NAME": "PATHARI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING PATHARI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 595,
      location: "23.317,77.842",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING PATHARI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING PATHARI R.NO.1',23.317,77.842)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING PATHARI R.NO.1',23.317,77.842),",
    },
    {
      "PART NO": 192,
      "PART NAME": "SURAI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING SURAI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 558,
      location: "23.326,77.808",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING SURAI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SURAI R.NO.1',23.326,77.808)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SURAI R.NO.1',23.326,77.808),",
    },
    {
      "PART NO": 193,
      "PART NAME": "CHILAVAHA",
      "Name of polling station building": "AADIWASI BOYS HOSTEL CHILVAAH",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 779,
      location: "23.274,77.916",
      "inverted one": "'AADIWASI BOYS HOSTEL CHILVAAH'",
      cosolideted:
        "ElectionBooth('AADIWASI BOYS HOSTEL CHILVAAH',23.274,77.916)",
      "with comma":
        "ElectionBooth('AADIWASI BOYS HOSTEL CHILVAAH',23.274,77.916),",
    },
    {
      "PART NO": 194,
      "PART NAME": "CHILAVAHA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . CHILVAHA NEAR BERIYAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 750,
      location: "23.269,77.916",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . CHILVAHA NEAR BERIYAR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . CHILVAHA NEAR BERIYAR',23.269,77.916)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . CHILVAHA NEAR BERIYAR',23.269,77.916),",
    },
    {
      "PART NO": 195,
      "PART NAME": "BAGHEDI (MAHUA KHEDA)",
      "Name of polling station building":
        "PRIMARY SCHOOL BUILDING BAGHEDI R.NO. 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 772,
      location: "23.199,77.905",
      "inverted one": "'PRIMARY SCHOOL BUILDING BAGHEDI R.NO. 1'",
      cosolideted:
        "ElectionBooth('PRIMARY SCHOOL BUILDING BAGHEDI R.NO. 1',23.199,77.905)",
      "with comma":
        "ElectionBooth('PRIMARY SCHOOL BUILDING BAGHEDI R.NO. 1',23.199,77.905),",
    },
    {
      "PART NO": 196,
      "PART NAME": "MAHUAKHEDA (GHENTAKACHHAR)",
      "Name of polling station building":
        "PRIMARY SCHOOL BUILDING MAHUAKHEDA (GHENTAKACHAR) R.NO. 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 709,
      location: "23.225,77.909",
      "inverted one":
        "'PRIMARY SCHOOL BUILDING MAHUAKHEDA (GHENTAKACHAR) R.NO. 1'",
      cosolideted:
        "ElectionBooth('PRIMARY SCHOOL BUILDING MAHUAKHEDA (GHENTAKACHAR) R.NO. 1',23.225,77.909)",
      "with comma":
        "ElectionBooth('PRIMARY SCHOOL BUILDING MAHUAKHEDA (GHENTAKACHAR) R.NO. 1',23.225,77.909),",
    },
    {
      "PART NO": 197,
      "PART NAME": "GUNDARAI",
      "Name of polling station building": "GOVT. HIGH SCHOOL BUILDING GUNDRAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 828,
      location: "23.230,77.967",
      "inverted one": "'GOVT. HIGH SCHOOL BUILDING GUNDRAI'",
      cosolideted:
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING GUNDRAI',23.230,77.967)",
      "with comma":
        "ElectionBooth('GOVT. HIGH SCHOOL BUILDING GUNDRAI',23.230,77.967),",
    },
    {
      "PART NO": 198,
      "PART NAME": "CHANDAN GODA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING .CHANDAN GODA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 666,
      location: "23.188,77.958",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING .CHANDAN GODA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING .CHANDAN GODA',23.188,77.958)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING .CHANDAN GODA',23.188,77.958),",
    },
    {
      "PART NO": 199,
      "PART NAME": "KAMAKA",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING . KMKA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 606,
      location: "23.227,77.964",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . KMKA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . KMKA',23.227,77.964)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . KMKA',23.227,77.964),",
    },
    {
      "PART NO": 200,
      "PART NAME": "BHUSIMENTA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING BHUSIBETA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 801,
      location: "23.248,77.937",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING BHUSIBETA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BHUSIBETA R.NO.1',23.248,77.937)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BHUSIBETA R.NO.1',23.248,77.937),",
    },
    {
      "PART NO": 201,
      "PART NAME": "NARAVAR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING NNARWAR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 981,
      location: "23.303,77.965",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING NNARWAR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NNARWAR R.NO.1',23.303,77.965)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NNARWAR R.NO.1',23.303,77.965),",
    },
    {
      "PART NO": 202,
      "PART NAME": "NARAVAR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING NARWAR R.NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 801,
      location: "23.303,77.964",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING NARWAR R.NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NARWAR R.NO.2',23.303,77.964)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NARWAR R.NO.2',23.303,77.964),",
    },
    {
      "PART NO": 203,
      "PART NAME": "KHANDERA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . KHANDERA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 787,
      location: "23.330,77.792",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . KHANDERA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . KHANDERA',23.330,77.792)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . KHANDERA',23.330,77.792),",
    },
    {
      "PART NO": 204,
      "PART NAME": "BHARDA",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING BHARDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 297,
      location: "23.334,77.781",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING BHARDA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BHARDA',23.334,77.781)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BHARDA',23.334,77.781),",
    },
    {
      "PART NO": 205,
      "PART NAME": "NAKATARA",
      "Name of polling station building":
        "GOVT.MIDDLE SCHOOL BUILDING NAKTARA ADD. ROOM",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 647,
      location: "23.330,77.792",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING NAKTARA ADD. ROOM'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING NAKTARA ADD. ROOM',23.330,77.792)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING NAKTARA ADD. ROOM',23.330,77.792),",
    },
    {
      "PART NO": 206,
      "PART NAME": "NAKATARA",
      "Name of polling station building": "GRAM PANCHAYAT BUILDING NAKTARA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 640,
      location: "23.330,77.792",
      "inverted one": "'GRAM PANCHAYAT BUILDING NAKTARA'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING NAKTARA',23.330,77.792)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING NAKTARA',23.330,77.792),",
    },
    {
      "PART NO": 207,
      "PART NAME": "NAKATARA",
      "Name of polling station building": "SEVA SAHKARI SANTHA MARYADIT NAKTRA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 600,
      location: "23.330,77.792",
      "inverted one": "'SEVA SAHKARI SANTHA MARYADIT NAKTRA'",
      cosolideted:
        "ElectionBooth('SEVA SAHKARI SANTHA MARYADIT NAKTRA',23.330,77.792)",
      "with comma":
        "ElectionBooth('SEVA SAHKARI SANTHA MARYADIT NAKTRA',23.330,77.792),",
    },
    {
      "PART NO": 208,
      "PART NAME": "BANAKHEDI",
      "Name of polling station building":
        "INTEGRETED GOVT. H SCHOOL BUILDING VANKHEDI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 889,
      location: "23.330,77.792",
      "inverted one": "'INTEGRETED GOVT. H SCHOOL BUILDING VANKHEDI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING VANKHEDI R.NO.1',23.330,77.792)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING VANKHEDI R.NO.1',23.330,77.792),",
    },
    {
      "PART NO": 209,
      "PART NAME": "AMARAVAD",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . AMRAVAT",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 752,
      location: "23.328,77.942",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . AMRAVAT'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . AMRAVAT',23.328,77.942)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . AMRAVAT',23.328,77.942),",
    },
    {
      "PART NO": 210,
      "PART NAME": "MUNGALIYA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . MUNGALIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 678,
      location: "23.310,77.917",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . MUNGALIYA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . MUNGALIYA',23.310,77.917)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . MUNGALIYA',23.310,77.917),",
    },
    {
      "PART NO": 211,
      "PART NAME": "DAVAR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING DAWAR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 584,
      location: "23.328,77.942",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING DAWAR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING DAWAR R.NO.1',23.328,77.942)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING DAWAR R.NO.1',23.328,77.942),",
    },
    {
      "PART NO": 212,
      "PART NAME": "BISANKHEDI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING BISANKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 613,
      location: "23.340,77.962",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING BISANKHEDI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BISANKHEDI',23.340,77.962)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BISANKHEDI',23.340,77.962),",
    },
    {
      "PART NO": 213,
      "PART NAME": "NASARUDDINAKHEDA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING NASIRUDINKHEDA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 500,
      location: "23.364,77.966",
      "inverted one":
        "'INTEGRETED GOVT.M.SCHOOL BUILDING NASIRUDINKHEDA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NASIRUDINKHEDA R.NO.1',23.364,77.966)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING NASIRUDINKHEDA R.NO.1',23.364,77.966),",
    },
    {
      "PART NO": 214,
      "PART NAME": "KANTH",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING . KANTH",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1132,
      location: "23.338,77.981",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING . KANTH'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING . KANTH',23.338,77.981)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING . KANTH',23.338,77.981),",
    },
    {
      "PART NO": 215,
      "PART NAME": "GUDAVAL",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING . GUDAVAL",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 572,
      location: "23.311,77.984",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING . GUDAVAL'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . GUDAVAL',23.311,77.984)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING . GUDAVAL',23.311,77.984),",
    },
    {
      "PART NO": 216,
      "PART NAME": "TIKODA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING . TIKODA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 696,
      location: "23.305,77.940",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING . TIKODA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING . TIKODA',23.305,77.940)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING . TIKODA',23.305,77.940),",
    },
    {
      "PART NO": 217,
      "PART NAME": "SIRASODA",
      "Name of polling station building":
        "INTEGRETED GOVT. H SCHOOL BUILDING SIRSOUDA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1186,
      location: "23.303,78.004",
      "inverted one": "'INTEGRETED GOVT. H SCHOOL BUILDING SIRSOUDA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING SIRSOUDA R.NO.1',23.303,78.004)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING SIRSOUDA R.NO.1',23.303,78.004),",
    },
    {
      "PART NO": 218,
      "PART NAME": "BELANA MAHALAPUR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING BELNA MAHALPUR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 676,
      location: "23.3285291,77.8017981",
      "inverted one":
        "'INTEGRETED GOVT.M.SCHOOL BUILDING BELNA MAHALPUR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BELNA MAHALPUR R.NO.1',23.3285291,77.8017981)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BELNA MAHALPUR R.NO.1',23.3285291,77.8017981),",
    },
    {
      "PART NO": 219,
      "PART NAME": "HINOTIYA MAHALPUR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING HINOTIYA MAHALPUR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 869,
      location: "23.32266667,78.018485",
      "inverted one":
        "'INTEGRETED GOVT.M.SCHOOL BUILDING HINOTIYA MAHALPUR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING HINOTIYA MAHALPUR R.NO.1',23.32266667,78.018485)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING HINOTIYA MAHALPUR R.NO.1',23.32266667,78.018485),",
    },
    {
      "PART NO": 220,
      "PART NAME": "AMAKHEDA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING AAMKHEDA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1019,
      location: "23.29739866,78.04103927",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING AAMKHEDA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING AAMKHEDA R.NO.1',23.29739866,78.04103927)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING AAMKHEDA R.NO.1',23.29739866,78.04103927),",
    },
    {
      "PART NO": 221,
      "PART NAME": "BAVALIYA IMALIYA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING BABLIYA IMLIYA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 776,
      location: "23.297453,78.0409",
      "inverted one":
        "'INTEGRETED GOVT.M.SCHOOL BUILDING BABLIYA IMLIYA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BABLIYA IMLIYA R.NO.1',23.297453,78.0409)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BABLIYA IMLIYA R.NO.1',23.297453,78.0409),",
    },
    {
      "PART NO": 222,
      "PART NAME": "SILARI",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING SILARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 525,
      location: "23.27118,78.021067",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING SILARI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SILARI',23.27118,78.021067)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SILARI',23.27118,78.021067),",
    },
    {
      "PART NO": 223,
      "PART NAME": "MAHALAPUR PATHA",
      "Name of polling station building":
        "INTEGRETED NEW GOVT. H.SCHOOL BUILDING MAHALAPUR PATHA R.NO. 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 508,
      location: "23.278986,78.047179",
      "inverted one":
        "'INTEGRETED NEW GOVT. H.SCHOOL BUILDING MAHALAPUR PATHA R.NO. 1'",
      cosolideted:
        "ElectionBooth('INTEGRETED NEW GOVT. H.SCHOOL BUILDING MAHALAPUR PATHA R.NO. 1',23.278986,78.047179)",
      "with comma":
        "ElectionBooth('INTEGRETED NEW GOVT. H.SCHOOL BUILDING MAHALAPUR PATHA R.NO. 1',23.278986,78.047179),",
    },
    {
      "PART NO": 224,
      "PART NAME": "BHILADIYA",
      "Name of polling station building":
        "PANCHAYAT BUILDING BHILADIYA R.NO. 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 704,
      location: "23.31509667,78.09042",
      "inverted one": "'PANCHAYAT BUILDING BHILADIYA R.NO. 1'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING BHILADIYA R.NO. 1',23.31509667,78.09042)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING BHILADIYA R.NO. 1',23.31509667,78.09042),",
    },
    {
      "PART NO": 225,
      "PART NAME": "LILAGAVA",
      "Name of polling station building": "PANCHAYAT BUILDING LILGAVAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 773,
      location: "23.31509666,78.09042",
      "inverted one": "'PANCHAYAT BUILDING LILGAVAN'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING LILGAVAN',23.31509666,78.09042)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING LILGAVAN',23.31509666,78.09042),",
    },
    {
      "PART NO": 226,
      "PART NAME": "KISHANAPUR",
      "Name of polling station building": "GOVT.BUILDING KISHANPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1104,
      location: "23.3162352,78.0734376",
      "inverted one": "'GOVT.BUILDING KISHANPUR'",
      cosolideted:
        "ElectionBooth('GOVT.BUILDING KISHANPUR',23.3162352,78.0734376)",
      "with comma":
        "ElectionBooth('GOVT.BUILDING KISHANPUR',23.3162352,78.0734376),",
    },
    {
      "PART NO": 227,
      "PART NAME": "KISHANAPUR",
      "Name of polling station building":
        "GOVT.MIDDLE SCHOOL BUILDING KISHANPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 685,
      location: "23.313268,78.069982",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING KISHANPUR'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING KISHANPUR',23.313268,78.069982)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING KISHANPUR',23.313268,78.069982),",
    },
    {
      "PART NO": 228,
      "PART NAME": "BORAPANI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING BORPANI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1153,
      location: "23.30454013,78.11028638",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING BORPANI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BORPANI R.NO.1',23.30454013,78.11028638)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BORPANI R.NO.1',23.30454013,78.11028638),",
    },
    {
      "PART NO": 229,
      "PART NAME": "DEHAGAV",
      "Name of polling station building":
        "INTEGRETED GOVT. H S SCHOOL BUILDING DEHGAON R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1142,
      location: "23.319144,78.090022",
      "inverted one": "'INTEGRETED GOVT. H S SCHOOL BUILDING DEHGAON R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING DEHGAON R.NO.1',23.319144,78.090022)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING DEHGAON R.NO.1',23.319144,78.090022),",
    },
    {
      "PART NO": 230,
      "PART NAME": "DEHAGAV",
      "Name of polling station building":
        "INTEGRETED GOVT. H S SCHOOL BUILDING DEHGAON R.NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1056,
      location: "23.319144,78.090022",
      "inverted one": "'INTEGRETED GOVT. H S SCHOOL BUILDING DEHGAON R.NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING DEHGAON R.NO.2',23.319144,78.090022)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING DEHGAON R.NO.2',23.319144,78.090022),",
    },
    {
      "PART NO": 231,
      "PART NAME": "DEHAGAV",
      "Name of polling station building":
        "INTEGRETED GOVT. H S SCHOOL BUILDING DEHGAON R.NO.3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 841,
      location: "23.319144,78.090022",
      "inverted one": "'INTEGRETED GOVT. H S SCHOOL BUILDING DEHGAON R.NO.3'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING DEHGAON R.NO.3',23.319144,78.090022)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING DEHGAON R.NO.3',23.319144,78.090022),",
    },
    {
      "PART NO": 232,
      "PART NAME": "DEHAGAV",
      "Name of polling station building": "PANCHAYAT BUILDING DEHAGAV R.NO. 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 803,
      location: "23.319026,78.089363",
      "inverted one": "'PANCHAYAT BUILDING DEHAGAV R.NO. 1'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING DEHAGAV R.NO. 1',23.319026,78.089363)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING DEHAGAV R.NO. 1',23.319026,78.089363),",
    },
    {
      "PART NO": 233,
      "PART NAME": "DEHAGAV",
      "Name of polling station building":
        "INTEGRETED GOVT. K.M.SCHOOL BUILDING DEHGAON R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 883,
      location: "23.36197506,78.07962123",
      "inverted one": "'INTEGRETED GOVT. K.M.SCHOOL BUILDING DEHGAON R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. K.M.SCHOOL BUILDING DEHGAON R.NO.1',23.36197506,78.07962123)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. K.M.SCHOOL BUILDING DEHGAON R.NO.1',23.36197506,78.07962123),",
    },
    {
      "PART NO": 234,
      "PART NAME": "DEHAGAV",
      "Name of polling station building":
        "INTEGRETED GOVT. K.M. SCHOOL BUILDING DEHGAON R.NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 874,
      location: "23.312368,78.097817",
      "inverted one": "'INTEGRETED GOVT. K.M. SCHOOL BUILDING DEHGAON R.NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. K.M. SCHOOL BUILDING DEHGAON R.NO.2',23.312368,78.097817)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. K.M. SCHOOL BUILDING DEHGAON R.NO.2',23.312368,78.097817),",
    },
    {
      "PART NO": 235,
      "PART NAME": "TARAVALI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING TARAVALI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1026,
      location: "23.33351819,78.06236102",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING TARAVALI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING TARAVALI',23.33351819,78.06236102)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING TARAVALI',23.33351819,78.06236102),",
    },
    {
      "PART NO": 236,
      "PART NAME": "VANIYAKHEDI",
      "Name of polling station building":
        "INTEGRATED G.M.S BUILDING BANIYAKHEDI ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 972,
      location: "23.34861308,78.04361373",
      "inverted one": "'INTEGRATED G.M.S BUILDING BANIYAKHEDI ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S BUILDING BANIYAKHEDI ROOM NO 1',23.34861308,78.04361373)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S BUILDING BANIYAKHEDI ROOM NO 1',23.34861308,78.04361373),",
    },
    {
      "PART NO": 237,
      "PART NAME": "SANKAL",
      "Name of polling station building":
        "INTEGRATED G.M.S BUILDING SANKAL ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1196,
      location: "23.361933,78.079648",
      "inverted one": "'INTEGRATED G.M.S BUILDING SANKAL ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S BUILDING SANKAL ROOM NO 1',23.361933,78.079648)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S BUILDING SANKAL ROOM NO 1',23.361933,78.079648),",
    },
    {
      "PART NO": 238,
      "PART NAME": "BANAKHEDI",
      "Name of polling station building":
        "INTEGRATED G.M.S. BUILDING BANKHEDI ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 708,
      location: "23.38433,78.03791",
      "inverted one": "'INTEGRATED G.M.S. BUILDING BANKHEDI ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S. BUILDING BANKHEDI ROOM NO 1',23.38433,78.03791)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S. BUILDING BANKHEDI ROOM NO 1',23.38433,78.03791),",
    },
    {
      "PART NO": 239,
      "PART NAME": "KHERKHEDI",
      "Name of polling station building": "GOVT.NEW M.S.SCHOOL KHERKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 807,
      location: "23.410121,78.077852",
      "inverted one": "'GOVT.NEW M.S.SCHOOL KHERKHEDI'",
      cosolideted:
        "ElectionBooth('GOVT.NEW M.S.SCHOOL KHERKHEDI',23.410121,78.077852)",
      "with comma":
        "ElectionBooth('GOVT.NEW M.S.SCHOOL KHERKHEDI',23.410121,78.077852),",
    },
    {
      "PART NO": 240,
      "PART NAME": "CHANDAPUR",
      "Name of polling station building":
        "GOVT.NEW MIDDLE SCHOOL BUILDING CHANDPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1042,
      location: "23.39534897,78.14388572",
      "inverted one": "'GOVT.NEW MIDDLE SCHOOL BUILDING CHANDPUR'",
      cosolideted:
        "ElectionBooth('GOVT.NEW MIDDLE SCHOOL BUILDING CHANDPUR',23.39534897,78.14388572)",
      "with comma":
        "ElectionBooth('GOVT.NEW MIDDLE SCHOOL BUILDING CHANDPUR',23.39534897,78.14388572),",
    },
    {
      "PART NO": 241,
      "PART NAME": "KHEJADA MAHALAPUR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING KHEJRAMAHALPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 821,
      location: "23.39540783,78.14386624",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KHEJRAMAHALPUR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KHEJRAMAHALPUR',23.39540783,78.14386624)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KHEJRAMAHALPUR',23.39540783,78.14386624),",
    },
    {
      "PART NO": 242,
      "PART NAME": "TILAKHURD",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING TEELA KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 671,
      location: "23.424717,77.998905",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING TEELA KHURD'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING TEELA KHURD',23.424717,77.998905)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING TEELA KHURD',23.424717,77.998905),",
    },
    {
      "PART NO": 243,
      "PART NAME": "SUKARRA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING SURKARA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 778,
      location: "23.46432,78.02193",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING SURKARA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SURKARA',23.46432,78.02193)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SURKARA',23.46432,78.02193),",
    },
    {
      "PART NO": 244,
      "PART NAME": "BADHER",
      "Name of polling station building":
        "INTEGRATED G.M.S. BUILDING BADER ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 890,
      location: "23.437835,78.0137",
      "inverted one": "'INTEGRATED G.M.S. BUILDING BADER ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S. BUILDING BADER ROOM NO 1',23.437835,78.0137)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S. BUILDING BADER ROOM NO 1',23.437835,78.0137),",
    },
    {
      "PART NO": 245,
      "PART NAME": "GADHI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING MANKAPUR TOLA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 785,
      location: "23.39915349,78.13696663",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING MANKAPUR TOLA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MANKAPUR TOLA',23.39915349,78.13696663)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MANKAPUR TOLA',23.39915349,78.13696663),",
    },
    {
      "PART NO": 246,
      "PART NAME": "GADHI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING LAHAGARH GADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 772,
      location: "23.39214351,78.14066851",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING LAHAGARH GADI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING LAHAGARH GADI',23.39214351,78.14066851)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING LAHAGARH GADI',23.39214351,78.14066851),",
    },
    {
      "PART NO": 247,
      "PART NAME": "GADHI",
      "Name of polling station building":
        "ENTIGRATED GOVT. MIDDLE SCHOOL BUILDING EAST PART GADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 768,
      location: "23.400518,78.158046",
      "inverted one":
        "'ENTIGRATED GOVT. MIDDLE SCHOOL BUILDING EAST PART GADI'",
      cosolideted:
        "ElectionBooth('ENTIGRATED GOVT. MIDDLE SCHOOL BUILDING EAST PART GADI',23.400518,78.158046)",
      "with comma":
        "ElectionBooth('ENTIGRATED GOVT. MIDDLE SCHOOL BUILDING EAST PART GADI',23.400518,78.158046),",
    },
    {
      "PART NO": 248,
      "PART NAME": "GADHI",
      "Name of polling station building": "GOVT.HIGHER SECONDARY SCHOOL GADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 772,
      location: "23.393823,78.142369",
      "inverted one": "'GOVT.HIGHER SECONDARY SCHOOL GADI'",
      cosolideted:
        "ElectionBooth('GOVT.HIGHER SECONDARY SCHOOL GADI',23.393823,78.142369)",
      "with comma":
        "ElectionBooth('GOVT.HIGHER SECONDARY SCHOOL GADI',23.393823,78.142369),",
    },
    {
      "PART NO": 249,
      "PART NAME": "GADHI",
      "Name of polling station building":
        "ENTIGRATED GOVT. MIDDLE SCHOOL BUILDING WEST PART GADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 925,
      location: "23.39598769,78.14404758",
      "inverted one":
        "'ENTIGRATED GOVT. MIDDLE SCHOOL BUILDING WEST PART GADI'",
      cosolideted:
        "ElectionBooth('ENTIGRATED GOVT. MIDDLE SCHOOL BUILDING WEST PART GADI',23.39598769,78.14404758)",
      "with comma":
        "ElectionBooth('ENTIGRATED GOVT. MIDDLE SCHOOL BUILDING WEST PART GADI',23.39598769,78.14404758),",
    },
    {
      "PART NO": 250,
      "PART NAME": "MUDIYA KHEDA",
      "Name of polling station building":
        "INTEGRATED G.M.S. BUILDING MUDIYAKHEDA ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 811,
      location: "23.35483,78.09293222",
      "inverted one": "'INTEGRATED G.M.S. BUILDING MUDIYAKHEDA ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S. BUILDING MUDIYAKHEDA ROOM NO 1',23.35483,78.09293222)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S. BUILDING MUDIYAKHEDA ROOM NO 1',23.35483,78.09293222),",
    },
    {
      "PART NO": 251,
      "PART NAME": "MUDIYA KHEDA",
      "Name of polling station building":
        "INTEGRATED G.M.S. BUILDING MUDIYAKHEDA ROOM NO 2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 649,
      location: "23.343569,78.098531",
      "inverted one": "'INTEGRATED G.M.S. BUILDING MUDIYAKHEDA ROOM NO 2'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S. BUILDING MUDIYAKHEDA ROOM NO 2',23.343569,78.098531)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S. BUILDING MUDIYAKHEDA ROOM NO 2',23.343569,78.098531),",
    },
    {
      "PART NO": 252,
      "PART NAME": "SAGOR",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING SGOR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 466,
      location: "23.359326,78.143652",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING SGOR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SGOR',23.359326,78.143652)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SGOR',23.359326,78.143652),",
    },
    {
      "PART NO": 253,
      "PART NAME": "RASIDPUR",
      "Name of polling station building":
        "GOVT. P.SCHOOL BUILDING RASIDPUR R.NO. 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 713,
      location: "23.331464,78.14474",
      "inverted one": "'GOVT. P.SCHOOL BUILDING RASIDPUR R.NO. 1'",
      cosolideted:
        "ElectionBooth('GOVT. P.SCHOOL BUILDING RASIDPUR R.NO. 1',23.331464,78.14474)",
      "with comma":
        "ElectionBooth('GOVT. P.SCHOOL BUILDING RASIDPUR R.NO. 1',23.331464,78.14474),",
    },
    {
      "PART NO": 254,
      "PART NAME": "JHAMAR",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING JHAMAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 720,
      location: "23.31626,78.162743",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING JHAMAR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING JHAMAR',23.31626,78.162743)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING JHAMAR',23.31626,78.162743),",
    },
    {
      "PART NO": 255,
      "PART NAME": "ANDHIYARI",
      "Name of polling station building":
        "INTEGRATED G.M.S BUILDING ANDHIYARI ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1254,
      location: "23.319225,78.193312",
      "inverted one": "'INTEGRATED G.M.S BUILDING ANDHIYARI ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S BUILDING ANDHIYARI ROOM NO 1',23.319225,78.193312)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S BUILDING ANDHIYARI ROOM NO 1',23.319225,78.193312),",
    },
    {
      "PART NO": 256,
      "PART NAME": "LAVAJHIR",
      "Name of polling station building":
        "INTEGRATED G.M.S. BUILDING LAVAJHIR ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 994,
      location: "23.331622,78.217078",
      "inverted one": "'INTEGRATED G.M.S. BUILDING LAVAJHIR ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S. BUILDING LAVAJHIR ROOM NO 1',23.331622,78.217078)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S. BUILDING LAVAJHIR ROOM NO 1',23.331622,78.217078),",
    },
    {
      "PART NO": 257,
      "PART NAME": "RAMAPURAKALAN",
      "Name of polling station building":
        "GOVT.BOYS P. SCHOOL BUILDING RAMAPURAKALAN R.NO. 01",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 855,
      location: "23.335072,78.239683",
      "inverted one": "'GOVT.BOYS P. SCHOOL BUILDING RAMAPURAKALAN R.NO. 01'",
      cosolideted:
        "ElectionBooth('GOVT.BOYS P. SCHOOL BUILDING RAMAPURAKALAN R.NO. 01',23.335072,78.239683)",
      "with comma":
        "ElectionBooth('GOVT.BOYS P. SCHOOL BUILDING RAMAPURAKALAN R.NO. 01',23.335072,78.239683),",
    },
    {
      "PART NO": 258,
      "PART NAME": "RAMAPURAKALAN",
      "Name of polling station building":
        "INTEGRATED G.M.S. BUILDING RAMPURAKLA ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 920,
      location: "23.335239,78.239876",
      "inverted one": "'INTEGRATED G.M.S. BUILDING RAMPURAKLA ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S. BUILDING RAMPURAKLA ROOM NO 1',23.335239,78.239876)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S. BUILDING RAMPURAKLA ROOM NO 1',23.335239,78.239876),",
    },
    {
      "PART NO": 259,
      "PART NAME": "JUJHARAPUR",
      "Name of polling station building":
        "INTEGRATED G.H.S. BUILDING JUJHARPUR ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1023,
      location: "23.352889,78.175307",
      "inverted one": "'INTEGRATED G.H.S. BUILDING JUJHARPUR ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.H.S. BUILDING JUJHARPUR ROOM NO 1',23.352889,78.175307)",
      "with comma":
        "ElectionBooth('INTEGRATED G.H.S. BUILDING JUJHARPUR ROOM NO 1',23.352889,78.175307),",
    },
    {
      "PART NO": 260,
      "PART NAME": "HINOTIYA KHALSA",
      "Name of polling station building":
        "GOVT.NEW M.S.SCHOOL BUILDING HINOTIYA KHALSA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 902,
      location: "23.364055,78.20613",
      "inverted one": "'GOVT.NEW M.S.SCHOOL BUILDING HINOTIYA KHALSA'",
      cosolideted:
        "ElectionBooth('GOVT.NEW M.S.SCHOOL BUILDING HINOTIYA KHALSA',23.364055,78.20613)",
      "with comma":
        "ElectionBooth('GOVT.NEW M.S.SCHOOL BUILDING HINOTIYA KHALSA',23.364055,78.20613),",
    },
    {
      "PART NO": 261,
      "PART NAME": "GAIHURAS",
      "Name of polling station building": "GOVT.MIDDLE BUILDING GENHURAS",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1159,
      location: "23.392362,78.22284",
      "inverted one": "'GOVT.MIDDLE BUILDING GENHURAS'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE BUILDING GENHURAS',23.392362,78.22284)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE BUILDING GENHURAS',23.392362,78.22284),",
    },
    {
      "PART NO": 262,
      "PART NAME": "BAMHOURI GHAT",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BAMHORI GHAT",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 499,
      location: "23.386791,78.210392",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BAMHORI GHAT'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAMHORI GHAT',23.386791,78.210392)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAMHORI GHAT',23.386791,78.210392),",
    },
    {
      "PART NO": 263,
      "PART NAME": "PATI",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING PATI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 893,
      location: "23.39428348,78.17359964",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING PATI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PATI',23.39428348,78.17359964)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PATI',23.39428348,78.17359964),",
    },
    {
      "PART NO": 264,
      "PART NAME": "TIJALAPUR",
      "Name of polling station building": "NEW ANGANWADI BUILDING TIJALPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 378,
      location: "23.406941,78.208627",
      "inverted one": "'NEW ANGANWADI BUILDING TIJALPUR'",
      cosolideted:
        "ElectionBooth('NEW ANGANWADI BUILDING TIJALPUR',23.406941,78.208627)",
      "with comma":
        "ElectionBooth('NEW ANGANWADI BUILDING TIJALPUR',23.406941,78.208627),",
    },
    {
      "PART NO": 265,
      "PART NAME": "SAHAJPUR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING SAHAJPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 391,
      location: "23.406941,78.208627",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING SAHAJPUR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SAHAJPUR',23.406941,78.208627)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SAHAJPUR',23.406941,78.208627),",
    },
    {
      "PART NO": 266,
      "PART NAME": "KADHAIYA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING KADAIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 781,
      location: "23.40150698,78.15098542",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KADAIYA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KADAIYA',23.40150698,78.15098542)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KADAIYA',23.40150698,78.15098542),",
    },
    {
      "PART NO": 267,
      "PART NAME": "HINOTIYA KHAS",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING HINOUTIYA KHAS",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 830,
      location: "23.3639983,78.20598",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING HINOUTIYA KHAS'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING HINOUTIYA KHAS',23.3639983,78.20598)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING HINOUTIYA KHAS',23.3639983,78.20598),",
    },
    {
      "PART NO": 268,
      "PART NAME": "PENAGAVA",
      "Name of polling station building":
        "INTEGRATED G.M.S BUILDING PENGANVA ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 726,
      location: "23.432535,78.128155",
      "inverted one": "'INTEGRATED G.M.S BUILDING PENGANVA ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S BUILDING PENGANVA ROOM NO 1',23.432535,78.128155)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S BUILDING PENGANVA ROOM NO 1',23.432535,78.128155),",
    },
    {
      "PART NO": 269,
      "PART NAME": "VASADEHI",
      "Name of polling station building": "GRAM PANCHAYAT BUILDING BANSADEHI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 533,
      location: "23.43609116,78.15519079",
      "inverted one": "'GRAM PANCHAYAT BUILDING BANSADEHI'",
      cosolideted:
        "ElectionBooth('GRAM PANCHAYAT BUILDING BANSADEHI',23.43609116,78.15519079)",
      "with comma":
        "ElectionBooth('GRAM PANCHAYAT BUILDING BANSADEHI',23.43609116,78.15519079),",
    },
    {
      "PART NO": 270,
      "PART NAME": "GUNDRAI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING . GUNDARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 442,
      location: "23.4082057,78.22318652",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING . GUNDARI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING . GUNDARI',23.4082057,78.22318652)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING . GUNDARI',23.4082057,78.22318652),",
    },
    {
      "PART NO": 271,
      "PART NAME": "KHAMARIYA GADI",
      "Name of polling station building": "GOVT.MIDDLE SCHOOL KHAMARIYA GADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 684,
      location: "23.45238664,78.13991329",
      "inverted one": "'GOVT.MIDDLE SCHOOL KHAMARIYA GADI'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL KHAMARIYA GADI',23.45238664,78.13991329)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL KHAMARIYA GADI',23.45238664,78.13991329),",
    },
    {
      "PART NO": 272,
      "PART NAME": "BHANAPUR GADHI",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING BHANPUR GADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 628,
      location: "23.46095409,78.17049168",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING BHANPUR GADI'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING BHANPUR GADI',23.46095409,78.17049168)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING BHANPUR GADI',23.46095409,78.17049168),",
    },
    {
      "PART NO": 273,
      "PART NAME": "AGARIYAKALA",
      "Name of polling station building":
        "INTEGRATED G.M.S. BUILDING AGARIYAKLA ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 994,
      location: "23.4823158,78.17546995",
      "inverted one": "'INTEGRATED G.M.S. BUILDING AGARIYAKLA ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S. BUILDING AGARIYAKLA ROOM NO 1',23.4823158,78.17546995)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S. BUILDING AGARIYAKLA ROOM NO 1',23.4823158,78.17546995),",
    },
    {
      "PART NO": 274,
      "PART NAME": "BELNAGADI",
      "Name of polling station building": "GOVT.M.S.B.BELNAGADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 889,
      location: "23.50477159,78.14944468",
      "inverted one": "'GOVT.M.S.B.BELNAGADI'",
      cosolideted:
        "ElectionBooth('GOVT.M.S.B.BELNAGADI',23.50477159,78.14944468)",
      "with comma":
        "ElectionBooth('GOVT.M.S.B.BELNAGADI',23.50477159,78.14944468),",
    },
    {
      "PART NO": 275,
      "PART NAME": "UDADAMAU",
      "Name of polling station building":
        "INTEGRATED G.M.S. BUILDING UARADMAU ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1044,
      location: "23.49864843,78.1931007",
      "inverted one": "'INTEGRATED G.M.S. BUILDING UARADMAU ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S. BUILDING UARADMAU ROOM NO 1',23.49864843,78.1931007)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S. BUILDING UARADMAU ROOM NO 1',23.49864843,78.1931007),",
    },
    {
      "PART NO": 276,
      "PART NAME": "DEVARIGADHI",
      "Name of polling station building":
        "INTEGRATED G.M.S. BUILDING DEVRIGADI ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 578,
      location: "23.40824839,78.22314211",
      "inverted one": "'INTEGRATED G.M.S. BUILDING DEVRIGADI ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S. BUILDING DEVRIGADI ROOM NO 1',23.40824839,78.22314211)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S. BUILDING DEVRIGADI ROOM NO 1',23.40824839,78.22314211),",
    },
    {
      "PART NO": 277,
      "PART NAME": "BAMHORI GODAD",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING BAMHORI GODAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1133,
      location: "23.474535,78.22636",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING BAMHORI GODAR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BAMHORI GODAR',23.474535,78.22636)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BAMHORI GODAR',23.474535,78.22636),",
    },
    {
      "PART NO": 278,
      "PART NAME": "SIHAPUR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING SINGHPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 686,
      location: "23.49335011,78.23708307",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING SINGHPUR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SINGHPUR',23.49335011,78.23708307)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SINGHPUR',23.49335011,78.23708307),",
    },
    {
      "PART NO": 279,
      "PART NAME": "PATAN",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING PATAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 858,
      location: "23.51027768,78.24953935",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING PATAN'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PATAN',23.51027768,78.24953935)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PATAN',23.51027768,78.24953935),",
    },
    {
      "PART NO": 280,
      "PART NAME": "HARADOUT",
      "Name of polling station building":
        "INTEGRATED G.M.S. BUILDING HARDOUT ROOM NO 1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 842,
      location: "23.51599833,78.26612167",
      "inverted one": "'INTEGRATED G.M.S. BUILDING HARDOUT ROOM NO 1'",
      cosolideted:
        "ElectionBooth('INTEGRATED G.M.S. BUILDING HARDOUT ROOM NO 1',23.51599833,78.26612167)",
      "with comma":
        "ElectionBooth('INTEGRATED G.M.S. BUILDING HARDOUT ROOM NO 1',23.51599833,78.26612167),",
    },
    {
      "PART NO": 281,
      "PART NAME": "HARADOUT",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING HARDOUT R.NO.2",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 740,
      location: "23.51552302,78.26693833",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING HARDOUT R.NO.2'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING HARDOUT R.NO.2',23.51552302,78.26693833)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING HARDOUT R.NO.2',23.51552302,78.26693833),",
    },
    {
      "PART NO": 282,
      "PART NAME": "HARADOUT",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING HARDOUT R.NO.3",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1082,
      location: "23.516051,78.266343",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING HARDOUT R.NO.3'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING HARDOUT R.NO.3',23.516051,78.266343)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING HARDOUT R.NO.3',23.516051,78.266343),",
    },
    {
      "PART NO": 283,
      "PART NAME": "MURAPAR",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING MURPAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 997,
      location: "23.4909545,78.290751",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING MURPAR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING MURPAR',23.4909545,78.290751)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING MURPAR',23.4909545,78.290751),",
    },
    {
      "PART NO": 284,
      "PART NAME": "ALAMAPUR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING ALAMPUR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 907,
      location: "23.40174833,78.20379667",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING ALAMPUR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING ALAMPUR R.NO.1',23.40174833,78.20379667)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING ALAMPUR R.NO.1',23.40174833,78.20379667),",
    },
    {
      "PART NO": 285,
      "PART NAME": "BEENAPUR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING BEENAPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 388,
      location: "23.46317,78.26381",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING BEENAPUR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BEENAPUR',23.46317,78.26381)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BEENAPUR',23.46317,78.26381),",
    },
    {
      "PART NO": 286,
      "PART NAME": "CHANDONIGANJ",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING CHANDONIGANJ",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 791,
      location: "23.43234,78.24837",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING CHANDONIGANJ'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHANDONIGANJ',23.43234,78.24837)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHANDONIGANJ',23.43234,78.24837),",
    },
    {
      "PART NO": 287,
      "PART NAME": "VANDOLI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING BANDOLI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 418,
      location: "23.4446666,78.22686666",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING BANDOLI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BANDOLI R.NO.1',23.4446666,78.22686666)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING BANDOLI R.NO.1',23.4446666,78.22686666),",
    },
    {
      "PART NO": 288,
      "PART NAME": "CHHIRAKHEDA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING CHHEERKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 755,
      location: "23.454688,78.235611",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING CHHEERKHEDI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING CHHEERKHEDI',23.454688,78.235611)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING CHHEERKHEDI',23.454688,78.235611),",
    },
    {
      "PART NO": 289,
      "PART NAME": "USARMETA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING USARMETA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 836,
      location: "23.439595,78.199228",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING USARMETA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING USARMETA R.NO.1',23.439595,78.199228)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING USARMETA R.NO.1',23.439595,78.199228),",
    },
    {
      "PART NO": 290,
      "PART NAME": "CHANDONI GADHI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING CHANDONI GADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 565,
      location: "23.4233826,78.192411",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING CHANDONI GADI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING CHANDONI GADI',23.4233826,78.192411)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING CHANDONI GADI',23.4233826,78.192411),",
    },
    {
      "PART NO": 291,
      "PART NAME": "GAIRATAPUR",
      "Name of polling station building":
        "GOVT.NEW MIDDLE SCHOOL BUILDING GAIRATPUR",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1187,
      location: "23.41937,78.240208",
      "inverted one": "'GOVT.NEW MIDDLE SCHOOL BUILDING GAIRATPUR'",
      cosolideted:
        "ElectionBooth('GOVT.NEW MIDDLE SCHOOL BUILDING GAIRATPUR',23.41937,78.240208)",
      "with comma":
        "ElectionBooth('GOVT.NEW MIDDLE SCHOOL BUILDING GAIRATPUR',23.41937,78.240208),",
    },
    {
      "PART NO": 292,
      "PART NAME": "TEKAPAR GADHI",
      "Name of polling station building":
        "GOVT.NEW PRIMARY SCHOOL BUILDING TEKAPAR GADI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1179,
      location: "23.407595,78.207732",
      "inverted one": "'GOVT.NEW PRIMARY SCHOOL BUILDING TEKAPAR GADI'",
      cosolideted:
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING TEKAPAR GADI',23.407595,78.207732)",
      "with comma":
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING TEKAPAR GADI',23.407595,78.207732),",
    },
    {
      "PART NO": 293,
      "PART NAME": "TEKAPAR GADHI",
      "Name of polling station building": "JANPAD PANCHAYAT GAIRATGANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 822,
      location: "23.410514,78.220225",
      "inverted one": "'JANPAD PANCHAYAT GAIRATGANJ'",
      cosolideted:
        "ElectionBooth('JANPAD PANCHAYAT GAIRATGANJ',23.410514,78.220225)",
      "with comma":
        "ElectionBooth('JANPAD PANCHAYAT GAIRATGANJ',23.410514,78.220225),",
    },
    {
      "PART NO": 294,
      "PART NAME": "TEKAPAR GADHI",
      "Name of polling station building":
        "GOVT.EXTRA ROOM JANPAD PANCHAYAT GAIRATGANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 858,
      location: "23.407332,78.219",
      "inverted one": "'GOVT.EXTRA ROOM JANPAD PANCHAYAT GAIRATGANJ'",
      cosolideted:
        "ElectionBooth('GOVT.EXTRA ROOM JANPAD PANCHAYAT GAIRATGANJ',23.407332,78.219)",
      "with comma":
        "ElectionBooth('GOVT.EXTRA ROOM JANPAD PANCHAYAT GAIRATGANJ',23.407332,78.219),",
    },
    {
      "PART NO": 295,
      "PART NAME": "TEKAPAR GADHI",
      "Name of polling station building":
        "GOVT.VIPRAN SANGH SEVA GOVT. SANSTHA TEKAPAAR GADI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1118,
      location: "23.407332,78.219",
      "inverted one": "'GOVT.VIPRAN SANGH SEVA GOVT. SANSTHA TEKAPAAR GADI'",
      cosolideted:
        "ElectionBooth('GOVT.VIPRAN SANGH SEVA GOVT. SANSTHA TEKAPAAR GADI',23.407332,78.219)",
      "with comma":
        "ElectionBooth('GOVT.VIPRAN SANGH SEVA GOVT. SANSTHA TEKAPAAR GADI',23.407332,78.219),",
    },
    {
      "PART NO": 296,
      "PART NAME": "GAIRATAGANJ",
      "Name of polling station building":
        "GOVT.GIRLS PRIMARY SCHOOL BUILDING GAIRATGANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 888,
      location: "23.410242,78.220864",
      "inverted one": "'GOVT.GIRLS PRIMARY SCHOOL BUILDING GAIRATGANJ'",
      cosolideted:
        "ElectionBooth('GOVT.GIRLS PRIMARY SCHOOL BUILDING GAIRATGANJ',23.410242,78.220864)",
      "with comma":
        "ElectionBooth('GOVT.GIRLS PRIMARY SCHOOL BUILDING GAIRATGANJ',23.410242,78.220864),",
    },
    {
      "PART NO": 297,
      "PART NAME": "GAIRATAGANJ",
      "Name of polling station building":
        "INTEGRETED GOVT.NEW BOYS MIDDLE SCHOOL BUILDING GAIRATGANJ ROOM NO.1",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1142,
      location: "23.409546,78.221071",
      "inverted one":
        "'INTEGRETED GOVT.NEW BOYS MIDDLE SCHOOL BUILDING GAIRATGANJ ROOM NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.NEW BOYS MIDDLE SCHOOL BUILDING GAIRATGANJ ROOM NO.1',23.409546,78.221071)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.NEW BOYS MIDDLE SCHOOL BUILDING GAIRATGANJ ROOM NO.1',23.409546,78.221071),",
    },
    {
      "PART NO": 298,
      "PART NAME": "GAIRATAGANJ",
      "Name of polling station building":
        "GOVT.GIRL MIDDEL SCHOOL BUILDING GAIRATGANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1117,
      location: "23.410242,78.220864",
      "inverted one": "'GOVT.GIRL MIDDEL SCHOOL BUILDING GAIRATGANJ'",
      cosolideted:
        "ElectionBooth('GOVT.GIRL MIDDEL SCHOOL BUILDING GAIRATGANJ',23.410242,78.220864)",
      "with comma":
        "ElectionBooth('GOVT.GIRL MIDDEL SCHOOL BUILDING GAIRATGANJ',23.410242,78.220864),",
    },
    {
      "PART NO": 299,
      "PART NAME": "GAIRATAGANJ",
      "Name of polling station building":
        "EXCELLENCE H.S.SCHOOL (SOUTH PART) GAIRATGANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 691,
      location: "23.409234,78.225572",
      "inverted one": "'EXCELLENCE H.S.SCHOOL (SOUTH PART) GAIRATGANJ'",
      cosolideted:
        "ElectionBooth('EXCELLENCE H.S.SCHOOL (SOUTH PART) GAIRATGANJ',23.409234,78.225572)",
      "with comma":
        "ElectionBooth('EXCELLENCE H.S.SCHOOL (SOUTH PART) GAIRATGANJ',23.409234,78.225572),",
    },
    {
      "PART NO": 300,
      "PART NAME": "GAIRATAGANJ",
      "Name of polling station building":
        "GOVT. EXCELLENCE HIGHER SECONDARY SCHOOL GAIRATGANJ WEST PART",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 662,
      location: "23.409213,78.225593",
      "inverted one":
        "'GOVT. EXCELLENCE HIGHER SECONDARY SCHOOL GAIRATGANJ WEST PART'",
      cosolideted:
        "ElectionBooth('GOVT. EXCELLENCE HIGHER SECONDARY SCHOOL GAIRATGANJ WEST PART',23.409213,78.225593)",
      "with comma":
        "ElectionBooth('GOVT. EXCELLENCE HIGHER SECONDARY SCHOOL GAIRATGANJ WEST PART',23.409213,78.225593),",
    },
    {
      "PART NO": 301,
      "PART NAME": "GAIRATAGANJ",
      "Name of polling station building": "OFFICE OF THE BRCC.GAIRATGANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 741,
      location: "23.409816,78.22571",
      "inverted one": "'OFFICE OF THE BRCC.GAIRATGANJ'",
      cosolideted:
        "ElectionBooth('OFFICE OF THE BRCC.GAIRATGANJ',23.409816,78.22571)",
      "with comma":
        "ElectionBooth('OFFICE OF THE BRCC.GAIRATGANJ',23.409816,78.22571),",
    },
    {
      "PART NO": 302,
      "PART NAME": "GAIRATAGANJ",
      "Name of polling station building": "GOVT.SENIOR GIRLS HOSTEL GAIRATGANJ",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1009,
      location: "23.415306,78.2297741",
      "inverted one": "'GOVT.SENIOR GIRLS HOSTEL GAIRATGANJ'",
      cosolideted:
        "ElectionBooth('GOVT.SENIOR GIRLS HOSTEL GAIRATGANJ',23.415306,78.2297741)",
      "with comma":
        "ElectionBooth('GOVT.SENIOR GIRLS HOSTEL GAIRATGANJ',23.415306,78.2297741),",
    },
    {
      "PART NO": 303,
      "PART NAME": "GAIRATAGANJ",
      "Name of polling station building":
        "GOVT.SHIKSHA GARANTI SCHOOL GAIRATGANJ (PATHA)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 907,
      location: "23.415467,78.222408",
      "inverted one": "'GOVT.SHIKSHA GARANTI SCHOOL GAIRATGANJ (PATHA)'",
      cosolideted:
        "ElectionBooth('GOVT.SHIKSHA GARANTI SCHOOL GAIRATGANJ (PATHA)',23.415467,78.222408)",
      "with comma":
        "ElectionBooth('GOVT.SHIKSHA GARANTI SCHOOL GAIRATGANJ (PATHA)',23.415467,78.222408),",
    },
    {
      "PART NO": 304,
      "PART NAME": "BHANAPURAGANJ",
      "Name of polling station building":
        "GOVT.NEW PRIMARY SCHOOL BUILDING . BHANPUR GANJ",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 981,
      location: "23.393328,78.269725",
      "inverted one": "'GOVT.NEW PRIMARY SCHOOL BUILDING . BHANPUR GANJ'",
      cosolideted:
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING . BHANPUR GANJ',23.393328,78.269725)",
      "with comma":
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING . BHANPUR GANJ',23.393328,78.269725),",
    },
    {
      "PART NO": 305,
      "PART NAME": "DHANGAWAN",
      "Name of polling station building":
        "GOVT.NEW PRIMARY SCHOOL BUILDING . DHANGANVA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 971,
      location: "23.406378,78.241857",
      "inverted one": "'GOVT.NEW PRIMARY SCHOOL BUILDING . DHANGANVA'",
      cosolideted:
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING . DHANGANVA',23.406378,78.241857)",
      "with comma":
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING . DHANGANVA',23.406378,78.241857),",
    },
    {
      "PART NO": 306,
      "PART NAME": "GHANA",
      "Name of polling station building":
        "GOVT.NEW MIDDLE SCHOOL BUILDING GHANA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 944,
      location: "23.37029,78.219023",
      "inverted one": "'GOVT.NEW MIDDLE SCHOOL BUILDING GHANA'",
      cosolideted:
        "ElectionBooth('GOVT.NEW MIDDLE SCHOOL BUILDING GHANA',23.37029,78.219023)",
      "with comma":
        "ElectionBooth('GOVT.NEW MIDDLE SCHOOL BUILDING GHANA',23.37029,78.219023),",
    },
    {
      "PART NO": 307,
      "PART NAME": "KHAMARIYA GANJ",
      "Name of polling station building":
        "GOVT.NEW PRIMARY SCHOOL BUILDING . KHAMARIYA GANJ",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 586,
      location: "23.37589427,78.25381674",
      "inverted one": "'GOVT.NEW PRIMARY SCHOOL BUILDING . KHAMARIYA GANJ'",
      cosolideted:
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING . KHAMARIYA GANJ',23.37589427,78.25381674)",
      "with comma":
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING . KHAMARIYA GANJ',23.37589427,78.25381674),",
    },
    {
      "PART NO": 308,
      "PART NAME": "SAMANAPURAKANLA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING SAMNAPUR KALAN R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 913,
      location: "23.356649,78263681",
      "inverted one":
        "'INTEGRETED GOVT.M.SCHOOL BUILDING SAMNAPUR KALAN R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SAMNAPUR KALAN R.NO.1',23.356649,78263681)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SAMNAPUR KALAN R.NO.1',23.356649,78263681),",
    },
    {
      "PART NO": 309,
      "PART NAME": "JAMUNIYAKALA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING JAMUNIYAKLA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 973,
      location: "23.356328,78.263724",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING JAMUNIYAKLA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING JAMUNIYAKLA',23.356328,78.263724)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING JAMUNIYAKLA',23.356328,78.263724),",
    },
    {
      "PART NO": 310,
      "PART NAME": "VILAVANI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING BILBANI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 761,
      location: "23.34555982,78.25426908",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING BILBANI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BILBANI',23.34555982,78.25426908)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING BILBANI',23.34555982,78.25426908),",
    },
    {
      "PART NO": 311,
      "PART NAME": "SARRA",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING SARRA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 748,
      location: "23.35015,78.295156",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING SARRA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SARRA',23.35015,78.295156)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SARRA',23.35015,78.295156),",
    },
    {
      "PART NO": 312,
      "PART NAME": "SIHORAKHURD",
      "Name of polling station building":
        "GOVT.NEW MIDDLE SCHOOL BUILDING SEEHORA KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 785,
      location: "23.369717,78.309717",
      "inverted one": "'GOVT.NEW MIDDLE SCHOOL BUILDING SEEHORA KHURD'",
      cosolideted:
        "ElectionBooth('GOVT.NEW MIDDLE SCHOOL BUILDING SEEHORA KHURD',23.369717,78.309717)",
      "with comma":
        "ElectionBooth('GOVT.NEW MIDDLE SCHOOL BUILDING SEEHORA KHURD',23.369717,78.309717),",
    },
    {
      "PART NO": 313,
      "PART NAME": "KARAMODI",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING KARMODI R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 703,
      location: "23.353721,78.323945",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING KARMODI R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING KARMODI R.NO.1',23.353721,78.323945)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING KARMODI R.NO.1',23.353721,78.323945),",
    },
    {
      "PART NO": 314,
      "PART NAME": "KHUMARI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KHUMARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 643,
      location: "23.37587,78.33526",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KHUMARI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHUMARI',23.37587,78.33526)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHUMARI',23.37587,78.33526),",
    },
    {
      "PART NO": 315,
      "PART NAME": "DEVARIGANJ",
      "Name of polling station building":
        "INTEGRETED GOVT. H SCHOOL BUILDING DEORIGANJ R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 590,
      location: "23.393733,78.335934",
      "inverted one": "'INTEGRETED GOVT. H SCHOOL BUILDING DEORIGANJ R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING DEORIGANJ R.NO.1',23.393733,78.335934)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H SCHOOL BUILDING DEORIGANJ R.NO.1',23.393733,78.335934),",
    },
    {
      "PART NO": 316,
      "PART NAME": "MADANAPUR",
      "Name of polling station building":
        "GOVT.NEW PRIMARY SCHOOL BUILDING MADANPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 880,
      location: "23.40743532,78.30847085",
      "inverted one": "'GOVT.NEW PRIMARY SCHOOL BUILDING MADANPUR'",
      cosolideted:
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING MADANPUR',23.40743532,78.30847085)",
      "with comma":
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING MADANPUR',23.40743532,78.30847085),",
    },
    {
      "PART NO": 317,
      "PART NAME": "PADARIYAGANJ",
      "Name of polling station building":
        "GOVT.NEW HIGH SCHOOL BUILDING PADARIYAGANJ",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 979,
      location: "23.42480478,78.28083968",
      "inverted one": "'GOVT.NEW HIGH SCHOOL BUILDING PADARIYAGANJ'",
      cosolideted:
        "ElectionBooth('GOVT.NEW HIGH SCHOOL BUILDING PADARIYAGANJ',23.42480478,78.28083968)",
      "with comma":
        "ElectionBooth('GOVT.NEW HIGH SCHOOL BUILDING PADARIYAGANJ',23.42480478,78.28083968),",
    },
    {
      "PART NO": 318,
      "PART NAME": "PAPADA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING PAPDA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 977,
      location: "23.44795,78.289119",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING PAPDA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING PAPDA R.NO.1',23.44795,78.289119)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING PAPDA R.NO.1',23.44795,78.289119),",
    },
    {
      "PART NO": 319,
      "PART NAME": "SAIDAPUR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING SAIDPUR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 898,
      location: "23.426127,78.324019",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING SAIDPUR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SAIDPUR R.NO.1',23.426127,78.324019)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SAIDPUR R.NO.1',23.426127,78.324019),",
    },
    {
      "PART NO": 320,
      "PART NAME": "KHAMKHEDA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING KHAMKHEDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 426,
      location: "23.4121781,78.32633961",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KHAMKHEDA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KHAMKHEDA',23.4121781,78.32633961)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KHAMKHEDA',23.4121781,78.32633961),",
    },
    {
      "PART NO": 321,
      "PART NAME": "TEHARI MURAPAR",
      "Name of polling station building":
        "GOVT.MIDDLE SCHOOL BUILDING TEHRI MURPAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 952,
      location: "23.47084664,78.31207845",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING TEHRI MURPAR'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING TEHRI MURPAR',23.47084664,78.31207845)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING TEHRI MURPAR',23.47084664,78.31207845),",
    },
    {
      "PART NO": 322,
      "PART NAME": "KARHOLA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING KARHOLA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 577,
      location: "23.4679793,78.3523701",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KARHOLA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KARHOLA',23.4679793,78.3523701)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KARHOLA',23.4679793,78.3523701),",
    },
    {
      "PART NO": 323,
      "PART NAME": "PIPALIYA AMARSINGH",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING PIPALIYA AMARSINGH",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 611,
      location: "23.44927252,78.38380926",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING PIPALIYA AMARSINGH'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PIPALIYA AMARSINGH',23.44927252,78.38380926)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PIPALIYA AMARSINGH',23.44927252,78.38380926),",
    },
    {
      "PART NO": 324,
      "PART NAME": "SHOBHAPUR",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING SHOBHAPUR R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 733,
      location: "23.43547955,78.39614857",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING SHOBHAPUR R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SHOBHAPUR R.NO.1',23.43547955,78.39614857)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING SHOBHAPUR R.NO.1',23.43547955,78.39614857),",
    },
    {
      "PART NO": 325,
      "PART NAME": "RAJPURA",
      "Name of polling station building":
        "INTEGRETED GOVT.M.SCHOOL BUILDING RAJPURA R.NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1127,
      location: "23.4308285,78.35544359",
      "inverted one": "'INTEGRETED GOVT.M.SCHOOL BUILDING RAJPURA R.NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING RAJPURA R.NO.1',23.4308285,78.35544359)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT.M.SCHOOL BUILDING RAJPURA R.NO.1',23.4308285,78.35544359),",
    },
    {
      "PART NO": 326,
      "PART NAME": "KAHULA",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING KAHULA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 676,
      location: "23.41623454,78.3640935",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KAHULA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KAHULA',23.41623454,78.3640935)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KAHULA',23.41623454,78.3640935),",
    },
    {
      "PART NO": 327,
      "PART NAME": "MAHUNA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING . MAHUNA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 500,
      location: "23.3904363,78.35772419",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING . MAHUNA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING . MAHUNA',23.3904363,78.35772419)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING . MAHUNA',23.3904363,78.35772419),",
    },
    {
      "PART NO": 328,
      "PART NAME": "MEHAGANVA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING MEHGANVA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 457,
      location: "23.40859035,78.22286894",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING MEHGANVA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MEHGANVA',23.40859035,78.22286894)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MEHGANVA',23.40859035,78.22286894),",
    },
    {
      "PART NO": 329,
      "PART NAME": "TEKAPAR KHODI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING TEKAPARKHORI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 870,
      location: "23.38965532,78.39532172",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING TEKAPARKHORI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING TEKAPARKHORI',23.38965532,78.39532172)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING TEKAPARKHORI',23.38965532,78.39532172),",
    },
    {
      "PART NO": 330,
      "PART NAME": "GORKHA",
      "Name of polling station building": "PANCHAYAT BUILDING GORKHA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 783,
      location: "23.43103165,78.40981844",
      "inverted one": "'PANCHAYAT BUILDING GORKHA'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING GORKHA',23.43103165,78.40981844)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING GORKHA',23.43103165,78.40981844),",
    },
    {
      "PART NO": 331,
      "PART NAME": "SODARPUR",
      "Name of polling station building":
        "INTEGRETED GOVT. H S SCHOOL BUILDING SODARPUR .NO.1",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1263,
      location: "23.39462109,78.41816607",
      "inverted one": "'INTEGRETED GOVT. H S SCHOOL BUILDING SODARPUR .NO.1'",
      cosolideted:
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING SODARPUR .NO.1',23.39462109,78.41816607)",
      "with comma":
        "ElectionBooth('INTEGRETED GOVT. H S SCHOOL BUILDING SODARPUR .NO.1',23.39462109,78.41816607),",
    },
    {
      "PART NO": 332,
      "PART NAME": "SINGHAD",
      "Name of polling station building":
        "GOVT.NEW PRIMARY SCHOOL BUILDING SIGHAD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 450,
      location: "23.3932371,78.420769",
      "inverted one": "'GOVT.NEW PRIMARY SCHOOL BUILDING SIGHAD'",
      cosolideted:
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING SIGHAD',23.3932371,78.420769)",
      "with comma":
        "ElectionBooth('GOVT.NEW PRIMARY SCHOOL BUILDING SIGHAD',23.3932371,78.420769),",
    },
  ],
  "143 Silwani": [
    {
      "PART NO": 1,
      "PART NAME": "KHAJURIYA BARA GADI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING KHAJURIYA BARAMAD GADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 972,
      location: "23.6980083,78.35911244",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KHAJURIYA BARAMAD GADI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KHAJURIYA BARAMAD GADI',23.6980083,78.35911244)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KHAJURIYA BARAMAD GADI',23.6980083,78.35911244),",
    },
    {
      "PART NO": 2,
      "PART NAME": "JHIRIYA BARAMAD GADHI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING JHIRIYA BARAMAD GADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 439,
      location: "23.71622442,78.36736304",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING JHIRIYA BARAMAD GADI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING JHIRIYA BARAMAD GADI',23.71622442,78.36736304)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING JHIRIYA BARAMAD GADI',23.71622442,78.36736304),",
    },
    {
      "PART NO": 3,
      "PART NAME": "MADHIYA MAHUAKHEDA",
      "Name of polling station building": "G.P.S Bhawan Madhiya Mahuakheda",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 895,
      location: "23.73461587,78.40206197",
      "inverted one": "'G.P.S Bhawan Madhiya Mahuakheda'",
      cosolideted:
        "ElectionBooth('G.P.S Bhawan Madhiya Mahuakheda',23.73461587,78.40206197)",
      "with comma":
        "ElectionBooth('G.P.S Bhawan Madhiya Mahuakheda',23.73461587,78.40206197),",
    },
    {
      "PART NO": 4,
      "PART NAME": "CHANDAMAU",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING CHANDAMAU",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 429,
      location: "23.73468125,78.40208552",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING CHANDAMAU'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHANDAMAU',23.73468125,78.40208552)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHANDAMAU',23.73468125,78.40208552),",
    },
    {
      "PART NO": 5,
      "PART NAME": "BHURERU",
      "Name of polling station building": "Integrated G.M.S Bhawan Bhureru",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 958,
      location: "23.70709338,78.42874647",
      "inverted one": "'Integrated G.M.S Bhawan Bhureru'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Bhureru',23.70709338,78.42874647)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Bhureru',23.70709338,78.42874647),",
    },
    {
      "PART NO": 6,
      "PART NAME": "KALYANPUR",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING KALYANPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 447,
      location: "23.68820728,78.41944199",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING KALYANPUR'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING KALYANPUR',23.68820728,78.41944199)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING KALYANPUR',23.68820728,78.41944199),",
    },
    {
      "PART NO": 7,
      "PART NAME": "KASBA CHOUKA",
      "Name of polling station building":
        "Integrated G.B.P.S Bhawan Kasba Chouka",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 704,
      location: "23.68718279,78.43284971",
      "inverted one": "'Integrated G.B.P.S Bhawan Kasba Chouka'",
      cosolideted:
        "ElectionBooth('Integrated G.B.P.S Bhawan Kasba Chouka',23.68718279,78.43284971)",
      "with comma":
        "ElectionBooth('Integrated G.B.P.S Bhawan Kasba Chouka',23.68718279,78.43284971),",
    },
    {
      "PART NO": 8,
      "PART NAME": "JHIRIYA TA CHOUKA",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Jhiria Ta.Chouka",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 429,
      location: "23.68718279,78.43284971",
      "inverted one": "'Integrated G.M.S Bhawan Jhiria Ta.Chouka'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Jhiria Ta.Chouka',23.68718279,78.43284971)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Jhiria Ta.Chouka',23.68718279,78.43284971),",
    },
    {
      "PART NO": 9,
      "PART NAME": "SEMRA",
      "Name of polling station building":
        "Integrated School Building Additional Room Semra",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 477,
      location: "23.66386029,78.41542828",
      "inverted one": "'Integrated School Building Additional Room Semra'",
      cosolideted:
        "ElectionBooth('Integrated School Building Additional Room Semra',23.66386029,78.41542828)",
      "with comma":
        "ElectionBooth('Integrated School Building Additional Room Semra',23.66386029,78.41542828),",
    },
    {
      "PART NO": 10,
      "PART NAME": "SUNEHRA",
      "Name of polling station building":
        "Integrated G.H.S Sunehra-Western Part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 789,
      location: "23.5563095,78.4861387",
      "inverted one": "'Integrated G.H.S Sunehra-Western Part'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Sunehra-Western Part',23.5563095,78.4861387)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Sunehra-Western Part',23.5563095,78.4861387),",
    },
    {
      "PART NO": 11,
      "PART NAME": "SUNEHRA",
      "Name of polling station building":
        "Integrated High School Sunehara-North Part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 594,
      location: "23.59341499,78.33748666",
      "inverted one": "'Integrated High School Sunehara-North Part'",
      cosolideted:
        "ElectionBooth('Integrated High School Sunehara-North Part',23.59341499,78.33748666)",
      "with comma":
        "ElectionBooth('Integrated High School Sunehara-North Part',23.59341499,78.33748666),",
    },
    {
      "PART NO": 12,
      "PART NAME": "BHAISWAI KALA",
      "Name of polling station building":
        "Integrated G.M.S Building Bhainswai Kala",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 994,
      location: "23.5934133,78.3376066",
      "inverted one": "'Integrated G.M.S Building Bhainswai Kala'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Building Bhainswai Kala',23.5934133,78.3376066)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Building Bhainswai Kala',23.5934133,78.3376066),",
    },
    {
      "PART NO": 13,
      "PART NAME": "HAPSILI",
      "Name of polling station building": "Integrated G.M.S Bhavan Hapsili",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 782,
      location: "23.59302,78.3378083",
      "inverted one": "'Integrated G.M.S Bhavan Hapsili'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhavan Hapsili',23.59302,78.3378083)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhavan Hapsili',23.59302,78.3378083),",
    },
    {
      "PART NO": 14,
      "PART NAME": "MADIYA NIWARI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING MADIYA NIWARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 617,
      location: "23.59326833,78.33769",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING MADIYA NIWARI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MADIYA NIWARI',23.59326833,78.33769)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MADIYA NIWARI',23.59326833,78.33769),",
    },
    {
      "PART NO": 15,
      "PART NAME": "SUMER",
      "Name of polling station building": "Integrated G.H.S Building Sumer",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 921,
      location: "23.636344,78.3553272",
      "inverted one": "'Integrated G.H.S Building Sumer'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Building Sumer',23.636344,78.3553272)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Building Sumer',23.636344,78.3553272),",
    },
    {
      "PART NO": 16,
      "PART NAME": "DEVLAPUR",
      "Name of polling station building": "Integrated G.M.S Bhawan Devlapur",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 499,
      location: "23.64683798,78.37967628",
      "inverted one": "'Integrated G.M.S Bhawan Devlapur'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Devlapur',23.64683798,78.37967628)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Devlapur',23.64683798,78.37967628),",
    },
    {
      "PART NO": 17,
      "PART NAME": "BERKHEDI B. GADHI",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Berkhedi B.g.",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 658,
      location: "23.63762809,78.609717",
      "inverted one": "'Integrated G.M.S Bhawan Berkhedi B.g.'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Berkhedi B.g.',23.63762809,78.609717)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Berkhedi B.g.',23.63762809,78.609717),",
    },
    {
      "PART NO": 18,
      "PART NAME": "KOKALPUR",
      "Name of polling station building": "Integrated G.M.S. Building Kokalpur",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 993,
      location: "23.65941366,78.32505546",
      "inverted one": "'Integrated G.M.S. Building Kokalpur'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Building Kokalpur',23.65941366,78.32505546)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Building Kokalpur',23.65941366,78.32505546),",
    },
    {
      "PART NO": 19,
      "PART NAME": "PEELPHADI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOLBUILDING PEELPAHADI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 805,
      location: "23.647432,78.314313",
      "inverted one": "'GOVT. PRIMARY SCHOOLBUILDING PEELPAHADI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOLBUILDING PEELPAHADI',23.647432,78.314313)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOLBUILDING PEELPAHADI',23.647432,78.314313),",
    },
    {
      "PART NO": 20,
      "PART NAME": "GOPAALPUR VARAMAD GADHI",
      "Name of polling station building":
        "Integrated G.M.S. Bhawan Gopalpur Baramad Gadhi",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 605,
      location: "23.63255455,78.30352812",
      "inverted one": "'Integrated G.M.S. Bhawan Gopalpur Baramad Gadhi'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Gopalpur Baramad Gadhi',23.63255455,78.30352812)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Gopalpur Baramad Gadhi',23.63255455,78.30352812),",
    },
    {
      "PART NO": 21,
      "PART NAME": "MALA",
      "Name of polling station building": "GOVT. PRIMARY SCHOOLBUILDING MALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 813,
      location: "23.6208867,78.3253867",
      "inverted one": "'GOVT. PRIMARY SCHOOLBUILDING MALA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOLBUILDING MALA',23.6208867,78.3253867)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOLBUILDING MALA',23.6208867,78.3253867),",
    },
    {
      "PART NO": 22,
      "PART NAME": "CHANDOURIYA",
      "Name of polling station building": "Integrated G.M.S Bhawan Chandauria",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1052,
      location: "23.6069098,78.30599317",
      "inverted one": "'Integrated G.M.S Bhawan Chandauria'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Chandauria',23.6069098,78.30599317)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Chandauria',23.6069098,78.30599317),",
    },
    {
      "PART NO": 23,
      "PART NAME": "DHIMROLI",
      "Name of polling station building": "Integrated G.M.S Bhawan Dhimrauli",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 830,
      location: "23.55811333,78.26970333",
      "inverted one": "'Integrated G.M.S Bhawan Dhimrauli'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Dhimrauli',23.55811333,78.26970333)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Dhimrauli',23.55811333,78.26970333),",
    },
    {
      "PART NO": 24,
      "PART NAME": "MUDALA CHAWAL",
      "Name of polling station building":
        "Govt.High School Bhawan Mundla Chawal",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1051,
      location: "23.5581183333333,78.2697016666666",
      "inverted one": "'Govt.High School Bhawan Mundla Chawal'",
      cosolideted:
        "ElectionBooth('Govt.High School Bhawan Mundla Chawal',23.5581183333333,78.2697016666666)",
      "with comma":
        "ElectionBooth('Govt.High School Bhawan Mundla Chawal',23.5581183333333,78.2697016666666),",
    },
    {
      "PART NO": 25,
      "PART NAME": "VINYAKPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING GEHUNRAS",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 458,
      location: "23.54981,78.27165833",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING GEHUNRAS'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GEHUNRAS',23.54981,78.27165833)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GEHUNRAS',23.54981,78.27165833),",
    },
    {
      "PART NO": 26,
      "PART NAME": "GEHURAAS",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING GEHUNRAS",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 932,
      location: "23.5326366666666,78.2585333333333",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING GEHUNRAS'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GEHUNRAS',23.5326366666666,78.2585333333333)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GEHUNRAS',23.5326366666666,78.2585333333333),",
    },
    {
      "PART NO": 27,
      "PART NAME": "PALOHA",
      "Name of polling station building": "Integrated G.M.S Bhawan Paloha",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 548,
      location: "23.53115633,78.29675007",
      "inverted one": "'Integrated G.M.S Bhawan Paloha'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Paloha',23.53115633,78.29675007)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Paloha',23.53115633,78.29675007),",
    },
    {
      "PART NO": 28,
      "PART NAME": "DHADIYA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING DHADIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 753,
      location: "23.509842,78.2934973",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING DHADIYA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING DHADIYA',23.509842,78.2934973)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING DHADIYA',23.509842,78.2934973),",
    },
    {
      "PART NO": 29,
      "PART NAME": "CHANDBAD",
      "Name of polling station building": "Integrated G.H.S Bhawan Chandwad",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1036,
      location: "23.49268446,78.31903691",
      "inverted one": "'Integrated G.H.S Bhawan Chandwad'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Chandwad',23.49268446,78.31903691)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Chandwad',23.49268446,78.31903691),",
    },
    {
      "PART NO": 30,
      "PART NAME": "BARKHUA",
      "Name of polling station building": "PRIMARY SCHOOL BUILDING BARKHUA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 405,
      location: "23.51124166,78.315435",
      "inverted one": "'PRIMARY SCHOOL BUILDING BARKHUA'",
      cosolideted:
        "ElectionBooth('PRIMARY SCHOOL BUILDING BARKHUA',23.51124166,78.315435)",
      "with comma":
        "ElectionBooth('PRIMARY SCHOOL BUILDING BARKHUA',23.51124166,78.315435),",
    },
    {
      "PART NO": 31,
      "PART NAME": "UMARKOH",
      "Name of polling station building": "Integrated G.M.S Bhawan Umarkhoh",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 996,
      location: "23.53978211,78.31156751",
      "inverted one": "'Integrated G.M.S Bhawan Umarkhoh'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Umarkhoh',23.53978211,78.31156751)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Umarkhoh',23.53978211,78.31156751),",
    },
    {
      "PART NO": 32,
      "PART NAME": "BARRIKALAN",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BARRI KALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 536,
      location: "23.55058166,78.29536499",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BARRI KALAN'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BARRI KALAN',23.55058166,78.29536499)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BARRI KALAN',23.55058166,78.29536499),",
    },
    {
      "PART NO": 33,
      "PART NAME": "FATEHPUR",
      "Name of polling station building": "PANCHAYAT BUILDING FATEHPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 442,
      location: "23.57650179,78.33719524",
      "inverted one": "'PANCHAYAT BUILDING FATEHPUR'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING FATEHPUR',23.57650179,78.33719524)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING FATEHPUR',23.57650179,78.33719524),",
    },
    {
      "PART NO": 34,
      "PART NAME": "PACHIPURA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING PACHIPURA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 285,
      location: "23.5793619,78.30851233",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING PACHIPURA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PACHIPURA',23.5793619,78.30851233)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING PACHIPURA',23.5793619,78.30851233),",
    },
    {
      "PART NO": 35,
      "PART NAME": "KHIRIYA SUKALRAY",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KHIRIYA SUKALRAY",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 702,
      location: "23.57936119,78.30851233",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KHIRIYA SUKALRAY'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHIRIYA SUKALRAY',23.57936119,78.30851233)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHIRIYA SUKALRAY',23.57936119,78.30851233),",
    },
    {
      "PART NO": 36,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING MAKBRA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 982,
      location: "23.5927815916206,78.3376831188797",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING MAKBRA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MAKBRA',23.5927815916206,78.3376831188797)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MAKBRA',23.5927815916206,78.3376831188797),",
    },
    {
      "PART NO": 37,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Kila (W.Part) Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1131,
      location: "23.61001833,78.33783833",
      "inverted one": "'Integrated G.M.S Bhawan Kila (W.Part) Begumganj'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Kila (W.Part) Begumganj',23.61001833,78.33783833)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Kila (W.Part) Begumganj',23.61001833,78.33783833),",
    },
    {
      "PART NO": 38,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Kila (M.Part) Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1286,
      location: "23.6036033,78.33811833",
      "inverted one": "'Integrated G.M.S Bhawan Kila (M.Part) Begumganj'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Kila (M.Part) Begumganj',23.6036033,78.33811833)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Kila (M.Part) Begumganj',23.6036033,78.33811833),",
    },
    {
      "PART NO": 39,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Kila (E.Part) Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 844,
      location: "23.60359,78.33815",
      "inverted one": "'Integrated G.M.S Bhawan Kila (E.Part) Begumganj'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Kila (E.Part) Begumganj',23.60359,78.33815)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Kila (E.Part) Begumganj',23.60359,78.33815),",
    },
    {
      "PART NO": 40,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Kanya Kila Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1200,
      location: "23.59826666,78.33962166",
      "inverted one": "'Integrated G.M.S Bhawan Kanya Kila Begumganj'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Kanya Kila Begumganj',23.59826666,78.33962166)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Kanya Kila Begumganj',23.59826666,78.33962166),",
    },
    {
      "PART NO": 41,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated G.G.H.S.S Begumganj Northern part",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1123,
      location: "23.5987866,78.34055333",
      "inverted one": "'Integrated G.G.H.S.S Begumganj Northern part'",
      cosolideted:
        "ElectionBooth('Integrated G.G.H.S.S Begumganj Northern part',23.5987866,78.34055333)",
      "with comma":
        "ElectionBooth('Integrated G.G.H.S.S Begumganj Northern part',23.5987866,78.34055333),",
    },
    {
      "PART NO": 42,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING NEW SHAHAPUR",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1325,
      location: "23.59775666,78.343708333",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING NEW SHAHAPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NEW SHAHAPUR',23.59775666,78.343708333)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NEW SHAHAPUR',23.59775666,78.343708333),",
    },
    {
      "PART NO": 43,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Khiriya Narayan Das Naveen Begumganj Eastern part",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1005,
      location: "23.6007149,78.3425883",
      "inverted one":
        "'Integrated G.M.S Bhawan Khiriya Narayan Das Naveen Begumganj Eastern part'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Khiriya Narayan Das Naveen Begumganj Eastern part',23.6007149,78.3425883)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Khiriya Narayan Das Naveen Begumganj Eastern part',23.6007149,78.3425883),",
    },
    {
      "PART NO": 44,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building": "VIKAS KHAND OFFICE (EAST PART)",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 760,
      location: "23.5955483333333,78.3411833333333",
      "inverted one": "'VIKAS KHAND OFFICE (EAST PART)'",
      cosolideted:
        "ElectionBooth('VIKAS KHAND OFFICE (EAST PART)',23.5955483333333,78.3411833333333)",
      "with comma":
        "ElectionBooth('VIKAS KHAND OFFICE (EAST PART)',23.5955483333333,78.3411833333333),",
    },
    {
      "PART NO": 45,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "GOVT. GIRLS PRIMARY SCHOOL BUILDING MAHADEVPURA",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1130,
      location: "23.60118333,78.334375",
      "inverted one": "'GOVT. GIRLS PRIMARY SCHOOL BUILDING MAHADEVPURA'",
      cosolideted:
        "ElectionBooth('GOVT. GIRLS PRIMARY SCHOOL BUILDING MAHADEVPURA',23.60118333,78.334375)",
      "with comma":
        "ElectionBooth('GOVT. GIRLS PRIMARY SCHOOL BUILDING MAHADEVPURA',23.60118333,78.334375),",
    },
    {
      "PART NO": 46,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Govt.Primary Bhawan Northern Part Naveen Shahpur Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 604,
      location: "23.59274,78.3387866",
      "inverted one":
        "'Govt.Primary Bhawan Northern Part Naveen Shahpur Begumganj'",
      cosolideted:
        "ElectionBooth('Govt.Primary Bhawan Northern Part Naveen Shahpur Begumganj',23.59274,78.3387866)",
      "with comma":
        "ElectionBooth('Govt.Primary Bhawan Northern Part Naveen Shahpur Begumganj',23.59274,78.3387866),",
    },
    {
      "PART NO": 47,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated G.M.S Bhawan K.N.D (West.Part) Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1315,
      location: "23.5988733,78.345305",
      "inverted one": "'Integrated G.M.S Bhawan K.N.D (West.Part) Begumganj'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan K.N.D (West.Part) Begumganj',23.5988733,78.345305)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan K.N.D (West.Part) Begumganj',23.5988733,78.345305),",
    },
    {
      "PART NO": 48,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Hadaipur (Naveen) Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1141,
      location: "23.59089333,78.34397833",
      "inverted one": "'Integrated G.M.S Bhawan Hadaipur (Naveen) Begumganj'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Hadaipur (Naveen) Begumganj',23.59089333,78.34397833)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Hadaipur (Naveen) Begumganj',23.59089333,78.34397833),",
    },
    {
      "PART NO": 49,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated Govt. Middle School Building Hadaipur Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1260,
      location: "23.5929333,78.33912166",
      "inverted one":
        "'Integrated Govt. Middle School Building Hadaipur Begumganj'",
      cosolideted:
        "ElectionBooth('Integrated Govt. Middle School Building Hadaipur Begumganj',23.5929333,78.33912166)",
      "with comma":
        "ElectionBooth('Integrated Govt. Middle School Building Hadaipur Begumganj',23.5929333,78.33912166),",
    },
    {
      "PART NO": 50,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Govt.Middle School Bhawan Eastern part Naveen Shahpur Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 828,
      location: "23.5926697,78.33914016",
      "inverted one":
        "'Govt.Middle School Bhawan Eastern part Naveen Shahpur Begumganj'",
      cosolideted:
        "ElectionBooth('Govt.Middle School Bhawan Eastern part Naveen Shahpur Begumganj',23.5926697,78.33914016)",
      "with comma":
        "ElectionBooth('Govt.Middle School Bhawan Eastern part Naveen Shahpur Begumganj',23.5926697,78.33914016),",
    },
    {
      "PART NO": 51,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Govt.Middle.School Bhawan Western Part Naveen Shahpur Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 836,
      location: "23.59341833,78.338735",
      "inverted one":
        "'Govt.Middle.School Bhawan Western Part Naveen Shahpur Begumganj'",
      cosolideted:
        "ElectionBooth('Govt.Middle.School Bhawan Western Part Naveen Shahpur Begumganj',23.59341833,78.338735)",
      "with comma":
        "ElectionBooth('Govt.Middle.School Bhawan Western Part Naveen Shahpur Begumganj',23.59341833,78.338735),",
    },
    {
      "PART NO": 52,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Govt.Primary.Shala .Bhawan Northern Part Shyam Nagar Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 755,
      location: "23.5932066,78.3393966",
      "inverted one":
        "'Govt.Primary.Shala .Bhawan Northern Part Shyam Nagar Begumganj'",
      cosolideted:
        "ElectionBooth('Govt.Primary.Shala .Bhawan Northern Part Shyam Nagar Begumganj',23.5932066,78.3393966)",
      "with comma":
        "ElectionBooth('Govt.Primary.Shala .Bhawan Northern Part Shyam Nagar Begumganj',23.5932066,78.3393966),",
    },
    {
      "PART NO": 53,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Govt.Primary .School .Bhawan Southern Part Shyam Nagar Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 654,
      location: "23.59326666,78.33928666",
      "inverted one":
        "'Govt.Primary .School .Bhawan Southern Part Shyam Nagar Begumganj'",
      cosolideted:
        "ElectionBooth('Govt.Primary .School .Bhawan Southern Part Shyam Nagar Begumganj',23.59326666,78.33928666)",
      "with comma":
        "ElectionBooth('Govt.Primary .School .Bhawan Southern Part Shyam Nagar Begumganj',23.59326666,78.33928666),",
    },
    {
      "PART NO": 54,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Gadhoipur Begumganj North Part",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1224,
      location: "23.6007149999999,78.3425883333333",
      "inverted one":
        "'Integrated G.M.S Bhawan Gadhoipur Begumganj North Part'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Gadhoipur Begumganj North Part',23.6007149999999,78.3425883333333)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Gadhoipur Begumganj North Part',23.6007149999999,78.3425883333333),",
    },
    {
      "PART NO": 55,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building": "Tribal Boys Hostel Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 740,
      location: "23.594076,78.3374283",
      "inverted one": "'Tribal Boys Hostel Begumganj'",
      cosolideted:
        "ElectionBooth('Tribal Boys Hostel Begumganj',23.594076,78.3374283)",
      "with comma":
        "ElectionBooth('Tribal Boys Hostel Begumganj',23.594076,78.3374283),",
    },
    {
      "PART NO": 56,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building": "SC Boys Excellent Hostel Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 630,
      location: "23.59504833,78.336333",
      "inverted one": "'SC Boys Excellent Hostel Begumganj'",
      cosolideted:
        "ElectionBooth('SC Boys Excellent Hostel Begumganj',23.59504833,78.336333)",
      "with comma":
        "ElectionBooth('SC Boys Excellent Hostel Begumganj',23.59504833,78.336333),",
    },
    {
      "PART NO": 57,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated Girls U.M. Secondary Department (North Part) Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 710,
      location: "23.5951,78.33637166",
      "inverted one":
        "'Integrated Girls U.M. Secondary Department (North Part) Begumganj'",
      cosolideted:
        "ElectionBooth('Integrated Girls U.M. Secondary Department (North Part) Begumganj',23.5951,78.33637166)",
      "with comma":
        "ElectionBooth('Integrated Girls U.M. Secondary Department (North Part) Begumganj',23.5951,78.33637166),",
    },
    {
      "PART NO": 58,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated G.H.S.School Secondary Department (Southern Part) Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 784,
      location: "23.5922566,78.338735",
      "inverted one":
        "'Integrated G.H.S.School Secondary Department (Southern Part) Begumganj'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S.School Secondary Department (Southern Part) Begumganj',23.5922566,78.338735)",
      "with comma":
        "ElectionBooth('Integrated G.H.S.School Secondary Department (Southern Part) Begumganj',23.5922566,78.338735),",
    },
    {
      "PART NO": 59,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated G.Middle School Bhawan Gilrs Kila (Western part) Begumganj",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 822,
      location: "23.5917266666666,78.3406083333333",
      "inverted one":
        "'Integrated G.Middle School Bhawan Gilrs Kila (Western part) Begumganj'",
      cosolideted:
        "ElectionBooth('Integrated G.Middle School Bhawan Gilrs Kila (Western part) Begumganj',23.5917266666666,78.3406083333333)",
      "with comma":
        "ElectionBooth('Integrated G.Middle School Bhawan Gilrs Kila (Western part) Begumganj',23.5917266666666,78.3406083333333),",
    },
    {
      "PART NO": 60,
      "PART NAME": "BEGAMGANJ",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Gadhoipur Begumganj Southern Part",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1150,
      location: "23.60295333,78.34431333",
      "inverted one":
        "'Integrated G.M.S Bhawan Gadhoipur Begumganj Southern Part'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Gadhoipur Begumganj Southern Part',23.60295333,78.34431333)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Gadhoipur Begumganj Southern Part',23.60295333,78.34431333),",
    },
    {
      "PART NO": 61,
      "PART NAME": "PANDAJHIR",
      "Name of polling station building": "Gram Panchayat Bhawan Pandajhir",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 773,
      location: "23.576677,78.399012",
      "inverted one": "'Gram Panchayat Bhawan Pandajhir'",
      cosolideted:
        "ElectionBooth('Gram Panchayat Bhawan Pandajhir',23.576677,78.399012)",
      "with comma":
        "ElectionBooth('Gram Panchayat Bhawan Pandajhir',23.576677,78.399012),",
    },
    {
      "PART NO": 62,
      "PART NAME": "SALAIYA",
      "Name of polling station building": "Integrated G.M.S Bhawan Saliya",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 769,
      location: "23.5930933,78.33786166",
      "inverted one": "'Integrated G.M.S Bhawan Saliya'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Saliya',23.5930933,78.33786166)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Saliya',23.5930933,78.33786166),",
    },
    {
      "PART NO": 63,
      "PART NAME": "PARSORA",
      "Name of polling station building": "Integrated G.M.S Bhawan Parsora",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 691,
      location: "23.593075,78.33762",
      "inverted one": "'Integrated G.M.S Bhawan Parsora'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Parsora',23.593075,78.33762)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Parsora',23.593075,78.33762),",
    },
    {
      "PART NO": 64,
      "PART NAME": "RAHATWAS",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING RAHATWAS",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 873,
      location: "23.59335,78.3377816",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING RAHATWAS'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAHATWAS',23.59335,78.3377816)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAHATWAS',23.59335,78.3377816),",
    },
    {
      "PART NO": 65,
      "PART NAME": "SAGOUNI GUSAI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SAGOUNI GUSAI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 538,
      location: "23.5964093,78.34063569",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SAGOUNI GUSAI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SAGOUNI GUSAI',23.5964093,78.34063569)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SAGOUNI GUSAI',23.5964093,78.34063569),",
    },
    {
      "PART NO": 66,
      "PART NAME": "DHWAJ",
      "Name of polling station building": "Integrated G.M.S Bhawan Dwaj",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 836,
      location: "23.473080032,78.52397282",
      "inverted one": "'Integrated G.M.S Bhawan Dwaj'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Dwaj',23.473080032,78.52397282)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Dwaj',23.473080032,78.52397282),",
    },
    {
      "PART NO": 67,
      "PART NAME": "KARHOLA",
      "Name of polling station building": "Integrated G.M.S Bhawan Karhola",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 823,
      location: "23.47040367,78.35294027",
      "inverted one": "'Integrated G.M.S Bhawan Karhola'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Karhola',23.47040367,78.35294027)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Karhola',23.47040367,78.35294027),",
    },
    {
      "PART NO": 68,
      "PART NAME": "JAMUNIYA TA MAHUAKHEDA",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Jamuniya Ta MahuaKheda",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 824,
      location: "23.5935443,78.33770083",
      "inverted one": "'Integrated G.M.S Bhawan Jamuniya Ta MahuaKheda'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Jamuniya Ta MahuaKheda',23.5935443,78.33770083)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Jamuniya Ta MahuaKheda',23.5935443,78.33770083),",
    },
    {
      "PART NO": 69,
      "PART NAME": "MARKHEDA TAPPA",
      "Name of polling station building":
        "Integrated G.H.S Bhawan Markheda Tappa",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1093,
      location: "23.48336509,78.39153711",
      "inverted one": "'Integrated G.H.S Bhawan Markheda Tappa'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Markheda Tappa',23.48336509,78.39153711)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Markheda Tappa',23.48336509,78.39153711),",
    },
    {
      "PART NO": 70,
      "PART NAME": "MAHUAKHEDA KALA",
      "Name of polling station building":
        "Integrated G.H.S Bhawan Mahuakheda Kalan",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 935,
      location: "23.59588492,78.34027041",
      "inverted one": "'Integrated G.H.S Bhawan Mahuakheda Kalan'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Mahuakheda Kalan',23.59588492,78.34027041)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Mahuakheda Kalan',23.59588492,78.34027041),",
    },
    {
      "PART NO": 71,
      "PART NAME": "MAHAGAVAN TAPPA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING MAHAGAVAN TAPPA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 937,
      location: "23.59301166,78.33805333",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING MAHAGAVAN TAPPA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MAHAGAVAN TAPPA',23.59301166,78.33805333)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MAHAGAVAN TAPPA',23.59301166,78.33805333),",
    },
    {
      "PART NO": 72,
      "PART NAME": "KHIRENTI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KHIRETI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1039,
      location: "23.59547111,78.3400086",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KHIRETI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHIRETI',23.59547111,78.3400086)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHIRETI',23.59547111,78.3400086),",
    },
    {
      "PART NO": 73,
      "PART NAME": "HINOTIYA BAMNAI",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Hinotia Bamnai",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1031,
      location: "23.5933749999999,78.3378966666666",
      "inverted one": "'Integrated G.M.S Bhawan Hinotia Bamnai'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Hinotia Bamnai',23.5933749999999,78.3378966666666)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Hinotia Bamnai',23.5933749999999,78.3378966666666),",
    },
    {
      "PART NO": 74,
      "PART NAME": "TULSIPAAR",
      "Name of polling station building":
        "Integrated G.H.S Bhawan Tulsipar East Part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 791,
      location: "23.59338,78.337905",
      "inverted one": "'Integrated G.H.S Bhawan Tulsipar East Part'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Tulsipar East Part',23.59338,78.337905)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Tulsipar East Part',23.59338,78.337905),",
    },
    {
      "PART NO": 75,
      "PART NAME": "TULSIPAAR",
      "Name of polling station building":
        "Integrated G.H.S Bhawan Tulsipar West Part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 710,
      location: "23.59338,78.337905",
      "inverted one": "'Integrated G.H.S Bhawan Tulsipar West Part'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Tulsipar West Part',23.59338,78.337905)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Tulsipar West Part',23.59338,78.337905),",
    },
    {
      "PART NO": 76,
      "PART NAME": "VEERPUR",
      "Name of polling station building":
        "Integrated G.H.S Bhawan Veerpur North Part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1008,
      location: "23.5968394,78.3379334",
      "inverted one": "'Integrated G.H.S Bhawan Veerpur North Part'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Veerpur North Part',23.5968394,78.3379334)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Veerpur North Part',23.5968394,78.3379334),",
    },
    {
      "PART NO": 77,
      "PART NAME": "VEERPUR",
      "Name of polling station building":
        "Integrated G.H.S Bhawan Veerpur Southern Part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 613,
      location: "23.58461,78.422335",
      "inverted one": "'Integrated G.H.S Bhawan Veerpur Southern Part'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Veerpur Southern Part',23.58461,78.422335)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Veerpur Southern Part',23.58461,78.422335),",
    },
    {
      "PART NO": 78,
      "PART NAME": "BIJORA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BIJORA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 489,
      location: "23.5933466,78.33785833",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BIJORA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BIJORA',23.5933466,78.33785833)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BIJORA',23.5933466,78.33785833),",
    },
    {
      "PART NO": 79,
      "PART NAME": "BADGAWAN MUNJAPTA",
      "Name of polling station building":
        "Integrated G.H.S Bhawan Badgawan Mu.",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1036,
      location: "23.57918833,78.46230166",
      "inverted one": "'Integrated G.H.S Bhawan Badgawan Mu.'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Badgawan Mu.',23.57918833,78.46230166)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Badgawan Mu.',23.57918833,78.46230166),",
    },
    {
      "PART NO": 80,
      "PART NAME": "KIRATPUR",
      "Name of polling station building": "Integrated M.S Bhawan Kiratpur",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 512,
      location: "23.607745,78.45756333",
      "inverted one": "'Integrated M.S Bhawan Kiratpur'",
      cosolideted:
        "ElectionBooth('Integrated M.S Bhawan Kiratpur',23.607745,78.45756333)",
      "with comma":
        "ElectionBooth('Integrated M.S Bhawan Kiratpur',23.607745,78.45756333),",
    },
    {
      "PART NO": 81,
      "PART NAME": "KHERI TA. CHOUKA",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING KHERI TA.CHOUKA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 811,
      location: "23.6269383333333,78.473475",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING KHERI TA.CHOUKA'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING KHERI TA.CHOUKA',23.6269383333333,78.473475)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING KHERI TA.CHOUKA',23.6269383333333,78.473475),",
    },
    {
      "PART NO": 82,
      "PART NAME": "NAYANAGAR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING NAYANAGAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 868,
      location: "23.6235304,78.4956255",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING NAYANAGAR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NAYANAGAR',23.6235304,78.4956255)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NAYANAGAR',23.6235304,78.4956255),",
    },
    {
      "PART NO": 83,
      "PART NAME": "BANSADEHI",
      "Name of polling station building": "Integrated G.H.S Bhawan Bansadehi",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 824,
      location: "23.60938166,78.5019966",
      "inverted one": "'Integrated G.H.S Bhawan Bansadehi'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Bansadehi',23.60938166,78.5019966)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Bansadehi',23.60938166,78.5019966),",
    },
    {
      "PART NO": 84,
      "PART NAME": "JAMUNIYA JAAMSAA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING JAAMSHA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 825,
      location: "23.589329,78.480825",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING JAAMSHA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING JAAMSHA',23.589329,78.480825)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING JAAMSHA',23.589329,78.480825),",
    },
    {
      "PART NO": 85,
      "PART NAME": "MARKHANDI",
      "Name of polling station building": "GOVT.PS SCHOOL BULDING MARKHANDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 964,
      location: "23.5570488,78.4862261",
      "inverted one": "'GOVT.PS SCHOOL BULDING MARKHANDI'",
      cosolideted:
        "ElectionBooth('GOVT.PS SCHOOL BULDING MARKHANDI',23.5570488,78.4862261)",
      "with comma":
        "ElectionBooth('GOVT.PS SCHOOL BULDING MARKHANDI',23.5570488,78.4862261),",
    },
    {
      "PART NO": 86,
      "PART NAME": "MARKHANDI",
      "Name of polling station building":
        "Integrated G.H.S. Bhawan Markhandi-West",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 797,
      location: "23.5563095,78.4861387",
      "inverted one": "'Integrated G.H.S. Bhawan Markhandi-West'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S. Bhawan Markhandi-West',23.5563095,78.4861387)",
      "with comma":
        "ElectionBooth('Integrated G.H.S. Bhawan Markhandi-West',23.5563095,78.4861387),",
    },
    {
      "PART NO": 87,
      "PART NAME": "BHAINSA",
      "Name of polling station building": "Integrated G.M.S Bhawan Bhainsa",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 618,
      location: "23.5316387,78.4874131",
      "inverted one": "'Integrated G.M.S Bhawan Bhainsa'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Bhainsa',23.5316387,78.4874131)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Bhainsa',23.5316387,78.4874131),",
    },
    {
      "PART NO": 88,
      "PART NAME": "GHOGHARI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING GHOGHARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 542,
      location: "23.5232657,78.4844328",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING GHOGHARI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GHOGHARI',23.5232657,78.4844328)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GHOGHARI',23.5232657,78.4844328),",
    },
    {
      "PART NO": 89,
      "PART NAME": "TINSUA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING TINSUA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 914,
      location: "23.5961333,78.3423302",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING TINSUA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING TINSUA',23.5961333,78.3423302)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING TINSUA',23.5961333,78.3423302),",
    },
    {
      "PART NO": 90,
      "PART NAME": "MAJGAWAN SAANI",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Majgawan Sani",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 423,
      location: "23.59479426,78.33950875",
      "inverted one": "'Integrated G.M.S Bhawan Majgawan Sani'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Majgawan Sani',23.59479426,78.33950875)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Majgawan Sani',23.59479426,78.33950875),",
    },
    {
      "PART NO": 91,
      "PART NAME": "BARH",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL BUILDING BARAH",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 997,
      location: "23.5942506907488,78.3391372243344",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BARAH'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BARAH',23.5942506907488,78.3391372243344)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BARAH',23.5942506907488,78.3391372243344),",
    },
    {
      "PART NO": 92,
      "PART NAME": "KUNDA",
      "Name of polling station building": "Integrated G.H.S. Bhawan Kunda",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 917,
      location: "23.5914741,78.3367504",
      "inverted one": "'Integrated G.H.S. Bhawan Kunda'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S. Bhawan Kunda',23.5914741,78.3367504)",
      "with comma":
        "ElectionBooth('Integrated G.H.S. Bhawan Kunda',23.5914741,78.3367504),",
    },
    {
      "PART NO": 93,
      "PART NAME": "SUNETI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SUNETI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1120,
      location: "23.5928392620395,78.3381652502426",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SUNETI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SUNETI',23.5928392620395,78.3381652502426)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SUNETI',23.5928392620395,78.3381652502426),",
    },
    {
      "PART NO": 94,
      "PART NAME": "PADARIYA RAJADHAAR",
      "Name of polling station building":
        "Integrated G.H.S. Bhawan Padaria Rajadhaar",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 609,
      location: "23.59347735,78.3386004",
      "inverted one": "'Integrated G.H.S. Bhawan Padaria Rajadhaar'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S. Bhawan Padaria Rajadhaar',23.59347735,78.3386004)",
      "with comma":
        "ElectionBooth('Integrated G.H.S. Bhawan Padaria Rajadhaar',23.59347735,78.3386004),",
    },
    {
      "PART NO": 95,
      "PART NAME": "RAMPURA",
      "Name of polling station building": "Integrated G.M.S Bhawan Rampura",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 650,
      location: "23.5937506,78.3387934",
      "inverted one": "'Integrated G.M.S Bhawan Rampura'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Rampura',23.5937506,78.3387934)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Rampura',23.5937506,78.3387934),",
    },
    {
      "PART NO": 96,
      "PART NAME": "DHILWAR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DHILWAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1160,
      location: "23.43448999,78.4778333333333",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DHILWAR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DHILWAR',23.43448999,78.4778333333333)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DHILWAR',23.43448999,78.4778333333333),",
    },
    {
      "PART NO": 97,
      "PART NAME": "MAWAI",
      "Name of polling station building": "Govt.Middle .School Bhawan Mawai",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1141,
      location: "23.3932799,78.4466216",
      "inverted one": "'Govt.Middle .School Bhawan Mawai'",
      cosolideted:
        "ElectionBooth('Govt.Middle .School Bhawan Mawai',23.3932799,78.4466216)",
      "with comma":
        "ElectionBooth('Govt.Middle .School Bhawan Mawai',23.3932799,78.4466216),",
    },
    {
      "PART NO": 98,
      "PART NAME": "PAPADA",
      "Name of polling station building": "Integrated G.M.S Bhawan Papada",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 800,
      location: "23.41575333,78.48643166",
      "inverted one": "'Integrated G.M.S Bhawan Papada'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Papada',23.41575333,78.48643166)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Papada',23.41575333,78.48643166),",
    },
    {
      "PART NO": 99,
      "PART NAME": "PADARIYA SITARAM",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PADARIYA SITARAM",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 633,
      location: "23.41040012,78.46641361",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PADARIYA SITARAM'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PADARIYA SITARAM',23.41040012,78.46641361)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PADARIYA SITARAM',23.41040012,78.46641361),",
    },
    {
      "PART NO": 100,
      "PART NAME": "TEKAPAR KHURD",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING TEKAPAR KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 678,
      location: "23.45374547,78.49582921",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING TEKAPAR KHURD'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING TEKAPAR KHURD',23.45374547,78.49582921)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING TEKAPAR KHURD',23.45374547,78.49582921),",
    },
    {
      "PART NO": 101,
      "PART NAME": "JASARATHI",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL BUILDING",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 777,
      location: "23.5870752,78.3422256",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING',23.5870752,78.3422256)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING',23.5870752,78.3422256),",
    },
    {
      "PART NO": 102,
      "PART NAME": "GHANA KALAN",
      "Name of polling station building":
        "GOVT.MIDDLE SCHOOL BUILDING GHANA KALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 721,
      location: "23.5000999,78.52588",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING GHANA KALAN'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING GHANA KALAN',23.5000999,78.52588)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING GHANA KALAN',23.5000999,78.52588),",
    },
    {
      "PART NO": 103,
      "PART NAME": "KAISHLOUN TA.KO.",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KESHLOUN TA KA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 819,
      location: "23.500099,78.52588",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KESHLOUN TA KA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KESHLOUN TA KA',23.500099,78.52588)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KESHLOUN TA KA',23.500099,78.52588),",
    },
    {
      "PART NO": 104,
      "PART NAME": "UDKA",
      "Name of polling station building": "Integrated G.H.S. Bhawan Udka",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 950,
      location: "23.5388616,78.5202366",
      "inverted one": "'Integrated G.H.S. Bhawan Udka'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S. Bhawan Udka',23.5388616,78.5202366)",
      "with comma":
        "ElectionBooth('Integrated G.H.S. Bhawan Udka',23.5388616,78.5202366),",
    },
    {
      "PART NO": 105,
      "PART NAME": "MARKHEDA GULAB",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Markheda Gulab",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 875,
      location: "23.59354833,78.33775666",
      "inverted one": "'Integrated G.M.S Bhawan Markheda Gulab'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Markheda Gulab',23.59354833,78.33775666)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Markheda Gulab',23.59354833,78.33775666),",
    },
    {
      "PART NO": 106,
      "PART NAME": "TEKAPAAR KALA",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Tekapar Kala",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 877,
      location: "23.55124666,78.55489166",
      "inverted one": "'Integrated G.M.S Bhawan Tekapar Kala'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Tekapar Kala',23.55124666,78.55489166)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Tekapar Kala',23.55124666,78.55489166),",
    },
    {
      "PART NO": 107,
      "PART NAME": "PIPALIYA BICHOULI",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Pipaliya Bichouli",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 732,
      location: "23.53926202,78.5927824",
      "inverted one": "'Integrated G.M.S Bhawan Pipaliya Bichouli'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Pipaliya Bichouli',23.53926202,78.5927824)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Pipaliya Bichouli',23.53926202,78.5927824),",
    },
    {
      "PART NO": 108,
      "PART NAME": "BAMHORI TITOR",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Bamhori Teetor",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 778,
      location: "23.5177833333333,78.5861233333333",
      "inverted one": "'Integrated G.M.S Bhawan Bamhori Teetor'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Bamhori Teetor',23.5177833333333,78.5861233333333)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Bamhori Teetor',23.5177833333333,78.5861233333333),",
    },
    {
      "PART NO": 109,
      "PART NAME": "KHAJURIYA GUSAI",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Khajuria Gusai",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 670,
      location: "23.50143855,78.58124851",
      "inverted one": "'Integrated G.M.S Bhawan Khajuria Gusai'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Khajuria Gusai',23.50143855,78.58124851)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Khajuria Gusai',23.50143855,78.58124851),",
    },
    {
      "PART NO": 110,
      "PART NAME": "RAHMA",
      "Name of polling station building": "Integrated G.M.S Bhawan Rahma",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 806,
      location: "23.52267035,78.60282363",
      "inverted one": "'Integrated G.M.S Bhawan Rahma'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Rahma',23.52267035,78.60282363)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Rahma',23.52267035,78.60282363),",
    },
    {
      "PART NO": 111,
      "PART NAME": "CHANDOUDA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING CHANDODA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 866,
      location: "23.5143917,78.6215133",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING CHANDODA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHANDODA',23.5143917,78.6215133)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHANDODA',23.5143917,78.6215133),",
    },
    {
      "PART NO": 112,
      "PART NAME": "PANDHARBHATA",
      "Name of polling station building": "Integrated G.M.S Bhawan Pandarbhata",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 937,
      location: "23.51743166,78.63738833",
      "inverted one": "'Integrated G.M.S Bhawan Pandarbhata'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Pandarbhata',23.51743166,78.63738833)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Pandarbhata',23.51743166,78.63738833),",
    },
    {
      "PART NO": 113,
      "PART NAME": "KHAMKHEDA",
      "Name of polling station building": "Integrated G.M.S Bhawan Khamkheda",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 774,
      location: "23.5293676,78.6512245",
      "inverted one": "'Integrated G.M.S Bhawan Khamkheda'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Khamkheda',23.5293676,78.6512245)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Khamkheda',23.5293676,78.6512245),",
    },
    {
      "PART NO": 114,
      "PART NAME": "MOIYA",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL BUILDING MOIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 756,
      location: "23.54167823,78.662245",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING MOIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MOIYA',23.54167823,78.662245)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MOIYA',23.54167823,78.662245),",
    },
    {
      "PART NO": 115,
      "PART NAME": "SUNAVAHA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING SUNVAHA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1280,
      location: "23.50394833,78.553785",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING SUNVAHA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SUNVAHA',23.50394833,78.553785)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SUNVAHA',23.50394833,78.553785),",
    },
    {
      "PART NO": 116,
      "PART NAME": "GORKHI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING GORKHI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 548,
      location: "23.50394833,78.553785",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING GORKHI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GORKHI',23.50394833,78.553785)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GORKHI',23.50394833,78.553785),",
    },
    {
      "PART NO": 117,
      "PART NAME": "JAMUNIYA TA. KO.",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Jamuniya T.Ko.",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 516,
      location: "23.5710133333333,78.7165316666666",
      "inverted one": "'Integrated G.M.S Bhawan Jamuniya T.Ko.'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Jamuniya T.Ko.',23.5710133333333,78.7165316666666)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Jamuniya T.Ko.',23.5710133333333,78.7165316666666),",
    },
    {
      "PART NO": 118,
      "PART NAME": "NARAYANPUR",
      "Name of polling station building": "Integrated G.M.S Bhawan Narayanpur",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 620,
      location: "23.550625,78.73417333",
      "inverted one": "'Integrated G.M.S Bhawan Narayanpur'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Narayanpur',23.550625,78.73417333)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Narayanpur',23.550625,78.73417333),",
    },
    {
      "PART NO": 119,
      "PART NAME": "BAMHORI T. K.",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BAMHORI T.K.",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 385,
      location: "23.5543433333333,78.7133616666666",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BAMHORI T.K.'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAMHORI T.K.',23.5543433333333,78.7133616666666)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BAMHORI T.K.',23.5543433333333,78.7133616666666),",
    },
    {
      "PART NO": 120,
      "PART NAME": "TEKAPAAR T. K.",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Tekapar T.K.",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 669,
      location: "23.5335966,78.6967366",
      "inverted one": "'Integrated G.M.S Bhawan Tekapar T.K.'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Tekapar T.K.',23.5335966,78.6967366)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Tekapar T.K.',23.5335966,78.6967366),",
    },
    {
      "PART NO": 121,
      "PART NAME": "KOTHIKHOH",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KOTHIKHOH",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 960,
      location: "23.51608333,78.69223166",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KOTHIKHOH'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KOTHIKHOH',23.51608333,78.69223166)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KOTHIKHOH',23.51608333,78.69223166),",
    },
    {
      "PART NO": 122,
      "PART NAME": "JAMUNIYA PIPALIYA",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Jamuniya Pipliya",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 711,
      location: "23.46240554,78.651146",
      "inverted one": "'Integrated G.M.S Bhawan Jamuniya Pipliya'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Jamuniya Pipliya',23.46240554,78.651146)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Jamuniya Pipliya',23.46240554,78.651146),",
    },
    {
      "PART NO": 123,
      "PART NAME": "GULWADA",
      "Name of polling station building":
        "Integrated G.M.S Naveen Bhawan Gulwada",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 697,
      location: "23.47347825,78.62763963",
      "inverted one": "'Integrated G.M.S Naveen Bhawan Gulwada'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Naveen Bhawan Gulwada',23.47347825,78.62763963)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Naveen Bhawan Gulwada',23.47347825,78.62763963),",
    },
    {
      "PART NO": 124,
      "PART NAME": "GUNLAVADA",
      "Name of polling station building": "GOVT.HIGH SCHOOL BUILDING GULBADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 549,
      location: "23.46225981,78.62682889",
      "inverted one": "'GOVT.HIGH SCHOOL BUILDING GULBADA'",
      cosolideted:
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING GULBADA',23.46225981,78.62682889)",
      "with comma":
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING GULBADA',23.46225981,78.62682889),",
    },
    {
      "PART NO": 125,
      "PART NAME": "UMARHARI T. SILWANI",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Umarhari Ta.Sil.",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 937,
      location: "23.45352975,78.60653997",
      "inverted one": "'Integrated G.M.S Bhawan Umarhari Ta.Sil.'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Umarhari Ta.Sil.',23.45352975,78.60653997)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Umarhari Ta.Sil.',23.45352975,78.60653997),",
    },
    {
      "PART NO": 126,
      "PART NAME": "NAIGADHIYA",
      "Name of polling station building": "Integrated G.H.S Bhawan Naigadiya",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1105,
      location: "23.48737929,78.6271062",
      "inverted one": "'Integrated G.H.S Bhawan Naigadiya'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Naigadiya',23.48737929,78.6271062)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Naigadiya',23.48737929,78.6271062),",
    },
    {
      "PART NO": 127,
      "PART NAME": "DUNGARIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DUNGARIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 700,
      location: "23.47548275,78.5846929",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DUNGARIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DUNGARIYA',23.47548275,78.5846929)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DUNGARIYA',23.47548275,78.5846929),",
    },
    {
      "PART NO": 128,
      "PART NAME": "BERSALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BHAJIYA (BERSALA)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 413,
      location: "23.48386375,78.56603231",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BHAJIYA (BERSALA)'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BHAJIYA (BERSALA)',23.48386375,78.56603231)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BHAJIYA (BERSALA)',23.48386375,78.56603231),",
    },
    {
      "PART NO": 129,
      "PART NAME": "SULTANGANJ",
      "Name of polling station building":
        "Integrated H.S.S Bhawan Sultanganj Northern part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1056,
      location: "23.5103171,78.5594738",
      "inverted one": "'Integrated H.S.S Bhawan Sultanganj Northern part'",
      cosolideted:
        "ElectionBooth('Integrated H.S.S Bhawan Sultanganj Northern part',23.5103171,78.5594738)",
      "with comma":
        "ElectionBooth('Integrated H.S.S Bhawan Sultanganj Northern part',23.5103171,78.5594738),",
    },
    {
      "PART NO": 130,
      "PART NAME": "SULTANGANJ",
      "Name of polling station building":
        "Integrated G.G.M.S Bhawan Sultanganj",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1199,
      location: "23.50432,78.5539533",
      "inverted one": "'Integrated G.G.M.S Bhawan Sultanganj'",
      cosolideted:
        "ElectionBooth('Integrated G.G.M.S Bhawan Sultanganj',23.50432,78.5539533)",
      "with comma":
        "ElectionBooth('Integrated G.G.M.S Bhawan Sultanganj',23.50432,78.5539533),",
    },
    {
      "PART NO": 131,
      "PART NAME": "RATANHARI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING RATANHARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 704,
      location: "23.46061029,78.52841357",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING RATANHARI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RATANHARI',23.46061029,78.52841357)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RATANHARI',23.46061029,78.52841357),",
    },
    {
      "PART NO": 132,
      "PART NAME": "DEHGAWAN",
      "Name of polling station building": "Integrated G.M.S Bhawan Dehgawan",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 816,
      location: "23.471305,78.424665",
      "inverted one": "'Integrated G.M.S Bhawan Dehgawan'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Dehgawan',23.471305,78.424665)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Dehgawan',23.471305,78.424665),",
    },
    {
      "PART NO": 133,
      "PART NAME": "BICHHU JAGIR",
      "Name of polling station building": "PANCHAYAT BUILDING BICHHUA JAGEER",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 670,
      location: "23.441403,78.543819",
      "inverted one": "'PANCHAYAT BUILDING BICHHUA JAGEER'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING BICHHUA JAGEER',23.441403,78.543819)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING BICHHUA JAGEER',23.441403,78.543819),",
    },
    {
      "PART NO": 134,
      "PART NAME": "BILAKHEDA JAGIR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BEELKHEDA JAGEER",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 654,
      location: "23.44813481,78.55843303",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BEELKHEDA JAGEER'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BEELKHEDA JAGEER',23.44813481,78.55843303)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BEELKHEDA JAGEER',23.44813481,78.55843303),",
    },
    {
      "PART NO": 135,
      "PART NAME": "KHAMARIYA KALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KHAMARIYA KALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 492,
      location: "23.45362816,78.56398996",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KHAMARIYA KALA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHAMARIYA KALA',23.45362816,78.56398996)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHAMARIYA KALA',23.45362816,78.56398996),",
    },
    {
      "PART NO": 136,
      "PART NAME": "SEEHORA JAGEER",
      "Name of polling station building":
        "GOVT.PS SCHOOL BULDING SEEHORA JAGEER",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 440,
      location: "23.4685592,78.5622372",
      "inverted one": "'GOVT.PS SCHOOL BULDING SEEHORA JAGEER'",
      cosolideted:
        "ElectionBooth('GOVT.PS SCHOOL BULDING SEEHORA JAGEER',23.4685592,78.5622372)",
      "with comma":
        "ElectionBooth('GOVT.PS SCHOOL BULDING SEEHORA JAGEER',23.4685592,78.5622372),",
    },
    {
      "PART NO": 137,
      "PART NAME": "SHAHAPUR SULTANPUR",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Shahpur Sultanpur",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 842,
      location: "23.44153627,78.58291751",
      "inverted one": "'Integrated G.M.S Bhawan Shahpur Sultanpur'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Shahpur Sultanpur',23.44153627,78.58291751)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Shahpur Sultanpur',23.44153627,78.58291751),",
    },
    {
      "PART NO": 138,
      "PART NAME": "PIPALIYA KHURD",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PIPALIYA KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 422,
      location: "23.43055465,78.5900406",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PIPALIYA KHURD'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PIPALIYA KHURD',23.43055465,78.5900406)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PIPALIYA KHURD',23.43055465,78.5900406),",
    },
    {
      "PART NO": 139,
      "PART NAME": "AMAPANI KHURD",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL BUILDING",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 741,
      location: "23.417248,78.578795",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING',23.417248,78.578795)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING',23.417248,78.578795),",
    },
    {
      "PART NO": 140,
      "PART NAME": "DEVKANI",
      "Name of polling station building": "Integrated G.M.S Bhawan Devkani",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 570,
      location: "23.424681,78.547807",
      "inverted one": "'Integrated G.M.S Bhawan Devkani'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Devkani',23.424681,78.547807)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Devkani',23.424681,78.547807),",
    },
    {
      "PART NO": 141,
      "PART NAME": "KHAMARIYA KHURD",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KHAMARIYA KHURD",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1073,
      location: "23.393567,78.617949",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KHAMARIYA KHURD'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHAMARIYA KHURD',23.393567,78.617949)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHAMARIYA KHURD',23.393567,78.617949),",
    },
    {
      "PART NO": 142,
      "PART NAME": "TINGHARA",
      "Name of polling station building": "Integrated G.M.S Bhawan Tinghara",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1041,
      location: "23.377353,78.377353",
      "inverted one": "'Integrated G.M.S Bhawan Tinghara'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Tinghara',23.377353,78.377353)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Tinghara',23.377353,78.377353),",
    },
    {
      "PART NO": 143,
      "PART NAME": "SIYARMAU",
      "Name of polling station building":
        "Integrated G.M.G.S Bhavan Siyarmau-Eastern part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1150,
      location: "23.402209,78.546882",
      "inverted one": "'Integrated G.M.G.S Bhavan Siyarmau-Eastern part'",
      cosolideted:
        "ElectionBooth('Integrated G.M.G.S Bhavan Siyarmau-Eastern part',23.402209,78.546882)",
      "with comma":
        "ElectionBooth('Integrated G.M.G.S Bhavan Siyarmau-Eastern part',23.402209,78.546882),",
    },
    {
      "PART NO": 144,
      "PART NAME": "SIYARAMAU",
      "Name of polling station building":
        "Integrated G.M.G.S Bhavan Siyarmau - Western part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 900,
      location: "23.402098,78.546652",
      "inverted one": "'Integrated G.M.G.S Bhavan Siyarmau - Western part'",
      cosolideted:
        "ElectionBooth('Integrated G.M.G.S Bhavan Siyarmau - Western part',23.402098,78.546652)",
      "with comma":
        "ElectionBooth('Integrated G.M.G.S Bhavan Siyarmau - Western part',23.402098,78.546652),",
    },
    {
      "PART NO": 145,
      "PART NAME": "PIPALIYA KALAN",
      "Name of polling station building":
        "Integrated G.M.S. Bhawan Pipliya Kala",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 869,
      location: "23.42825667,78.50576333",
      "inverted one": "'Integrated G.M.S. Bhawan Pipliya Kala'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Pipliya Kala',23.42825667,78.50576333)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Pipliya Kala',23.42825667,78.50576333),",
    },
    {
      "PART NO": 146,
      "PART NAME": "SULTANPUR",
      "Name of polling station building": "Integrated G.M.S Bhawan Sultanpur",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 703,
      location: "23.29934314,78.4431915",
      "inverted one": "'Integrated G.M.S Bhawan Sultanpur'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Sultanpur',23.29934314,78.4431915)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Sultanpur',23.29934314,78.4431915),",
    },
    {
      "PART NO": 147,
      "PART NAME": "KHAMERA",
      "Name of polling station building": "GRAM SWARAJ BUILDING KHAMERA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 779,
      location: "23.330758,78.500754",
      "inverted one": "'GRAM SWARAJ BUILDING KHAMERA'",
      cosolideted:
        "ElectionBooth('GRAM SWARAJ BUILDING KHAMERA',23.330758,78.500754)",
      "with comma":
        "ElectionBooth('GRAM SWARAJ BUILDING KHAMERA',23.330758,78.500754),",
    },
    {
      "PART NO": 148,
      "PART NAME": "KAKARUA",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING KAKARUA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 619,
      location: "23.32103,78.5078183",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KAKARUA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KAKARUA',23.32103,78.5078183)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KAKARUA',23.32103,78.5078183),",
    },
    {
      "PART NO": 149,
      "PART NAME": "RAMPURA KHURD",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING RAMPURA KHURD E. PART",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 902,
      location: "23.30332686,78.45271125",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING RAMPURA KHURD E. PART'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAMPURA KHURD E. PART',23.30332686,78.45271125)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAMPURA KHURD E. PART',23.30332686,78.45271125),",
    },
    {
      "PART NO": 150,
      "PART NAME": "RAMPURA KHURD",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING RAMPURA KHURD WEST PART",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1226,
      location: "23.29920333,78.440765",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING RAMPURA KHURD WEST PART'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAMPURA KHURD WEST PART',23.29920333,78.440765)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAMPURA KHURD WEST PART',23.29920333,78.440765),",
    },
    {
      "PART NO": 151,
      "PART NAME": "AMAPANI KALAN",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING AMAPANI KALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 703,
      location: "23.32268415,78.43206784",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING AMAPANI KALAN'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING AMAPANI KALAN',23.32268415,78.43206784)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING AMAPANI KALAN',23.32268415,78.43206784),",
    },
    {
      "PART NO": 152,
      "PART NAME": "JUNIYA",
      "Name of polling station building": "Integrated G.M.S Bhawan Junia",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1000,
      location: "23.31291912,78.40941789",
      "inverted one": "'Integrated G.M.S Bhawan Junia'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Junia',23.31291912,78.40941789)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Junia',23.31291912,78.40941789),",
    },
    {
      "PART NO": 153,
      "PART NAME": "UCHERA JAMANIYA",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Uchera Jamuniya - Eastern part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1117,
      location: "23.3659213,78.3756297",
      "inverted one":
        "'Integrated G.M.S Bhawan Uchera Jamuniya - Eastern part'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Uchera Jamuniya - Eastern part',23.3659213,78.3756297)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Uchera Jamuniya - Eastern part',23.3659213,78.3756297),",
    },
    {
      "PART NO": 154,
      "PART NAME": "UCHERA JAMANIYA",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Uchera Jamuniya - Western part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 739,
      location: "23.36573741,78.3757027",
      "inverted one":
        "'Integrated G.M.S Bhawan Uchera Jamuniya - Western part'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Uchera Jamuniya - Western part',23.36573741,78.3757027)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Uchera Jamuniya - Western part',23.36573741,78.3757027),",
    },
    {
      "PART NO": 155,
      "PART NAME": "KHAMARIYA MAANPUR",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Khamaria Manpur",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 683,
      location: "23.309995,78.373889",
      "inverted one": "'Integrated G.M.S Bhawan Khamaria Manpur'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Khamaria Manpur',23.309995,78.373889)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Khamaria Manpur',23.309995,78.373889),",
    },
    {
      "PART NO": 156,
      "PART NAME": "KHAIRI",
      "Name of polling station building": "Integrated G.M.S Bhawan Khairi",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 706,
      location: "23.27929833,78.37376",
      "inverted one": "'Integrated G.M.S Bhawan Khairi'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Khairi',23.27929833,78.37376)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Khairi',23.27929833,78.37376),",
    },
    {
      "PART NO": 157,
      "PART NAME": "KHAIRI",
      "Name of polling station building": "GOVT.HIGH SCHOOL BUILDING KHAIRI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 719,
      location: "23.281841,78.370022",
      "inverted one": "'GOVT.HIGH SCHOOL BUILDING KHAIRI'",
      cosolideted:
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING KHAIRI',23.281841,78.370022)",
      "with comma":
        "ElectionBooth('GOVT.HIGH SCHOOL BUILDING KHAIRI',23.281841,78.370022),",
    },
    {
      "PART NO": 158,
      "PART NAME": "SAHAJAPURI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SAHAJPURI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 502,
      location: "23.28009,78.3497",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SAHAJPURI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SAHAJPURI',23.28009,78.3497)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SAHAJPURI',23.28009,78.3497),",
    },
    {
      "PART NO": 159,
      "PART NAME": "SINGHPURI UCHERA",
      "Name of polling station building":
        "Integrated G.H.S Bhawan Singhpuri Uchera",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1035,
      location: "23.287688,78.33406",
      "inverted one": "'Integrated G.H.S Bhawan Singhpuri Uchera'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Singhpuri Uchera',23.287688,78.33406)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Singhpuri Uchera',23.287688,78.33406),",
    },
    {
      "PART NO": 160,
      "PART NAME": "SAMANAPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SAMNAPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1116,
      location: "23.2592217,78.3087817",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SAMNAPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SAMNAPUR',23.2592217,78.3087817)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SAMNAPUR',23.2592217,78.3087817),",
    },
    {
      "PART NO": 161,
      "PART NAME": "SIMARIYA KALAN",
      "Name of polling station building":
        "GOVT. MIDDLE SCHOOL BUILDING SIMARIYA KALAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 954,
      location: "23.2786893,78.2619175",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING SIMARIYA KALAN'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING SIMARIYA KALAN',23.2786893,78.2619175)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING SIMARIYA KALAN',23.2786893,78.2619175),",
    },
    {
      "PART NO": 162,
      "PART NAME": "NIMNAPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING NIMNAPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 887,
      location: "23.239002,78.28525",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING NIMNAPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NIMNAPUR',23.239002,78.28525)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NIMNAPUR',23.239002,78.28525),",
    },
    {
      "PART NO": 163,
      "PART NAME": "PEHARIYA",
      "Name of polling station building": "Integrated G.M.S Bhawan Paheriya",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1017,
      location: "23.21573964,78.27074943",
      "inverted one": "'Integrated G.M.S Bhawan Paheriya'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Paheriya',23.21573964,78.27074943)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Paheriya',23.21573964,78.27074943),",
    },
    {
      "PART NO": 164,
      "PART NAME": "CHHITAPAAR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING CHHITAPAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 536,
      location: "23.21573964,78.27074943",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING CHHITAPAR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHHITAPAR',23.21573964,78.27074943)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING CHHITAPAR',23.21573964,78.27074943),",
    },
    {
      "PART NO": 165,
      "PART NAME": "BELGANV",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BELGANV",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 642,
      location: "23.2016432,78.29271111",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BELGANV'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BELGANV',23.2016432,78.29271111)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BELGANV',23.2016432,78.29271111),",
    },
    {
      "PART NO": 166,
      "PART NAME": "KARTOLI",
      "Name of polling station building": "Integrated G.H.S Bhawan Kartauli",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 985,
      location: "23.18765186,78.20017833",
      "inverted one": "'Integrated G.H.S Bhawan Kartauli'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Kartauli',23.18765186,78.20017833)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Kartauli',23.18765186,78.20017833),",
    },
    {
      "PART NO": 167,
      "PART NAME": "SIYALWADA",
      "Name of polling station building": "Integrated G.H.S Bhawan Siyalwada",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1243,
      location: "23.1538931,78.2314975",
      "inverted one": "'Integrated G.H.S Bhawan Siyalwada'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Siyalwada',23.1538931,78.2314975)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Siyalwada',23.1538931,78.2314975),",
    },
    {
      "PART NO": 168,
      "PART NAME": "PADARIYA KALA",
      "Name of polling station building":
        "Integrated G.M.S Bhawan padriya Kala",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1009,
      location: "23.1695647,78.2687934",
      "inverted one": "'Integrated G.M.S Bhawan padriya Kala'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan padriya Kala',23.1695647,78.2687934)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan padriya Kala',23.1695647,78.2687934),",
    },
    {
      "PART NO": 169,
      "PART NAME": "GAGANBADA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING GAGANBADA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 496,
      location: "23.16935,78.2445267",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING GAGANBADA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GAGANBADA',23.16935,78.2445267)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING GAGANBADA',23.16935,78.2445267),",
    },
    {
      "PART NO": 170,
      "PART NAME": "SINGAPUR",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING SINGPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 465,
      location: "23.122615,78.31987667",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING SINGPUR'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SINGPUR',23.122615,78.31987667)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING SINGPUR',23.122615,78.31987667),",
    },
    {
      "PART NO": 171,
      "PART NAME": "SIMARIYA KHURD",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Simaria Khurd",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 740,
      location: "23.13733291,78.30569468",
      "inverted one": "'Integrated G.M.S Bhawan Simaria Khurd'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Simaria Khurd',23.13733291,78.30569468)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Simaria Khurd',23.13733291,78.30569468),",
    },
    {
      "PART NO": 172,
      "PART NAME": "KHERI",
      "Name of polling station building": "Integrated G.M.S Bhawan Khairi",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 906,
      location: "23.14999817,78.29219113",
      "inverted one": "'Integrated G.M.S Bhawan Khairi'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Khairi',23.14999817,78.29219113)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Khairi',23.14999817,78.29219113),",
    },
    {
      "PART NO": 173,
      "PART NAME": "DHAMANPANI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING DHAMANPANI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 732,
      location: "23.14565687,78.31509739",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING DHAMANPANI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING DHAMANPANI',23.14565687,78.31509739)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING DHAMANPANI',23.14565687,78.31509739),",
    },
    {
      "PART NO": 174,
      "PART NAME": "DILHARI",
      "Name of polling station building":
        "Integrated G.B.P.S. Bhawan Delhari-East part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1082,
      location: "23.20230445,78.29757462",
      "inverted one": "'Integrated G.B.P.S. Bhawan Delhari-East part'",
      cosolideted:
        "ElectionBooth('Integrated G.B.P.S. Bhawan Delhari-East part',23.20230445,78.29757462)",
      "with comma":
        "ElectionBooth('Integrated G.B.P.S. Bhawan Delhari-East part',23.20230445,78.29757462),",
    },
    {
      "PART NO": 175,
      "PART NAME": "DILHARI",
      "Name of polling station building":
        "Integrated G.B.P.S. Bhawan Delhari-Western part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 735,
      location: "23.202221,78.3013428",
      "inverted one": "'Integrated G.B.P.S. Bhawan Delhari-Western part'",
      cosolideted:
        "ElectionBooth('Integrated G.B.P.S. Bhawan Delhari-Western part',23.202221,78.3013428)",
      "with comma":
        "ElectionBooth('Integrated G.B.P.S. Bhawan Delhari-Western part',23.202221,78.3013428),",
    },
    {
      "PART NO": 176,
      "PART NAME": "KUNDALI",
      "Name of polling station building":
        "Integrated G.M.S.Bhawan Kundali Northern part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1204,
      location: "23.18899234,78.27927961",
      "inverted one": "'Integrated G.M.S.Bhawan Kundali Northern part'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S.Bhawan Kundali Northern part',23.18899234,78.27927961)",
      "with comma":
        "ElectionBooth('Integrated G.M.S.Bhawan Kundali Northern part',23.18899234,78.27927961),",
    },
    {
      "PART NO": 177,
      "PART NAME": "KUNDALI",
      "Name of polling station building":
        "Integrated G.M.S. Bhawan Kundali Southern part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1113,
      location: "23.18873547,78.27931222",
      "inverted one": "'Integrated G.M.S. Bhawan Kundali Southern part'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Kundali Southern part',23.18873547,78.27931222)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Kundali Southern part',23.18873547,78.27931222),",
    },
    {
      "PART NO": 178,
      "PART NAME": "KUNDALI",
      "Name of polling station building":
        "Integrated G.M.S. Bhawan Kundali center part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1015,
      location: "23.18888205,78.27934105",
      "inverted one": "'Integrated G.M.S. Bhawan Kundali center part'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Kundali center part',23.18888205,78.27934105)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Kundali center part',23.18888205,78.27934105),",
    },
    {
      "PART NO": 179,
      "PART NAME": "KHIRKA GHANTI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING KHIRKAGANTI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 382,
      location: "23.18882051,78.27934105",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING KHIRKAGANTI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KHIRKAGANTI',23.18882051,78.27934105)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING KHIRKAGANTI',23.18882051,78.27934105),",
    },
    {
      "PART NO": 180,
      "PART NAME": "BAMHORI",
      "Name of polling station building":
        "Integrated G.M.S Balika Bhawan Bamhori",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 765,
      location: "23.2040726,78.2955262",
      "inverted one": "'Integrated G.M.S Balika Bhawan Bamhori'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Balika Bhawan Bamhori',23.2040726,78.2955262)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Balika Bhawan Bamhori',23.2040726,78.2955262),",
    },
    {
      "PART NO": 181,
      "PART NAME": "BAMHORI",
      "Name of polling station building":
        "GOVT.PRIMARY SCHOOL BUILDING GOUDIPURA COLONY",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 930,
      location: "23.2087335,78.296564",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING GOUDIPURA COLONY'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING GOUDIPURA COLONY',23.2087335,78.296564)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING GOUDIPURA COLONY',23.2087335,78.296564),",
    },
    {
      "PART NO": 182,
      "PART NAME": "BAMHORI",
      "Name of polling station building": "PANCHAYAT BUILDING BAMHORI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1346,
      location: "23.2036539,78.2948012",
      "inverted one": "'PANCHAYAT BUILDING BAMHORI'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING BAMHORI',23.2036539,78.2948012)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING BAMHORI',23.2036539,78.2948012),",
    },
    {
      "PART NO": 183,
      "PART NAME": "BAMHORI",
      "Name of polling station building": "PANCHAYAT BUILDING BAMHORI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 856,
      location: "23.2035125,78.2912134",
      "inverted one": "'PANCHAYAT BUILDING BAMHORI'",
      cosolideted:
        "ElectionBooth('PANCHAYAT BUILDING BAMHORI',23.2035125,78.2912134)",
      "with comma":
        "ElectionBooth('PANCHAYAT BUILDING BAMHORI',23.2035125,78.2912134),",
    },
    {
      "PART NO": 184,
      "PART NAME": "BHAISARA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BHAISARA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 670,
      location: "23.2102917,78.2848017",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BHAISARA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BHAISARA',23.2102917,78.2848017)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BHAISARA',23.2102917,78.2848017),",
    },
    {
      "PART NO": 185,
      "PART NAME": "ARJANI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING ARJANI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 707,
      location: "23.21665167,78.31307",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING ARJANI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ARJANI',23.21665167,78.31307)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ARJANI',23.21665167,78.31307),",
    },
    {
      "PART NO": 186,
      "PART NAME": "BARCHHEKA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BARCHHEKA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 829,
      location: "23.24330833,78.30582666",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BARCHHEKA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BARCHHEKA',23.24330833,78.30582666)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BARCHHEKA',23.24330833,78.30582666),",
    },
    {
      "PART NO": 187,
      "PART NAME": "GUNDRAI",
      "Name of polling station building": "Integrated G.M.S Bhawan Gundrai",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 657,
      location: "23.21387679,78.32257088",
      "inverted one": "'Integrated G.M.S Bhawan Gundrai'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Gundrai',23.21387679,78.32257088)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Gundrai',23.21387679,78.32257088),",
    },
    {
      "PART NO": 188,
      "PART NAME": "PRATAPGARH",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING NAGPURA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 459,
      location: "23.18807667,78.33395667",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING NAGPURA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NAGPURA',23.18807667,78.33395667)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NAGPURA',23.18807667,78.33395667),",
    },
    {
      "PART NO": 189,
      "PART NAME": "SHIVNI",
      "Name of polling station building": "Integrated G.M.S Bhawan Shivni",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 555,
      location: "23.16286472,78.34338381",
      "inverted one": "'Integrated G.M.S Bhawan Shivni'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Shivni',23.16286472,78.34338381)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Shivni',23.16286472,78.34338381),",
    },
    {
      "PART NO": 190,
      "PART NAME": "ROSARAGHATI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING ROSRA GHATI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1037,
      location: "23.18823455,78.34724299",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING ROSRA GHATI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ROSRA GHATI',23.18823455,78.34724299)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING ROSRA GHATI',23.18823455,78.34724299),",
    },
    {
      "PART NO": 191,
      "PART NAME": "MANAKVADA",
      "Name of polling station building": "Integrated G.M.S Bhawan Manakwada",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 966,
      location: "23.18563966,78.38150136",
      "inverted one": "'Integrated G.M.S Bhawan Manakwada'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Manakwada',23.18563966,78.38150136)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Manakwada',23.18563966,78.38150136),",
    },
    {
      "PART NO": 192,
      "PART NAME": "RIMJHA",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING RIMJHA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 507,
      location: "23.18970423,78.37584325",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING RIMJHA'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING RIMJHA',23.18970423,78.37584325)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING RIMJHA',23.18970423,78.37584325),",
    },
    {
      "PART NO": 193,
      "PART NAME": "PADARIYA KHURD",
      "Name of polling station building":
        "Integrated G.M.S. Bhawan Padaria Khurd",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 695,
      location: "23.22103,78.37462",
      "inverted one": "'Integrated G.M.S. Bhawan Padaria Khurd'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Padaria Khurd',23.22103,78.37462)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Padaria Khurd',23.22103,78.37462),",
    },
    {
      "PART NO": 194,
      "PART NAME": "MUDHAPAR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING MUDHAPAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 678,
      location: "23.22979534,78.3377705",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING MUDHAPAR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MUDHAPAR',23.22979534,78.3377705)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MUDHAPAR',23.22979534,78.3377705),",
    },
    {
      "PART NO": 195,
      "PART NAME": "TULSIPAAR",
      "Name of polling station building": "Govt.H.S.School Bhawan Tulsipaar",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 594,
      location: "23.2341004,78.3382875",
      "inverted one": "'Govt.H.S.School Bhawan Tulsipaar'",
      cosolideted:
        "ElectionBooth('Govt.H.S.School Bhawan Tulsipaar',23.2341004,78.3382875)",
      "with comma":
        "ElectionBooth('Govt.H.S.School Bhawan Tulsipaar',23.2341004,78.3382875),",
    },
    {
      "PART NO": 196,
      "PART NAME": "CHINGWADA KALA",
      "Name of polling station building":
        "Integrated G.M.S. Bhawan Chingwada Kala",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1156,
      location: "23.25294981,78.32349997",
      "inverted one": "'Integrated G.M.S. Bhawan Chingwada Kala'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Chingwada Kala',23.25294981,78.32349997)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Chingwada Kala',23.25294981,78.32349997),",
    },
    {
      "PART NO": 197,
      "PART NAME": "USHAPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING USHAPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 594,
      location: "23.23174836,78.36447",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING USHAPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING USHAPUR',23.23174836,78.36447)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING USHAPUR',23.23174836,78.36447),",
    },
    {
      "PART NO": 198,
      "PART NAME": "RAMPURA KALA",
      "Name of polling station building":
        "Integrated G.M.S. Bhawan Rampura Kala",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1211,
      location: "23.23184824,78.367578",
      "inverted one": "'Integrated G.M.S. Bhawan Rampura Kala'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Rampura Kala',23.23184824,78.367578)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Rampura Kala',23.23184824,78.367578),",
    },
    {
      "PART NO": 199,
      "PART NAME": "KIRATPUR",
      "Name of polling station building": "Integrated G.H.S.Bhawan Kiratpur",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1207,
      location: "23.23187484,78.37668586",
      "inverted one": "'Integrated G.H.S.Bhawan Kiratpur'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S.Bhawan Kiratpur',23.23187484,78.37668586)",
      "with comma":
        "ElectionBooth('Integrated G.H.S.Bhawan Kiratpur',23.23187484,78.37668586),",
    },
    {
      "PART NO": 200,
      "PART NAME": "BAMHORI VARDA",
      "Name of polling station building":
        "Integrated G.N.M.S Bhawan Bamhori Wardha",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 473,
      location: "23.20763582,78.4017799",
      "inverted one": "'Integrated G.N.M.S Bhawan Bamhori Wardha'",
      cosolideted:
        "ElectionBooth('Integrated G.N.M.S Bhawan Bamhori Wardha',23.20763582,78.4017799)",
      "with comma":
        "ElectionBooth('Integrated G.N.M.S Bhawan Bamhori Wardha',23.20763582,78.4017799),",
    },
    {
      "PART NO": 201,
      "PART NAME": "VARDHA",
      "Name of polling station building":
        "GOVT. NEW PRIMARY SCHOOL BUILDING VARDHA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 364,
      location: "23.2266523,78.39802182",
      "inverted one": "'GOVT. NEW PRIMARY SCHOOL BUILDING VARDHA'",
      cosolideted:
        "ElectionBooth('GOVT. NEW PRIMARY SCHOOL BUILDING VARDHA',23.2266523,78.39802182)",
      "with comma":
        "ElectionBooth('GOVT. NEW PRIMARY SCHOOL BUILDING VARDHA',23.2266523,78.39802182),",
    },
    {
      "PART NO": 202,
      "PART NAME": "SALAIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SALAIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 596,
      location: "23.25126117,78.39079479",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SALAIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SALAIYA',23.25126117,78.39079479)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SALAIYA',23.25126117,78.39079479),",
    },
    {
      "PART NO": 203,
      "PART NAME": "BHOUDIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BHOUDIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 617,
      location: "23.2835513,78.4053737",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BHOUDIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BHOUDIYA',23.2835513,78.4053737)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BHOUDIYA',23.2835513,78.4053737),",
    },
    {
      "PART NO": 204,
      "PART NAME": "SAIKHEDA",
      "Name of polling station building":
        "Integrated G.H.S.S Bhawan Saikheda East Part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1008,
      location: "23.25133365,78.42120661",
      "inverted one": "'Integrated G.H.S.S Bhawan Saikheda East Part'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S.S Bhawan Saikheda East Part',23.25133365,78.42120661)",
      "with comma":
        "ElectionBooth('Integrated G.H.S.S Bhawan Saikheda East Part',23.25133365,78.42120661),",
    },
    {
      "PART NO": 205,
      "PART NAME": "SAIKHEDA",
      "Name of polling station building":
        "Integrated G.H.S. Bhawan saikheda (North Part)",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 923,
      location: "23.24848,78.4250317",
      "inverted one": "'Integrated G.H.S. Bhawan saikheda (North Part)'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S. Bhawan saikheda (North Part)',23.24848,78.4250317)",
      "with comma":
        "ElectionBooth('Integrated G.H.S. Bhawan saikheda (North Part)',23.24848,78.4250317),",
    },
    {
      "PART NO": 206,
      "PART NAME": "SAIKHEDA",
      "Name of polling station building":
        "Integrated G.G.M.S Bhawan Saikheda Southern Part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 768,
      location: "23.24904,78.42723",
      "inverted one": "'Integrated G.G.M.S Bhawan Saikheda Southern Part'",
      cosolideted:
        "ElectionBooth('Integrated G.G.M.S Bhawan Saikheda Southern Part',23.24904,78.42723)",
      "with comma":
        "ElectionBooth('Integrated G.G.M.S Bhawan Saikheda Southern Part',23.24904,78.42723),",
    },
    {
      "PART NO": 207,
      "PART NAME": "SAIKHEDA",
      "Name of polling station building":
        "Integrated G.G.M.S. Bhawan Saikheda western part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 713,
      location: "23.25133681,78.42120478",
      "inverted one": "'Integrated G.G.M.S. Bhawan Saikheda western part'",
      cosolideted:
        "ElectionBooth('Integrated G.G.M.S. Bhawan Saikheda western part',23.25133681,78.42120478)",
      "with comma":
        "ElectionBooth('Integrated G.G.M.S. Bhawan Saikheda western part',23.25133681,78.42120478),",
    },
    {
      "PART NO": 208,
      "PART NAME": "SIMARIYA KALAN",
      "Name of polling station building":
        "Integrated G.M.S.Bhawan Simaria Kala",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 739,
      location: "23.22928659,78.44564894",
      "inverted one": "'Integrated G.M.S.Bhawan Simaria Kala'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S.Bhawan Simaria Kala',23.22928659,78.44564894)",
      "with comma":
        "ElectionBooth('Integrated G.M.S.Bhawan Simaria Kala',23.22928659,78.44564894),",
    },
    {
      "PART NO": 209,
      "PART NAME": "BUDHA",
      "Name of polling station building": "GOVT.PS SCHOOL BULDING BUDHA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 556,
      location: "23.216745,78.43098",
      "inverted one": "'GOVT.PS SCHOOL BULDING BUDHA'",
      cosolideted:
        "ElectionBooth('GOVT.PS SCHOOL BULDING BUDHA',23.216745,78.43098)",
      "with comma":
        "ElectionBooth('GOVT.PS SCHOOL BULDING BUDHA',23.216745,78.43098),",
    },
    {
      "PART NO": 210,
      "PART NAME": "UMARJHIR",
      "Name of polling station building": "Integrated G.M.S. Bhawan Umarjhir",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 887,
      location: "23.196102,78.419872",
      "inverted one": "'Integrated G.M.S. Bhawan Umarjhir'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Umarjhir',23.196102,78.419872)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Umarjhir',23.196102,78.419872),",
    },
    {
      "PART NO": 211,
      "PART NAME": "MORAN PIPALIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING MORAN PIPALIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 596,
      location: "23.20407266,78.4456992",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING MORAN PIPALIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MORAN PIPALIYA',23.20407266,78.4456992)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MORAN PIPALIYA',23.20407266,78.4456992),",
    },
    {
      "PART NO": 212,
      "PART NAME": "CHUNHETIYA",
      "Name of polling station building":
        "Integrated G.H.S. Chunhaitia Eastern Part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 694,
      location: "23.2089797,78.4782561",
      "inverted one": "'Integrated G.H.S. Chunhaitia Eastern Part'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S. Chunhaitia Eastern Part',23.2089797,78.4782561)",
      "with comma":
        "ElectionBooth('Integrated G.H.S. Chunhaitia Eastern Part',23.2089797,78.4782561),",
    },
    {
      "PART NO": 213,
      "PART NAME": "CHUNHETIYA",
      "Name of polling station building":
        "Integrated G.H.S. Chunhaitia Western Part",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 658,
      location: "23.22924566,78.44568918",
      "inverted one": "'Integrated G.H.S. Chunhaitia Western Part'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S. Chunhaitia Western Part',23.22924566,78.44568918)",
      "with comma":
        "ElectionBooth('Integrated G.H.S. Chunhaitia Western Part',23.22924566,78.44568918),",
    },
    {
      "PART NO": 214,
      "PART NAME": "SAHAJAPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SAHAJPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 496,
      location: "23.22928659,78.44564894",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SAHAJPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SAHAJPUR',23.22928659,78.44564894)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SAHAJPUR',23.22928659,78.44564894),",
    },
    {
      "PART NO": 215,
      "PART NAME": "SARRA KALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SARRA KALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 462,
      location: "23.222655,78.500007",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SARRA KALA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SARRA KALA',23.222655,78.500007)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SARRA KALA',23.222655,78.500007),",
    },
    {
      "PART NO": 216,
      "PART NAME": "CHICHOLI",
      "Name of polling station building": "Integrated G.M.S. Bhawan Chicholi",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1028,
      location: "23.27349863,78.47140845",
      "inverted one": "'Integrated G.M.S. Bhawan Chicholi'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Chicholi',23.27349863,78.47140845)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Chicholi',23.27349863,78.47140845),",
    },
    {
      "PART NO": 217,
      "PART NAME": "KUNWAR PIPALIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KUNWAR PIPALIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 777,
      location: "23.25434794,78.46425008",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KUNWAR PIPALIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KUNWAR PIPALIYA',23.25434794,78.46425008)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KUNWAR PIPALIYA',23.25434794,78.46425008),",
    },
    {
      "PART NO": 218,
      "PART NAME": "NIGARI",
      "Name of polling station building": "GOVT.PRIMARY SCHOOL BUILDING NIGARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 645,
      location: "23.272625,78.426172",
      "inverted one": "'GOVT.PRIMARY SCHOOL BUILDING NIGARI'",
      cosolideted:
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING NIGARI',23.272625,78.426172)",
      "with comma":
        "ElectionBooth('GOVT.PRIMARY SCHOOL BUILDING NIGARI',23.272625,78.426172),",
    },
    {
      "PART NO": 219,
      "PART NAME": "SILAWANI",
      "Name of polling station building": "GANDHI ASHRAM (NORTH PART) SILWANI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 851,
      location: "23.3001807,78.4375346",
      "inverted one": "'GANDHI ASHRAM (NORTH PART) SILWANI'",
      cosolideted:
        "ElectionBooth('GANDHI ASHRAM (NORTH PART) SILWANI',23.3001807,78.4375346)",
      "with comma":
        "ElectionBooth('GANDHI ASHRAM (NORTH PART) SILWANI',23.3001807,78.4375346),",
    },
    {
      "PART NO": 220,
      "PART NAME": "SILAVANI",
      "Name of polling station building":
        "JANPAD PANCHAYAT PRASHIKSHAN BUILDING SILWANI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1401,
      location: "23.3054109,78.4358349",
      "inverted one": "'JANPAD PANCHAYAT PRASHIKSHAN BUILDING SILWANI'",
      cosolideted:
        "ElectionBooth('JANPAD PANCHAYAT PRASHIKSHAN BUILDING SILWANI',23.3054109,78.4358349)",
      "with comma":
        "ElectionBooth('JANPAD PANCHAYAT PRASHIKSHAN BUILDING SILWANI',23.3054109,78.4358349),",
    },
    {
      "PART NO": 221,
      "PART NAME": "SILAWANI",
      "Name of polling station building": "GANDHI ASHRAM (SOUTH PART) SILWANI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 881,
      location: "23.29986964,78.43744581",
      "inverted one": "'GANDHI ASHRAM (SOUTH PART) SILWANI'",
      cosolideted:
        "ElectionBooth('GANDHI ASHRAM (SOUTH PART) SILWANI',23.29986964,78.43744581)",
      "with comma":
        "ElectionBooth('GANDHI ASHRAM (SOUTH PART) SILWANI',23.29986964,78.43744581),",
    },
    {
      "PART NO": 222,
      "PART NAME": "SILAVANI",
      "Name of polling station building": "GANDHI ASHRAM NORTH PART SILWANI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 541,
      location: "23.300238,78.43781",
      "inverted one": "'GANDHI ASHRAM NORTH PART SILWANI'",
      cosolideted:
        "ElectionBooth('GANDHI ASHRAM NORTH PART SILWANI',23.300238,78.43781)",
      "with comma":
        "ElectionBooth('GANDHI ASHRAM NORTH PART SILWANI',23.300238,78.43781),",
    },
    {
      "PART NO": 223,
      "PART NAME": "SILAWANI",
      "Name of polling station building":
        "GANDHI ASHRAM NORTH EAST PART SILWANI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 961,
      location: "23.3001196,78.4375744",
      "inverted one": "'GANDHI ASHRAM NORTH EAST PART SILWANI'",
      cosolideted:
        "ElectionBooth('GANDHI ASHRAM NORTH EAST PART SILWANI',23.3001196,78.4375744)",
      "with comma":
        "ElectionBooth('GANDHI ASHRAM NORTH EAST PART SILWANI',23.3001196,78.4375744),",
    },
    {
      "PART NO": 224,
      "PART NAME": "SILWANI",
      "Name of polling station building":
        "Govt. Bhimrao Ambedkar Mangal Bhawan Silwani",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 922,
      location: "23.300527,78.436067",
      "inverted one": "'Govt. Bhimrao Ambedkar Mangal Bhawan Silwani'",
      cosolideted:
        "ElectionBooth('Govt. Bhimrao Ambedkar Mangal Bhawan Silwani',23.300527,78.436067)",
      "with comma":
        "ElectionBooth('Govt. Bhimrao Ambedkar Mangal Bhawan Silwani',23.300527,78.436067),",
    },
    {
      "PART NO": 225,
      "PART NAME": "SILWANI",
      "Name of polling station building":
        "Integrated G.G.H.S. School Building Room No.-5 Silwani",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 908,
      location: "23.29917,78.441255",
      "inverted one":
        "'Integrated G.G.H.S. School Building Room No.-5 Silwani'",
      cosolideted:
        "ElectionBooth('Integrated G.G.H.S. School Building Room No.-5 Silwani',23.29917,78.441255)",
      "with comma":
        "ElectionBooth('Integrated G.G.H.S. School Building Room No.-5 Silwani',23.29917,78.441255),",
    },
    {
      "PART NO": 226,
      "PART NAME": "SILWANI",
      "Name of polling station building":
        "Govt.B.P.Shool Bhawan Hall North Eastern Part No.-2",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 803,
      location: "23.29967424,78.44129095",
      "inverted one": "'Govt.B.P.Shool Bhawan Hall North Eastern Part No.-2'",
      cosolideted:
        "ElectionBooth('Govt.B.P.Shool Bhawan Hall North Eastern Part No.-2',23.29967424,78.44129095)",
      "with comma":
        "ElectionBooth('Govt.B.P.Shool Bhawan Hall North Eastern Part No.-2',23.29967424,78.44129095),",
    },
    {
      "PART NO": 227,
      "PART NAME": "SILWANI",
      "Name of polling station building":
        "Integrated Govt.G.H.S. School Bhawan Room No-7 Silwani",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 630,
      location: "23.300113,78.442475",
      "inverted one":
        "'Integrated Govt.G.H.S. School Bhawan Room No-7 Silwani'",
      cosolideted:
        "ElectionBooth('Integrated Govt.G.H.S. School Bhawan Room No-7 Silwani',23.300113,78.442475)",
      "with comma":
        "ElectionBooth('Integrated Govt.G.H.S. School Bhawan Room No-7 Silwani',23.300113,78.442475),",
    },
    {
      "PART NO": 228,
      "PART NAME": "SILWANI",
      "Name of polling station building": "Govt.P.S. Bhawan Rajiv Nagar Padan",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1313,
      location: "23.3108972,78.4483179",
      "inverted one": "'Govt.P.S. Bhawan Rajiv Nagar Padan'",
      cosolideted:
        "ElectionBooth('Govt.P.S. Bhawan Rajiv Nagar Padan',23.3108972,78.4483179)",
      "with comma":
        "ElectionBooth('Govt.P.S. Bhawan Rajiv Nagar Padan',23.3108972,78.4483179),",
    },
    {
      "PART NO": 229,
      "PART NAME": "SILAVANI",
      "Name of polling station building": "VIKAS KHAND STROT KENDRA SILWANI",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1230,
      location: "23.29996,78.441162",
      "inverted one": "'VIKAS KHAND STROT KENDRA SILWANI'",
      cosolideted:
        "ElectionBooth('VIKAS KHAND STROT KENDRA SILWANI',23.29996,78.441162)",
      "with comma":
        "ElectionBooth('VIKAS KHAND STROT KENDRA SILWANI',23.29996,78.441162),",
    },
    {
      "PART NO": 230,
      "PART NAME": "SILAVANI",
      "Name of polling station building":
        "GOVT. Boys Middle School Building Silwani",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1448,
      location: "23.29932667,78.44242167",
      "inverted one": "'GOVT. Boys Middle School Building Silwani'",
      cosolideted:
        "ElectionBooth('GOVT. Boys Middle School Building Silwani',23.29932667,78.44242167)",
      "with comma":
        "ElectionBooth('GOVT. Boys Middle School Building Silwani',23.29932667,78.44242167),",
    },
    {
      "PART NO": 231,
      "PART NAME": "SILWANI",
      "Name of polling station building":
        "Integrated G.G.H.S. Building Room No.1 Silwani",
      "PS TYPE": "U",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 631,
      location: "23.299192,78.44196",
      "inverted one": "'Integrated G.G.H.S. Building Room No.1 Silwani'",
      cosolideted:
        "ElectionBooth('Integrated G.G.H.S. Building Room No.1 Silwani',23.299192,78.44196)",
      "with comma":
        "ElectionBooth('Integrated G.G.H.S. Building Room No.1 Silwani',23.299192,78.44196),",
    },
    {
      "PART NO": 232,
      "PART NAME": "DUGARIYAN KALA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING DUGARIYAN KALA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 956,
      location: "23.29922262,78.44314029",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING DUGARIYAN KALA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DUGARIYAN KALA',23.29922262,78.44314029)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING DUGARIYAN KALA',23.29922262,78.44314029),",
    },
    {
      "PART NO": 233,
      "PART NAME": "JAITAPUR",
      "Name of polling station building":
        "GOVT.MIDDLE SCHOOL BUILDING JAITAPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 987,
      location: "23.29110711,78.51643625",
      "inverted one": "'GOVT.MIDDLE SCHOOL BUILDING JAITAPUR'",
      cosolideted:
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING JAITAPUR',23.29110711,78.51643625)",
      "with comma":
        "ElectionBooth('GOVT.MIDDLE SCHOOL BUILDING JAITAPUR',23.29110711,78.51643625),",
    },
    {
      "PART NO": 234,
      "PART NAME": "HINOTIYA",
      "Name of polling station building": "Integrated G.M.S. Bhawan Hinotia",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1127,
      location: "23.297337,78.536043",
      "inverted one": "'Integrated G.M.S. Bhawan Hinotia'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Hinotia',23.297337,78.536043)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Hinotia',23.297337,78.536043),",
    },
    {
      "PART NO": 235,
      "PART NAME": "MOUAR",
      "Name of polling station building": "GOVT. MIDDLE SCHOOL BUILDING MOUAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1205,
      location: "23.271896,78.504154",
      "inverted one": "'GOVT. MIDDLE SCHOOL BUILDING MOUAR'",
      cosolideted:
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING MOUAR',23.271896,78.504154)",
      "with comma":
        "ElectionBooth('GOVT. MIDDLE SCHOOL BUILDING MOUAR',23.271896,78.504154),",
    },
    {
      "PART NO": 236,
      "PART NAME": "PATHAPOUDI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PATHAPOUDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 985,
      location: "23.253912,78.503853",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PATHAPOUDI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PATHAPOUDI',23.253912,78.503853)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PATHAPOUDI',23.253912,78.503853),",
    },
    {
      "PART NO": 237,
      "PART NAME": "HAMEERPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING HAMEERPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 486,
      location: "23.246542,78.487697",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING HAMEERPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING HAMEERPUR',23.246542,78.487697)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING HAMEERPUR',23.246542,78.487697),",
    },
    {
      "PART NO": 238,
      "PART NAME": "DEVRI",
      "Name of polling station building": "Integrated G.M.S Bhawan Deori",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 764,
      location: "23.253802,78.53382",
      "inverted one": "'Integrated G.M.S Bhawan Deori'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Deori',23.253802,78.53382)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Deori',23.253802,78.53382),",
    },
    {
      "PART NO": 239,
      "PART NAME": "MAGARMOULI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING MAGARMOULI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 939,
      location: "23.2425509,78.5552606",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING MAGARMOULI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MAGARMOULI',23.2425509,78.5552606)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MAGARMOULI',23.2425509,78.5552606),",
    },
    {
      "PART NO": 240,
      "PART NAME": "RAYPURA",
      "Name of polling station building": "Integrated G.M.S Bhawan Raypura",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 681,
      location: "23.24222,78.5974428",
      "inverted one": "'Integrated G.M.S Bhawan Raypura'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Raypura',23.24222,78.5974428)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Raypura',23.24222,78.5974428),",
    },
    {
      "PART NO": 241,
      "PART NAME": "FULMAAR",
      "Name of polling station building": "Integrated G.M.S Bhawan Fulmar",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 985,
      location: "23.24874167,78.62532667",
      "inverted one": "'Integrated G.M.S Bhawan Fulmar'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Fulmar',23.24874167,78.62532667)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Fulmar',23.24874167,78.62532667),",
    },
    {
      "PART NO": 242,
      "PART NAME": "BARRAPOUDI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BARRAPOUDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 743,
      location: "23.2714383,78.581675",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BARRAPOUDI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BARRAPOUDI',23.2714383,78.581675)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BARRAPOUDI',23.2714383,78.581675),",
    },
    {
      "PART NO": 243,
      "PART NAME": "KHAMKUNA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KHAMKUNA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1093,
      location: "23.29792156,78.57478115",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KHAMKUNA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHAMKUNA',23.29792156,78.57478115)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KHAMKUNA',23.29792156,78.57478115),",
    },
    {
      "PART NO": 244,
      "PART NAME": "DEVRI HATNAPUR",
      "Name of polling station building":
        "Integrated G.H.S Bhawan Deori Hathnapur",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1214,
      location: "23.328925,78.6121338",
      "inverted one": "'Integrated G.H.S Bhawan Deori Hathnapur'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S Bhawan Deori Hathnapur',23.328925,78.6121338)",
      "with comma":
        "ElectionBooth('Integrated G.H.S Bhawan Deori Hathnapur',23.328925,78.6121338),",
    },
    {
      "PART NO": 245,
      "PART NAME": "SANAIDAR",
      "Name of polling station building": "Integrated G.M.S. Bhawan Sanaidaar",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 542,
      location: "23.32283848,78.58964916",
      "inverted one": "'Integrated G.M.S. Bhawan Sanaidaar'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Sanaidaar',23.32283848,78.58964916)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Sanaidaar',23.32283848,78.58964916),",
    },
    {
      "PART NO": 246,
      "PART NAME": "PARASIYA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PARASIYA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 973,
      location: "23.244989,78.628702",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PARASIYA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PARASIYA',23.244989,78.628702)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PARASIYA',23.244989,78.628702),",
    },
    {
      "PART NO": 247,
      "PART NAME": "MARAHETHI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING MARHETHI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1070,
      location: "23.2536237,78.6467564",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING MARHETHI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MARHETHI',23.2536237,78.6467564)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING MARHETHI',23.2536237,78.6467564),",
    },
    {
      "PART NO": 248,
      "PART NAME": "PRATAPGADH",
      "Name of polling station building":
        "Integrated G.H.S.S Bhawan Pratapgarh",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 777,
      location: "23.266399,78.643321",
      "inverted one": "'Integrated G.H.S.S Bhawan Pratapgarh'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S.S Bhawan Pratapgarh',23.266399,78.643321)",
      "with comma":
        "ElectionBooth('Integrated G.H.S.S Bhawan Pratapgarh',23.266399,78.643321),",
    },
    {
      "PART NO": 249,
      "PART NAME": "NAGPURA",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING NAGPURA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 384,
      location: "23.28703486,78.64506802",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING NAGPURA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NAGPURA',23.28703486,78.64506802)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING NAGPURA',23.28703486,78.64506802),",
    },
    {
      "PART NO": 250,
      "PART NAME": "SEMARAKHAS",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SEMARAKHAS",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1213,
      location: "23.3100978,78.682767",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SEMARAKHAS'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SEMARAKHAS',23.3100978,78.682767)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SEMARAKHAS',23.3100978,78.682767),",
    },
    {
      "PART NO": 251,
      "PART NAME": "GHOGHARI",
      "Name of polling station building": "GOVT.PS SCHOOL BULDING GHOGHARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 769,
      location: "23.318548,78.698614",
      "inverted one": "'GOVT.PS SCHOOL BULDING GHOGHARI'",
      cosolideted:
        "ElectionBooth('GOVT.PS SCHOOL BULDING GHOGHARI',23.318548,78.698614)",
      "with comma":
        "ElectionBooth('GOVT.PS SCHOOL BULDING GHOGHARI',23.318548,78.698614),",
    },
    {
      "PART NO": 252,
      "PART NAME": "RAIPANI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING RAIPANI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 631,
      location: "23.31986056,78.69889695",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING RAIPANI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAIPANI',23.31986056,78.69889695)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING RAIPANI',23.31986056,78.69889695),",
    },
    {
      "PART NO": 253,
      "PART NAME": "SAMNAPUR JAGEER",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Samnapur Jaageer",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1037,
      location: "23.312284,78.744344",
      "inverted one": "'Integrated G.M.S Bhawan Samnapur Jaageer'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Samnapur Jaageer',23.312284,78.744344)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Samnapur Jaageer',23.312284,78.744344),",
    },
    {
      "PART NO": 254,
      "PART NAME": "SHALAVARRU",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING SHALAVARRU",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1172,
      location: "23.2718848,78.73013724",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING SHALAVARRU'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SHALAVARRU',23.2718848,78.73013724)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING SHALAVARRU',23.2718848,78.73013724),",
    },
    {
      "PART NO": 255,
      "PART NAME": "PAPADA",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL BUILDING PAPDA",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 515,
      location: "23.2911165,78.7265404",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PAPDA'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PAPDA',23.2911165,78.7265404)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PAPDA',23.2911165,78.7265404),",
    },
    {
      "PART NO": 256,
      "PART NAME": "AMAGAVAN",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING AMAGAVAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 719,
      location: "23.2864632,78.7043809",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING AMAGAVAN'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING AMAGAVAN',23.2864632,78.7043809)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING AMAGAVAN',23.2864632,78.7043809),",
    },
    {
      "PART NO": 257,
      "PART NAME": "PIPALIYA KHAS",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING PIPALIYA KHAS",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 628,
      location: "23.25118564,78.7034557",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING PIPALIYA KHAS'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PIPALIYA KHAS',23.25118564,78.7034557)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING PIPALIYA KHAS',23.25118564,78.7034557),",
    },
    {
      "PART NO": 258,
      "PART NAME": "POUNAR",
      "Name of polling station building":
        "GOVT. NEW PRIMARY SCHOOL BUILDING POUNAR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 576,
      location: "23.257392,78.70847",
      "inverted one": "'GOVT. NEW PRIMARY SCHOOL BUILDING POUNAR'",
      cosolideted:
        "ElectionBooth('GOVT. NEW PRIMARY SCHOOL BUILDING POUNAR',23.257392,78.70847)",
      "with comma":
        "ElectionBooth('GOVT. NEW PRIMARY SCHOOL BUILDING POUNAR',23.257392,78.70847),",
    },
    {
      "PART NO": 259,
      "PART NAME": "GUTORI",
      "Name of polling station building": "Integrated G.M.S. Bhawan Gutori",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 706,
      location: "23.233086,78.69800241",
      "inverted one": "'Integrated G.M.S. Bhawan Gutori'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Gutori',23.233086,78.69800241)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Gutori',23.233086,78.69800241),",
    },
    {
      "PART NO": 260,
      "PART NAME": "SARRA",
      "Name of polling station building": "Integrated G.M.S.Bhawan Sarra",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 473,
      location: "23.19357,78.683544",
      "inverted one": "'Integrated G.M.S.Bhawan Sarra'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S.Bhawan Sarra',23.19357,78.683544)",
      "with comma":
        "ElectionBooth('Integrated G.M.S.Bhawan Sarra',23.19357,78.683544),",
    },
    {
      "PART NO": 261,
      "PART NAME": "POUDI",
      "Name of polling station building": "GOVT. PRIMARY SCHOOL BUILDING POUDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 731,
      location: "23.24378176,78.67484754",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING POUDI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING POUDI',23.24378176,78.67484754)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING POUDI',23.24378176,78.67484754),",
    },
    {
      "PART NO": 262,
      "PART NAME": "CHAINPUR",
      "Name of polling station building":
        "Integrated G.Higher Secondary School Chainpur",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 669,
      location: "23.23308135,78.69799006",
      "inverted one": "'Integrated G.Higher Secondary School Chainpur'",
      cosolideted:
        "ElectionBooth('Integrated G.Higher Secondary School Chainpur',23.23308135,78.69799006)",
      "with comma":
        "ElectionBooth('Integrated G.Higher Secondary School Chainpur',23.23308135,78.69799006),",
    },
    {
      "PART NO": 263,
      "PART NAME": "KHAMARIYA KHURD",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Khamaria Khurd",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 955,
      location: "23.2441349,78.6568017",
      "inverted one": "'Integrated G.M.S Bhawan Khamaria Khurd'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Khamaria Khurd',23.2441349,78.6568017)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Khamaria Khurd',23.2441349,78.6568017),",
    },
    {
      "PART NO": 264,
      "PART NAME": "CHHIND",
      "Name of polling station building": "Integrated G.G.M.S Bhavan Chhind",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1156,
      location: "23.22739396,78.62167885",
      "inverted one": "'Integrated G.G.M.S Bhavan Chhind'",
      cosolideted:
        "ElectionBooth('Integrated G.G.M.S Bhavan Chhind',23.22739396,78.62167885)",
      "with comma":
        "ElectionBooth('Integrated G.G.M.S Bhavan Chhind',23.22739396,78.62167885),",
    },
    {
      "PART NO": 265,
      "PART NAME": "JAITHARI",
      "Name of polling station building": "SWARAJ BUILDING JAITHARI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 695,
      location: "23.209699,78.614397",
      "inverted one": "'SWARAJ BUILDING JAITHARI'",
      cosolideted:
        "ElectionBooth('SWARAJ BUILDING JAITHARI',23.209699,78.614397)",
      "with comma":
        "ElectionBooth('SWARAJ BUILDING JAITHARI',23.209699,78.614397),",
    },
    {
      "PART NO": 266,
      "PART NAME": "JAITHARI",
      "Name of polling station building": "Integrated G.M.S. Bhawan Jaithari",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 816,
      location: "23.206589,78.608146",
      "inverted one": "'Integrated G.M.S. Bhawan Jaithari'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Jaithari',23.206589,78.608146)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Jaithari',23.206589,78.608146),",
    },
    {
      "PART NO": 267,
      "PART NAME": "VIKALPUR",
      "Name of polling station building": "Integrated G.H.S. Bhawan Vikalpur",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1172,
      location: "23.211601,78.580638",
      "inverted one": "'Integrated G.H.S. Bhawan Vikalpur'",
      cosolideted:
        "ElectionBooth('Integrated G.H.S. Bhawan Vikalpur',23.211601,78.580638)",
      "with comma":
        "ElectionBooth('Integrated G.H.S. Bhawan Vikalpur',23.211601,78.580638),",
    },
    {
      "PART NO": 268,
      "PART NAME": "HATODA",
      "Name of polling station building": "Integrated G.M.S. Bhawan Hatoda",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 1051,
      location: "23.23165359,78.5543728",
      "inverted one": "'Integrated G.M.S. Bhawan Hatoda'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Hatoda',23.23165359,78.5543728)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Hatoda',23.23165359,78.5543728),",
    },
    {
      "PART NO": 269,
      "PART NAME": "MAHGAWA KALA",
      "Name of polling station building":
        "Integrated G.M.S. Bhawan Mahgwan Kala",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 874,
      location: "23.299225,78.44125833",
      "inverted one": "'Integrated G.M.S. Bhawan Mahgwan Kala'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S. Bhawan Mahgwan Kala',23.299225,78.44125833)",
      "with comma":
        "ElectionBooth('Integrated G.M.S. Bhawan Mahgwan Kala',23.299225,78.44125833),",
    },
    {
      "PART NO": 270,
      "PART NAME": "DHANAGAVAN",
      "Name of polling station building":
        "GOVT. NEW PRIMARY SCHOOL BUILDING DHANAGAVAN",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 652,
      location: "23. 22108166,78. 5170666",
      "inverted one": "'GOVT. NEW PRIMARY SCHOOL BUILDING DHANAGAVAN'",
      cosolideted:
        "ElectionBooth('GOVT. NEW PRIMARY SCHOOL BUILDING DHANAGAVAN',23. 22108166,78. 5170666)",
      "with comma":
        "ElectionBooth('GOVT. NEW PRIMARY SCHOOL BUILDING DHANAGAVAN',23. 22108166,78. 5170666),",
    },
    {
      "PART NO": 271,
      "PART NAME": "BHANAPUR",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING BHANAPUR",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 673,
      location: "23.205125,78.486614",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING BHANAPUR'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BHANAPUR',23.205125,78.486614)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING BHANAPUR',23.205125,78.486614),",
    },
    {
      "PART NO": 272,
      "PART NAME": "CHANDAN PIPALIYA",
      "Name of polling station building":
        "Integrated G.M.S Bhawan Chandanpipliya",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 941,
      location: "23.2042059,78.5286737",
      "inverted one": "'Integrated G.M.S Bhawan Chandanpipliya'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Chandanpipliya',23.2042059,78.5286737)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Chandanpipliya',23.2042059,78.5286737),",
    },
    {
      "PART NO": 273,
      "PART NAME": "CHILLI",
      "Name of polling station building": "RAJIV GANDHI PRIMARY SCHOOL CHILLI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 944,
      location: "23.193418,78.521489",
      "inverted one": "'RAJIV GANDHI PRIMARY SCHOOL CHILLI'",
      cosolideted:
        "ElectionBooth('RAJIV GANDHI PRIMARY SCHOOL CHILLI',23.193418,78.521489)",
      "with comma":
        "ElectionBooth('RAJIV GANDHI PRIMARY SCHOOL CHILLI',23.193418,78.521489),",
    },
    {
      "PART NO": 274,
      "PART NAME": "VITALI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING VITALI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 763,
      location: "23.1939484,78.5524841",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING VITALI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING VITALI',23.1939484,78.5524841)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING VITALI',23.1939484,78.5524841),",
    },
    {
      "PART NO": 275,
      "PART NAME": "BERU",
      "Name of polling station building": "Integrated G.M.S Bhawan Berua",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 632,
      location: "23.20688135,78.55383416",
      "inverted one": "'Integrated G.M.S Bhawan Berua'",
      cosolideted:
        "ElectionBooth('Integrated G.M.S Bhawan Berua',23.20688135,78.55383416)",
      "with comma":
        "ElectionBooth('Integrated G.M.S Bhawan Berua',23.20688135,78.55383416),",
    },
    {
      "PART NO": 276,
      "PART NAME": "KUNARAKHEDI",
      "Name of polling station building":
        "GOVT. PRIMARY SCHOOL BUILDING KUNARAKHEDI",
      "PS TYPE": "R",
      "BUILDING QUALITY": "Pucca",
      ELECTORS: 904,
      location: "23.19666617,78.5747817",
      "inverted one": "'GOVT. PRIMARY SCHOOL BUILDING KUNARAKHEDI'",
      cosolideted:
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KUNARAKHEDI',23.19666617,78.5747817)",
      "with comma":
        "ElectionBooth('GOVT. PRIMARY SCHOOL BUILDING KUNARAKHEDI',23.19666617,78.5747817),",
    },
  ],
};
