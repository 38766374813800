import { React } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import MyDropdown from "./components/MyDropdown";
import "./App.css";

export default function App() {
  return (
    <>
      <div class="wrapper header-wrapper">
        <div class="container header-container">
          <div class="logo">
            <a
              href="https://raisen.nic.in/en/"
              title="Go to home"
              class="emblem"
              rel="home"
            >
              <img
                class="site_logo"
                height="100"
                id="logo"
                src="https://cdn.s3waas.gov.in/s3c24cd76e1ce41366a4bbe8a49b02a028/uploads/2019/07/20190709100.jpg"
                alt=""
              />
              <div class="logo-text">
                <strong lang="hi" class="site_name_regional">
                  जिला रायसेन
                </strong>
                <h1 class="site_name_english">DISTRICT RAISEN</h1>
              </div>
            </a>
          </div>
          <div class="header-right clearfix">
            <div class="right-content clearfix">
              <div class="float-element">
                <a
                  aria-label="Digital India - External site that opens in a new window"
                  href="http://digitalindia.gov.in/"
                  target="_blank"
                  title="Digital India"
                  onclick="return confirm('You are being redirected to an external website. Please note that District Raisen, Government of Madhya Pradesh cannot be held responsible for external websites content &amp; privacy policies.');"
                  rel="noopener noreferrer"
                >
                  <img
                    class="sw-logo"
                    height="95"
                    src="https://raisen.nic.in/wp-content/themes/district-theme/images/digital-india.png"
                    alt="Digital India"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MyDropdown />
      <footer>Powered by SAR Consultancy Services</footer>
    </>
  );
}
