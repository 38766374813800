import React from "react";
import Map, { Marker } from "react-map-gl";
import { handleMarkerClick, getLat, getLng } from "../utils";

function Maps({ filteredData }) {
  return (
    <Map
      style={{ height: "700px" }}
      initialViewState={{
        longitude: 78.4053936,
        latitude: 23.0971010844,
        zoom: 9,
      }}
      mapStyle="mapbox://styles/mapbox/streets-v10"
    >
      {filteredData.map((item, index) => {
        const latitude = getLat(item.location);
        const longitude = getLng(item.location);

        if (latitude === null || longitude === null) {
          return null;
        }

        return (
          <Marker
            key={index}
            longitude={longitude}
            latitude={latitude}
            anchor="bottom"
            onClick={() => handleMarkerClick(item)}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          >
            <img
              title={`${"Polling Station Number: " + item["PART NO"]}\n${
                item["Name of polling station building"]
              }, ${item["PART NAME"]}`}
              src="./mapbox-marker-icon-20px-red.png"
              alt=""
            />
          </Marker>
        );
      })}
    </Map>
  );
}

export default Maps;
