export const getLat = (latLon) => {
  const latLonArr = latLon.split(",");
  const lat = parseFloat(latLonArr[0]);

  if (isNaN(lat) || lat < -90 || lat > 90) {
    return null;
  }

  return lat;
};

export const getLng = (latLon) => {
  const latLonArr = latLon.split(",");
  const lon = parseFloat(latLonArr[1]);

  if (isNaN(lon) || lon < -180 || lon > 180) {
    return null;
  }

  return lon;
};

export const handleMarkerClick = (item) => {
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${getLat(
    item.location
  )},${getLng(item.location)}`;
  window.open(googleMapsUrl, "_blank"); // Open Google Maps in a new tab
};
