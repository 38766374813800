import React, { useState } from "react";
import { Data } from "../MockData/Data";
import { Form } from "react-bootstrap";
import Maps from "./Maps";
import { handleMarkerClick } from "../utils";

const MyDropdown = () => {
  const fullData = Data;
  const [selectedObject, setSelectedObject] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleSelect = (e) => {
    const selectedObjectName = e.target.value;
    setSelectedObject(selectedObjectName);
    filterData(selectedObjectName, searchQuery);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterData(selectedObject, query);
  };

  const filterData = (assembly, query) => {
    if (assembly) {
      const assemblyData = fullData[assembly];
      const filtered = assemblyData.filter(
        (item) =>
          item["PART NO"].toString().includes(query.toString()) ||
          item["PART NAME"].toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  };

  return (
    <div className="container">
      <div className="left-container">
        <label htmlFor="objectSelect" className="select-label">
          Select Assembly
        </label>
        <br />
        <select
          id="objectSelect"
          onChange={handleSelect}
          value={selectedObject || ""}
          className="select-box"
        >
          <option value="">Select Constituency</option>
          {Object.keys(fullData).map((objectName) => (
            <option key={objectName} value={objectName}>
              {objectName}
            </option>
          ))}
        </select>

        {selectedObject && (
          <div>
            <h3>
              TOTAL <span className="countPs">{filteredData?.length} </span>
              Polling Stations
            </h3>
            <Form>
              <Form.Group controlId="searchInput">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Search Polling Stations"
                    value={searchQuery}
                    onChange={handleSearch}
                    className="search-input form-control"
                  />
                </div>
              </Form.Group>
            </Form>

            <div className="selected-data">
              {filteredData.map((item, index) => (
                <a href="#" key={index} onClick={() => handleMarkerClick(item)}>
                  <ul className="selected-data-list">
                    <li>
                      <p>
                        <span className="head-title">
                          Polling Station Number:
                        </span>{" "}
                        <span className="part-name">{item["PART NO"]}</span>
                      </p>
                      <p>
                        <span className="head-title">
                          Polling Station Name:
                        </span>{" "}
                        <span className="part-name">{item["PART NAME"]}</span>
                      </p>
                      <p>
                        <span className="head-title">
                          Name of Polling Station Building:
                        </span>{" "}
                        <div className="part-name">
                          {item["Name of polling station building"]}
                        </div>
                      </p>
                      <p>
                        <span className="head-title">ELECTORS:</span>{" "}
                        <span className="part-name">{item["ELECTORS"]}</span>
                      </p>
                    </li>
                  </ul>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="right-container">
        <Maps filteredData={filteredData} />
      </div>
    </div>
  );
};

export default MyDropdown;
